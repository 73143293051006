import BaseFunction, {ComponentFunctionError} from "../baseFunction";
import {scriptTableData} from "../scriptTable";
import NineSlice from "../../component/nineSlice";
import * as PIXI from "pixi.js";
import ComponentFunction from "./componentFunction";
import {CheckComp, CheckValueType} from "../scriptError";


export const NineSliceFunctionTable: scriptTableData[] = [
    {
        value: `nineSlice.width`,
        caption: `nineSlice.width`,
        score: 10,
        meta: 'getter,setter',
        name: 'nineSlice.width',
        description: {
            ko: 'NineSlice 의 넓이를 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `nineSlice.height`,
        caption: `nineSlice.height`,
        score: 10,
        meta: 'getter,setter',
        name: 'nineSlice.height',
        description: {
            ko: 'NineSlice 의 높이를 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `nineSlice.tint`,
        caption: `nineSlice.tint`,
        score: 10,
        meta: 'getter,setter',
        name: 'nineSlice.tint',
        description: {
            ko: 'NineSlice 의 틴트색상를 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `nineSlice.alpha`,
        caption: `nineSlice.alpha`,
        score: 10,
        meta: 'getter,setter',
        name: 'nineSlice.alpha',
        description: {
            ko: 'NineSlice 의 투명도를 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `nineSlice.scale`,
        caption: `nineSlice.scale`,
        score: 10,
        meta: 'getter,setter',
        name: 'nineSlice.scale',
        description: {
            ko: 'NineSlice 의 스케일 정보를 가져옵니다.',
            en: '',
        },
    },
]

export default class NineSliceFunction extends ComponentFunction {
    protected _name: string = 'NineSlice';
    protected _temp: NineSlice = null;
    public get instance(): NineSliceFunction {
        this._temp = this.gameObject.getComponent(this._name);
        return this;
    }

    destroy() {
        super.destroy();
        this._temp = null;
    }

    public get width(): number {
        if(!CheckComp(this,'width')) return;
        return this._temp.width;
    }
    public get height(): number {
        if(!CheckComp(this,'height')) return;
        return this._temp.height;
    }
    public set width(v:number) {
        if(!CheckComp(this,'width')) return;
        if(!CheckValueType(this, v, 'number', 'width')) return;
        this._temp.width = v;
    }
    public set height(v:number) {
        if(!CheckComp(this,'height')) return;
        if(!CheckValueType(this, v, 'number', 'height')) return;
        this._temp.height = v;
    }

    public get tint(): number {
        if(!CheckComp(this,'tint')) return;
        return this._temp.tint;
    }
    public set tint(v:number) {
        if(!CheckComp(this,'tint')) return;
        if(!CheckValueType(this, v, 'number', 'tint')) return;
        this._temp.tint = v;
    }

    public get alpha(): number {
        if(!CheckComp(this,'alpha')) return;
        return this._temp.alpha;
    }
    public set alpha(v:number) {
        if(!CheckComp(this,'alpha')) return;
        if(!CheckValueType(this, v, 'number', 'alpha')) return;
        this._temp.alpha = v;
    }
    public get scale(): PIXI.Point {
        if(!CheckComp(this,'scale')) return;
        return this._temp.scale;
    }
    public set scale(scale: {x: number, y: number}) {
        if(!CheckComp(this,'scale')) return;
        this._temp.scale.set(scale.x, scale.y);
    }
}
import BaseFunction, {ComponentFunctionError} from "../baseFunction";
import Sprite from "../../component/sprite";
import * as PIXI from 'pixi.js';
import TextureAsset from "../../asset/textureAsset";
import {scriptTableData} from "../scriptTable";
import ScriptError, {CheckComp, CheckValueType} from "../scriptError";
import {Util} from "../../util/util";
import ComponentFunction from "./componentFunction";

export const SpriteFunctionTable: scriptTableData[] = [
    {
        value: `sprite.flipX`,
        caption: `sprite.flipX`,
        score: 10,
        meta: 'getter,setter',
        name: 'sprite.flipX',
        tooltip: 'const flipX = sprite.flipX\nsprite.flipX = true',
        description: {
            ko: 'Sprite의 좌우반전을 설정하거나 값을 가져옵니다.',
            en: '',
        },
    },
    {
        value: `sprite.flipY`,
        caption: `sprite.flipY`,
        score: 10,
        meta: 'getter,setter',
        name: 'sprite.flipY',
        tooltip: 'const flipY = sprite.flipY\nsprite.flipY = true',
        description: {
            ko: 'Sprite의 상하반전을 설정하거나 값을 가져옵니다.',
            en: '',
        },
    },
    {
        value: `sprite.setFlipX(true);`,
        caption: `sprite.setFlipX(flip: boolean)`,
        score: 10,
        meta: 'function',
        name: 'sprite.setFlipX',
        description: {
            ko: 'Sprite의 좌우반전을 설정합니다.',
            en: '',
        },
    },
    {
        value: `sprite.setFlipY(true);`,
        caption: `sprite.setFlipY(flip: boolean)`,
        score: 10,
        meta: 'function',
        name: 'sprite.setFlipY',
        description: {
            ko: 'Sprite의 상하반전을 설정합니다.',
            en: '',
        },
    },
    {
        value: `sprite.getFlipX()`,
        caption: `sprite.getFlipX(): boolean`,
        score: 10,
        meta: 'function',
        name: 'sprite.getFlipX',
        description: {
            ko: 'Sprite의 좌우반전 값을 가져옵니다.',
            en: '',
        },
    },
    {
        value: `sprite.getFlipY()`,
        caption: `sprite.getFlipY(): boolean`,
        score: 10,
        meta: 'function',
        name: 'sprite.getFlipY',
        description: {
            ko: 'Sprite의 상하반전 값을 가져옵니다.',
            en: '',
        },
    },
    {
        value: `sprite.width`,
        caption: `sprite.width`,
        score: 10,
        meta: 'getter,setter',
        name: 'sprite.width',
        description: {
            ko: 'Sprite의 넓이를 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `sprite.height`,
        caption: `sprite.height`,
        score: 10,
        meta: 'getter,setter',
        name: 'sprite.height',
        description: {
            ko: 'Sprite의 높이를 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `sprite.tint`,
        caption: `sprite.tint`,
        score: 10,
        meta: 'getter,setter',
        name: 'sprite.tint',
        description: {
            ko: 'Sprite의 틴트색상를 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `sprite.alpha`,
        caption: `sprite.alpha`,
        score: 10,
        meta: 'getter,setter',
        name: 'sprite.alpha',
        description: {
            ko: 'Sprite의 투명도를 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `sprite.scale`,
        caption: `sprite.scale`,
        score: 10,
        meta: 'getter,setter',
        name: 'sprite.scale',
        description: {
            ko: 'Sprite의 스케일 정보를 가져옵니다.',
            en: '',
        },
    },
    {
        value: `sprite.anchor`,
        caption: `sprite.anchor`,
        score: 10,
        meta: 'getter,setter',
        name: 'sprite.anchor',
        description: {
            ko: 'Sprite의 앵커 정보를 가져옵니다.',
            en: '',
        },
    },
    {
        value: `sprite.texture`,
        caption: `sprite.texture`,
        score: 10,
        meta: 'getter,setter',
        name: 'sprite.texture',
        description: {
            ko: 'Sprite의 텍스처를 가져오거나 설정합니다.',
            en: '',
        },
    },
];

export default class SpriteFunction extends ComponentFunction {
    protected _name: string = 'Sprite';
    protected _temp: Sprite = null;
    public get instance(): SpriteFunction {
        this._temp = this.gameObject.getComponent(this._name);
        return this;
    }

    destroy() {
        super.destroy();
        this._temp = null;
    }

    public get flipX(): boolean{
        if(!CheckComp(this,'flipX')) return;
        return this._temp.flipX;
    }
    public get flipY(): boolean{
        if(!CheckComp(this,'flipY')) return;
        return this._temp.flipY;
    }

    public set flipX(v: boolean) {
        if(!CheckComp(this,'flipX')) return;
        if(!CheckValueType(this, v, 'boolean', 'flipX')) return;
        this._temp.flipX = v;
    }

    public set flipY(v: boolean) {
        if(!CheckComp(this,'flipY')) return;
        if(!CheckValueType(this, v, 'boolean', 'flipY')) return;
        this._temp.flipY = v;
    }

    public getFlipX(): boolean {
        if(!CheckComp(this,'getFlipX')) return;
        return this._temp.flipX;
    }

    public getFlipY(): boolean {
        if(!CheckComp(this,'getFlipY')) return;
        return this._temp.flipY;
    }

    public setFlipX(v: boolean) {
        if(!CheckComp(this,'setFlipX')) return;
        if(!CheckValueType(this, v, 'boolean', 'setFlipX')) return;
        this._temp.flipX = v;
    }

    public setFlipY(v: boolean) {
        if(!CheckComp(this,'setFlipY')) return;
        if(!CheckValueType(this, v, 'boolean', 'setFlipY')) return;
        this._temp.flipY = v;
    }

    public get width(): number {
        if(!CheckComp(this,'width')) return;
        return this._temp.width;
    }
    public get height(): number {
        if(!CheckComp(this,'height')) return;
        return this._temp.height;
    }
    public set width(v:number) {
        if(!CheckComp(this,'width')) return;
        if(!CheckValueType(this, v, 'number', 'width')) return;
        this._temp.width = v;
    }
    public set height(v:number) {
        if(!CheckComp(this,'height')) return;
        if(!CheckValueType(this, v, 'number', 'height')) return;
        this._temp.height = v;
    }

    public get tint(): number {
        if(!CheckComp(this,'tint')) return;
        return this._temp.tint;
    }
    public set tint(v:number) {
        if(!CheckComp(this,'tint')) return;
        if(!CheckValueType(this, v, 'number', 'tint')) return;
        this._temp.tint = v;
    }

    public get alpha(): number {
        if(!CheckComp(this,'alpha')) return;
        return this._temp.alpha;
    }
    public set alpha(v:number) {
        if(!CheckComp(this,'alpha')) return;
        if(!CheckValueType(this, v, 'number', 'alpha')) return;
        this._temp.alpha = v;
    }
    public get anchor(): PIXI.Point {
        if(!CheckComp(this,'anchor')) return;
        return this._temp.anchor;
    }
    public set anchor(anchor: {x:number, y: number}) {
        if(!CheckComp(this,'anchor')) return;
        this._temp.anchor.set(anchor.x, anchor.y);
    }

    public get scale(): PIXI.Point {
        if(!CheckComp(this,'scale')) return;
        return this._temp.scale;
    }
    public set scale(scale: {x:number, y: number}) {
        if(!CheckComp(this,'scale')) return;
        this._temp.scale.set(scale.x, scale.y);
    }

    public get texture(): TextureAsset {
        if(!CheckComp(this,'texture')) return;
        return this._temp.texture;
    }
    public set texture(v: TextureAsset) {
        if(!CheckComp(this,'texture')) return;
        if(!CheckValueType(this, v, 'Texture', 'texture')) return;
        this._temp.texture = v;
    }

}
import table from '../table/tooltip.json';
import {LocalizeLang} from "../store/editorSettingSlice";
import {DEFAULT_LANG} from "./constSetting";

export default class TooltipManager {
    private static _instance: TooltipManager;
    public static get Instance() {
        if(!TooltipManager._instance) {
            TooltipManager._instance = new TooltipManager();
        }
        return TooltipManager._instance;
    }

    private _table: {[key:string]: {ko:string, en:string}};
    private _lang: LocalizeLang = localStorage.getItem('tooltipLocalize') as LocalizeLang || DEFAULT_LANG;

    constructor() {
        this._table = table as any;
    }

    setLang(lang: LocalizeLang) {
        this._lang = lang;
        localStorage.setItem('tooltipLocalize', lang);
    }

    getText(key: string) : string {
        if(this._table[key]) {
            return this._table[key][this._lang] || '';
        }
        return '';

    }
}
import * as PIXI from 'pixi.js';
import * as PIXISound from '@pixi/sound';
import Asset, {IAssetData} from "./asset";
import Game from "../core/game";

export interface ISoundAssetData extends IAssetData {
    dataUrl : string,
}

export default class SoundAsset extends Asset {
    private _resource: PIXI.LoaderResource;
    protected readonly _type : string = 'Sound';


    constructor(game: Game, loaderResource: PIXI.LoaderResource, uid?:string) {
        super(game, uid || loaderResource?.name);
        this._resource = loaderResource;
    }

    get sound() : PIXISound.Sound {
        return this.resource.sound;
    }

    get resource(): PIXI.LoaderResource {
        return this._resource;
    }

    get isPlaying(): boolean {
        if(!this.sound) {
            return false;
        }

        return this.sound.isPlaying;
    }

    play(isLoop: boolean = false, volume: number = 1) {
        if(!this.sound) {
            return;
        }

        if(this.sound.isPlaying) {
           this.sound.stop();
        }
        this.sound.loop = isLoop;
        this.sound.volume = volume;
        this.sound.play();
    }

    stop() {
        if(!this.sound) {
            return;
        }

        this.sound.stop();
    }



    destroy() {
        const resources = this.game.loader.resources;
        super.destroy();

        this._resource.sound?.destroy();
        delete resources[this.id];
        this._resource = null;
    }

    toPlayData() : ISoundAssetData {
        const json = super.toPlayData() as ISoundAssetData;
        json.dataUrl = this.resource.url;
        return json;
    }
}
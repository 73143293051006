import {Menu, Typography, Tag, Divider} from "antd";
import EditorManager from "../../../services/editorManager";
import {treeManager} from "./hierarchy";
import GameObject, {editable} from "../../../game/scripts/gameObject/gameObject";
import Sprite from "../../../game/scripts/component/sprite";
import TextComp from "../../../game/scripts/component/text";
import Shape from "../../../game/scripts/component/shape";
import Button from "../../../game/scripts/component/button";
import {
    CreateButton,
    CreateNineSlice,
    CreateShape,
    CreateSprite,
    CreateText
} from "../../../services/factory/createSample";
import LocalizationManager from "../../../services/localizationManager";


const {Text} = Typography;

const buttonStyle = {
    display: 'flex',
    width: '220px',
}

const growStyle = {
    flexGrow: '1'
}

const tagStyle = {
    margin: 0,
}

export default function HierarchyContextMenu() {


    let itemCount = 0;

    return (<Menu items={[
        {
            key: itemCount++,
            label: (<div onClick={function (){
                const selectGo = EditorManager.Instance.getSelectObject();
                if(selectGo && editable(selectGo, 'position')) {
                    const json = selectGo.toJson(true);
                    json.position.x = 0;
                    json.position.y = 0;
                    json.rotation = 0;
                    json.scale.x = 1;
                    json.scale.y = 1;
                    EditorManager.Instance.modifyGameObject( json, true );
                }
            }} style={buttonStyle}>
                <Text>{LocalizationManager.Instance.getText('HierarchyContextMenu.ResetTransform') || 'Reset Transform'}</Text>
            </div>),
        },
        {
            key: itemCount++,
            label: (<div onClick={function (){
                const selectGo = EditorManager.Instance.getSelectObject();
                if(selectGo && editable(selectGo, 'name')) {
                    treeManager.tree?.edit( selectGo.id.toString() );
                }
            }} style={buttonStyle}>
                <Text>{LocalizationManager.Instance.getText('HierarchyContextMenu.Rename') || 'Rename'}</Text>
                <div style={growStyle}></div>
                <div>
                    <Tag color="default" style={tagStyle}>F2</Tag>
                </div>
            </div>),
        },
        {
            type: 'divider',
        },
        {
            key: itemCount++,
            label: (<div onClick={function (){
                EditorManager.Instance.removeGameObject();
            }} style={buttonStyle}>
                <Text>{LocalizationManager.Instance.getText('HierarchyContextMenu.Remove') || 'Remove'}</Text>
                <div style={growStyle}></div>
                <div>
                    <Tag color="default" style={tagStyle}>Del</Tag>
                </div>
            </div>),
        },
        {
            key: itemCount++, label: (
                <div onClick={function (){
                    EditorManager.Instance.cloneGameObject();
                }} style={buttonStyle}>
                    <Text>{LocalizationManager.Instance.getText('HierarchyContextMenu.Clone') || 'Clone'}</Text>
                    <div style={growStyle}></div>
                    <div>
                        <Tag color="default" style={tagStyle}>Ctrl</Tag>
                        <Text>+</Text>
                        <Tag color="default" style={tagStyle}>D</Tag>
                    </div>
                </div>)
        },
        {
            key: itemCount++, label: (
                <div onClick={function (){
                    EditorManager.Instance.cloneGameObject(true);
                }} style={buttonStyle}>
                    <Text>{LocalizationManager.Instance.getText('HierarchyContextMenu.CloneDisconnect') || 'Clone(Disconnect Prefab)'}</Text>
                </div>)
        },
        {
            key: itemCount++, label: (
                <div onClick={function (){
                    EditorManager.Instance.copyHierarchyNode();
                }} style={buttonStyle}>
                    <Text>{LocalizationManager.Instance.getText('HierarchyContextMenu.Copy') || 'Copy'}</Text>
                    <div style={growStyle}></div>
                    <div>
                        <Tag color="default" style={tagStyle}>Ctrl</Tag>
                        <Text>+</Text>
                        <Tag color="default" style={tagStyle}>C</Tag>
                    </div>
                </div>)
        },
        {
            key: itemCount++, label: (
                <div onClick={function (){
                    EditorManager.Instance.paste();
                }} style={buttonStyle}>
                    <Text>{LocalizationManager.Instance.getText('HierarchyContextMenu.Paste') || 'Paste'}</Text>
                    <div style={growStyle}></div>
                    <div>
                        <Tag color="default" style={tagStyle}>Ctrl</Tag>
                        <Text>+</Text>
                        <Tag color="default" style={tagStyle}>V</Tag>
                    </div>
                </div>)
        },
        {
            type: 'divider',
        },
        {
            key: itemCount++,
            label: (<div onClick={function (){
                EditorManager.Instance.createPrefab();
            }} style={buttonStyle}>
                <Text>{LocalizationManager.Instance.getText('HierarchyContextMenu.CreatePrefab') || 'Create Prefab'}</Text>
            </div>)
        },
        {
            key: itemCount++,
            label: (<div onClick={function (){
                const selectGo = EditorManager.Instance.getSelectObject();
                EditorManager.Instance.updatePrefab(selectGo);
            }} style={buttonStyle}>
                <Text>{LocalizationManager.Instance.getText('HierarchyContextMenu.ApplyPrefab') || 'Apply Prefab'}</Text>
            </div>)
        },
        {
            type: 'divider',
        },
        {
            key: itemCount++,
            label: (<div style={{minWidth: '100px'}} onClick={function (){
                EditorManager.Instance.addGameObject();
            }}>{LocalizationManager.Instance.getText('HierarchyContextMenu.CreateGameObject') || 'Create Instance'}</div>)
        },
        {
            key: itemCount++,
            label: (<div style={{minWidth: '100px'}} onClick={function (){
                CreateSprite();
            }}>{LocalizationManager.Instance.getText('HierarchyContextMenu.Sprite') || 'Sprite'}</div>)
        },
        {
            key: itemCount++,
            label: (<div style={{minWidth: '100px'}} onClick={function (){
               CreateText();
            }}>{LocalizationManager.Instance.getText('HierarchyContextMenu.Text') || 'Text'}</div>)
        },
        {
            key: itemCount++,
            label: (<div style={{minWidth: '100px'}} onClick={function (){
                CreateShape();
            }}>{LocalizationManager.Instance.getText('HierarchyContextMenu.Shape') || 'Shape'}</div>)
        },
        {
            key: itemCount++,
            label: (<div style={{minWidth: '100px'}} onClick={function (){
                CreateButton();
            }}>{LocalizationManager.Instance.getText('HierarchyContextMenu.Button') || 'Button'}</div>)
        },
        {
            key: itemCount++,
            label: (<div style={{minWidth: '100px'}} onClick={function (){
                CreateNineSlice();
            }}>{LocalizationManager.Instance.getText('HierarchyContextMenu.NineSlice') || 'NineSlice'}</div>)
        },
    ]}>
    </Menu>)
}
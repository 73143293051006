import {
    Button,
    Modal,
    Image,
    Space,
    Row,
    Col,
    Typography,
    Checkbox,
    Dropdown,
    Menu,
    Popconfirm,
    Tabs,
    Badge
} from 'antd';
import {useEffect, useMemo, useState} from "react";
import {useAppSelector} from "../../app/hooks";
import {
    changeOnClickAsset,
    changeOnSelectAsset,
    changeOpenImageList, changeSelectAssetId,
    selectOnClickAsset, selectOnSelectAsset, selectOnSelectOk,
    selectOpenImageList, selectSelectAssetId
} from "../../store/modalSlice";
import {useDispatch} from "react-redux";
import EditorManager from "../../services/editorManager";
import {
    DeleteOutlined,
    DeleteFilled, DeleteTwoTone, SettingFilled, EditFilled,
    TagOutlined
} from '@ant-design/icons';
import AssetItem from "./assetItem";
import {store} from "../../app/store";
import * as React from "react";
import TextureAsset from "mogera-game/scripts/asset/textureAsset";
import {selectTextureTagList, changeTextureTagList} from "../../store/editorSlice";
import ImageSelect from "./control/imageSelect";
import {BYTE_TO_MEGABYTE, UPLOAD_IMAGE_MAX_SIZE} from "../../services/constSetting";
import LocalizationManager from "../../services/localizationManager";

const {Text} = Typography;
const { TabPane } = Tabs;

function selectCount(list: {[key:string]: boolean}) : number {
    let count = 0;
    for(let item in list) {
        if(list[item]) {
            count++;
        }
    }
    return count;
}

export default function ImageList() {

    const isOpen = useAppSelector(selectOpenImageList);
    const selectId = useAppSelector(selectSelectAssetId);
    const onClickImgFunc = selectOnClickAsset();
    const onSelectImgFunc = selectOnSelectAsset();


    const imageSelect = ImageSelect(()=>{
        for(let id in selectList) {
            selectList[id] = false;
        }
        for(let i = 0; i < imageList.length; i++) {
            const a = imageList[i];
            if( activeTag !== 'All' && a.tag !== activeTag ) {
                continue;
            }
            selectList[a.id] = true;
        }
        setSelectList({...selectList});
    });

    const dispatch = useDispatch();

    const handleOk = () => {
        dispatch(changeOpenImageList(false));
    };

    const handleCancel = () => {
        dispatch(changeOpenImageList(false));
    };

    const afterClose = () => {
        const onClickImgFunc = selectOnClickAsset()
        onClickImgFunc && onClickImgFunc(null);
        changeOnClickAsset(null);
        changeOnSelectAsset(null);
        setSelectList({});
        dispatch(changeSelectAssetId(''));
        imageSelect.setLastData(null);
    }

    const imageList = EditorManager.Instance.assetManager.getTextureAssetList();
    imageList.sort(function (a, b) {
        if (a.name > b.name) return 1;
        else if (b.name > a.name) return -1;
        else return 0;
    });
    const [initList, _setImageList] = useState(imageList);
    const [selectList, setSelectList ] = useState<{[id:string]:boolean}>({});

    function setImageList(list: any[]) {
        list.sort(function (a, b) {
            if (a.name > b.name) return 1;
            else if (b.name > a.name) return -1;
            else return 0;
        });
        _setImageList(list);
    }

    const [activeTag, setActiveTag] = useState('All');
    const tagList = useAppSelector(selectTextureTagList);
    const tagItems = useMemo(function (){
        return createTagItem(tagList);
    }, [tagList]);
    // const [tagItems, setTagItems] = useState(createTagItem([...tagList]));

    const addImage = () => {
        const input = document.createElement('input') as HTMLInputElement;
        input.type = 'file';
        input.accept = 'image/*';
        input.multiple = true;
        input.click();

        let overflowSize = false;

        input.onchange = async ()=> {
            const files = input.files
            for(let i = 0; i < files.length; i++) {
                const file = files[i];
                if(file.size > UPLOAD_IMAGE_MAX_SIZE) {
                    overflowSize = true;
                    continue;
                }
                if(file) {
                    await new Promise<void>((resolve)=>{
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL( file );
                        fileReader.addEventListener('load', async ()=>{
                            const dataUrl = fileReader.result as string;
                            const name = file.name.split('.')[0];
                            const asset = await EditorManager.Instance.addImage(dataUrl);
                            asset.rename(EditorManager.Instance.assetManager.getVerifiedName(name, asset.type));
                            if(activeTag !== 'All') {
                                asset.tag = activeTag;
                            }
                            const list = EditorManager.Instance.assetManager.getTextureAssetList();
                            setImageList([...list]);
                            resolve();
                        });
                    });
                }
            }

            if(overflowSize) {
                confirm( LocalizationManager.Instance.getText('Popup.failUploadImg', Math.round(UPLOAD_IMAGE_MAX_SIZE/BYTE_TO_MEGABYTE).toString()))
            }
        };
    };


    let listRender : any[] = [];
    createAssetList();

    function createAssetList() {
        for(let i = 0; i < imageList.length; i++) {
            const asset = imageList[i];
            if( activeTag !== 'All' && asset.tag !== activeTag ) {
                continue;
            }

            const resource = asset.resource;
            const render =<AssetItem key={asset.id}
                                     tag={asset.tag}
                                     select={selectList[asset.id] || selectId === asset.id}
                                     asset={asset}
                                     span={6}
                                     menu={<Menu items={[
                                         {
                                             key: '1', label: <Popconfirm
                                                 title={ onSelectImgFunc && selectCount(selectList) && selectList[asset.id] ?
                                                     LocalizationManager.Instance.getText('Popup.confirmRemoveImg')
                                                     : LocalizationManager.Instance.getText('Popup.confirmRemove', asset.name)}
                                                 onConfirm={function (event){
                                                     event.preventDefault();
                                                     event.stopPropagation();

                                                     if(onSelectImgFunc && selectCount(selectList) && selectList[asset.id] ) {
                                                         for(let item in selectList) {
                                                             if(selectList[item]) {
                                                                 const itemAsset = EditorManager.Instance.assetManager.getAsset(item) as TextureAsset;
                                                                 const idx = imageList.indexOf(itemAsset);
                                                                 if(idx > -1) {
                                                                     imageList.splice( idx, 1 );
                                                                 }
                                                                 EditorManager.Instance.removeImage( item );
                                                                 selectList[item] = false;
                                                             }
                                                         }
                                                         setSelectList({...selectList});
                                                         setImageList([...imageList]);
                                                     }
                                                     else {
                                                         imageList.splice( imageList.indexOf(asset), 1 );
                                                         EditorManager.Instance.removeImage( asset.id );
                                                         setImageList([...imageList]);
                                                     }
                                                 }}
                                                 onCancel={function (event){
                                                     event.preventDefault();
                                                     event.stopPropagation();
                                                 }}
                                                 okText="제거"
                                                 cancelText="취소"
                                             >
                                                 <div><DeleteFilled style={{marginRight:'10px'}}/>
                                                     {LocalizationManager.Instance.getText('ContextMenu.remove') || 'Remove'}
                                                 </div>
                                             </Popconfirm>
                                         },
                                         {
                                             key: '2', label: <div onClick={function (){
                                                 const name = prompt(LocalizationManager.Instance.getText('Popup.changeName'), asset.name);
                                                 if(name === asset.name || !name) {
                                                     return;
                                                 }

                                                 if( EditorManager.Instance.assetManager.getAsset(name, asset.type) ) {
                                                     window.confirm(LocalizationManager.Instance.getText('Popup.failChangeName'));
                                                 }
                                                 else {
                                                     asset.rename(name);
                                                     setImageList([...imageList]);
                                                 }
                                             }}><EditFilled style={{marginRight:'10px'}}/>
                                                 {LocalizationManager.Instance.getText('ContextMenu.rename') || 'Rename'}
                                             </div>,
                                         },
                                         {
                                             key: '3',
                                             label: <span><TagOutlined style={{marginRight:'10px'}}/>
                                                 {LocalizationManager.Instance.getText('ContextMenu.tag') || 'Tag'}
                                             </span>,
                                             children: [
                                                 ...tagList.map((tag)=>{
                                                         return {
                                                             key: tag,
                                                             label: <div onClick={function (){
                                                                 if(onSelectImgFunc && selectCount(selectList) && selectList[asset.id] ) {
                                                                     for(let item in selectList) {
                                                                         if(selectList[item]) {
                                                                             const itemAsset = EditorManager.Instance.assetManager.getAsset(item) as TextureAsset;
                                                                             itemAsset.tag = tag;
                                                                         }
                                                                     }
                                                                     createAssetList();
                                                                     setImageList([...imageList]);
                                                                     setSelectList({});
                                                                 }
                                                                 else {
                                                                     asset.tag = tag;
                                                                     createAssetList();
                                                                     setImageList([...imageList]);
                                                                 }
                                                             }}>{tag}</div>,
                                                         }
                                                     })
                                             ]
                                         },
                                     ]}/>}
                                     selectable={!!onSelectImgFunc}
                                     onClick={function (){
                                         if(onClickImgFunc) {
                                             onClickImgFunc(asset.id);
                                         }
                                         else if( onSelectImgFunc ) {
                                             if( imageSelect.isDownCtrl() ) {
                                                 selectList[asset.id] = !selectList[asset.id];
                                                 imageSelect.setLastData(asset);
                                                 setSelectList({...selectList});
                                             }
                                             else if(imageSelect.isDownShift()) {
                                                 let lastSelect = imageSelect.getLastData();
                                                 if(!lastSelect) {
                                                     for(let j = 0; j < imageList.length; j++) {
                                                         const a = imageList[j];
                                                         if( activeTag !== 'All' && a.tag !== activeTag ) {
                                                             continue;
                                                         }
                                                         lastSelect = a;
                                                         break;
                                                     }
                                                 }

                                                 if(!lastSelect) {
                                                     return;
                                                 }

                                                 const sIdx = imageList.indexOf(asset);
                                                 const dIdx = imageList.indexOf(lastSelect);

                                                 const s = sIdx < dIdx ? sIdx : dIdx;
                                                 const d = sIdx < dIdx ? dIdx : sIdx;
                                                 for(let id in selectList) {
                                                     if(id === asset.id) continue;
                                                     selectList[id] = false;
                                                 }
                                                 for(let j = s; j <= d; j++) {
                                                    const a = imageList[j];
                                                     if( activeTag !== 'All' && a.tag !== activeTag ) {
                                                         continue;
                                                     }
                                                     selectList[a.id] = true;
                                                 }
                                                 setSelectList({...selectList});
                                             }
                                             else {
                                                 for(let id in selectList) {
                                                     if(id === asset.id) continue;
                                                     selectList[id] = false;
                                                 }
                                                 selectList[asset.id] = true;
                                                 imageSelect.setLastData(asset);
                                                 setSelectList({...selectList});
                                             }
                                         }
                                     }}
            >
                {
                    asset.tag?  <Badge.Ribbon text={asset.tag} color={'#2d394c'} placement={"start"}>
                        <img src={resource.url}
                             height={80}
                             className={'image'}
                             draggable={false}
                             style={{
                                 userSelect: 'none',
                                 maxWidth: '80px',
                             }}
                        />
                    </Badge.Ribbon> :
                        <img src={resource.url}
                             height={80}
                             className={'image'}
                             draggable={false}
                             style={{
                                 userSelect: 'none',
                                 maxWidth: '80px',
                             }}
                        />
                }

            </AssetItem>
            listRender.push(render);
        }
    }

    const blankLength = listRender.length % 4;
    if(blankLength) {
        for(let i = 0; i < 4- blankLength; i++) {
            listRender.push(<Col
                key={(listRender.length + i).toString()}
                className="gutter-row" span={6}/>)
        }
    }


    const footer = [ <Button key="back" onClick={addImage}>
        {LocalizationManager.Instance.getText('AssetList.Texture.add') || 'Add Texture'}
    </Button>];

    if( onSelectImgFunc !== null && selectOnSelectOk() ) {
        footer.push(<Button key={'ok'} onClick={function (){
            const selectIds = [];
            for(let id in selectList) {
                if(selectList[id]) {
                    selectIds.push(id);
                }
            }
            if(selectIds.length) {
                selectIds.sort(function (a, b){
                    const i1 = EditorManager.Instance.assetManager.getAsset(a);
                    const i2 = EditorManager.Instance.assetManager.getAsset(b);
                    if (i1.name < i2.name) {
                        return -1;
                    }
                    if (i1.name > i2.name) {
                        return 1;
                    }
                    return 0;
                });
                onSelectImgFunc(selectIds);
            }
            else {
                handleCancel();
            }
        }}>선택 완료</Button>)
    }






    function createTagItem(list : any[]) {
        const _tagItems : {title: string, key: string, closable?: boolean}[] = [
            {title: 'All', key: 'All',closable: false},
        ];
        list.forEach(tag=>{
            _tagItems.push({
                title: tag, key: tag,
            })
        });
        return _tagItems;
    }

    const onChange = (newActiveKey: string) => {
        imageSelect.setLastData(null);
        setActiveTag(newActiveKey);
        createAssetList();
    };

    const add = () => {
        const newTag = (prompt(LocalizationManager.Instance.getText('Popup.addTag')) || '').trim();
        if(!newTag ) {
            window.confirm(LocalizationManager.Instance.getText('Popup.failChangeName2'));
            return;
        }

        if(tagList.indexOf(newTag) > -1) {
            window.confirm(LocalizationManager.Instance.getText('Popup.failChangeName'));
            return;
        }

        const list = [...tagList, newTag];
        // setTagItems(createTagItem(list));
        dispatch(changeTextureTagList(list));
    };

    const remove = (targetKey: string) => {
        const list = [...tagList];
        const idx = list.indexOf(targetKey);
        list.splice(idx, 1);
        // setTagItems(createTagItem(list));

        const imageList = EditorManager.Instance.assetManager.getTextureAssetList();
        for(let i = 0; i < imageList.length; i++) {
            if(imageList[i].tag === targetKey) {
                imageList[i].tag = '';
            }
        }

        dispatch(changeTextureTagList(list));
        if(targetKey === activeTag) {
            setActiveTag('All');
            createAssetList();
        }
    };

    const onEdit = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: 'add' | 'remove') => {
        if (action === 'add') {
            add();
        } else {
            remove(targetKey as string);
        }
    };


    return( <Modal title={LocalizationManager.Instance.getText('AssetList.Texture.title') || 'Texture List'}
                   visible={isOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   afterClose={afterClose}
                   zIndex={1002}
                   footer={footer}
    >
        <Tabs type="editable-card" onChange={onChange} activeKey={activeTag} onEdit={onEdit}>
            {tagItems.map(tagItem => (
                <TabPane tab={tagItem.title} key={tagItem.key} closable={tagItem.closable}>
                    <Row gutter={[12,12]} justify={'center'}>
                        {imageList.length ? listRender : <div>{LocalizationManager.Instance.getText('AssetList.Texture.empty') || 'empty'}</div>}
                    </Row>
                </TabPane>
            ))}
        </Tabs>
    </Modal>)
}
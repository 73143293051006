import React from 'react';
import './App.css';
import 'antd/dist/antd.dark.css';
import Editor from "./pages/editor";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Play from "./pages/play";
import Wizard from "./pages/wizard/wizard";
import Shooting from "./pages/wizard/shooting";
import Platform from "./pages/wizard/platform";
// import Wizard from "./pages/wizard/eu/wizard";
// import Shooting from "./pages/wizard/eu/shooting";
// import Platform from "./pages/wizard/eu/platform";
import Running from './pages/wizard/running';
import Match3 from "./pages/wizard/match3";
import RouteChangeTracker from "./routeChangeTracker";
// import Chatbot from "./components/modal/chatbot";
import Chatbot from "./chatbot";

//RouteChangeTracker();

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={
                        // <>
                        //     <Theme></Theme>
                        //     <div>
                        //         <Editor></Editor>
                        //     </div>
                        // </>
                        <>
                            <Editor></Editor>
                            <Chatbot></Chatbot>
                        </>
                        }></Route>
                    <Route path="/play" element={
                        <>
                            <Play></Play>
                        </>
                    }></Route>
                    <Route path="/wizard" element={
                        <>
                            <Wizard></Wizard>
                            <Chatbot></Chatbot>
                        </>
                    }></Route>
                    <Route path="/wizard/running" element={
                        <>
                            <Running></Running>
                            <Chatbot></Chatbot>
                        </>
                    }></Route>
                    <Route path="/wizard/shooting" element={
                        <>
                            <Shooting></Shooting>
                            <Chatbot></Chatbot>
                        </>
                    }></Route>
                    <Route path="/wizard/platform" element={
                        <>
                            <Platform></Platform>
                            <Chatbot></Chatbot>
                        </>
                    }></Route>
                    <Route path="/wizard/match3" element={
                        <>
                            <Match3></Match3>
                            <Chatbot></Chatbot>
                        </>
                    }></Route>
                </Routes>
            </BrowserRouter>
            {/*<Chatbot></Chatbot>*/}
        </div>
    );
}

export default App;

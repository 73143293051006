import {Button, Modal, Image, Space, Row, Col, Menu, Popconfirm, InputNumber, Checkbox} from 'antd';
import {useEffect, useMemo, useState} from "react";
import {useAppSelector} from "../../app/hooks";
import {
    changeSelectAnimatorIds,
    changeSelectPreviewAnimationInfo,
    selectSelectPreviewAnimationInfo
} from "../../store/modalSlice";
import {useDispatch} from "react-redux";
import EditorManager from "../../services/editorManager";
import {
    DeleteOutlined,
    FileOutlined,
    DeleteTwoTone,
    DeleteFilled,
    SettingFilled,
    CaretRightOutlined,
    PauseOutlined,
} from '@ant-design/icons';
import AnimatorAsset from "mogera-game/scripts/asset/animatorAsset";
import * as React from "react";
import * as PIXI from 'pixi.js';
import LocalizationManager from "../../services/localizationManager";
import TextureAsset from "mogera-game/scripts/asset/textureAsset";
import {store} from "../../app/store";
import {Slider} from "@mui/material";

class AnimationPreviewGame {
    private renderer : PIXI.Renderer;
    private ticker: PIXI.Ticker = new PIXI.Ticker();
    private stage: PIXI.Container = new PIXI.Container();
    private sprite: PIXI.Sprite = new PIXI.Sprite();

    public interval: number = 0;
    public textures: PIXI.Texture[] = [];
    public loop: boolean = false;

    private isPause: boolean = false;

    public onChangePauseCallback: (isPause:boolean)=>void = null;

    private idx : number = 0;
    private time : number = 0;

    constructor(canvas : HTMLCanvasElement = null) {
        const width = 800;
        const height = 600;

        this.renderer = PIXI.autoDetectRenderer({ width, height, view: canvas }) as PIXI.Renderer;
        this.sprite.anchor.set(0.5, 0.5);
        this.sprite.position.set(width/2, height/2);
        this.stage.addChild(this.sprite);
        this.ticker.autoStart = false;
        this.ticker.add((dt)=>{
            const delta = dt / 60;

            if(this.isPause) return;

            this.time += delta;
            if( this.time >= this.interval ) {
                this.time = 0;
                this.idx++;
                if(this.idx >= this.textures.length) {
                    if(this.loop) {
                        this.idx = 0;
                        this.sprite.texture = this.textures[this.idx] || null;
                    }
                    else {
                        this.stop();
                    }
                }
                else {
                    this.sprite.texture = this.textures[this.idx] || null;
                }
            }

            this.renderer.render(this.stage);
        })
    }

    getCanvas() : HTMLCanvasElement {
        return this.renderer.view;
    }

    setScale(v: number) {
        this.sprite.scale.set(v, v);
    }

    start() {
        this.ticker.start();
        this.idx = 0;
        this.time = 0;
        this.isPause = false;
        this.sprite.texture = this.textures[this.idx] || null;
        this.onChangePauseCallback && this.onChangePauseCallback(this.isPause);
    }

    pause() {
        this.isPause = true;
        this.onChangePauseCallback && this.onChangePauseCallback(this.isPause);
    }

    resume() {
        this.isPause = false;
        this.onChangePauseCallback && this.onChangePauseCallback(this.isPause);
    }

    started() : boolean {
        return this.ticker.started;
    }

    stop() {
        this.ticker.stop();
        this.isPause = false;
        this.onChangePauseCallback && this.onChangePauseCallback(true);
    }

    setVisible(visible: boolean) {
        this.sprite.visible = visible;
    }

    destroy() {
        this.ticker.stop();
        this.ticker.destroy();
        this.ticker = null;
        this.stage.destroy();
        this.stage = null;
        this.sprite = null;
        this.renderer.destroy(true);
        this.renderer = null;
    }
}

let previewGame: AnimationPreviewGame = null;

export default function AnimationPreview() {

    const animationInfo = useAppSelector(selectSelectPreviewAnimationInfo);
    const [isOpen, setIsOpen] = useState(false)
    const [isPlay, setIsPlay] = useState(true);
    const [animator, setAnimator] = useState(null);
    const [animation, setAnimation] = useState(null);
    const [scale, setScale] = useState(1);



    useMemo(function (){
        const _animator = EditorManager.Instance.assetManager.getAsset(animationInfo?.animatorId) as AnimatorAsset;
        const _animation = _animator?.json.animations[animationInfo?.animationIdx];

        if( animationInfo ) {
            const frames = _animation.frames;
            const textures = [];

            for(let i = 0; i < frames.length; i++) {
                const tex = EditorManager.Instance.assetManager.getAsset(frames[i]) as TextureAsset;
                textures.push(tex.resource.texture);
            }

            previewGame.interval = _animation.interval;
            previewGame.textures = textures;
            previewGame.loop = _animation.loop;
            previewGame.setScale(1);
            previewGame.setVisible(true);
            previewGame.start();
        }

        setScale(1);
        setAnimator( _animator );
        setAnimation( _animation );
        setIsOpen(!!animationInfo);
    }, [animationInfo]);

    const dispatch = useDispatch();

    const handleOk = () => {
        previewGame.setVisible(false);
        previewGame.stop();
        setIsOpen(false);
        dispatch(changeSelectPreviewAnimationInfo(null));
        dispatch(changeSelectAnimatorIds([...store.getState().modal.selectAnimatorIds]));
    };

    const handleCancel = () => {
        previewGame.setVisible(false);
        previewGame.stop();
        setIsOpen(false);
        dispatch(changeSelectPreviewAnimationInfo(null));
        dispatch(changeSelectAnimatorIds([...store.getState().modal.selectAnimatorIds]));
    };

    useEffect(function (){
        if(!previewGame) {
            previewGame = new AnimationPreviewGame();
            previewGame.onChangePauseCallback = function (isPause) {
                setIsPlay(!isPause);
            }
        }

        return function (){
            // previewGame.destroy();
        }
    }, []);

    return(<>
        <Modal title={animator?.name + ' - ' + animation?.name}
               width={848}
               visible={isOpen}
               afterClose={function (){
                   //
               }}
               onOk={handleOk}
               onCancel={handleCancel}
               zIndex={1002}
               footer={
                   animator ? <div style={{display:'flex'}}>
                       <Space style={{marginLeft: '20px'}}>
                           <span>{LocalizationManager.Instance.getText('AnimationEditor.interval')}</span>
                           <InputNumber onChange={function (num){
                               animation.interval = num;
                               previewGame.interval = animation.interval;
                               setAnimation({...animation} );
                           }} value={animation?.interval} style={{width:80}} min={0.01} step={0.01}></InputNumber>
                       </Space>
                       <Space style={{marginLeft: '20px'}}>
                           <Checkbox checked={animation.loop} onChange={function (e){
                               animation.loop = e.target.checked;
                               previewGame.loop = animation.loop;
                               setAnimation({...animation} );
                           }}>{LocalizationManager.Instance.getText('AnimationEditor.loop')}</Checkbox>
                       </Space>
                       <Space style={{marginLeft: '20px'}}>
                           <Button onClick={function (){
                               previewGame.start();
                           }}><CaretRightOutlined></CaretRightOutlined>재시작</Button>
                       </Space>
                       <Space style={{marginLeft: '20px'}}>
                           {
                               isPlay ? <Button onClick={function (){
                                       previewGame.pause();
                                   }}><PauseOutlined style={{marginRight:'5px'}}></PauseOutlined>정지</Button> :
                                   <Button onClick={function (){
                                       if(previewGame.started()) {
                                           previewGame.resume();
                                       }
                                       else {
                                           previewGame.start();
                                       }
                                   }}><CaretRightOutlined style={{marginRight:'5px'}}></CaretRightOutlined>재생</Button>
                           }
                       </Space>
                   </div> : null
               }
        >
            <>
                <div style={{display:'flex'}}>
                    <div style={{flexGrow: 1}}></div>
                    <Slider style={{width: '200px', marginRight: '20px'}} value={scale} min={0.1} max={3} step={0.01} onChange={function (event, value, activeThumb){
                        const v = value as number;
                        setScale(v);
                        previewGame.setScale(v);
                    }}></Slider>
                    <div style={{width:'50px', textAlign:'center'}}>{`${Math.round(scale * 100)}%`}</div>
                </div>
                <div ref={function (instance){
                    instance?.appendChild(previewGame.getCanvas());
                }}>

                </div>
            </>
        </Modal>
    </>)
}
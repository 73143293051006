import table from '../table/localization.json';

import {LocalizeLang} from "../store/editorSettingSlice";
import {DEFAULT_LANG} from "./constSetting";

export default class LocalizationManager {
    private static _instance: LocalizationManager;
    public static get Instance() {
        if(!LocalizationManager._instance) {
            LocalizationManager._instance = new LocalizationManager();
        }
        return LocalizationManager._instance;
    }

    private _table: {[key:string]: {ko:string, en:string}};
    private _lang: LocalizeLang = localStorage.getItem('textLocalize') as LocalizeLang || DEFAULT_LANG;

    constructor() {
        this._table = table as any;
    }

    setLang(lang: LocalizeLang) {
        this._lang = lang;
        localStorage.setItem('textLocalize', lang);
    }

    // getText(key: string) : string {
    //     if(this._table[key]) {
    //         return this._table[key][this._lang] || '';
    //     }
    //     return '';
    // }

    getText(key: string, ...args: string[]) : string {
        if(this._table[key]) {
            let str = this._table[key][this._lang] || '';
            str = str.replace(/\${(\d+)}/g, function(match, number) {
                return typeof args[number] != 'undefined'
                    ? args[number]
                    : match;
            });
            return str;
        }
        return '';
    }
}
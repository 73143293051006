import BaseFunction, {ComponentFunctionError} from "../baseFunction";
import {scriptTableData} from "../scriptTable";
import Shape from "../../component/shape";
import ComponentFunction from "./componentFunction";
import {CheckComp, CheckValueType} from "../scriptError";


export const ShapeFunctionTable: scriptTableData[] = [
    {
        value: `shape.width;`,
        caption: `shape.width`,
        score: 10,
        meta: 'getter,setter',
        name: 'shape.width',
        description: {
            ko: 'Shape 의 넓이를 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `shape.radius;`,
        caption: `shape.radius`,
        score: 10,
        meta: 'getter,setter',
        name: 'shape.radius',
        description: {
            ko: 'Shape 의  반지름 크기를 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `shape.height`,
        caption: `shape.height`,
        score: 10,
        meta: 'getter,setter',
        name: 'shape.height',
        description: {
            ko: 'Shape 의 높이를 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `shape.color`,
        caption: `shape.color`,
        score: 10,
        meta: 'getter,setter',
        name: 'shape.color',
        description: {
            ko: 'Shape 의 색상를 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `shape.alpha`,
        caption: `shape.alpha`,
        score: 10,
        meta: 'getter,setter',
        name: 'shape.alpha',
        description: {
            ko: 'Shape 의 투명도를 가져오거나 설정합니다.',
            en: '',
        },
    },
]

export default class ShapeFunction extends ComponentFunction {
    protected _name: string = 'Shape';
    protected _temp: Shape = null;
    public get instance(): ShapeFunction {
        this._temp = this.gameObject.getComponent(this._name);
        return this;
    }

    destroy() {
        super.destroy();
        this._temp = null;
    }

    public get width(): number {
        if(!CheckComp(this,'width')) return;
        return this._temp.width;
    }
    public get height(): number {
        if(!CheckComp(this,'height')) return;
        return this._temp.height;
    }
    public set width(v:number) {
        if(!CheckComp(this,'width')) return;
        if(!CheckValueType(this,v, 'number', 'width')) return;
        this._temp.width = v;
    }
    public set height(v:number) {
        if(!CheckComp(this,'height')) return;
        if(!CheckValueType(this,v, 'number', 'height')) return;
        this._temp.height = v;
    }

    public get radius(): number {
        if(!CheckComp(this,'radius')) return;
        return this._temp.width / 2;
    }
    public set radius(v:number) {
        if(!CheckComp(this,'radius')) return;
        if(!CheckValueType(this,v, 'number', 'radius')) return;
        this._temp.width = v * 2;
    }

    public get color(): number {
        if(!CheckComp(this,'color')) return;
        return this._temp.color;
    }
    public set color(v:number) {
        if(!CheckComp(this,'color')) return;
        if(!CheckValueType(this,v, 'number', 'color')) return;
        this._temp.color = v;
    }

    public get alpha(): number {
        if(!CheckComp(this,'alpha')) return;
        return this._temp.alpha;
    }
    public set alpha(v:number) {
        if(!CheckComp(this,'alpha')) return;
        if(!CheckValueType(this,v, 'number', 'alpha')) return;
        this._temp.alpha = v;
    }
}
import {Util} from "../util/util";
import Component from "../core/component";
import BaseFunction from "./baseFunction";
import ComponentFunction from "./component/componentFunction";

export default class ScriptError extends Error {

    public script : string = '';
    public func : string = '';

    constructor(name: string, message: string, script?: string, func?: string) {
        super();
        this.name = name;
        this.message = message;
        this.script = script;
        this.func = func;
    }
}

export function CheckComp(comFun: ComponentFunction, funcName: string = undefined) : boolean {
    if(!comFun.temp) {
        throw new ScriptError('컴포넌트 없음',
            `${comFun.name} 컴포넌트를 추가하여 주십시오.`,
            comFun.log.script?.script?.name,
            `${comFun.camelName}.${funcName}`
        )
    }
    return true;
}
export function CheckValueType(comFun: ComponentFunction, value: any, type: string, funcName: string = undefined) : boolean {
    if( Util.Type.typeof(value) !== type) {
        throw new ScriptError('인수 타입 오류',
            `매개 변수의 타입은 ${type}이지만 인수로 ${Util.Type.typeof(value)} 타입이 전달되었습니다.`,
            comFun.log.script?.script?.name,
            `${comFun.camelName}.${funcName}`
        )
    }
    return true;
}
import * as PIXI from 'pixi.js';
import Game from "../../core/game";
import GameObject, {IGameObjectJson} from "../../gameObject/gameObject";

export default class Previewer {
    static PreviewRender(game:Game, json: IGameObjectJson) : string{
        const go = new GameObject( game );
        go.fromJson(json);
        const parent = new PIXI.Container();
        parent.addChild(go);
        go.x = 0;
        go.y = 0;
        go.updateTransform();

        const bounds = go.getBounds();
        go.x = bounds.width/2;
        go.y = bounds.height/2;
        go.updateTransform();

        const brt = new PIXI.BaseRenderTexture({
            width: bounds.width,
            height: bounds.height,
        });
        const rt = new PIXI.RenderTexture(brt);

        game.renderer.render(go, {
            renderTexture: rt
        });
        go.destroy();
        parent.destroy();

        const canvas = game.renderer.plugins.extract.canvas(rt);
        return canvas.toDataURL('image/png');
    }
}
import {Button, Modal, Image, Space, Row, Col, Select, Tooltip} from 'antd';
import {useState} from "react";
import {useAppSelector} from "../../app/hooks";
import {
    changeOpenEditorSetting,
    changeOpenProjectSetting,
    selectOpenEditorSetting,
    selectOpenProjectSetting
} from "../../store/modalSlice";
import {useDispatch} from "react-redux";
import EditorManager from "../../services/editorManager";
import {
    DeleteOutlined,
    FileOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import AssetItem from "./assetItem";
import PointView from "../panel/propertyView/valueView/pointView";
import {
    changeCameraHeight,
    changeCameraWidth,
    changeGrid,
    selectCameraHeight,
    selectCameraWidth, selectFixedGridMove,
    selectGrid,
    changeFixedGridMove, selectProjectName, changeProjectName, selectCDNList, changeCDNList
} from "../../store/editorSlice";
import {
    changeEditorViewOption, changeTextLocalize,
    changeTooltipLocalize,
    selectEditorViewOption, selectTextLocalize,
    selectTooltipLocalize
} from "../../store/editorSettingSlice";
import SelectView from "../panel/propertyView/valueView/selectView";
import LocalizationManager from "../../services/localizationManager";
import TooltipManager from "../../services/tooltipManager";
import {LayoutManager} from "../editorLayout/editorLayout";
const { Option } = Select;

export default function EditorSetting() {

    const isOpen = useAppSelector(selectOpenEditorSetting);
    const tooltipLocalize = useAppSelector(selectTooltipLocalize);
    const textLocalize = useAppSelector(selectTextLocalize);

    const dispatch = useDispatch();

    const handleOk = () => {
        dispatch(changeOpenEditorSetting(false));
    };

    const handleCancel = () => {
        dispatch(changeOpenEditorSetting(false));
    };

    const afterClose = () => {

    }

    return(<>
        <Modal title={LocalizationManager.Instance.getText('Edit.EditorSetting') || 'Editor Setting'}
               visible={isOpen}
               onOk={handleOk}
               onCancel={handleCancel}
               afterClose={afterClose}
               footer={[]}
        >
            <>
                <SelectView label={LocalizationManager.Instance.getText('EditorSetting.textLocalize') || 'textLocalize'}
                            tooltip={TooltipManager.Instance.getText('EditorSetting.textLocalize') }
                            value={textLocalize} onChange={function (value){
                    dispatch(changeTextLocalize(value));
                    LayoutManager.changeLang();
                }}>
                    <Option value={'ko'}>{'한국어'}</Option>
                    <Option value={'en'}>{'English'}</Option>
                </SelectView>
                <SelectView label={LocalizationManager.Instance.getText('EditorSetting.tooltipLocalize') || 'tooltipLocalize'}
                            tooltip={TooltipManager.Instance.getText('EditorSetting.tooltipLocalize') }
                            value={tooltipLocalize} onChange={function (value){
                    dispatch(changeTooltipLocalize(value));
                    TooltipManager.Instance.setLang(value);
                }}>
                    <Option value={'ko'}>{'한국어'}</Option>
                    <Option value={'en'}>{'English'}</Option>
                </SelectView>
            </>
        </Modal>
    </>)
}
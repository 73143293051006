import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import themeReducer from "../features/theme/themeSlice";
import hierarchyReducer from "../store/hierarchySlice";
import modalReducer from "../store/modalSlice";
import editorSettingReducer from "../store/editorSettingSlice";
import editorReducer from "../store/editorSlice";
import refreshReducer from "../store/refeshSlice";
import userReducer from "../store/userSlice"

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        theme: themeReducer,
        hierarchy: hierarchyReducer,
        modal: modalReducer,
        editorSetting: editorSettingReducer,
        editor: editorReducer,
        refresh: refreshReducer,
        userInfo:userReducer
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

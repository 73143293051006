import GameObject, {IGameObjectJson} from "mogera-game/scripts/gameObject/gameObject";
import Asset from "mogera-game/scripts/asset/asset";
import {IComponentJson} from "mogera-game/scripts/core/component";
import Game from "mogera-game/scripts/core/game";
import ScriptAsset from "mogera-game/scripts/asset/scriptAsset";
import {IScriptJson} from "mogera-game/scripts/component/script";

export default class GoJsonHelper {
    static findGameObjectByName(data: IGameObjectJson, name: string): IGameObjectJson {
        return this._findGameObjectByName(data, name);
    }

    static findGameObjectByNames(data: IGameObjectJson, name: string): IGameObjectJson[] {
        const arr: IGameObjectJson[] = [];
        this._findGameObjectByNames(data, name, arr);
        return arr;
    }

    static findGameObjectByPath(data: IGameObjectJson, path: string): IGameObjectJson {
        const paths = path.split('/');
        let gameObject: IGameObjectJson = data;
        for(let i = 0; i < paths.length; i++) {
            const _path = paths[i];
            gameObject = gameObject.children.find( go => go.name === _path );
            if(!gameObject) {
                return undefined;
            }
        }

        return gameObject;
    }

    private static _findGameObjectByName(data: IGameObjectJson, name: string): IGameObjectJson {
        if( data.name === name ) {
            return data;
        }

        for(let i = 0; i < data.children.length; i++) {
            const _data = this._findGameObjectByName(data.children[i], name);
            if(_data) {
                return _data;
            }
        }

        return undefined;
    }

    private static _findGameObjectByNames(data: IGameObjectJson, name: string, arr: IGameObjectJson[]) {
        if( data.name === name ) {
            arr.push(data);
        }

        for(let i = 0; i < data.children.length; i++) {
            this._findGameObjectByNames(data.children[i], name, arr);
        }
    }

    static findComponent(data: IGameObjectJson, name: string): IComponentJson {
        const components = data.components;
        for(let i = 0; i < components.length; i++) {
            if(components[i].name === name) {
                return components[i];
            }
        }
    }

    static findScript(data: IGameObjectJson, name: string, game: Game): IScriptJson {
        const components = data.components;
        for(let i = 0; i < components.length; i++) {
            const comp = components[i];
            if(comp.name === 'Script') {
                const scriptAsset = game.assetManager.getAsset((comp as IScriptJson).script);
                if(scriptAsset.name === name) {
                    return comp as IScriptJson;
                }
            }
        }
    }
}

import Command from "./command";
import {IComponentConstructor, IComponentJson} from "../../game/scripts/core/component";
import Game from "../../game/scripts/core/game";
import EditorManager from "../editorManager";
import {store} from "../../app/store";
import {treeManager} from "../../components/panel/hierarchy/hierarchy";
// import {changeHierarchySelectKeys} from "../../store/hierarchySlice";

export default class AddComponentCommand extends Command {
    private gameObjectId : string;
    // private ctor: IComponentConstructor;
    private compName: string;

    constructor(gameObjectId: string, compName: string) {
        super();
        this.gameObjectId = gameObjectId;
        this.compName = compName;
    }

    execute() {
        const gameObject = EditorManager.Instance.findGameObject( this.gameObjectId );
        gameObject.addComponent(this.compName);
        treeManager.tree?.selectById( gameObject.id );
    }

    undo() {
        const gameObject = EditorManager.Instance.findGameObject( this.gameObjectId );
        gameObject.removeComponent(this.compName);
        treeManager.tree?.selectById( gameObject.id );
    }
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {RootState} from "../../app/store";

export type ThemeType =  'dark' | 'light';

export interface ThemeState {
    theme: ThemeType
}

const initialState: ThemeState = {
    theme: 'dark'
};

export const selectTheme = (state: RootState) => state.theme.theme;

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        changeTheme: (state, action: PayloadAction<ThemeType>) => {
            state.theme = action.payload;
        },
    },
});

export const { changeTheme } = themeSlice.actions;

export default themeSlice.reducer;

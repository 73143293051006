import {SpriteFunctionTable} from "./component/spriteFunction";
import {ShapeFunctionTable} from "./component/shapeFunction";
import {NineSliceFunctionTable} from "./component/nineSliceFunction";
import {TextFunctionTable} from "./component/textFunction";
import {AnimatorFunctionTable} from "./component/animatorFunction";
import {TextAnimationFunctionTable} from "./component/textAnimationFunction";
import {BodyFunctionTable} from "./component/bodyFunction";
import {TransformFunctionTable} from "./function/transformFunction";
import {SystemFunctionTable} from "./function/systemFunction";
import {EventFunctionTable} from "./function/eventFunction";
import {SceneFunctionTable} from "./function/sceneFunction";
import PrefabFunction, {PrefabFunctionTable} from "./function/prefabFunction";
import {AssetFunctionTable} from "./function/assetFunction";
import {InputFunctionTable} from "./function/inputFunction";
import {ConsoleFunctionTable, GlobalFunctionTable, InstanceFunctionTable} from "./functionManager";
import {SoundFunctionTable} from "./function/soundFunction";
import {ActionFunctionTable} from "./function/actionFunction";
import {ObjectFunctionTable} from "./function/objectFunction";
import {TilingSpriteFunctionTable} from "./component/tilingSpriteFunction";
import {RandomFunctionTable} from "./function/randomFunction";
import {UtilFunctionTable} from "./function/utilFunction";

export interface scriptTableData {
    value: string,
    caption: string,
    score: number,
    meta: string,
    name: string,
    tooltip?: string,
    description: {
        ko : string,
        en: string,
    }
}

export const scriptTable_v2: scriptTableData[] = [
    ...InstanceFunctionTable,

    ...InputFunctionTable,
    ...ConsoleFunctionTable,
    ...GlobalFunctionTable,
    ...RandomFunctionTable,
    ...UtilFunctionTable,

    ...ObjectFunctionTable,
    ...TransformFunctionTable,
    ...SystemFunctionTable,
    ...EventFunctionTable,
    ...SceneFunctionTable,
    ...PrefabFunctionTable,
    ...PrefabFunctionTable,
    ...AssetFunctionTable,
    ...SoundFunctionTable,
    ...ActionFunctionTable,

    ...SpriteFunctionTable,
    ...TilingSpriteFunctionTable,
    ...ShapeFunctionTable,
    ...NineSliceFunctionTable,
    ...TextFunctionTable,
    ...AnimatorFunctionTable,
    ...TextAnimationFunctionTable,
    ...BodyFunctionTable,
]

export const scriptTable: scriptTableData[] = [
    {
        value: `const comp = getComponent('Sprite');`,
        caption: `getComponent(componentName: string)`,
        score: 10,
        meta: 'function',
        name: 'getComponent',
        description: {
            ko: '게임오브젝트의 컴포넌트를 가져옵니다.',
            en: '',
        },
    },
    {
        value: `const x = getX();`,
        caption: `getX(): number`,
        score: 10,
        meta: 'function',
        name: 'getX',
        description: {
            ko: '게임오브젝트의 로컬 x좌표를 가져옵니다.',
            en: '',
        },
    },
    {
        value: `const y = getY();`,
        caption: `getY(): number`,
        score: 10,
        meta: 'function',
        name: 'getY',
        description: {
            ko: '게임오브젝트의 로컬 y좌표를 가져옵니다.',
            en: '',
        },
    },
    {
        value: `if(isPause()){\n\n}`,
        caption: `isPause(): boolean`,
        score: 10,
        meta: 'function',
        name: 'isPause',
        description: {
            ko: '게임의 일시정지 여부를 가져옵니다.',
            en: '',
        },
    },
    {
        value: `setX(100);`,
        caption: `setX(x: number)`,
        score: 10,
        meta: 'function',
        name: 'setX',
        description: {
            ko: '게임오브젝트의 로컬 x좌표 값을 설정합니다.',
            en: '',
        },
    },
    {
        value: `setY(100);`,
        caption: `setY(y: number)`,
        score: 10,
        meta: 'function',
        name: 'setY',
        description: {
            ko: '게임오브젝트의 로컬 y좌표 값을 설정합니다.',
            en: '',
        },
    },
    {
        value: `changeScene('default');`,
        caption: `changeScene(sceneName: string)`,
        score: 10,
        meta: 'function',
        name: 'changeScene',
        description: {
            ko: '씬을 전환합니다.',
            en: '',
        },
    },
    {
        value: `playSound('sound');`,
        caption: `playSound(soundName: string, loop: boolean = false)`,
        score: 10,
        meta: 'function',
        name: 'playSound',
        description: {
            ko: '사운드를 재생합니다.',
            en: '',
        },
    },
    {
        value: `pause();`,
        caption: `pause()`,
        score: 10,
        meta: 'function',
        name: 'pause',
        description: {
            ko: '게임을 일시정지합니다.',
            en: '',
        },
    },
    {
        value: `resume();`,
        caption: `resume()`,
        score: 10,
        meta: 'function',
        name: 'resume',
        description: {
            ko: '일시정지된 게임을 다시 진행시킵니다.',
            en: '',
        },
    },
    {
        value: `clone('prefab');`,
        caption: `clone(prefabName: string | prefab: PrefabAsset)`,
        score: 10,
        meta: 'function',
        name: 'clone',
        description: {
            ko: '프리팹의 복제하여 게임오브젝트를 만듭니다.',
            en: '',
        },
    },
    {
        value: `onCollisionStart(function(info){\n\n});`,
        caption: `onCollisionStart(callback: (info: collisionInfo)=>void)`,
        score: 10,
        meta: 'function',
        name: 'onCollisionStart',
        description: {
            ko: '충돌이 시작됬을때 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `onCollisionEnd(function(info){\n\n});`,
        caption: `onCollisionEnd(callback: (info: collisionInfo)=>void)`,
        score: 10,
        meta: 'function',
        name: 'onCollisionEnd',
        description: {
            ko: '충돌이 끝났을때 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `onButton('buttonKey', function(){\n\n});`,
        caption: `onButton(buttonKey: string, callback: ()=>void)`,
        score: 10,
        meta: 'function',
        name: 'onButton',
        description: {
            ko: '설정된 버튼키가 눌러지면 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `onUpdate(function(delta){\n\n});`,
        caption: `onUpdate(callback: (delta: number)=>void)`,
        score: 10,
        meta: 'function',
        name: 'onUpdate',
        description: {
            ko: '매 업데이트 마다 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `onEnable(function(){\n\n});`,
        caption: `onEnable(callback: ()=>void)`,
        score: 10,
        meta: 'function',
        name: 'onEnable',
        description: {
            ko: '오브젝트가 비활성화 상태에서 활화될때 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `onDisable(function(){\n\n});`,
        caption: `onDisable(callback: ()=>void)`,
        score: 10,
        meta: 'function',
        name: 'onDisable',
        description: {
            ko: '오브젝트가 활성화 상태에서 비활화될때 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `onDestroy(function(){\n\n});`,
        caption: `onDestroy(callback: ()=>void)`,
        score: 10,
        meta: 'function',
        name: 'onDestroy',
        description: {
            ko: '오브젝트가 파괴될때 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `onClick(function(){\n\n});`,
        caption: `onClick(callback: ()=>void)`,
        score: 10,
        meta: 'function',
        name: 'onClick',
        description: {
            ko: '오브젝트가 클릭됬을때 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `onMessage('messageKey', function(){\n\n});`,
        caption: `onMessage(key: string, callback: ()=>void)`,
        score: 10,
        meta: 'function',
        name: 'onMessage',
        description: {
            ko: '설정된 키의 글로벌 메세지가 보내졌을때 호출됩니다.',
            en: '',
        },
    },
    {
        value: `sendMessage('messageKey');`,
        caption: `sendMessage(key: string)`,
        score: 10,
        meta: 'function',
        name: 'sendMessage',
        description: {
            ko: '설정된 키의 글로벌 메세지를 보냅니다.',
            en: '',
        },
    },
    {
        value: `const buttonKey = await waitButton();`,
        caption: `await waitButton(): string`,
        score: 10,
        meta: 'function',
        name: 'waitButton',
        description: {
            ko: '버튼 이벤트가 발생할떄까지 대기합니다.',
            en: '',
        },
    },
    {
        value: `await wait(1);`,
        caption: `await wait(duration: number)`,
        score: 10,
        meta: 'function',
        name: 'wait',
        description: {
            ko: '설정된 시간(초) 만큼 대기합니다.',
            en: '',
        },
    },
    {
        value: `await moveX(100, 1);`,
        caption: `await moveX(x: number, duration: number)`,
        score: 10,
        meta: 'function',
        name: 'moveX',
        description: {
            ko: '설정된 로컬 x좌표로 설정된 시간(초) 동안 게임오브젝트를 이동시키고 대기합니다.',
            en: '',
        },
    },
    {
        value: `await moveY(100, 1);`,
        caption: `await moveY(y: number, duration: number)`,
        score: 10,
        meta: 'function',
        name: 'moveY',
        description: {
            ko: '설정된 로컬 y좌표로 설정된 시간(초) 동안 게임오브젝트를 이동시키고 대기합니다.',
            en: '',
        },
    },
    {
        value: `if(input.left){\n\n}`,
        caption: `input.left`,
        score: 10,
        meta: 'input',
        name: 'input.left',
        description: {
            ko: '왼쪽 방향키 버튼이 눌러졌는지 확인합니다.',
            en: '',
        },
    },
    {
        value: `if(input.right){\n\n}`,
        caption: `input.right`,
        score: 10,
        meta: 'input',
        name: 'input.right',
        description: {
            ko: '오른쪽 방향키 버튼이 눌러졌는지 확인합니다.',
            en: '',
        },
    },
    {
        value: `if(input.up){\n\n}`,
        caption: `input.up`,
        score: 10,
        meta: 'input',
        name: 'input.up',
        description: {
            ko: '위쪽 방향키 버튼이 눌러졌는지 확인합니다.',
            en: '',
        },
    },
    {
        value: `if(input.down){\n\n}`,
        caption: `input.down`,
        score: 10,
        meta: 'input',
        name: 'input.down',
        description: {
            ko: '아래쪽 방향키 버튼이 눌러졌는지 확인합니다.',
            en: '',
        },
    },
    {
        value: `if(input.space){\n\n}`,
        caption: `input.space`,
        score: 10,
        meta: 'input',
        name: 'input.space',
        description: {
            ko: '스페이바 버튼이 눌러졌는지 확인합니다.',
            en: '',
        },
    },
    {
        value: `const asset = assetManager.getAsset('assetName');`,
        caption: `assetManager.getAsset(name: string)`,
        score: 10,
        meta: 'assetManager',
        name: 'assetManager.getAsset',
        description: {
            ko: '설정된 이름의 에셋을 가져옵니다.',
            en: '',
        },
    },
    {
        value: `await`,
        caption: `await`,
        score: 10,
        meta: '',
        name: 'await',
        description: {
            ko: '호출하는 함수가 끝날떄까지 대기합니다.',
            en: '',
        },
    },
]
import BaseFunction from "../baseFunction";
import {scriptTableData} from "../scriptTable";
import SceneAsset from "../../asset/sceneAsset";
import FunctionManager from "../functionManager";
import ScriptError from "../scriptError";

export const SceneFunctionTable: scriptTableData[] = [
    {
        value: `scene.change('default')`,
        caption: `scene.change(sceneName: string)`,
        score: 10,
        meta: 'function',
        name: 'scene.change',
        description: {
            ko: '씬을 전환합니다.',
            en: '',
        },
    },
    {
        value: `scene.find('name', root: FunctionManager = null)`,
        caption: `scene.find('name')`,
        score: 10,
        meta: 'function',
        name: 'scene.find',
        description: {
            ko: '인스턴스를 검색합니다.',
            en: '',
        },
    },
    {
        value: `scene.findWorld('name')`,
        caption: `scene.findWorld('name')`,
        score: 10,
        meta: 'function',
        name: 'scene.findWorld',
        description: {
            ko: 'World에서 인스턴스를 검색합니다.',
            en: '',
        },
    },
    {
        value: `scene.findUI('name')`,
        caption: `scene.findUI('name')`,
        score: 10,
        meta: 'function',
        name: 'scene.findUI',
        description: {
            ko: 'UI에서 인스턴스를 검색합니다.',
            en: '',
        },
    },
];

export default class SceneFunction extends BaseFunction {
    protected _name: string = 'Scene';
    public get instance(): SceneFunction {
        return this;
    }

    change(name: string) {
        const scene = this.gameObject.game.assetManager.getAsset(name, 'Scene') as SceneAsset;
        if(!scene) {
            throw new ScriptError('씬 없음',
                `${name} 이름의 씬을 찾을 수 없습니다.`,
                this.log.script?.script?.name, 'scene.change');
        }
        else {
            scene.start();
        }
    }

    find(name: string, root: FunctionManager = null): FunctionManager {
        if(root instanceof FunctionManager) {
            const go = root.gameObject.findByName(name);
            return go?.functionManager;
        }
        else {
            return this.gameObject.findByName(name)?.functionManager;
        }
    }

    findWorld(name: string): FunctionManager {
        return this.gameObject.game.scenes.crtScene.world.findByName(name)?.functionManager;
    }

    findUI(name: string): FunctionManager {
        return this.gameObject.game.scenes.crtScene.fixed.findByName(name)?.functionManager;
    }
}
import {IComponentViewProps} from "./componentView";
import {ISpriteJson} from "../../../../game/scripts/component/sprite";
import EditorManager from "../../../../services/editorManager";
import {store} from "../../../../app/store";
import {Button, Input, InputNumber, Space, Typography, Tooltip, Col, Row, Select} from "antd";
import PointView from "../valueView/pointView";
import SizeView from "../valueView/sizeView";
import TextureAssetView from "../valueView/ref/textureAssetView";
import TextureAsset from "../../../../game/scripts/asset/textureAsset";
import {CaretDownOutlined, CaretRightOutlined, ClearOutlined} from "@ant-design/icons";
import CheckboxView from "../valueView/checkboxView";
import ColorView from "../valueView/colorView";
import * as PIXI from 'pixi.js';
import InputNumberView from "../valueView/inputNumberView";
import SelectView from "../valueView/selectView";
import BorderView from "../valueView/borderView";
import NineSlice, {INineSliceJson} from "../../../../game/scripts/component/nineSlice";
import {useState} from "react";
import LocalizationManager from "../../../../services/localizationManager";
import TooltipManager from "../../../../services/tooltipManager";


const { Text } = Typography;
const { Option } = Select;


export default function NineSliceView(props: IComponentViewProps) {

    const json = props.json as INineSliceJson;
    const copyJson = JSON.parse(JSON.stringify(json));
    // const [more, setMore] = useState(false);

    return(<>
        <TextureAssetView label={LocalizationManager.Instance.getText('NineSlice.texture') || 'texture'}
                          tooltip={  TooltipManager.Instance.getText('NineSlice.texture') }
                          texture={json.texture} onClick={async () => {
            const imgKey = await EditorManager.Instance.selectImage( json.texture );
            if(imgKey !== null) {
                json.texture = imgKey;
                EditorManager.Instance.modifyComponent(json);
            }
        }} onRemove={function (){
            json.texture = '';
            EditorManager.Instance.modifyComponent(json);
        }}></TextureAssetView>
        <BorderView label={LocalizationManager.Instance.getText('NineSlice.border') || 'border'}
                    tooltip={  TooltipManager.Instance.getText('NineSlice.border') }
                    border={json.border} onChange={function (l, t, r, b) {
            json.border.l = l;
            json.border.t = t;
            json.border.r = r;
            json.border.b = b;
            EditorManager.Instance.modifyComponent(json);
        }}/>
        <SizeView
            label={LocalizationManager.Instance.getText('NineSlice.size') || 'size'}
            tooltip={  TooltipManager.Instance.getText('NineSlice.size') }
            size={json} disabled={false} onChange={(width, height) => {
            json.width = width;
            json.height = height;
            EditorManager.Instance.modifyComponent(json);
        }}></SizeView>
        <div className={'fold'} onClick={function (){
            json.more = !json.more;
            EditorManager.Instance.modifyComponent(json);
        }}>
            {
                json.more ?   (
                        <>
                            <CaretDownOutlined/>
                            <span>{LocalizationManager.Instance.getText('Component.hideOption') || 'hideOption'}</span>
                        </>) :
                    (<>
                        <CaretRightOutlined/>
                        <span>{LocalizationManager.Instance.getText('Component.showOption') || 'showOption'}</span>
                    </>)
            }
        </div>
        {
            json.more ? <>
                <ColorView  key={json.tint}
                            name={LocalizationManager.Instance.getText('NineSlice.tint') || 'tint'}
                            tooltip={  TooltipManager.Instance.getText('NineSlice.tint') }
                            value={PIXI.utils.hex2string(json.tint)} onChange={function (color){
                    const colorStr = color.replace('#', '');
                    const comp = EditorManager.Instance.findComponent(json.id) as NineSlice
                    comp.tint = PIXI.utils.string2hex(colorStr);
                }} onChangeComplete={function (color){
                    const colorStr = color.replace('#', '');
                    json.tint = PIXI.utils.string2hex(colorStr);
                    EditorManager.Instance.modifyComponent(json, copyJson);
                }}/>
                <InputNumberView
                    label={LocalizationManager.Instance.getText('NineSlice.alpha') || 'alpha'}
                    tooltip={  TooltipManager.Instance.getText('NineSlice.alpha') }
                    value={json.alpha} onChange={function (num){
                    json.alpha = num;
                    EditorManager.Instance.modifyComponent(json);
                }}/>
            </> : null
        }
    </>)
}
import {IComponentViewProps} from "./componentView";
import Camera, {ICameraJson} from "../../../../game/scripts/component/camera";
import {Divider, Input, InputNumber, Space, Typography} from "antd";
import EditorManager from "../../../../services/editorManager";
import SizeView from "../valueView/sizeView";
import BoundsView from "../valueView/boundsView";
import CheckboxView from "../valueView/checkboxView";
import GoRefView from "../valueView/ref/goRefView";
import {useDrop} from "react-dnd";
import {DragItem} from "react-arborist/dist/types";
import {DropResult} from "react-arborist/dist/dnd/drop-hook";
import {IScriptAssetProp} from "../../../../game/scripts/asset/scriptAsset";
import {IScriptJson} from "../../../../game/scripts/component/script";
import {editable} from "../../../../game/scripts/gameObject/gameObject";
import {store} from "../../../../app/store";
import {useAppSelector} from "../../../../app/hooks";
import {selectSelectScriptId} from "../../../../store/modalSlice";
import Button from "../../../../game/scripts/component/button";
import PointView from "../valueView/pointView";
import LocalizationManager from "../../../../services/localizationManager";
import TooltipManager from "../../../../services/tooltipManager";
const { Text } = Typography;

let dropProp: string;

export default function CameraView(props: IComponentViewProps) {
    const json = props.json as ICameraJson;
    const selectId = useAppSelector(selectSelectScriptId);

    const [, drop] = useDrop<DragItem, DropResult | null, { isOver: boolean }>(
        () => ({
            accept: "NODE",
            canDrop: (item: DragItem, monitor: any) => {
                if (dropProp === 'GameObject') {
                    const go = EditorManager.Instance.findGameObject(item.id);
                    return editable(go, 'position');
                }
                return false;
            },
            hover: (item: DragItem, monitor: any) => {
            },
            drop: (item: DragItem, monitor: any) => {
                if( dropProp === 'GameObject' ){
                    const selectGo = EditorManager.Instance.getSelectGameObject();
                    const cameraComp = selectGo.getComponent(Camera.Name);
                    const go = EditorManager.Instance.findGameObject(item.id);
                    const newJson = cameraComp.toJson() as ICameraJson;
                    newJson.followTarget = go?.id;
                    EditorManager.Instance.modifyCamera(newJson);
                }
                return null;
            }
        })
    );

    return ( <div ref={drop}>
        {/*<SizeView label={"size"} size={json} onChange={function (width, height){*/}
        {/*    json.width = width;*/}
        {/*    json.height = height*/}
        {/*    EditorManager.Instance.modifyCamera(json);*/}
        {/*}}></SizeView>*/}
        <GoRefView label={LocalizationManager.Instance.getText('Camera.followTarget') || 'followTarget'}
                   tooltip={TooltipManager.Instance.getText('Camera.followTarget') }
                   id={json.followTarget} onRemove={function (){
            json.followTarget = undefined;
            EditorManager.Instance.modifyCamera(json);
        }} onDragEnter={function (){
            dropProp = 'GameObject';
        }} onDragLeave={function (){
            dropProp = null;
        }}></GoRefView>
        <PointView label={LocalizationManager.Instance.getText('Camera.followOffset') || 'followOffset'}
                   tooltip={TooltipManager.Instance.getText('Camera.followOffset') }
                   point={json.followOffset} onChange={function (x, y){
            json.followOffset.x = x;
            json.followOffset.y = y;
            EditorManager.Instance.modifyCamera(json);
        }}></PointView>
        <Divider></Divider>
        <CheckboxView label={LocalizationManager.Instance.getText('Camera.enable') || 'enable'}
                      tooltip={TooltipManager.Instance.getText('Camera.enable') }
                      checked={json.enableBounds} onChange={function (checked){
            json.enableBounds = checked;
            EditorManager.Instance.modifyCamera(json);
        }}></CheckboxView>
        <BoundsView label={LocalizationManager.Instance.getText('Camera.bounds') || 'bounds'}
                    tooltip={TooltipManager.Instance.getText('Camera.bounds') }
                    disabled={!json.enableBounds} bounds={json.bounds} onChange={function (bounds) {
            json.bounds = bounds;
            EditorManager.Instance.modifyCamera(json);
        }}></BoundsView>

    </div>);
}
import LogManager from "../logManager";
import GameObject from "../../gameObject/gameObject";
import BaseFunction from "../baseFunction";
import {scriptTableData} from "../scriptTable";

export const TransformFunctionTable: scriptTableData[] = [
    {
        value: `transform.worldPosition`,
        caption: `transform.worldPosition`,
        score: 10,
        meta: 'getter,setter',
        name: 'transform.worldPosition',
        tooltip: 'transform.worldPosition',
        description: {
            ko: 'Instance 월드 좌표를 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `transform.position`,
        caption: `transform.position`,
        score: 10,
        meta: 'getter,setter',
        name: 'transform.position',
        tooltip: 'transform.position',
        description: {
            ko: 'Instance 의 위치값을 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `transform.scale`,
        caption: `transform.scale`,
        score: 10,
        meta: 'getter,setter',
        name: 'transform.scale.x',
        tooltip: 'transform.scale',
        description: {
            ko: 'Instance 의 스케일값을 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `transform.rotation`,
        caption: `transform.rotation`,
        score: 10,
        meta: 'getter,setter',
        name: 'transform.rotation',
        tooltip: 'const rot = transform.rotation\ntransform.rotation = 0',
        description: {
            ko: 'Instance 의 회전각을 가져오거나 설정합니다.(라디안)',
            en: '',
        },
    },
];

class RefPoint {
    get x(): number {
        return this._getX();
    }
    set x(n: number) {
        this._setX(n);
    }
    get y(): number {
        return this._getY();
    }
    set y(n: number) {
        this._setY(n);
    }
    private readonly _getX: ()=>number;
    private readonly _getY: ()=>number;
    private readonly _setX: (n:number)=>void;
    private readonly _setY: (n:number)=>void;
    constructor(_getX: ()=>number, _getY: ()=>number, _setX: (n:number)=>void, _setY: (n:number)=>void) {
        this._getX = _getX;
        this._getY = _getY;
        this._setX = _setX;
        this._setY = _setY;
    }
}

export default class TransformFunction extends BaseFunction {
    protected _name: string = 'Transform';
    public get instance(): TransformFunction {
        return this;
    }

    private _position: RefPoint = new RefPoint(
        ()=>{
            return this.gameObject.position.x;
        },
        ()=>{
            return this.gameObject.position.y;
        },
        (n:number)=>{
            this.gameObject.position.x = n;
        },
        (n:number)=>{
            this.gameObject.position.y = n;
        },
    );
    public get position(): RefPoint {
        return this._position;
    }

    private _worldPosition: RefPoint = new RefPoint(
        ()=>{
            return this.gameObject.worldPosition.x
        },
        ()=>{
            return this.gameObject.worldPosition.y;
        },
        (n:number)=>{
            this.gameObject.worldPosition = {x: n, y: this.gameObject.worldPosition.y};
        },
        (n:number)=>{
            this.gameObject.worldPosition= {x: this.gameObject.worldPosition.x, y: n};
        },
    );
    public get worldPosition(): RefPoint {
        return this._worldPosition;
    }

    private _scale: RefPoint = new RefPoint(
        ()=>{
            return this.gameObject.scale.x;
        },
        ()=>{
            return this.gameObject.scale.y;
        },
        (n:number)=>{
            this.gameObject.scale.x = n;
        },
        (n:number)=>{
            this.gameObject.scale.y = n;
        },
    );
    public get scale(): RefPoint {
        return this._scale;
    }

    public get rotation(): number {
        return this.gameObject.rotation;
    }
    public set rotation(r: number) {
        this.gameObject.rotation = r;
    }
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {RootState} from "../app/store";

export interface RefreshState {
    refreshPrefabView: number;
}

const initialState: RefreshState = {
    refreshPrefabView: 0,
};

export const selectRefreshPrefabView = (state: RootState) => state.refresh.refreshPrefabView;


export const refreshSlice = createSlice({
    name: 'refresh',
    initialState,
    reducers: {
        changeRefreshPrefabView: (state, action: PayloadAction<number>) => {
            state.refreshPrefabView = action.payload;
        },
    },
});

export const {
    changeRefreshPrefabView,
} = refreshSlice.actions;

export default refreshSlice.reducer;
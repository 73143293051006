import {Button, Checkbox, Col, Dropdown, Image, Menu, Popconfirm, Badge} from "antd";
import EditorManager from "../../services/editorManager";
import {DeleteFilled, FileOutlined, DeleteOutlined, DeleteTwoTone  } from "@ant-design/icons";
import Asset from "../../game/scripts/asset/asset";
import './assetItem.css';
import {DragEventHandler, MouseEventHandler} from "react";
import * as React from "react";

export default function AssetItem( {asset, onClick, onMouseDown, onMouseOver, select, children,
                                       selectable, onDragStart, draggable, span, menu, nameColor,
                                       nameBackColor, tag}:
    {
        asset: Asset, onClick: Function,onMouseDown?:MouseEventHandler, onMouseOver?: MouseEventHandler,
        select?:boolean, children? :any, selectable?:boolean, onDragStart?: DragEventHandler<HTMLDivElement>,
        draggable?: boolean, span?: number, menu?: React.ReactElement, nameColor?: string, nameBackColor?: string,
        tag?: string,
    }) {

    return <Col className="gutter-row"
                onDragStart={onDragStart}
                draggable={draggable}
                span={span}
    >
        <Dropdown overlay={menu || <Menu items={[]}/>} trigger={['contextMenu']}>
            <div className={`assetItem ${select?'select':''}` }
                 onClick={()=>{
                     onClick && onClick();
                 }}
                 onMouseOver={onMouseOver}
                 onMouseDown={onMouseDown}
            >
                <div
                    style={{
                        // backgroundImage: 'url(/img/bg_pattern.png)',
                        // backgroundColor: 'black',
                        pointerEvents: 'none',
                    }}
                >
                    {children ? children : <div  style={{height:'80px', fontSize: '30px'}}>
                        <FileOutlined></FileOutlined>
                    </div>}
                    <div className={'nameContainer'}>
                        <div style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            backgroundColor: nameBackColor? nameBackColor : 'black',
                            opacity: '0.8',
                        }}></div>
                        <div style={{
                            width: '100%',
                            height: '100%',
                            lineHeight: '100%',
                            wordBreak: 'break-word',
                            position: 'absolute',
                            color: nameColor ? nameColor: '',
                            fontWeight: '600',
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            {asset.name}
                        </div>
                    </div>
                    { selectable ?
                        <Checkbox checked={select}
                                  style={{position: 'absolute', left:0, top:0}}
                        >
                        </Checkbox> : null
                    }
                </div>
            </div>
        </Dropdown>
    </Col>
}
import * as PIXI from 'pixi.js';

import Component, {IComponentConstructor, IComponentJson} from "../core/component";
import GameObject, {TJsonRefers} from "../gameObject/gameObject";
import {component, property} from "../util/decorator";
import {IEventLoaderResource} from "../core/game";
import TextureAsset from "../asset/textureAsset";
import EditorScene, {ECtrlMode} from "../tools/editor/editorScene";
import {SetEditorInteraction} from "../tools/editor/editorScene";

export interface ITilingSpriteJson extends IComponentJson {
    texture: string;
    width: number;
    height: number;
    anchor: { x: number, y: number };
    // scale: { x: number, y:number };
    tileScale: {x: number, y: number};
    tilePosition: {x: number, y: number};
    tint: number,
    alpha: number,
}

@component('TilingSprite')
export default class TilingSprite extends Component {
    private _sprite : PIXI.TilingSprite
    public get sprite() : PIXI.TilingSprite {
        return this._sprite;
    }

    @property(TextureAsset)
    public texture: TextureAsset
    private __texture : TextureAsset;
    private get _texture(): TextureAsset {
        return this.__texture;
    }
    private set _texture(texture: TextureAsset) {
        this.__texture = texture;
        if(texture) {
            this._sprite.texture = texture.texture;
        }
        else {
            this._sprite.texture = PIXI.Texture.EMPTY;
        }
    }

    public get tint(): number {
        return this.sprite.tint;
    }
    public set tint(color: number) {
        this.sprite.tint = color;
    }

    public get alpha(): number {
        return this.sprite.alpha;
    }
    public set alpha(a: number) {
        this.sprite.alpha = a;
    }

    public get width(): number {
        return this.sprite.width;
    }
    public get height(): number {
        return this.sprite.height;
    }

    public set width(w: number) {
        this.sprite.width = w;
    }
    public set height(h: number) {
        this.sprite.height = h;
    }

    private _tileScale: PIXI.Point = new PIXI.Point(1, 1);
    public get tileScale() : PIXI.Point {
        return this._tileScale;
    }
    public set tileScale(scale: {x:number, y: number}) {
        this._tileScale.set(scale.x, scale.y);
        this.sprite.tileScale.set(scale.x, scale.y);
    }

    // private _tilePosition: PIXI.Point = new PIXI.Point(0, 0);
    public get tilePosition() : PIXI.Point {
        return this.sprite.tilePosition;
    }
    public set tilePosition(position: {x:number, y: number}) {
        // this._tilePosition.set(position.x, position.y);
        this.sprite.tilePosition.x = position.x;
        this.sprite.tilePosition.y = position.y;
    }

    public get anchor() : PIXI.Point {
        return this.sprite.anchor;
    }

    /* internal */
    create() {
        this._sprite = new PIXI.TilingSprite( PIXI.Texture.EMPTY );
        this.sprite.visible = this.gameObject.worldActive;
        (this.gameObject as PIXI.utils.EventEmitter).on('added', this.onAdded, this );
        (this.sprite as PIXI.Sprite).anchor.set(0.5);
        this.gameObject.addChildAt(this.sprite, 0);

        if(this.game.scenes.crtScene instanceof EditorScene) {
            SetEditorInteraction( this._sprite, this.gameObject );
        }
    }

    awake() {
    }

    private onAdded() {
        this.sprite.visible = this.gameObject.worldActive;
    }

    destroy() {
        (this.gameObject as PIXI.utils.EventEmitter).off('added', this.onAdded, this );
        this.texture = null;
        this.sprite.destroy();
        this._sprite = null;
        super.destroy();
    }

    onEnable() {
        super.onEnable();
        if(this.sprite){
            this.sprite.visible = true;
        }
    }

    onDisable() {
        super.onDisable();
        if(this.sprite){
            this.sprite.visible = false;
        }
    }

    _debugRender(graphic:PIXI.Graphics) {
        // const bound = this.sprite.getBounds();
        // graphic.drawRect(bound.x, bound.y, bound.width, bound.height);
    }

    toJson() : ITilingSpriteJson {
        const json : ITilingSpriteJson = super.toJson() as ITilingSpriteJson;
        if(this.texture) {
            json.texture = this.texture.id;
        }

        json.anchor = {
            x: this.anchor.x,
            y: this.anchor.y
        };
        json.tileScale = {
            x: this.tileScale.x,
            y: this.tileScale.y,
        };
        json.tilePosition = {
            x: this.tilePosition.x,
            y: this.tilePosition.y,
        };
        json.width = this.width;
        json.height = this.height;
        json.tint = this.tint;
        json.alpha = this.alpha;

        return json;
    }

    fromJson(json : ITilingSpriteJson, refers: TJsonRefers) {
        super.fromJson(json, refers);
        if(json.texture) {
            const asset = this.game.assetManager.getAsset(json.texture) as TextureAsset;
            if(asset) {
                this.texture = asset;
            }
            else{
                this.texture = null;
            }

        }
        else {
            this.texture = null;
        }

        this.anchor.set(
            json.anchor.x,
            json.anchor.y
        );
        this.tileScale = json.tileScale;
        this.tilePosition = json.tilePosition;
        this.width = json.width;
        this.height = json.height;
        this.tint = json.tint || 0xffffff;
        this.alpha = json.alpha;
    }
}
import Asset from "../asset/asset";
import Component from "../core/component";
import GameObject from "../gameObject/gameObject";
import FunctionManager from "../function/functionManager";
import TextureAsset from "../asset/textureAsset";
import SoundAsset from "../asset/soundAsset";
import SceneAsset from "../asset/sceneAsset";

export const Util = {
    Math : {
        length: function (x1: number, y1: number, x2: number, y2: number): number {
            return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
        }
    },
    Type: {
        typeof: function (type: any): string {
            if( type === null ) {
                return 'null';
            }
            else if( typeof type === 'object' ) {
                if( type instanceof Asset ){
                    return type.type;
                }
                else if(type instanceof Component ) {
                    return type.name;
                }
                else if(type instanceof GameObject) {
                    return 'GameObject'
                }
                else if(type instanceof FunctionManager) {
                    return 'Instance';
                }
                else {
                    return typeof type;
                }
            }
            else {
                return typeof type;
            }
        }
    }
}
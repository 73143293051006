import {Tree, TreeApi} from 'react-arborist';
import React, {useEffect, useRef} from 'react';
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    changeHierarchySelectIds,
    HierarchyData,
} from "../../../store/hierarchySlice";
import {
    DownOutlined
} from '@ant-design/icons';

//@ts-ignore
import {AutoSizer} from "react-virtualized";

import useBackend from "./backend";
import HierarchyNode from "./hierarchyNode";
import './hierarchy.css';
import EventEmitter from "events";
import EditorManager from "../../../services/editorManager";
import GameObject, {editable, EEditable} from "../../../game/scripts/gameObject/gameObject";
import {Dropdown} from "antd";
import HierarchyContextMenu from "./hierarchyContextMenu";
import {store} from "../../../app/store";
import {prefabSelector} from "../prefabView/prefabView";

export const treeManager: {tree: TreeApi, map: {[id:string]: any}, dragId: string } = {
    tree: null,
    map: {},
    dragId:'',
}

export default function Hierarchy () {
    const backend = useBackend();
    const rootElement = useRef<HTMLDivElement | null>(null);
    const dispatch = useAppDispatch();
    // const selectIds = useAppSelector( selectHierarchySelectIds );

    useEffect(()=>{

        return function () {
        }
    }, []);

    useEffect(()=>{
        OnHierarchyTree.emit('init');
        return function () {
        }
    });

    return (
        <Dropdown overlay={<HierarchyContextMenu/>} trigger={['contextMenu']}>
            <div className={'hierarchyRoot'} ref={rootElement}>
                <AutoSizer>
                    {(props:any)=>(
                        <Tree
                            ref={(tree) => {
                                treeManager.tree = tree;
                                const ids = tree?.getSelectedIds();
                                // if(ids && ids.length > 1) {
                                //     tree?.selectById(ids[0]);
                                // }
                                if(ids) {
                                    dispatch(changeHierarchySelectIds([...ids]));
                                    EditorManager.Instance.onSelect(ids);
                                }
                                if(ids && ids.length) {
                                    prefabSelector?.select && prefabSelector.select(undefined);
                                }
                            }}
                            data={backend.data}
                            className="react-aborist treeRoot scrollType2"
                            disableDrop={undefined}
                            hideRoot={true}
                            getChildren="children"
                            isOpen="isOpen"
                            indent={24}
                            rowHeight={22}
                            onMove={backend.onMove}
                            onToggle={backend.onToggle}
                            onEdit={backend.onEdit}
                            width={props.width}
                            height={props.height}
                            onClick={(e) => {
                                const div = e.target as HTMLDivElement;
                                if(div.classList.contains('treeRoot')) {
                                    treeManager.tree?.selectById(undefined);
                                }
                            }}
                            onContextMenu={() => {
                                // console.log("context menu the tree")
                            }}
                            dndRootElement={undefined}
                        >
                            {HierarchyNode}
                        </Tree>
                    )}
                </AutoSizer>

            </div>
        </Dropdown>

    );
};

export const OnHierarchyTree = new EventEmitter();
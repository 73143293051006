import CheckboxView from "../valueView/checkboxView";
import EditorManager from "../../../../services/editorManager";
import InputView from "../valueView/inputView";
import PointView from "../valueView/pointView";
import InputNumberView from "../valueView/inputNumberView";
import {isReadOnly, isView} from "../isView";
import {EEditable, IGameObjectJson} from "../../../../game/scripts/gameObject/gameObject";
import {EEditorViewOption} from "../../../../store/editorSettingSlice";
import ComponentBox from "./componentBox";
import {useState} from "react";
import {treeManager} from "../../hierarchy/hierarchy";
import LocalizationManager from "../../../../services/localizationManager";
import TooltipManager from "../../../../services/tooltipManager";

export default function GameObjectBox({disableOptions, json, editorViewOption}:
    {disableOptions : {[key:string]: EEditable }, json: IGameObjectJson, editorViewOption: EEditorViewOption}) {

    const [isOpen, setIsOpen] = useState({expanded: true});

    const renders = [];
    if( isView(disableOptions, 'active', editorViewOption) ) {
        const disable = isReadOnly(disableOptions, 'active', editorViewOption);
        renders.push( <CheckboxView disabled={disable} key={'active'}
                                    label={LocalizationManager.Instance.getText('GameObject.active') || 'active'}
                                    tooltip={  TooltipManager.Instance.getText('GameObject.active') }
                                    checked={json.active} onChange={function (checked:boolean){
            json.active = checked;
            EditorManager.Instance.modifyGameObject(json);
            treeManager.tree?.selectById(json.id);
        }}></CheckboxView>)
    }
    if( isView(disableOptions, 'name', editorViewOption) ) {
        const disable = isReadOnly(disableOptions, 'name', editorViewOption);
        renders.push(<InputView key={'name'} disabled={disable}
                                label={LocalizationManager.Instance.getText('GameObject.name') || 'name'}
                                tooltip={  TooltipManager.Instance.getText('GameObject.name') }
                                value={json.name} onChange={function (value){
            json.name = value;
            EditorManager.Instance.modifyGameObject(json, true);
            treeManager.tree?.selectById(json.id);
        }}/>);
    }
    if( isView(disableOptions, 'position', editorViewOption) ) {
        const disable = isReadOnly(disableOptions, 'position', editorViewOption);
        renders.push( <PointView key={'position'}
                                 label={LocalizationManager.Instance.getText('GameObject.position') || 'position'}
                                 tooltip={  TooltipManager.Instance.getText('GameObject.position') }
                                 disabled={disable}
                                 point={{x:json.position.x, y:json.position.y}}
                                 onChange={(x,y)=>{
                                     json.position.x = x;
                                     json.position.y = y;
                                     EditorManager.Instance.modifyGameObject(json);
                                     treeManager.tree?.selectById(json.id);
                                     // EditorManager.changeObjectPosition(jsonData.position.x, jsonData.position.y);
                                 }}></PointView>)
    }
    if( isView(disableOptions, 'rotation', editorViewOption) ) {
        const disabled = isReadOnly(disableOptions, 'rotation', editorViewOption);
        renders.push(<InputNumberView key={'angle'} disabled={disabled}
                                      label={LocalizationManager.Instance.getText('GameObject.angle') || 'angle'}
                                      tooltip={  TooltipManager.Instance.getText('GameObject.angle') }
                                      value={json.angle} onChange={function (num){
            json.angle = num;
            EditorManager.Instance.modifyGameObject(json);
            treeManager.tree?.selectById(json.id);
        }}/>)
    }
    if( isView(disableOptions, 'scale', editorViewOption) ) {
        const disable = isReadOnly(disableOptions, 'scale', editorViewOption);
        renders.push( <PointView
            key={'scale'}
            label={LocalizationManager.Instance.getText('GameObject.scale') || 'scale'}
            tooltip={  TooltipManager.Instance.getText('GameObject.scale') }
            disabled={disable}
            point={{x:json.scale.x, y:json.scale.y}}
            onChange={(x,y)=>{
                json.scale.x = x;
                json.scale.y = y;
                EditorManager.Instance.modifyGameObject(json);
                treeManager.tree?.selectById(json.id);
            }}></PointView>)
    }

    return (<ComponentBox name={'GameObject'} json={isOpen} onChange={function (checked){
        isOpen.expanded = checked;
        setIsOpen({...isOpen});
    }}>
        { isOpen.expanded ? renders : null}
    </ComponentBox>);
}
import Game from "mogera-game/scripts/core/game";

export interface IWizardSaveData {
    isData: boolean,
    isLoad: boolean,
    path: string,
    onLoadCallback: (game: Game)=>void,
}

/*
wizardSaveData.isData = true;
wizardSaveData.path = '/example/runningGame.mogera';
 */

const wizardSaveData : IWizardSaveData = {
    isData: false,
    // isData: true,
    isLoad: false,
    path: '',
    // path: '/example/runningGame.mogera',
    onLoadCallback: null,
};

export default wizardSaveData;
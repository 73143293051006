import {IComponentViewProps} from "./componentView";
import EditorManager from "../../../../services/editorManager";
import {Button, Checkbox, InputNumber, Select, Space, Switch, Typography} from "antd";
import {ECtrlMode} from "../../../../game/scripts/tools/editor/editorScene";
import SizeView from "../valueView/sizeView";
import InputNumberView from "../valueView/inputNumberView";
import SelectView from "../valueView/selectView";
import CheckboxView from "../valueView/checkboxView";
import {IBodyJson} from "mogera-game/scripts/component/body";
import {b2BodyType} from "@box2d/core";
import PointView from "../valueView/pointView";
import {useState} from "react";
import {
    CaretDownOutlined,
    CaretRightOutlined
} from '@ant-design/icons';
import LocalizationManager from "../../../../services/localizationManager";
import TooltipManager from "../../../../services/tooltipManager";
import SliderView from "../valueView/sliderView";

const { Text } = Typography;
const { Option } = Select;


export default function BodyView(props: IComponentViewProps) {

    const json = props.json as IBodyJson;
    const [showList, setShowList] = useState(true);
    // const [more, setMore] = useState(false);

    let view = null;
    if(json.colliderType === 'box'){
        view =  <SizeView
            label={LocalizationManager.Instance.getText('Body.size') || 'size'}
            tooltip={  TooltipManager.Instance.getText('Body.size') }
            size={json} onChange={function (width, height){
            json.width = width;
            json.height = height;
            EditorManager.Instance.modifyComponent(json);
        }} onClick={function (){
            EditorManager.Instance.changeCtrlMode( ECtrlMode.bodyResizer );
        }}></SizeView>
    }
    else if(json.colliderType === 'circle') {
        view =  <InputNumberView
            label={LocalizationManager.Instance.getText('Body.radius') || 'radius'}
            tooltip={  TooltipManager.Instance.getText('Body.radius') }
            value={json.radius} onChange={function (num){
            json.radius = num;
            EditorManager.Instance.modifyComponent(json);
        }} onClick={function (){
            EditorManager.Instance.changeCtrlMode( ECtrlMode.bodyResizer );
        }}></InputNumberView>
    }
    else if(json.colliderType === 'edge') {
        const list = [];

        for(let i = 0; i < 2; i++) {
            list.push(<PointView key={i} label={i.toString()} point={json.points[i] || {x:0, y:0}} onChange={function (x, y){
                json.points[i] = {x,y};
                EditorManager.Instance.modifyComponent(json);
            }} onClick={function (){
                EditorManager.Instance.changeCtrlMode( ECtrlMode.bodyResizer );
            }}/>);
        }

        view =  <>
            <div className={'fold'} style={{
                marginLeft:10,
            }} onClick={function (){
                setShowList(!showList);
            }}>
                {
                    showList ?   (
                            <>
                                <CaretDownOutlined/>
                                <span> 리스트 숨기기</span>
                            </>) :
                        (<>
                            <CaretRightOutlined/>
                            <span> 리스트 보기</span>
                        </>)
                }

            </div>
            { showList ? list : null}
        </>
    }
    else if(json.colliderType === 'polygon') {

        const list = [];

        for(let i = 0; i < json.pointCount; i++) {
            list.push(<PointView key={i} label={i.toString()} point={json.points[i] || {x:0, y:0}} onChange={function (x, y){
                json.points[i] = {x,y};
                EditorManager.Instance.modifyComponent(json);
            }} onClick={function (){
                EditorManager.Instance.changeCtrlMode( ECtrlMode.bodyResizer );
            }}/>);
        }

        view =  <>
            <InputNumberView
                label={LocalizationManager.Instance.getText('Body.points') || 'points'}
                tooltip={  TooltipManager.Instance.getText('Body.points') }
                value={json.pointCount} onChange={function (num){
                if(num < 3 ) {
                    num = 3;
                }
                json.pointCount = num;
                EditorManager.Instance.modifyComponent(json);
            }}></InputNumberView>

            <div className={'fold'} onClick={function (){
                setShowList(!showList);
            }}>
                {
                    showList ?   (
                        <>
                            <CaretDownOutlined/>
                            <span> 리스트 숨기기</span>
                        </>) :
                        (<>
                            <CaretRightOutlined/>
                            <span> 리스트 보기</span>
                        </>)
                }

            </div>

            {/*<Switch size={'small'} checked={showList} onChange={function (checked){*/}
            {/*    setShowList(checked);*/}
            {/*}}></Switch>*/}

            { showList ? list : null}
        </>
    }

    return(
        <>
            <SelectView label={LocalizationManager.Instance.getText('Body.colliderType') || 'colliderType'}
                        tooltip={  TooltipManager.Instance.getText('Body.colliderType') }
                        value={json.colliderType} onChange={function (value){
                json.colliderType = value;
                if( value === 'polygon' && json.pointCount < 3 ) {
                    const hw = json.width/2;
                    const hh = json.height/2;
                    json.pointCount = 4;
                    json.points = [
                        {x: hw, y: -hh},
                        {x: hw, y: hh},
                        {x: -hw, y: hh},
                        {x: -hw, y: -hh},
                    ]
                }
                EditorManager.Instance.modifyComponent(json);
            }}>
                <Option value='box'>{LocalizationManager.Instance.getText('Body.colliderType.box') || 'box'}</Option>
                <Option value="circle">{LocalizationManager.Instance.getText('Body.colliderType.circle') || 'circle'}</Option>
                <Option value="edge">{LocalizationManager.Instance.getText('Body.colliderType.edge') || 'edge'}</Option>
                <Option value="polygon">{LocalizationManager.Instance.getText('Body.colliderType.polygon') || 'polygon'}</Option>
            </SelectView>
            {view}
            <SelectView label={LocalizationManager.Instance.getText('Body.bodyType') || 'bodyType'}
                        tooltip={  TooltipManager.Instance.getText('Body.bodyType') }
                        value={json.bodyType} onChange={function (value){
                json.bodyType = value;
                EditorManager.Instance.modifyComponent(json);
            }}>
                <Option value={b2BodyType.b2_staticBody}>{LocalizationManager.Instance.getText('Body.bodyType.staticBody') || 'staticBody'}</Option>
                <Option value={b2BodyType.b2_kinematicBody}>{LocalizationManager.Instance.getText('Body.bodyType.kinematicBody') || 'kinematicBody'}</Option>
                <Option value={b2BodyType.b2_dynamicBody}>{LocalizationManager.Instance.getText('Body.bodyType.dynamicBody') || 'dynamicBody'}</Option>
            </SelectView>
            <CheckboxView label={LocalizationManager.Instance.getText('Body.isSensor') || 'isSensor'}
                          tooltip={  TooltipManager.Instance.getText('Body.isSensor') }
                          checked={json.isSensor} onChange={function (checked){
                json.isSensor = checked;
                EditorManager.Instance.modifyComponent(json);
            }}></CheckboxView>

            {json.bodyType === b2BodyType.b2_dynamicBody ? <CheckboxView label={LocalizationManager.Instance.getText('Body.freezeRotation') || 'freezeRotation'}
                                                                         tooltip={  TooltipManager.Instance.getText('Body.freezeRotation') }
                                                                         checked={json.fixedRotation} onChange={function (checked) {
                json.fixedRotation = checked;
                EditorManager.Instance.modifyComponent(json);
            }}></CheckboxView> : null}

            <div className={'fold'} onClick={function (){
                json.more = !json.more;
                EditorManager.Instance.modifyComponent(json);
            }}>
                {
                    json.more ?   (
                            <>
                                <CaretDownOutlined/>
                                <span>{LocalizationManager.Instance.getText('Component.hideOption') || 'hideOption'}</span>
                            </>) :
                        (<>
                            <CaretRightOutlined/>
                            <span> {LocalizationManager.Instance.getText('Component.showOption') || 'showOption'}</span>
                        </>)
                }
            </div>
            {
                json.more ? <>
                    {json.bodyType === b2BodyType.b2_dynamicBody ?<InputNumberView label={LocalizationManager.Instance.getText('Body.gravityScale') || 'gravityScale'}
                                                                                   tooltip={  TooltipManager.Instance.getText('Body.gravityScale') }
                                                                                   value={json.gravityScale} onChange={function (num) {
                        json.gravityScale = num;
                        EditorManager.Instance.modifyComponent(json);
                    }}></InputNumberView>:null}
                    {json.bodyType === b2BodyType.b2_dynamicBody ? <CheckboxView label={LocalizationManager.Instance.getText('Body.bullet') || 'bullet'}
                                                                                 tooltip={  TooltipManager.Instance.getText('Body.bullet') }
                                                                                 checked={json.bullet} onChange={function (checked) {
                        json.bullet = checked;
                        EditorManager.Instance.modifyComponent(json);
                    }}></CheckboxView> : null}
                    <SliderView key={json.id + 'friction'}
                                label={LocalizationManager.Instance.getText('Body.friction') || 'friction'}
                                tooltip={  TooltipManager.Instance.getText('Body.friction') }
                                value={json.friction} min={0} max={1}
                                onChange={function (num){
                        json.friction = num;
                        EditorManager.Instance.modifyComponent(json);
                    }}></SliderView>
                    {json.bodyType === b2BodyType.b2_dynamicBody ?<InputNumberView label={LocalizationManager.Instance.getText('Body.linearDamping') || 'linearDamping'}
                                                                                   tooltip={  TooltipManager.Instance.getText('Body.linearDamping') }
                                                                                   value={json.linearDamping} onChange={function (num) {
                        json.linearDamping = num;
                        EditorManager.Instance.modifyComponent(json);
                    }}></InputNumberView>:null}
                    {json.bodyType === b2BodyType.b2_dynamicBody ?<InputNumberView label={LocalizationManager.Instance.getText('Body.angularDamping') || 'angularDamping'}
                                                                                   tooltip={  TooltipManager.Instance.getText('Body.angularDamping') }
                                                                                   value={json.angularDamping} onChange={function (num) {
                        json.angularDamping = num;
                        EditorManager.Instance.modifyComponent(json);
                    }}></InputNumberView>:null}
                    <SliderView  key={json.id + 'restitution'}
                                 label={LocalizationManager.Instance.getText('Body.restitution') || 'restitution'}
                                 tooltip={  TooltipManager.Instance.getText('Body.restitution') }
                                 value={json.restitution} min={0} max={1}
                                 onChange={function (num){
                        json.restitution = num;
                        EditorManager.Instance.modifyComponent(json);
                    }}></SliderView>
                </> : null
            }
        </>)
}
import {Button, Modal, Image, Space, Row, Col, Menu, Popconfirm} from 'antd';
import {useEffect, useState} from "react";
import {useAppSelector} from "../../app/hooks";
import {
    changeOnClickAsset, changeOpenSceneList, changeOpenScriptList, changeSelectAssetId, changeSelectScriptId,
    selectOnClickAsset,
    selectOpenSceneList, selectOpenScriptList, selectSelectAssetId
} from "../../store/modalSlice";
import {useDispatch} from "react-redux";
import EditorManager from "../../services/editorManager";
import {
    DeleteFilled,
    DeleteOutlined, EditFilled,
    FileOutlined, CopyOutlined, CopyFilled, SettingFilled
} from '@ant-design/icons';
import AssetItem from "./assetItem";
import * as React from "react";
import ScriptAsset from "mogera-game/scripts/asset/scriptAsset";
import InstanceManager from "mogera-game/scripts/core/instanceManager";
import AnimatorAsset from "mogera-game/scripts/asset/animatorAsset";
import LocalizationManager from "../../services/localizationManager";

export default function ScriptList() {

    const isOpen = useAppSelector(selectOpenScriptList);
    const selectId = useAppSelector(selectSelectAssetId);
    const onClickFunc = selectOnClickAsset();

    const dispatch = useDispatch();

    const handleOk = () => {
        dispatch(changeOpenScriptList(false));
    };

    const handleCancel = () => {
        dispatch(changeOpenScriptList(false));
    };

    const afterClose = () => {
        const onClickFunc = selectOnClickAsset()
        onClickFunc && onClickFunc(null);
        changeOnClickAsset(null);
        dispatch(changeSelectAssetId(''));
    }

    const assetList = EditorManager.Instance.assetManager.getScriptAssetList();
    const [, setAssetList] = useState(assetList);

    let preClickTime = 0;

    const addScript = () => {
        // const name = prompt('새로운 씬 이름');
        // if(!name) {
        //     return;
        // }
        //
        // if( EditorManager.Instance.assetManager.getAsset(name, 'Scene') ) {
        //     window.confirm(LocalizationManager.Instance.getText('Popup.failChangeName'));
        //     return;
        // }
        //
        // const asset = EditorManager.Instance.addScene(name);
        // if( asset ) {
        //     assetList.push(asset);
        //     setAssetList(assetList);
        // }
    };

    let listRender = [];
    for(let i = 0; i < assetList.length; i++) {
        const asset = assetList[i];
        listRender.push(<AssetItem
            key={asset.id}
            asset={asset}
            span={6}
            select={selectId === asset.id}
            menu={<Menu items={[
                {
                    key: '1', label: <div onClick={function (){
                        dispatch(changeSelectScriptId(asset.id));
                    }}><SettingFilled  style={{marginRight:'10px'}}/>
                        {LocalizationManager.Instance.getText('ContextMenu.edit') || 'Edit'}
                    </div>,
                },
                {
                    key: '2', label: <Popconfirm
                        title={LocalizationManager.Instance.getText('Popup.confirmRemove', asset.name)}
                        onConfirm={function (event){
                            event.preventDefault();
                            event.stopPropagation();
                            const removeIdx = assetList.indexOf(asset);
                            if(EditorManager.Instance.removeScene( asset.id )) {
                                assetList.splice( removeIdx, 1 );
                                setAssetList([...assetList]);
                            }
                        }}
                        onCancel={function (event){
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                        okText="제거"
                        cancelText="취소"
                    >
                        <div><DeleteFilled  style={{marginRight:'10px'}}/>
                            {LocalizationManager.Instance.getText('ContextMenu.remove') || 'Remove'}
                        </div>
                    </Popconfirm>
                },
                {
                    key: '3', label: <div onClick={function (event){
                        event.preventDefault();
                        const name = prompt(LocalizationManager.Instance.getText('Popup.changeName'), asset.name);
                        if(!name) return;
                        if(name === asset.name || !name) {
                            return;
                        }

                        const comp = EditorManager.Instance.game.components.getComponent(name);
                        if(comp) {
                            alert(LocalizationManager.Instance.getText('Popup.failAddScript'))
                            return;
                        }

                        if( EditorManager.Instance.assetManager.getAsset(name, asset.type) ) {
                            window.confirm(LocalizationManager.Instance.getText('Popup.failChangeName'));
                        }
                        else {
                            asset.rename(name);
                            setAssetList([...assetList]);
                            EditorManager.Instance.refreshProperty();
                        }
                    }}><EditFilled style={{marginRight:'10px'}}/>
                        {LocalizationManager.Instance.getText('ContextMenu.rename') || 'Rename'}
                    </div>,
                },
                {
                    key: '4', label: <div onClick={function (event){
                        event.preventDefault();
                        let name = EditorManager.Instance.assetManager.getVerifiedName(asset.name, asset.type);
                        const newAsset =  new ScriptAsset(EditorManager.Instance.game);
                        newAsset.rename(name);
                        newAsset.json = JSON.parse(JSON.stringify(asset.json));
                        EditorManager.Instance.assetManager.addAsset( newAsset );
                        assetList.push(newAsset);
                        setAssetList([...assetList]);

                    }}><CopyFilled style={{marginRight:'10px'}}/>
                        {LocalizationManager.Instance.getText('ContextMenu.clone') || 'Clone'}
                    </div>,
                },
            ]}/>}
            onClick={function (){
                if(onClickFunc){
                    onClickFunc(asset.id);
                }
                else {
                    const now = Date.now();
                    if(now - preClickTime <= 250) {
                        dispatch(changeSelectScriptId(asset.id));
                    }
                    preClickTime = now;
                }
            }} selectable={false}>
        </AssetItem>);
    }

    const blankLength = listRender.length % 4;
    if(blankLength) {
        for(let i = 0; i < 4- blankLength; i++) {
            listRender.push(<Col
                key={(listRender.length + i).toString()}
                className="gutter-row" span={6}/>)
        }
    }

    const addAnimator = () => {
        const name = prompt(LocalizationManager.Instance.getText('Popup.inputScriptName'));
        if(!name) {
            return;
        }

        // if( EditorManager.Instance.assetManager.getAsset(name, 'Scene') ) {
        //     window.confirm('이미 사용중인 이름입니다.');
        //     return;
        // }

        const scriptAsset = new ScriptAsset(EditorManager.Instance.game);
        scriptAsset.rename(EditorManager.Instance.assetManager.getVerifiedName(name, scriptAsset.type));
        EditorManager.Instance.assetManager.addAsset(scriptAsset);

        if( scriptAsset ) {
            assetList.push(scriptAsset);
            setAssetList(assetList);
        }
    };

    return( <Modal title={LocalizationManager.Instance.getText('AssetList.Script.title') || 'Script List'}
                   visible={isOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   afterClose={afterClose}
                   footer={[
                       <Button key="back" onClick={addAnimator}>
                           {LocalizationManager.Instance.getText('AssetList.Script.add') || 'Add Script'}
                       </Button>,
                   ]}
    >
        <Row gutter={[12,12]} justify={'center'}>
            {assetList.length ? listRender : <div>{LocalizationManager.Instance.getText('AssetList.Script.empty') || 'empty'}</div>}
        </Row>
    </Modal>)

}
import {Button, Modal, Image, Space, Row, Col, Menu, Popconfirm} from 'antd';
import {useEffect, useState} from "react";
import {useAppSelector} from "../../app/hooks";
import {
    changeOnClickAsset, changeOpenSceneList, changeSelectAssetId,
    selectOnClickAsset,
    selectOpenSceneList, selectSelectAssetId
} from "../../store/modalSlice";
import {useDispatch} from "react-redux";
import EditorManager from "../../services/editorManager";
import {
    CopyFilled,
    DeleteFilled,
    DeleteOutlined, EditFilled,
    FileOutlined
} from '@ant-design/icons';
import {changeCurrentScene} from "../../store/editorSlice";
import AssetItem from "./assetItem";
import * as React from "react";
import {store} from "../../app/store";
import LocalizationManager from "../../services/localizationManager";

export default function SceneList() {

    const isOpen = useAppSelector(selectOpenSceneList);
    const selectId = useAppSelector(selectSelectAssetId);
    const onClickFunc = selectOnClickAsset();


    const dispatch = useDispatch();

    const handleOk = () => {
        dispatch(changeOpenSceneList(false));
    };

    const handleCancel = () => {
        dispatch(changeOpenSceneList(false));
    };

    const afterClose = () => {
        const onClickFunc = selectOnClickAsset()
        onClickFunc && onClickFunc(null);
        changeOnClickAsset(null);
        dispatch(changeSelectAssetId(''));
    }

    const assetList = EditorManager.Instance.assetManager.getSceneAssetList();
    const [initList, setAssetList] = useState(assetList);

    const addScene = () => {
        const name = prompt(LocalizationManager.Instance.getText('Popup.inputNewScene'));
        if(!name) {
            return;
        }

        if( EditorManager.Instance.assetManager.getAsset(name, 'Scene') ) {
            window.confirm(LocalizationManager.Instance.getText('Popup.failChangeName'));
            return;
        }

        const asset = EditorManager.Instance.addScene(name);
        if( asset ) {
            assetList.push(asset);
            setAssetList(assetList);
            dispatch(changeCurrentScene({...store.getState().editor.currentScene}));
        }
    };

    let listRender = [];
    for(let i = 0; i < assetList.length; i++) {
        const asset = assetList[i];
        listRender.push(<AssetItem
            key={asset.id}
            asset={asset}
            span={6}
            select={selectId === asset.id}
            menu={<Menu items={[
                {
                    key: '1', label: <Popconfirm
                        title={LocalizationManager.Instance.getText('Popup.confirmRemove', asset.name)}
                        onConfirm={function (event){
                            event.preventDefault();
                            event.stopPropagation();
                            const removeIdx = assetList.indexOf(asset);
                            if(EditorManager.Instance.removeScene( asset.id )) {
                                assetList.splice( removeIdx, 1 );
                                setAssetList([...assetList]);
                                dispatch(changeCurrentScene({...store.getState().editor.currentScene}));
                            }
                        }}
                        onCancel={function (event){
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                        okText="제거"
                        cancelText="취소"
                    >
                        <div><DeleteFilled  style={{marginRight:'10px'}}/>
                            {LocalizationManager.Instance.getText('ContextMenu.remove') || 'Remove'}
                        </div>
                    </Popconfirm>
                },
                {
                    key: '2', label: <div onClick={function (event){
                        event.preventDefault();
                        const name = prompt(LocalizationManager.Instance.getText('Popup.changeName'), asset.name);
                        if(name === asset.name || !name) {
                            return;
                        }

                        if( EditorManager.Instance.assetManager.getAsset(name, asset.type) ) {
                            window.confirm(LocalizationManager.Instance.getText('Popup.failChangeName'));
                        }
                        else {
                            asset.rename(name);
                            setAssetList([...assetList]);
                            dispatch(changeCurrentScene({...store.getState().editor.currentScene}));
                        }
                    }}><EditFilled style={{marginRight:'10px'}}/>
                        {LocalizationManager.Instance.getText('ContextMenu.rename') || 'Rename'}
                    </div>,
                },
                {
                    key: '3', label: <div onClick={function (event){
                        event.preventDefault();
                        let name = EditorManager.Instance.assetManager.getVerifiedName(asset.name, asset.type);
                        const newAsset =  EditorManager.Instance.addScene(name);
                        newAsset.rename(name);
                        newAsset.scene = JSON.parse(JSON.stringify(asset.scene));
                        assetList.push(newAsset);
                        setAssetList([...assetList]);
                        dispatch(changeCurrentScene({...store.getState().editor.currentScene}));
                    }}><CopyFilled style={{marginRight:'10px'}}/>
                        {LocalizationManager.Instance.getText('ContextMenu.clone') || 'Clone'}
                    </div>,
                },
            ]}/>}
            onClick={function (){
                if(onClickFunc){
                    onClickFunc(asset.id);
                }
                else {
                    const crtSceneAsset = EditorManager.Instance.game.scenes.crtSceneAsset;
                    if( crtSceneAsset.id !== asset.id ) {
                        EditorManager.Instance.saveScene();
                        EditorManager.Instance.startScene(asset.id);
                        handleCancel();
                    }
                }
            }} selectable={false}>
        </AssetItem>);
    }

    const blankLength = listRender.length % 4;
    if(blankLength) {
        for(let i = 0; i < 4- blankLength; i++) {
            listRender.push(<Col
                key={(listRender.length + i).toString()}
                className="gutter-row" span={6}/>)
        }
    }


    return( <Modal title={LocalizationManager.Instance.getText('AssetList.Scene.title') || 'Scene List'}
                   visible={isOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   afterClose={afterClose}
                   footer={[
                       <Button key="back" onClick={addScene}>
                           {LocalizationManager.Instance.getText('AssetList.Scene.add') || 'Add Scene'}
                       </Button>,
                   ]}
    >
        <Row gutter={[12,12]} justify={'center'}>
            {assetList.length ? listRender : <div>{LocalizationManager.Instance.getText('AssetList.Scene.empty') || 'empty'}</div>}
        </Row>
    </Modal>)

}
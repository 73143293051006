import {Button, Col, Input, Row, Space, Typography} from "antd";
import EditorManager from "../../../../../services/editorManager";
import TextureAsset from "../../../../../game/scripts/asset/textureAsset";
import {
    DeleteOutlined,
    DeleteTwoTone,
    DeleteFilled,
    SettingFilled
} from '@ant-design/icons';
import AnimatorAsset from "../../../../../game/scripts/asset/animatorAsset";
import {store} from "../../../../../app/store";
import {changeOpenAnimatorList, changeSelectAnimatorIds} from "../../../../../store/modalSlice";
import {ReactElement} from "react";
import ViewTooltip from "../../viewTooltip";

const {Text} = Typography;

export default function AnimatorAssetView({label, disabled, assetId, onClick, onRemove, onCreate, onModify, tooltip}:
    {label: string, disabled?: boolean, assetId?: string,
        onClick: Function, onRemove: Function, onCreate?: Function, onModify?: Function, tooltip?:string}) {

    const asset = EditorManager.Instance.game.assetManager.getAsset(assetId);

    return <Row wrap={false} className={"valueContainer"}>
        <Col className={'valueLabel'}>
            {
                tooltip? <ViewTooltip title={tooltip}>
                    <Text style={{marginRight: '5px'}}>{label}</Text>
                </ViewTooltip> :  <Text style={{marginRight: '5px'}}>{label}</Text>
            }
        </Col>
        <Col className={'valueBody'} flex="auto">
            <Input  status="warning"  readOnly placeholder={'None(AnimatorAsset)'} className={'inputRef'} value={asset?.name} onClick={()=>{onClick()}}></Input>
        </Col>
        {
            asset || !onCreate? null :
            <Col>
                <Button onClick={()=>{onCreate()}}>New</Button>
            </Col>
        }
        {
            asset && onModify ?
            <Col>
                <Button style={{marginLeft: '5px'}} shape={'circle'} onClick={()=>{onModify()}}>
                    <SettingFilled/>
                </Button>
            </Col> : null
        }
        {
            asset  ? <Col>
                <Button style={{marginLeft: '5px'}} shape={'circle'} onClick={()=>{
                    onRemove();
                }}><DeleteFilled /></Button>
            </Col> : null
        }
    </Row>
}
import React, {LegacyRef, useEffect, useRef} from "react";
import TopMenu from "../components/topMenu/topMenu";
import './editor.css';
import EditorManager from "../services/editorManager";
import EditorLayout, {LayoutManager} from "../components/editorLayout/editorLayout";
import Modal from "../components/modal/modal";
import {store} from "../app/store";
import {Spin} from "antd";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import EditorSpin from "../components/editorLayout/editorSpin";
import Hotkeys from 'react-hot-keys';
import {HotkeysEvent} from "hotkeys-js";
import {treeManager} from "../components/panel/hierarchy/hierarchy";
import {editable} from "../game/scripts/gameObject/gameObject";
import PlayConsole from "../components/editorLayout/playConsole";
import ReactHotkeys from "react-hot-keys";
import wizardSaveData from "../services/wizard/wizardSave";
import {changeTextLocalize, changeTooltipLocalize, LocalizeLang} from "../store/editorSettingSlice";
import { storeUserInfo } from "../store/userSlice"
import LocalizationManager from "../services/localizationManager";
import TooltipManager from "../services/tooltipManager";
import {DEFAULT_LANG} from "../services/constSetting";
import {useSearchParams} from "react-router-dom"
import axios from "axios"


export default function Editor() {
    const [searchParams] = useSearchParams();
    const userToken = searchParams.get('key');

    if(userToken) {

        axios.get(`/game/user/info?token=${userToken}`, {
            baseURL: process.env.REACT_APP_BASE_API_URL,
            headers: {Authorization: `Bearer ${process.env.REACT_APP_API_AUTH_TOKEN}`}

        }).then((res) => {
            const {result} = res.data
            dispatch(storeUserInfo(result.user))

        })
    }
    
    if(wizardSaveData.isData) {
        if(!wizardSaveData.isLoad) {
            wizardSaveData.isLoad = true;
            EditorManager.Instance.loadWizardProject(wizardSaveData.path);
        }
    }
    else
        if(!EditorManager.Instance.scene) {
        EditorManager.Instance.new();
    }


    window.history.pushState(null, null, window.location.href);
    window.history.back();
    window.onpopstate = () => window.history.forward();

    window.onbeforeunload = function(e) {
        return '';
    };
    //
    //
    //

    const dispatch = useAppDispatch();
    const ref = useRef(null);


    useEffect(()=>{
        console.log('Editor mounted');

        dispatch(changeTextLocalize(localStorage.getItem('textLocalize') as LocalizeLang || DEFAULT_LANG));
        dispatch(changeTooltipLocalize(localStorage.getItem('tooltipLocalize') as LocalizeLang || DEFAULT_LANG));

        (ref.current as ReactHotkeys).isKeyDown = false;
        // window.addEventListener('keydown', preventBrowserShorCuts);
        window.addEventListener('keydown', hierarchyKeyDown);
        window.addEventListener('keydown', keyDown);
        window.addEventListener('keyup', keyUp);
        return ()=>{
            console.log('Editor mounted return');
            // window.removeEventListener('keydown', preventBrowserShorCuts);
            window.removeEventListener('keydown', hierarchyKeyDown);
            window.removeEventListener('keydown', keyDown);
            window.removeEventListener('keyup', keyUp);
        }
    },[]);

    function shortcutKey(shortcut: string, event: KeyboardEvent, handle: HotkeysEvent) {
        if(store.getState().modal.selectScriptId) {
            return;
        }

        switch (shortcut) {
            case 'ctrl+d':
                EditorManager.Instance.cloneGameObject();
                event.preventDefault()
                break;
            case 'ctrl+s':
                // EditorManager.Instance.saveScene(false);
                event.preventDefault()
                EditorManager.Instance.saveProject();
                break;
            case 'ctrl+z':
                EditorManager.Instance.undo();
                event.preventDefault()
                break;
            case 'ctrl+p':
                EditorManager.Instance.play();
                event.preventDefault()
                break;
            case 'ctrl+c':
                EditorManager.Instance.copyHierarchyNode();
                event.preventDefault();
                break;
            case 'ctrl+v':
                EditorManager.Instance.paste();
                event.preventDefault();
                break;

        }
    }

    function preventBrowserShorCuts(event: KeyboardEvent) {
        // if (event.ctrlKey && 'cvxspwuazdf'.indexOf(event.key) !== -1) {
        //     event.preventDefault()
        // }
    }

    function keyDown(event: KeyboardEvent) {
        switch (event.key){
            case 'Shift':
                break;
        }
    }

    function keyUp(event: KeyboardEvent) {
        switch (event.key){
            case 'Shift':
                break;
        }
    }

    function hierarchyKeyDown(event: KeyboardEvent) {
        if(event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
            return;
        }

        if(store.getState().modal.selectScriptId) {
            return;
        }

        if(event.altKey || event.ctrlKey || event.shiftKey) return;
        const ids = treeManager.tree?.getSelectedIds();
        if(!ids || !ids.length) return;
        const selectTabId = store.getState().editor.selectTabId;


        const idx = treeManager.tree.visibleIds.indexOf(ids[0]);

        switch (event.key) {
            case 'ArrowUp': {
                if (selectTabId !== 'Hierarchy') {
                    return;
                }
                treeManager.tree.select(idx - 1);
                event.preventDefault()
                break;
            }
            case 'ArrowDown': {
                if (selectTabId !== 'Hierarchy') {
                    return;
                }
                treeManager.tree.select(idx + 1);
                event.preventDefault()
                break;
            }
            case 'ArrowLeft': {
                if (selectTabId !== 'Hierarchy') {
                    return;
                }
                treeManager.tree.props.onToggle(ids[0], false);
                event.preventDefault()
                break;
            }
            case 'ArrowRight': {
                if (selectTabId !== 'Hierarchy') {
                    return;
                }
                treeManager.tree.open(ids[0]);
                event.preventDefault()
                break;
            }
            case 'Delete': {
                if (selectTabId !== 'Hierarchy' && selectTabId !== 'GameView') {
                    return;
                }
                EditorManager.Instance.removeGameObject();
                event.preventDefault()
                break;
            }
            case 'F2':
            {
                if (selectTabId !== 'Hierarchy' ) {
                    return;
                }
                const selectGo = EditorManager.Instance.getSelectObject();
                if(selectGo && editable(selectGo, 'name')) {
                    treeManager.tree?.edit( selectGo.id.toString() );
                }
                break;
            }
        }

    }

    return (
        <Hotkeys
            ref={ref}
            keyName="ctrl+d,ctrl+s,ctrl+z,ctrl+p,ctrl+c,ctrl+v"
            onKeyDown={shortcutKey}

        >
            <div className={"editorRoot"}>
                <TopMenu/>
                <EditorLayout></EditorLayout>
                <Modal></Modal>
                <EditorSpin></EditorSpin>
                <PlayConsole></PlayConsole>
            </div>
        </Hotkeys>

    );
}
import ScriptAsset, {IScriptAssetProp} from "../../game/scripts/asset/scriptAsset";
import {Button, Input, Select, Space, Popconfirm, Dropdown, Menu} from "antd";
import EditorManager from "../../services/editorManager";
import {IComponentConstructor} from "../../game/scripts/core/component";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../app/hooks";
import {changeSelectScriptId, selectSelectScriptId} from "../../store/modalSlice";
import {useDispatch} from "react-redux";
import {changeHierarchySelectIds, selectHierarchySelectIds} from "../../store/hierarchySlice";
import {treeManager} from "../panel/hierarchy/hierarchy";
import {FunctionCategoryList} from "../../game/scripts/function/functionManager";
import {AssetList} from "../../game/scripts/asset/assetManager";
import {DeleteFilled, DownCircleOutlined, MoreOutlined, UpCircleOutlined} from "@ant-design/icons";
import * as React from "react";
import LocalizationManager from "../../services/localizationManager";
const {Option} = Select;

export function ScriptVariable({ json, idx, deleteCallback, applyCallback, moveCallback }: {
    json:IScriptAssetProp,
    idx:number,
    deleteCallback:()=>void,
    applyCallback:(p:IScriptAssetProp)=>boolean,
    moveCallback:(idx:number, dir:number)=>void,
}) {

    const [prop, setProp] = useState({...json});
    // let [modify, setModify] = useState(false);
    let oriProp = json;

    let select = null;

    if( prop.type === 'Value' ) {
        select = <Select style={{width:100}} key={'type_Value'} value={prop.valueType} onChange={function (e){
            prop.valueType = e;
            // setModify(true);
            if(applyCallback(prop)){
                oriProp = prop;
            }
        }}>
            <Option key={'Value_Script'} value={'String'}>String</Option>
            <Option key={'Value_Number'} value={'Number'}>Number</Option>
            <Option key={'Value_Boolean'} value={'Boolean'}>Boolean</Option>
        </Select>

        // 'String' | 'Number' | 'Boolean',
        //     assetType?: 'Texture' | 'Sound' | 'Scene' | 'Script' | 'Prefab' | string,
    }
    else if(prop.type === 'Asset') {
        const options = [];
        for(let i = 0; i < AssetList.length; i++) {
            options.push(<Option key={`Asset_${AssetList[i]}`} value={AssetList[i]}>{AssetList[i]}</Option>)
        }

        select =  <Select style={{width:100}} key={'type_Asset'} value={prop.assetType} onChange={function (e){
            prop.assetType = e;
            // setModify(true);
            if(applyCallback(prop)){
                oriProp = prop;
            }
        }}>
            {options}
        </Select>
    }
    else if(prop.type === 'GameObject') {
        select = <div style={{width:100}}></div>
    }
    else if(prop.type === 'Instance') {
        select = <div style={{width:100}}></div>
    }
    else if(prop.type === 'Component') {
        const options = [];
        const components = EditorManager.Instance.game.components.components;
        for(let k in components) {
            const comp = components[k] as IComponentConstructor;
            if(comp.Name !== 'Script') {
                options.push(<Option value={comp.Name} key={k}>{comp.Name}</Option>)
            }
        }
        const scriptAssets = EditorManager.Instance.game.assetManager.getScriptAssetList();
        for(let i = 0; i < scriptAssets.length; i++) {
            const scriptAsset = scriptAssets[i];
            options.push(<Option value={scriptAsset.name} key={scriptAsset.name}>{scriptAsset.name}</Option>);
        }

        select = <Select style={{width:100}} key={'select2'} value={prop.componentType} onChange={function (e){
            prop.componentType = e;
            // setModify(true);
            if(applyCallback(prop)){
                oriProp = prop;
            }
        }}>
            {options}
        </Select>
    }


    return(
        <div style={{width: '100%', display: 'flex', gap: '10px'}}>
            <input className={'ant-input'} value={prop.name} style={{maxWidth:150}} onBlur={function (){
                if(applyCallback(prop)){
                    oriProp = prop;
                }
                else {
                    setProp({...oriProp});
                    window.confirm(LocalizationManager.Instance.getText('failAddScriptVar'));
                }
            }} onKeyDown={function (e){
                if(e.key === "Enter") {
                    (e.target as HTMLInputElement).blur();
                }
            }} onChange={function (e){
                prop.name = e.target.value;
                setProp({...prop});
            }}/>
            <Select style={{width:100}} key={'select1'} value={prop.type} onChange={function (e){
                prop.type = e;
                prop.valueType = undefined;
                prop.assetType = undefined;
                prop.componentType = undefined;
                if(prop.type === "Value") {
                    prop.valueType = "Number"
                }
                else if(prop.type === "Asset") {
                    prop.assetType = "Texture";
                }
                else if(prop.type === "Component") {
                    prop.componentType = "Sprite";
                }

                if(applyCallback(prop)){
                    oriProp = prop;
                }
            }}>
                <Option key={'Value'} value={'Value'}>Value</Option>
                <Option key={'Asset'} value={'Asset'}>Asset</Option>
                {/*<Option key={'GameObject'} value={'GameObject'}>GameObject</Option>*/}
                <Option key={'Instance'} value={'Instance'}>Instance</Option>
                {/*<Option key={'Component'} value={'Component'}>Component</Option>*/}
            </Select>
            {select}

            <div style={{flexGrow:1}}>
                <Input value={prop.tooltip} placeholder={LocalizationManager.Instance.getText('ScriptEditor.placeHolder') || 'comment'} onChange={function (e){
                    prop.tooltip = e.target.value;
                    if(applyCallback(prop)){
                        oriProp = prop;
                    }
                }}></Input>
            </div>

            <Popconfirm
                title={ LocalizationManager.Instance.getText('Popup.confirmRemoveScriptVar', prop.name)}
                onConfirm={deleteCallback}
                onCancel={function (){

                }}
                okText="제거"
                cancelText="취소"
            >
                <Button><DeleteFilled/></Button>
            </Popconfirm>
            <div  onClick={function (event){
                event.preventDefault();
                event.stopPropagation();
            }}>
                {
                    <Dropdown overlay={<Menu items={[
                        {
                            key: 'item_up', label:  <div onClick={function (){
                                moveCallback(idx, -1);
                            }}><UpCircleOutlined style={{marginRight:'10px'}}/>{LocalizationManager.Instance.getText('ContextMenu.moveUp') || 'Move Up'}</div>
                        },
                        {
                            key: 'item_down', label:  <div onClick={function (){
                                moveCallback(idx, 1);
                            }}><DownCircleOutlined style={{marginRight:'10px'}}/>{LocalizationManager.Instance.getText('ContextMenu.moveDown') || 'Move Down'}</div>
                        }
                    ]}/>}>
                        <Button type={"text"}><MoreOutlined/></Button>
                    </Dropdown>
                }
            </div>
        </div>)
}

export default function ScriptVariableList() {

    const selectObjectId = useAppSelector(selectHierarchySelectIds);
    const selectScriptId = useAppSelector(selectSelectScriptId);
    const scriptAsset = EditorManager.Instance.game.assetManager.getAsset( selectScriptId ) as ScriptAsset;
    const props = scriptAsset?.json?.props as IScriptAssetProp[];
    const [count,setCount] = useState(0)
    const dispatch = useDispatch();


    const propRender = [];
    if(props) {
        for(let i = 0; i < props.length; i++) {
            const idx = i;
            const prop : IScriptAssetProp = props[i];
            propRender.push(<ScriptVariable
                idx={idx}
                key={prop.name + idx.toString()}
                json={prop}
                applyCallback={(p: IScriptAssetProp)=>{
                    for(let j = 0; j < FunctionCategoryList.length; j++) {
                        if(idx === j) continue;
                        if( FunctionCategoryList[j] === p.name )
                            return false;
                    }

                    for(let j = 0; j < props.length; j++) {
                        if(idx === j) continue;
                        if( props[j].name === p.name )
                            return false;
                    }

                    props[idx] = {...p};
                    scriptAsset.json = {
                        code: scriptAsset.json.code,
                        props: props
                    };
                    setCount(count + 1);
                    treeManager.tree?.selectById( selectObjectId[0] );
                    return true;
                }}
                deleteCallback={()=>{
                    props.splice(idx, 1);
                    scriptAsset.json = {
                        code: scriptAsset.json.code,
                        props: props
                    };
                    setCount(count + 1);
                    treeManager.tree?.selectById( selectObjectId[0] );
                }}
                moveCallback={(idx, dir)=>{
                    if((dir === 1 && idx < props.length  - 1)
                        || (dir === -1 && idx > 0)) {
                        const temp = props[idx];
                        props[idx] = props[idx + dir];
                        props[idx + dir] = temp;
                        setCount(count + 1);
                        treeManager.tree?.selectById( selectObjectId[0] );
                    }
                }}
            ></ScriptVariable>)
        }
    }

    return <Space direction={'vertical'} style={{width:'100%'}}>
        {propRender}
        <Button onClick={function (){
            const name = prompt(LocalizationManager.Instance.getText('Popup.inputScriptVarName'));
            if(!name) {
                return;
            }
            for(let j = 0; j < FunctionCategoryList.length; j++) {
                if( FunctionCategoryList[j] === name ) {
                    window.confirm(LocalizationManager.Instance.getText('Popup.failAddScriptVar2'));
                    return;
                }
            }
            for(let j = 0; j < props.length; j++) {
                if( props[j].name === name ) {
                    window.confirm(LocalizationManager.Instance.getText('failAddScriptVar'));
                    return;
                }
            }
            props.push({
                name,
                type:"Value",
                valueType:"Number",
                isArray: false,
            });
            setCount(count + 1);
            treeManager.tree?.selectById( selectObjectId[0] );
        }}>{LocalizationManager.Instance.getText('ScriptEditor.addVariable') || 'Add'}</Button>
    </Space>
}
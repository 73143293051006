import {IComponentViewProps} from "./componentView";
import EditorManager from "../../../../services/editorManager";
import {Input, InputNumber, Select, Space, Typography} from "antd";
import Shape, {EShapeType, IShapeJson} from "../../../../game/scripts/component/shape";
import * as PIXI from 'pixi.js';
import SizeView from "../valueView/sizeView";
import InputNumberView from "../valueView/inputNumberView";
import SelectView from "../valueView/selectView";
import ColorView from "../valueView/colorView";
import LocalizationManager from "../../../../services/localizationManager";
import TooltipManager from "../../../../services/tooltipManager";

const { Text } = Typography;
const { Option } = Select;


export default function ShapeView(props: IComponentViewProps) {

    const json = props.json as IShapeJson;
    const copyJson = JSON.parse(JSON.stringify(json));
    
    let view = null;
    if(json.shapeType === EShapeType.rect){
        view = <SizeView label={LocalizationManager.Instance.getText('Shape.size') || 'size'}
                         tooltip={  TooltipManager.Instance.getText('Shape.size') }
                         size={json} onChange={function (width, height){
            json.width = width;
            json.height = height
            EditorManager.Instance.modifyComponent(json);
        }}></SizeView>
    }
    else if(json.shapeType === EShapeType.circle) {
        view =  <InputNumberView label={LocalizationManager.Instance.getText('Shape.radius') || 'radius'}
                                 tooltip={  TooltipManager.Instance.getText('Shape.radius') }
                                 value={json.width/2} onChange={function (num) {
            json.width = num * 2;
            EditorManager.Instance.modifyComponent(json);
        }}></InputNumberView>
    }

    return(
        <>
            <SelectView label={LocalizationManager.Instance.getText('Shape.shape') || 'shape'}
                        tooltip={  TooltipManager.Instance.getText('Shape.shape') }
                        value={json.shapeType} onChange={function (value){
                json.shapeType = value;
                EditorManager.Instance.modifyComponent(json);
            }}>
                <Option value={EShapeType.rect}>{LocalizationManager.Instance.getText('Shape.shape.Rect') || 'Rect'}</Option>
                <Option value={EShapeType.circle}>{LocalizationManager.Instance.getText('Shape.shape.Circle') || 'Circle'}</Option>
            </SelectView>
            {view}
            <ColorView key={json.color}
                       name={LocalizationManager.Instance.getText('Shape.color') || 'color'}
                       tooltip={  TooltipManager.Instance.getText('Shape.color') }
                       value={PIXI.utils.hex2string(json.color)} onChange={function (color){
                const colorStr = color.replace('#', '');
                const comp = EditorManager.Instance.findComponent(json.id) as Shape;
                comp.color = PIXI.utils.string2hex(colorStr);
            }} onChangeComplete={function (color){
                const colorStr = color.replace('#', '');
                json.color = PIXI.utils.string2hex(colorStr);
                EditorManager.Instance.modifyComponent(json, copyJson);
            }}></ColorView>
            <InputNumberView label={LocalizationManager.Instance.getText('Shape.alpha') ||'alpha'}
                             tooltip={ TooltipManager.Instance.getText('Shape.alpha')}
                             value={json.alpha} onChange={function (num){
                json.alpha = num;
                EditorManager.Instance.modifyComponent(json);
            }}/>
        </>)
}
export enum EPostUpdateProperty {
    none,
    destroyObject,
    changeScene,
}

export interface IPostUpdateInfo {
    func: Function,
    property: EPostUpdateProperty,
}

export default class PostUpdate {
    private _list: IPostUpdateInfo[] = [];

    addPostUpdate(func: Function, property: EPostUpdateProperty = EPostUpdateProperty.none) {
        this._list.push({
            func,
            property,
        });
    }

    destroy() {
        this._list.length = 0;
    }

    update() {
        if( this._list.length ) {
            this._list.sort( (infoA, infoB) => {
                return infoA.property - infoB.property;
            } );
            for(let i = 0; i < this._list.length; i++) {
                this._list[i].func();
            }
            this._list.length = 0;
        }
    }
}
import BaseFunction from "../baseFunction";
import {scriptTableData} from "../scriptTable";

export const InputFunctionTable: scriptTableData[] = [
    // {
    //     value: `if(input.left){\n\n}`,
    //     caption: `if(input.left){\n\n}`,
    //     score: 10,
    //     meta: 'input',
    //     name: 'input.left',
    //     description: {
    //         ko: '왼쪽 방향키 버튼이 눌러졌는지 확인합니다.',
    //         en: '',
    //     },
    // },
    // {
    //     value: `if(input.right){\n\n}`,
    //     caption: `if(input.right){\n\n}`,
    //     score: 10,
    //     meta: 'input',
    //     name: 'input.right',
    //     description: {
    //         ko: '오른쪽 방향키 버튼이 눌러졌는지 확인합니다.',
    //         en: '',
    //     },
    // },
    // {
    //     value: `if(input.up){\n\n}`,
    //     caption: `if(input.up){\n\n}`,
    //     score: 10,
    //     meta: 'input',
    //     name: 'input.up',
    //     description: {
    //         ko: '위쪽 방향키 버튼이 눌러졌는지 확인합니다.',
    //         en: '',
    //     },
    // },
    // {
    //     value: `if(input.down){\n\n}`,
    //     caption: `if(input.down){\n\n}`,
    //     score: 10,
    //     meta: 'input',
    //     name: 'input.down',
    //     description: {
    //         ko: '아래쪽 방향키 버튼이 눌러졌는지 확인합니다.',
    //         en: '',
    //     },
    // },
    // {
    //     value: `if(input.space){\n\n}`,
    //     caption: `if(input.space){\n\n}`,
    //     score: 10,
    //     meta: 'input',
    //     name: 'input.space',
    //     description: {
    //         ko: '스페이바 버튼이 눌러졌는지 확인합니다.',
    //         en: '',
    //     },
    // },
    {
        value: `input.keyDown('ArrowLeft')`,
        caption: `input.keyDown('ArrowLeft'): boolean`,
        score: 10,
        meta: 'input',
        name: 'input.keyDown',
        description: {
            ko: '설정된 키가 눌러졌는지 확인합니다.',
            en: '',
        },
    },
    {
        value: `input.keyUp('ArrowLeft')`,
        caption: `input.keyUp('ArrowLeft'): boolean`,
        score: 10,
        meta: 'input',
        name: 'input.keyUp',
        description: {
            ko: '설정된 키가 눌러졌다가 때어졌는지 확인합니다.',
            en: '',
        },
    },
    {
        value: `input.key('ArrowLeft')`,
        caption: `input.key('ArrowLeft'): boolean`,
        score: 10,
        meta: 'input',
        name: 'input.key',
        description: {
            ko: '설정된 키가 눌러진 상태인지 확인합니다.',
            en: '',
        },
    },
    {
        value: `input.onKeyDown('ArrowLeft', function(){\n\n})`,
        caption: `input.onKeyDown(key: string, function(){\n\n})`,
        score: 10,
        meta: 'input',
        name: 'input.onKeyDown',
        description: {
            ko: '설정된 키가 눌러졌을때 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `input.onKeyUp('ArrowLeft', function(){\n\n})`,
        caption: `input.onKeyUp(key: string, function(){\n\n})`,
        score: 10,
        meta: 'input',
        name: 'input.onKeyUp',
        description: {
            ko: '설정된 키가 눌러졌다가 때어졌을때 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `input.onKey('ArrowLeft', function(){\n\n})`,
        caption: `input.onKey(key: string, function(){\n\n})`,
        score: 10,
        meta: 'input',
        name: 'input.onKey',
        description: {
            ko: '설정된 키가 눌러진 상태일때 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `input.position()`,
        caption: `input.position(): {x: number, y: number}`,
        score: 10,
        meta: 'input',
        name: 'input.position',
        description: {
            ko: '화면상의 포인터 좌표를 반환합니다.',
            en: '',
        },
    },
    {
        value: `input.position()`,
        caption: `input.position(): {x: number, y: number}`,
        score: 10,
        meta: 'input',
        name: 'input.position',
        description: {
            ko: '화면상의 포인터 좌표를 반환합니다.',
            en: '',
        },
    },
    {
        value: `input.pointerDown()`,
        caption: `input.pointerDown(): boolean`,
        score: 10,
        meta: 'input',
        name: 'input.pointerDown',
        description: {
            ko: '포인터가 화면을 누른 순간을 확인합니다.',
            en: '',
        },
    },
    {
        value: `input.pointerStay()`,
        caption: `input.pointerStay(): boolean`,
        score: 10,
        meta: 'input',
        name: 'input.pointerStay',
        description: {
            ko: '포인터가 화면을 누른 상태인지 여부를 확인합니다.',
            en: '',
        },
    },
    {
        value: `input.pointerUp()`,
        caption: `input.pointerUp(): boolean`,
        score: 10,
        meta: 'input',
        name: 'input.pointerUp',
        description: {
            ko: '포인터가 화면에서 때어진 순간은 확인합니디.',
            en: '',
        },
    },

];

export default class InputFunction extends BaseFunction {
    protected _name: string = 'Input';

    public get instance(): InputFunction {
        return this;
    }
    public get left(): boolean {
        return this.gameObject.game.input.left;
    }
    public get right(): boolean {
        return this.gameObject.game.input.right;
    }
    public get up(): boolean {
        return this.gameObject.game.input.up;
    }
    public get down(): boolean {
        return this.gameObject.game.input.down;
    }
    public get space(): boolean {
        return this.gameObject.game.input.space;
    }

    public onKeyDown(key: string, callback: Function) {
        this.manager.event.onKeyDown(key,callback);
    }
    public onKeyUp(key: string, callback: Function) {
        this.manager.event.onKeyUp(key,callback);
    }
    public onKey(key: string, callback: Function) {
        this.manager.event.onKey(key,callback);
    }

    public keyDown(key: string): boolean {
        return this.gameObject.game.input.getKeyDown(key);
    }

    public keyUp(key: string): boolean {
        return this.gameObject.game.input.getKeyUp(key);
    }

    public key(key: string): boolean {
        return this.gameObject.game.input.getKey(key);
    }

    public position(): {x:number,y:number} {
        return this.gameObject.game.input.getPointerPosition();
    }

    public pointerDown(): boolean {
        return this.gameObject.game.input.getPointerDown();
    }

    public pointerStay(): boolean {
        return this.gameObject.game.input.getPointerStay();
    }

    public pointerUp(): boolean {
        return this.gameObject.game.input.getPointerUp();
    }

    destroy() {
        super.destroy();
    }
}
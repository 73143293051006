import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {RootState} from "../app/store";
import {Key} from "antd/es/table/interface";
import EditorManager from "../services/editorManager";
import EventEmitter from 'events';
import {HierarchyData} from "./hierarchySlice";

export interface IUser {
    name: string,
    id: number,
    channel_id: string,
    email: string,
    uid: string,
}
export interface UserInfoState {
    user: IUser;
}
const initialState:UserInfoState = {
    user: undefined
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        storeUserInfo: (state, action) => {
            state.user = action.payload
        }
    },
});
export const { storeUserInfo } = userSlice.actions
export default userSlice.reducer;
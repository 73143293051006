export default class Command {
    constructor() {
    }

    execute() {
        
    }

    undo(){

    }
}
import {Button, Modal, Image, Space, Row, Col, Menu, Popconfirm} from 'antd';
import {useState} from "react";
import {useAppSelector} from "../../app/hooks";
import {
    changeOnClickAsset, changeOpenAnimatorList,
    changeOpenSoundList, changeSelectAnimatorIds, changeSelectAssetId, changeSelectScriptId,
    selectOnClickAsset, selectOpenAnimatorList,
    selectOpenSoundList, selectSelectAssetId
} from "../../store/modalSlice";
import {useDispatch} from "react-redux";
import EditorManager from "../../services/editorManager";
import {
    DeleteOutlined,
    FileOutlined,
    DeleteTwoTone,
    DeleteFilled,
    SettingFilled,
} from '@ant-design/icons';
import AssetItem from "./assetItem";
import TextureAsset from "../../game/scripts/asset/textureAsset";
import AnimatorAsset from "mogera-game/scripts/asset/animatorAsset";
import {changeHierarchySelectIds, selectHierarchySelectIds} from "../../store/hierarchySlice";
import {store} from "../../app/store";
import * as React from "react";
import LocalizationManager from "../../services/localizationManager";

export default function AnimatorList() {

    const isOpen = useAppSelector(selectOpenAnimatorList);
    const selectId = useAppSelector(selectSelectAssetId);
    const onClickFunc = selectOnClickAsset();

    const dispatch = useDispatch();

    const handleOk = () => {
        dispatch(changeOpenAnimatorList(false));
    };

    const handleCancel = () => {
        dispatch(changeOpenAnimatorList(false));
    };

    const afterClose = () => {
        const onClickFunc = selectOnClickAsset()
        onClickFunc && onClickFunc(null);
        changeOnClickAsset(null);
        dispatch(changeSelectAssetId(''));
    }

    const animatorList = EditorManager.Instance.assetManager.getAnimatorAssetList();
    const [, setAnimatorList] = useState(animatorList);

    const addAnimator = () => {
        const name = prompt('새로운 애니메이터 이름');
        if(!name) {
            return;
        }

        // if( EditorManager.Instance.assetManager.getAsset(name, 'Scene') ) {
        //     window.confirm(LocalizationManager.Instance.getText('Popup.failChangeName'));
        //     return;
        // }

        const animatorAsset = new AnimatorAsset(EditorManager.Instance.game);
        animatorAsset.rename(EditorManager.Instance.assetManager.getVerifiedName(name, animatorAsset.type));
        EditorManager.Instance.assetManager.addAsset(animatorAsset);

        if( animatorAsset ) {
            animatorList.push(animatorAsset);
            setAnimatorList(animatorList);
        }
    };

    let preClickTime = 0;

    let listRender = [];
    for(let i = 0; i < animatorList.length; i++) {
        const asset = animatorList[i];
        const previewId = asset.json.animations[0]?.frames[0]
        const tex = EditorManager.Instance.assetManager.getAsset(previewId) as TextureAsset;

        const render = <AssetItem
            key={asset.id}
            span={6}
            asset={asset}
            menu={<Menu items={[
                {
                    key: '1', label: <Popconfirm
                        title={LocalizationManager.Instance.getText('Popup.confirmRemove', asset.name)}
                        onConfirm={function (event){
                            event.preventDefault();
                            event.stopPropagation();
                            animatorList.splice( animatorList.indexOf(asset), 1 );
                            EditorManager.Instance.assetManager.removeAsset( asset.id );
                            setAnimatorList([...animatorList]);
                            dispatch( changeHierarchySelectIds( [...store.getState().hierarchy.selectIds] ));
                            EditorManager.Instance.onSelect(store.getState().hierarchy.selectIds);
                        }}
                        onCancel={function (event){
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                        okText="제거"
                        cancelText="취소"
                    >
                        <div><DeleteFilled style={{marginRight:'10px'}}/>
                            {LocalizationManager.Instance.getText('ContextMenu.remove') || 'Remove'}
                        </div>
                    </Popconfirm>
                },
                {
                    key: '2', label: <div onClick={function (){
                        dispatch(changeSelectAnimatorIds([asset?.id]));
                    }}><SettingFilled style={{marginRight:'10px'}}/>
                        {LocalizationManager.Instance.getText('ContextMenu.edit') || 'Edit'}
                    </div>,
                },
            ]}/>}
            select={selectId === asset.id}
            selectable={false}
            onClick={function (){
                if(onClickFunc){
                    onClickFunc(asset.id);
                }
                else {
                    const now = Date.now();
                    if(now - preClickTime <= 250) {
                        dispatch(changeSelectAnimatorIds([asset?.id]));
                    }
                    preClickTime = now;
                }
            }}>
                {
                    tex ? <Image src={tex.resource.url}
                                 height={80}
                                 preview={false}
                                 className={'image'}
                        /> : null
                }
        </AssetItem>
        listRender.push(render);
    }

    const blankLength = listRender.length % 4;
    if(blankLength) {
        for(let i = 0; i < 4- blankLength; i++) {
            listRender.push(<Col
                key={(listRender.length + i).toString()}
                className="gutter-row" span={6}/>)
        }
    }


    return(<>
        <Modal title={LocalizationManager.Instance.getText('AssetList.Animator.title') || 'Animator List'}
               visible={isOpen}
               onOk={handleOk}
               onCancel={handleCancel}
               afterClose={afterClose}
               footer={[
                   <Button key="back" onClick={addAnimator}>
                       {LocalizationManager.Instance.getText('AssetList.Animator.add') || 'Add Animator'}
                   </Button>,
               ]}
        >
            <Row gutter={[12,12]} justify={'center'}>
                {animatorList.length ? listRender : <div>{LocalizationManager.Instance.getText('AssetList.Animator.empty') || 'empty'}</div>}
            </Row>
        </Modal>
    </>)
}
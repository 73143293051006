import {IComponentViewProps} from "./componentView";
import Sprite, {ISpriteJson} from "../../../../game/scripts/component/sprite";
import EditorManager from "../../../../services/editorManager";
import {store} from "../../../../app/store";
import {Button, Input, InputNumber, Space, Typography, Tooltip, Col, Row, Select} from "antd";
import PointView from "../valueView/pointView";
import SizeView from "../valueView/sizeView";
import TextureAssetView from "../valueView/ref/textureAssetView";
import TextureAsset from "../../../../game/scripts/asset/textureAsset";
import {CaretDownOutlined, CaretRightOutlined, ClearOutlined} from "@ant-design/icons";
import CheckboxView from "../valueView/checkboxView";
import ColorView from "../valueView/colorView";
import * as PIXI from 'pixi.js';
import InputNumberView from "../valueView/inputNumberView";
import SelectView from "../valueView/selectView";
import BorderView from "../valueView/borderView";
import Shape from "mogera-game/scripts/component/shape";
import {useState} from "react";
import LocalizationManager from "../../../../services/localizationManager";
import TooltipManager from "../../../../services/tooltipManager";
import ViewTooltip from "../viewTooltip";


const { Text } = Typography;
const { Option } = Select;

export default function SpriteView(props: IComponentViewProps) {

    const json = props.json as ISpriteJson;
    const copyJson = JSON.parse(JSON.stringify(json));
    // const [more, setMore] = useState(false);


    function setSize(x:number, y:number) {
        json.scale.x = x;
        json.scale.y = y;
        EditorManager.Instance.modifyComponent(json);
    }

    return(<>
        <TextureAssetView
            label={LocalizationManager.Instance.getText('Sprite.texture') || 'texture'}
            tooltip={  TooltipManager.Instance.getText('Sprite.texture') }
            texture={json.texture} onClick={async () => {
            const imgKey = await EditorManager.Instance.selectImage( json.texture );
            if(imgKey !== null) {
                json.texture = imgKey;
                EditorManager.Instance.modifyComponent(json);
            }
        }} onRemove={function (){
            json.texture = '';
            EditorManager.Instance.modifyComponent(json);
        }}></TextureAssetView>
        <SizeView
                label={LocalizationManager.Instance.getText('Sprite.size') || 'size'}
                tooltip={ TooltipManager.Instance.getText('Sprite.size') }
                size={json} disabled={false} onChange={(width, height) => {
                json.applyWidth = width;
                json.applyHeight = height;
                EditorManager.Instance.modifyComponent(json);
                }}></SizeView>


        <div className={'fold'} onClick={function (){
            json.more = !json.more;
            EditorManager.Instance.modifyComponent(json);
        }}>
            {
                json.more ?   (
                        <>
                            <CaretDownOutlined/>
                            <span>{LocalizationManager.Instance.getText('Component.hideOption') || 'hideOption'}</span>
                        </>) :
                    (<>
                        <CaretRightOutlined/>
                        <span>{LocalizationManager.Instance.getText('Component.showOption') || 'showOption'}</span>
                    </>)
            }
        </div>
        {
            json.more ? <>
                <Row wrap={false}  className={"valueContainer"}>
                    <Col className={'valueLabel'}>

                    </Col>
                    <Col className={'valueBody'} flex={'auto'}>
                        <Space>
                            <Button size={'small'} onClick={()=>{setSize(2,2)}}>2x</Button>
                            <Button size={'small'} onClick={()=>{setSize(1,1)}}>1x</Button>
                            <Button size={'small'} onClick={()=>{setSize(0.5,0.5)}}>0.5x</Button>
                            <Button size={'small'} onClick={()=>{setSize(0.25,0.25)}}>0.25x</Button>
                        </Space>
                    </Col>
                </Row>
                <ColorView  key={json.tint} name={LocalizationManager.Instance.getText('Sprite.tint') ||'tint'}
                            tooltip={ TooltipManager.Instance.getText('Sprite.tint') }
                            value={PIXI.utils.hex2string(json.tint)} onChange={function (color){
                    const colorStr = color.replace('#', '');
                    const comp = EditorManager.Instance.findComponent(json.id) as Sprite;
                    comp.tint = PIXI.utils.string2hex(colorStr);

                }} onChangeComplete={function (color){
                    const colorStr = color.replace('#', '');
                    json.tint = PIXI.utils.string2hex(colorStr);
                    EditorManager.Instance.modifyComponent(json, copyJson);
                }}/>
                <InputNumberView label={LocalizationManager.Instance.getText('Sprite.alpha') ||'alpha'}
                                 tooltip={ TooltipManager.Instance.getText('Sprite.alpha')}
                                 value={json.alpha} onChange={function (num){
                    json.alpha = num;
                    EditorManager.Instance.modifyComponent(json);
                }}/>
                <PointView label={LocalizationManager.Instance.getText('Sprite.anchor') || 'anchor'}
                           tooltip={ TooltipManager.Instance.getText('Sprite.anchor') }
                           step={0.01} disabled={false} point={{x:json.anchor.x, y:json.anchor.y}}
                           onChange={(x,y)=>{
                               json.anchor.x = x;
                               json.anchor.y = y;
                               EditorManager.Instance.modifyComponent(json)
                           }}/>
                <CheckboxView label={LocalizationManager.Instance.getText('Sprite.flipX') ||'flipX'}
                              tooltip={  TooltipManager.Instance.getText('Sprite.flipX') }
                              checked={json.flipX} onChange={function (checked){
                    json.flipX = checked;
                    EditorManager.Instance.modifyComponent(json);
                }}></CheckboxView>
                <CheckboxView label={LocalizationManager.Instance.getText('Sprite.flipY') || 'flipY'}
                              tooltip={ TooltipManager.Instance.getText('Sprite.flipY') }
                              checked={json.flipY} onChange={function (checked){
                    json.flipY = checked;
                    EditorManager.Instance.modifyComponent(json);
                }}></CheckboxView>
            </> : null
        }
        </>)
}
import GameObject from "../gameObject/gameObject";
import Script from "../component/script";



export default class LogManager {
    private gameObject : GameObject;
    private _script: Script;
    public get script(): Script {
        return this._script;
    }

    constructor(gameObject: GameObject, script: Script = null) {
        this.gameObject = gameObject;
        this._script = script;
    }

    destroy() {
        this.gameObject = null;
        this._script = null;
    }

    log(text: string, func?: string) {
        console.log(text);
        // if(text === null) {
        //     text = 'null';
        // }
        // else if(text === undefined) {
        //     text = 'undefined'
        // }
        // if(typeof text !== 'string') {
        //     text = (text as any).toString();
        // }
        window.opener?.bridgeMessage && window.opener.bridgeMessage({
            type: 'log',
            data: {
                gameObject: this.gameObject.name,
                message : text,
                func: func,
                script: this.script?.script?.name,
            }
        });
    }

    error(text: string, func?: string, row?: number, column?: number) {
        console.error(text);
        // if(text === null) {
        //     text = 'null';
        // }
        // else if(text === undefined) {
        //     text = 'undefined'
        // }
        // if(typeof text !== 'string') {
        //     text = (text as any).toString();
        // }
        window.opener?.bridgeMessage && window.opener.bridgeMessage({
            type: 'error',
            data: {
                gameObject: this.gameObject.name,
                message : text,
                func: func,
                script: this.script?.script?.name,
                row: row,
                column: column,
            }
        });
    }
}
import AceEditor from "react-ace";
import {Ace, Range, config} from "ace-builds";

import {Button, Modal, Image, Space, Row, Col, Tabs, Input, Dropdown, Select, Menu} from 'antd';
import {useAppSelector} from "../../../app/hooks";
import {
    changeSelectScriptId,
    selectSelectScriptId,
} from "../../../store/modalSlice";
import {useDispatch} from "react-redux";
import EditorManager from "../../../services/editorManager";
import ScriptAsset, {IScriptAssetProp} from "../../../game/scripts/asset/scriptAsset";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import 'ace-builds/src-noconflict/ext-searchbox';
import langTools from "ace-builds/src-noconflict/ext-language_tools";
import Beautify from 'ace-builds/src-noconflict/ext-beautify';


import {ReactElement, useEffect, useRef, useState} from "react";
import ScriptVariableList from "../scriptVariableList";
import {scriptTable, scriptTable_v2, scriptTableData} from "../../../game/scripts/function/scriptTable";
import ScriptTooltip, {scriptTooltip, scriptTooltipChange} from "./scriptTooltip";
import * as acorn from "acorn";

import './aceEditor.css';


/* eslint-disable-next-line */ //@ts-ignore
import worker_js from "file-loader!ace-builds/src-noconflict/worker-js";
import LocalizationManager from "../../../services/localizationManager";
config.setModuleUrl('ace/mode/javascript_worker', worker_js);

const { TabPane } = Tabs;
const { Option } = Select;



// create a completer object with a required callback function:
const sqlTablesCompleter = {
    //@ts-ignore
    getCompletions: function(editor, session, pos, prefix, callback) {
        callback(null,
            scriptTable_v2.map(function(item: scriptTableData) {
                return {
                    caption: item.caption,
                    value: item.value,
                    score: item.score,
                    meta: item.meta,

                    // completer: {
                    //     //@ts-ignore
                    //     insertMatch: function(editor, data) {
                    //         editor.forEachSelection(function() {
                    //             editor.insert(data.caption)
                    //         })
                    //     }
                    // }
                };
        }));
    },
};

// finally, bind to langTools:
langTools.addCompleter(sqlTablesCompleter);
// langTools.setCompleters([langTools.snippetCompleter, langTools.textCompleter,sqlTablesCompleter]);


const aceEditor: {
    editor: Ace.Editor,
} = {
    editor: null
}

export default function ScriptEditor () {

    const selectScriptId = useAppSelector(selectSelectScriptId);
    const isOpen = !!selectScriptId;
    const dispatch = useDispatch();

    const scriptList = EditorManager.Instance.assetManager.getScriptAssetList();
    const scriptAsset = EditorManager.Instance.assetManager.getAsset( selectScriptId ) as ScriptAsset;
    const code = scriptAsset?.json?.code;
    const props = scriptAsset?.json?.props;

    let isChange = false;


    const handleOk = () => {
        try {
            scriptAsset.json = {
                code :aceEditor.editor?.getValue(),
                props: props,
            }
            dispatch(changeSelectScriptId(''));
        }
        catch (e: any) {
            window.confirm( `문법 오류입니다. 잘못된 코드가 없는지 확인해주세요.` );
            console.error(e);
        }
    };

    const handleCancel = () => {
        if(!isChange) {
            dispatch(changeSelectScriptId(''));
            return;
        }
        const ok = window.confirm('변경 내용을 저장하시겠습니까?');
        if(ok) {
            try {
                scriptAsset.json = {
                    code :aceEditor.editor?.getValue(),
                    props: props,
                }
                dispatch(changeSelectScriptId(''));
            }
            catch (e: any) {
                window.confirm( `문법 오류입니다. 잘못된 코드가 없는지 확인해주세요.` );
                console.error(e);
            }
        }
        else {
            if(window.confirm('변경된 내용이 취소됩니다.')) {
                dispatch(changeSelectScriptId(''));
            }
        }
    };

    useEffect(function (){
        isChange = false;
        setTimeout(()=>{
            const editor = aceEditor.editor;
            if(editor) {
                editor.focus();
                editor.navigateFileEnd();
            }
        }, 500);
    });

    function mouseMove( event:any, target: number) {
        const pos = event.getDocumentPosition();
        const text = event.editor.session.getLine(pos.row);
        const column = pos.column;

        const words: {
            start: number,
            end: number,
            word: string,
        }[] = [];
        let word = '';
        let start = -1;
        let selectWord = '';
        for(let i = 0; i < text.length; i++) {
            const t: string = text[i];
            if( t.match(/[`!@#$%^&*\-\'"=+\[\]<>?,|():;/*{} ]/) ) {
                if(word) {
                    words.push({ start: start + 1, end: i - 1, word });
                }
                if( column >= start + 1 && column <= i - 1 ) {
                    selectWord = word;
                }
                start = i;
                word = '';
            }
            else {
                word += t;
            }

            if(i === text.length - 1) {
                if(word) {
                    words.push({ start: start + 1, end: i, word });
                }
                if(column >= start + 1 && column <= i ) {
                    selectWord = word;
                }
            }
        }

        if(selectWord) {
            scriptTooltip.callback && scriptTooltip.callback( {
                x: event.domEvent.x,
                y: event.domEvent.y,
                name: selectWord,
            });
        }
        else {
            scriptTooltip.callback && scriptTooltip.callback();
        }

        // const regex = new RegExp(/([a-zA-Z_{1}][a-zA-Z0-9_]+)/gim);
        // const t = regex.exec( text );
        // console.log(t);
    }

    let markers : number[] = [];

    //@ts-ignore
    const editor = <AceEditor

        name="scriptEditor_AceEditor"
        mode="javascript"
        theme="monokai"
        onLoad={function (editor) {
            aceEditor.editor = editor;
            //@ts-ignore
            aceEditor.editor.on('mousemove', mouseMove);

            //@ts-ignore
            aceEditor.editor.session.$worker.send("setOptions", [{
                moz: true,
                devel: true,
                browser: true,
                node: true,
                laxcomma: true,
                laxbreak: true,
                lastsemic: true,
                onevar: false,
                passfail: false,
                maxerr: 100,
                expr: true,
                multistr: true,
                globalstrict: true,
                esversion: 9,
                evil: true,
            }]);
        }}
        onSelectionChange={function (value: any, event?: any){
            //console.log('onSelectionChange', value, event);
        }}
        onCursorChange={function (value: any, event?: any){
            // console.log('onCursorChange', value, event);
            // const token = aceEditor.editor.getSession().getTokenAt(value.cursor.row, value.cursor.column);
            // const tokens = aceEditor.editor.getSession().getTokens(value.cursor.row);
            // console.log(token);
            // console.log(tokens);
        }}
        onChange={function (value) {

            isChange = true;

            // console.log(aceEditor.editor.getSession().getAnnotations());


            // for(let i = 0; i < markers.length; i++) {
            //     aceEditor.editor.getSession().removeMarker(markers[i]);
            // }
            // markers.length = 0;
            //
            // let result;
            // try {
            //     result = acorn.parse(value, {
            //         ecmaVersion: 2017,
            //         allowAwaitOutsideFunction: true,
            //
            //     });
            //     aceEditor.editor.getSession().setAnnotations([])
            // }
            // catch(e: any) {
            //     const line = e.loc.line - 1;
            //     const column = e.loc.column;
            //     aceEditor.editor.getSession().setAnnotations([{
            //         row: line,
            //         column: column,
            //         text: e.message,
            //         type: 'error'
            //     }]);
            //
            //     const range = new Range(line, 0, line, 999 );
            //     markers.push(aceEditor.editor.getSession().addMarker(range, 'highlightError', 'fullLine', true));
            //
            //     //erroneousLine = editor.session.addMarker(new Range(lineNumber, 0, lineNumber, 144), "errorHighlight", "fullLine");
            // }
        }}
        fontSize={16}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        defaultValue={code}
        value={code}
        focus={true}
        commands={Beautify.commands}
        style={{
            width:'100%',
            height: '60vh'
        }}
        setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 4,
            // useWorker: false,

        }}
    >
    </AceEditor>

    const items = [];
    for(let i = 0; i < scriptList.length; i++) {
        const script = scriptList[i];
        items.push({
            key: script.id,
            label: script.name,
        })
    }


    return <>
        <Modal title={`${LocalizationManager.Instance.getText('ScriptEditor.title') || 'Script Editor'} - ${scriptAsset?.name}`}
               visible={isOpen}
               bodyStyle={{
               }}
               onOk={handleOk}
               onCancel={handleCancel}
               style={{
                   maxWidth: '1400px',
               }}
               width={'80%'}
               zIndex={1001}
        >
            <div style={{
                display: 'flex'
            }}>
                <Menu
                    onClick={function (e){

                        const code = aceEditor.editor?.getValue();

                        if(!isChange) {
                            dispatch(changeSelectScriptId(e.key));
                        }
                        else {
                            const ok = window.confirm('변경 내용을 저장하시겠습니까?');
                            if(ok) {
                                try {
                                    scriptAsset.json = {
                                        code :code,
                                        props: props,
                                    }
                                    dispatch(changeSelectScriptId(e.key));
                                }
                                catch (e: any) {
                                    window.confirm( `문법 오류입니다. 잘못된 코드가 없는지 확인해주세요.` );
                                    console.error(e);
                                }
                            }
                            else {
                                if(window.confirm('변경된 내용이 취소됩니다.')) {
                                    dispatch(changeSelectScriptId(e.key));
                                }
                            }
                        }

                    }}
                    style={{
                        maxWidth: '128px',
                        marginRight: '20px',
                        backgroundColor: '#1f1f1f',
                    }}
                    selectedKeys={[selectScriptId]}
                    mode="inline"
                    inlineCollapsed={false}
                    items={items}
                />
                <Tabs style={{flexGrow: '1',}} defaultActiveKey="2" onChange={function (){

                }}>
                    <TabPane tab={LocalizationManager.Instance.getText('ScriptEditor.variable') || 'variable'} key="1">
                        <Space key={'tab1'} direction={"vertical"} style={{width:'100%'}}>
                            { selectScriptId ? <ScriptVariableList/> : null }
                        </Space>
                    </TabPane>
                    <TabPane tab={LocalizationManager.Instance.getText('ScriptEditor.code') || 'code'} key="2">
                        <div style={{marginBottom: '5px'}}><Button style={{float:'right'}} onClick={function (){
                            Beautify.beautify(aceEditor.editor.session);
                        }}>{LocalizationManager.Instance.getText('ScriptEditor.codePrettier') || 'Code Prettier'}</Button></div>
                        {editor}
                        <ScriptTooltip></ScriptTooltip>
                    </TabPane>
                </Tabs>
            </div>

        </Modal>



    </>
}
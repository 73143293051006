import BaseFunction, {ComponentFunctionError} from "../baseFunction";
import {scriptTableData} from "../scriptTable";
import Body from "../../component/body";
import {XY} from "@box2d/core";
import ComponentFunction from "./componentFunction";
import {CheckComp} from "../scriptError";


export const BodyFunctionTable: scriptTableData[] = [
    {
        value: `body.setVelocity(100, 100)`,
        caption: `body.setVelocity(x: number, y: number)`,
        score: 10,
        meta: 'function',
        name: 'body.setVelocity',
        description: {
            ko: 'Body 의 이동 속도를 설정합니다.',
            en: '',
        },
    },
    {
        value: `body.setVelocityX(100)`,
        caption: `body.setVelocityX(v: number)`,
        score: 10,
        meta: 'function',
        name: 'body.setVelocityX',
        description: {
            ko: 'Body 의 x축 이동 속도를 설정합니다.',
            en: '',
        },
    },
    {
        value: `body.setVelocityY(100)`,
        caption: `body.setVelocityY(v: number)`,
        score: 10,
        meta: 'function',
        name: 'body.setVelocityY',
        description: {
            ko: 'Body 의 y축 이동 속도를 설정합니다.',
            en: '',
        },
    },
    {
        value: `body.setAngularVelocity(100)`,
        caption: `body.setAngularVelocity(v: number)`,
        score: 10,
        meta: 'function',
        name: 'body.setAngularVelocity',
        description: {
            ko: 'Body 의 회전속도를 설정합니다.',
            en: '',
        },
    },
    {
        value: `body.addVelocity(100, 100)`,
        caption: `body.setVelocityY(x: number, y: number)`,
        score: 10,
        meta: 'function',
        name: 'body.addVelocity',
        description: {
            ko: 'Body 의 이동 속도를 더해줍니다.',
            en: '',
        },
    },
    {
        value: `body.getVelocityX()`,
        caption: `body.getVelocityX(): number`,
        score: 10,
        meta: 'function',
        name: 'body.getVelocityX',
        description: {
            ko: 'Body 의 x축 이동속도를 가져옵니다.',
            en: '',
        },
    },
    {
        value: `body.getVelocityY()`,
        caption: `body.getVelocityY(): number`,
        score: 10,
        meta: 'function',
        name: 'body.getVelocityY',
        description: {
            ko: 'Body 의 y축 이동속도를 가져옵니다.',
            en: '',
        },
    },
    {
        value: `body.getAngularVelocity()`,
        caption: `body.getAngularVelocity(): number`,
        score: 10,
        meta: 'function',
        name: 'body.getAngularVelocity',
        description: {
            ko: 'Body 의 회전속도를 가져옵니다.',
            en: '',
        },
    },
    {
        value: `body.setBoxSize(100, 100)`,
        caption: `body.setBoxSize(w: number, h: number)`,
        score: 10,
        meta: 'function',
        name: 'body.setBoxSize',
        description: {
            ko: 'Body 의 박스콜라이더 크기를 변경합니다.',
            en: '',
        },
    },
    {
        value: `body.setPolygon(points)`,
        caption: `body.setPolygon(points: {x:number, y: number}[])`,
        score: 10,
        meta: 'function',
        name: 'body.setPolygon',
        description: {
            ko: 'Body 의 폴리곤 콜라이더를 변경합니다.',
            en: '',
        },
    },
    {
        value: `body.setPosition(10, 10)`,
        caption: `body.setPosition(x: number, y: number)`,
        score: 10,
        meta: 'function',
        name: 'body.setPosition',
        description: {
            ko: 'Body 의 위치를 설정합니다.',
            en: '',
        },
    },
    {
        value: `body.setFilter(mask, category)`,
        caption: `body.setFilter(mask: number, category: number)`,
        score: 10,
        meta: 'function',
        name: 'body.setFilter',
        description: {
            ko: 'Body 의 충돌 마스크와 충돌 카테고리를 설정합니다.',
            en: '',
        },
    },
    {
        value: `body.setAngle(angle)`,
        caption: `body.setAngle(angle: number)`,
        score: 10,
        meta: 'function',
        name: 'body.setAngle',
        description: {
            ko: 'Body 의 회전값을 설정합니다.',
            en: '',
        },
    },
    {
        value: `body.colBottom`,
        caption: `body.colBottom`,
        score: 10,
        meta: 'get',
        name: 'body.colBottom',
        description: {
            ko: 'Body 아래쪽의 접촉 유무를 가져옵니다.',
            en: '',
        },
    },
    {
        value: `body.colTop`,
        caption: `body.colTop`,
        score: 10,
        meta: 'get',
        name: 'body.colTop',
        description: {
            ko: 'Body 위쪽의 접촉 유무를 가져옵니다.',
            en: '',
        },
    },
    {
        value: `body.colLeft`,
        caption: `body.colLeft`,
        score: 10,
        meta: 'get',
        name: 'body.colLeft',
        description: {
            ko: 'Body 왼쪽의 접촉 유무를 가져옵니다.',
            en: '',
        },
    },
    {
        value: `body.colRight`,
        caption: `body.colRight`,
        score: 10,
        meta: 'get',
        name: 'body.colRight',
        description: {
            ko: 'Body 오른쪽의 접촉 유무를 가져옵니다.',
            en: '',
        },
    },
]

export default class BodyFunction extends ComponentFunction {
    protected _name: string = 'Body';
    protected _temp: Body = null;
    public get instance(): BodyFunction {
        this._temp = this.gameObject.getComponent(this._name);
        return this;
    }

    public get colBottom(): boolean {
        if(!CheckComp(this,'colBottom')) return;
        return this._temp.colBottom;
    }
    public get colTop(): boolean {
        if(!CheckComp(this,'colTop')) return;
        return this._temp.colTop;
    }
    public get colLeft(): boolean {
        if(!CheckComp(this,'colLeft')) return;
        return this._temp.colLeft;
    }
    public get colRight(): boolean {
        if(!CheckComp(this,'colRight')) return;
        return this._temp.colRight;
    }

    public setVelocity(x: number, y: number) {
        if(!CheckComp(this,'setVelocity')) return;
        this._temp.setVelocity({x,y});
    }
    public setVelocityX(v: number) {
        if(!CheckComp(this,'setVelocityX')) return;
        this._temp.setVelocityX(v);
    }
    public setVelocityY(v: number) {
        if(!CheckComp(this,'setVelocityY')) return;
        this._temp.setVelocityY(v);
    }
    public addVelocity(x: number, y: number) {
        if(!CheckComp(this,'setVelocityY')) return;
        this._temp.addVelocity({x,y});
    }
    public setAngularVelocity(v: number) {
        if(!CheckComp(this,'setAngularVelocity')) return;
        this._temp.setAngularVelocity(v);
    }
    public getVelocity(): {x:number, y:number} {
        if(!CheckComp(this,'getVelocity')) return;
        return this._temp.getVelocity();
    }
    public getVelocityX(): number {
        if(!CheckComp(this,'getVelocityX')) return;
        return this._temp.getVelocityX();
    }
    public getVelocityY(): number {
        if(!CheckComp(this,'getVelocityY')) return;
        return this._temp.getVelocityY();
    }
    public getAngularVelocity(): number {
        if(!CheckComp(this,'getAngularVelocity')) return;
        return this._temp.getAngularVelocity();
    }
    public setPosition(x: number, y: number) {
        if(!CheckComp(this,'setPosition')) return;
        this._temp.setPosition(x, y);
    }
    public setAngle(a: number) {
        if(!CheckComp(this,'setAngle')) return;
        this._temp.setAngle(a);
    }
    public setBoxSize(w: number, y: number) {
        if(!CheckComp(this,'setBoxSize')) return;
        this._temp.setBoxSize(w, y);
    }
    public setPolygon(points: XY[]) {
        if(!CheckComp(this,'setPolygon')) return;
        this._temp.setPolygon(points);
    }
    public setFilter(mask: number, category: number) {
        if(!CheckComp(this,'setFilter')) return;
        this._temp.setFilter(mask, category);
    }
    destroy() {
        super.destroy();
        this._temp = null;
    }
}
import {Button, Col, Input, Row, Typography} from "antd";
import EditorManager from "../../../../../services/editorManager";
import TextureAsset from "../../../../../game/scripts/asset/textureAsset";
import {
    DeleteOutlined,
    DeleteTwoTone,
    DeleteFilled
} from '@ant-design/icons';
import GameObject from "../../../../../game/scripts/gameObject/gameObject";
import Component from "../../../../../game/scripts/core/component";
import {ReactElement} from "react";
import ViewTooltip from "../../viewTooltip";

const {Text} = Typography;

export default function CompRefView({label, disabled, id, compType, onClick, onRemove, onDragEnter, onDragLeave, tooltip}:
    {label: string, disabled?: boolean, id?: string,
        compType: string,
        onClick?: Function, onRemove: Function, onDragEnter: Function, onDragLeave: Function, tooltip?: string}) {

    let comp = null;
    if(id) {
        comp = EditorManager.Instance.findComponent( id ) as Component;
    }

    return <Row wrap={false} className={"valueContainer"}>
        <Col className={'valueLabel'}>
            {
                tooltip? <ViewTooltip title={tooltip}>
                    <Text style={{marginRight: '5px'}}>{label}</Text>
                </ViewTooltip> :  <Text style={{marginRight: '5px'}}>{label}</Text>
            }
        </Col>
        <Col className={'valueBody'} flex="auto">
            <Input readOnly
                   status="warning"
                   className={'inputRef compRef'}
                   placeholder={`None(${compType})`}
                   onDragEnter={()=>{onDragEnter()}}
                   onDragLeave={()=>{onDragLeave()}}
                   disabled={disabled}
                   value={comp? `${comp.name}(${comp.gameObject.name})` : ''}
                   onClick={()=>{onClick && onClick()}}
            >
            </Input>
        </Col>
        {
            comp ? <Col>
                <Button style={{marginLeft: '5px'}} shape={'circle'} onClick={()=>{
                    onRemove();
                }}><DeleteFilled /></Button>
            </Col> : null
        }
    </Row>
}
import Command from "./command";
import Component, {IComponentJson} from "../../game/scripts/core/component";
import Game from "../../game/scripts/core/game";
import EditorManager from "../editorManager";
import {store} from "../../app/store";
import {treeManager} from "../../components/panel/hierarchy/hierarchy";
// import {changeHierarchySelectKeys} from "../../store/hierarchySlice";

export default class MoveComponent extends Command {

    private prevIdx: number;
    private afterIdx: number;
    private gameObjectId : string;

    constructor(gameObjectId: string, prevIdx:number, afterIdx:number) {
        super();
        this.gameObjectId = gameObjectId;
        this.prevIdx = prevIdx;
        this.afterIdx = afterIdx;
    }

    execute() {
        const gameObject = EditorManager.Instance.findGameObject( this.gameObjectId );
        const temp = gameObject.components[this.prevIdx];
        gameObject.components[this.prevIdx] = gameObject.components[this.afterIdx];
        gameObject.components[this.afterIdx] = temp;
        treeManager.tree?.selectById(gameObject.id);
    }

    undo() {
        const gameObject = EditorManager.Instance.findGameObject( this.gameObjectId );
        const temp = gameObject.components[this.afterIdx];
        gameObject.components[this.afterIdx] = gameObject.components[this.prevIdx];
        gameObject.components[this.prevIdx] = temp;
        treeManager.tree?.selectById(gameObject.id);
    }
}
import {Typography, Row, Col, Input, Space, InputNumber} from "antd";
import Slider from '@mui/material/Slider';
import {ReactElement, useState} from "react";
import ViewTooltip from "../viewTooltip";

const {Text} = Typography;

export default function SliderView({ label, value, onChange, min, max, disabled, tooltip }:
                                      {label:string, value:number, min: number, max: number, onChange:(value:number)=>void, disabled?:boolean, tooltip?:string,}) {

    const [val, setVal] = useState(value);

    return <Row wrap={false} className={"valueContainer"}>
        <Col className={'valueLabel'}>
            {
                tooltip? <ViewTooltip title={tooltip}>
                <Text style={{marginRight: '5px'}}>{label}</Text>
            </ViewTooltip> :  <Text style={{marginRight: '5px'}}>{label}</Text>
    }
        </Col>
        <Col className={'valueBody'} flex="auto">
            <div style={{
                display: 'flex'
            }}>
                <Slider
                    style={{
                        marginRight:'10px',
                    }}
                    value={val} min={min} max={max} step={0.01}
                    //@ts-ignore
                    onChange={function (event, num: number, activeThumb: number){
                        setVal(num);
                        //onChange(num);
                    }}
                    onChangeCommitted={function (event, value){
                        onChange(value as number);
                    }}
                    size="small"
                    aria-labelledby="input-slider"
                />
                <InputNumber min={min} max={max} value={val} onChange={function (num: number){
                    console.log(num);
                    setVal(num);
                    onChange(num);
                }} />
            </div>


            {/*<Slider value={val} min={min} max={max} disabled={disabled} step={0.01} onChange={function (num: number){*/}
            {/*    setVal(num);*/}
            {/*}} onAfterChange={function (num: number) {*/}
            {/*    onChange(num);*/}
            {/*}} />*/}
        </Col>
    </Row>
}
import React, {useEffect, useMemo, useRef} from "react";
import { createRoot } from "react-dom/client";
import * as FlexLayout from "flexlayout-react";
import 'flexlayout-react/style/dark.css';
import './editorLayout.css';

import GameView from "../panel/gameView/gameView";
import Hierarchy from "../panel/hierarchy/hierarchy";
import PropertyView from "../panel/propertyView/propertyView";
import PrefabView from "../panel/prefabView/prefabView";
import {IJsonModel, IJsonTabNode, IJsonTabSetNode} from "flexlayout-react/src/model/IJsonModel";
import {run} from "jest";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {changeSelectTabId} from "../../store/editorSlice";
import LocalizationManager from "../../services/localizationManager";
import {selectTextLocalize} from "../../store/editorSettingSlice";

/*
autoSelectTab?: boolean; // default: true - inherited from global tabSetAutoSelectTab
	borderInsets?: IInsets; // default: {"top":0,"right":0,"bottom":0,"left":0} - inherited from global tabSetBorderInsets
	classNameHeader?: string; //  - inherited from global tabSetClassNameHeader
	classNameTabStrip?: string; //  - inherited from global tabSetClassNameTabStrip
	config?: any;
	enableClose?: boolean; // default: false - inherited from global tabSetEnableClose
	enableDeleteWhenEmpty?: boolean; // default: true - inherited from global tabSetEnableDeleteWhenEmpty
	enableDivide?: boolean; // default: true - inherited from global tabSetEnableDivide
	enableDrag?: boolean; // default: true - inherited from global tabSetEnableDrag
	enableDrop?: boolean; // default: true - inherited from global tabSetEnableDrop
	enableMaximize?: boolean; // default: true - inherited from global tabSetEnableMaximize
	enableTabStrip?: boolean; // default: true - inherited from global tabSetEnableTabStrip
	headerHeight?: number; // default: 0 - inherited from global tabSetHeaderHeight
	height?: number;
	id?: string;
	marginInsets?: IInsets; // default: {"top":0,"right":0,"bottom":0,"left":0} - inherited from global tabSetMarginInsets
	minHeight?: number; // default: 0 - inherited from global tabSetMinHeight
	minWidth?: number; // default: 0 - inherited from global tabSetMinWidth
	name?: string;
	selected?: number; // default: 0
	tabLocation?: ITabLocation; // default: "top" - inherited from global tabSetTabLocation
	tabStripHeight?: number; // default: 0 - inherited from global tabSetTabStripHeight
	type: "tabset";
	weight?: number; // default: 100
	width?: number;
 */
const hierarchyJson: IJsonTabNode = {
    type: "tab",
    id:'Hierarchy',
    name: LocalizationManager.Instance.getText('Editor.Hierarchy') || 'Hierarchy',
    component: "Hierarchy",
    enableRename: false,
    enableRenderOnDemand:false,
    enableClose: false,
};

const propertyJson: IJsonTabNode = {
    type: "tab",
    id:'PropertyView',
    name: LocalizationManager.Instance.getText('Editor.PropertyView') || 'PropertyView',
    component: "PropertyView",
    enableRename: false,
    enableClose: false,
};

const prefabViewJSon: IJsonTabNode = {
    type: "tab",
    id:'PrefabView',
    name: LocalizationManager.Instance.getText('Editor.PrefabView') || 'PrefabView',
    component: "PrefabView",
    enableRename: false,
    enableClose: false,
};

const json : IJsonModel = {
    global: {
        tabSetEnableMaximize: false,
        "borderMinSize": 100,
        tabSetEnableTabStrip:true,
        // tabSetHeaderHeight:0,

    },
    borders: [
        {
            "type": "border",
            "location": "left",
            "children": [
                prefabViewJSon
            ]
        },
    ],
    layout: {
        type: "row",
        weight: 100,
        id: 'root',
        children: [
            {
                type: "tabset",
                weight: 25,
                id:'left',
                enableTabStrip: true,
                children: [
                    propertyJson,
                    // prefabViewJSon,
                ]
            },
            {
                type: "tabset",
                weight: 50,
                enableDrop: false,
                enableTabStrip: false,
                children: [
                    {
                        type: "row",
                        id:'GameView',
                        name: undefined,
                        component: "GameView",
                        enableClose: false,
                        enableDrag: false,
                        enableRename: false,
                    },
                ]
            },
            {
                type: "tabset",
                weight: 25,
                enableTabStrip: true,
                id:'right',
                children: [
                    hierarchyJson,
                ]
            }
        ]
    }
};

export const LayoutManager : {
    layout: FlexLayout.Layout,
    initHierarchy: Function,
    initProperty: Function,
    initPrefabList: Function,
    changeLang: Function,
} = {
    layout: null,
    initHierarchy: function (){
        const node = LayoutManager.layout.props.model.getNodeById('Hierarchy');
        if(node) {
            LayoutManager.layout.props.model.doAction( FlexLayout.Actions.selectTab( node.getId() ) );
        }
        else {
            const parent = LayoutManager.layout.props.model.getNodeById('right');
            if(parent) {
                LayoutManager.layout.props.model.doAction( FlexLayout.Actions.addNode(
                    hierarchyJson, 'right', FlexLayout.DockLocation.CENTER, 1  ) );
            }
            else {
                LayoutManager.layout.props.model.doAction( FlexLayout.Actions.addNode(
                    hierarchyJson, 'root', FlexLayout.DockLocation.RIGHT, 0  ) );
            }
        }
    },
    initProperty: function (){
        const node = LayoutManager.layout.props.model.getNodeById('PropertyView');
        if(node) {
            LayoutManager.layout.props.model.doAction( FlexLayout.Actions.selectTab( node.getId() ) );
        }
        else {
            const parent = LayoutManager.layout.props.model.getNodeById('left');
            if(parent) {
                LayoutManager.layout.props.model.doAction( FlexLayout.Actions.addNode(
                    propertyJson, 'left', FlexLayout.DockLocation.CENTER, 1  ) );
            }
            else {
                LayoutManager.layout.props.model.doAction( FlexLayout.Actions.addNode(
                    propertyJson, 'root', FlexLayout.DockLocation.LEFT, 0  ) );
            }
        }
    },
    initPrefabList: function (){
        const node = LayoutManager.layout.props.model.getNodeById('PrefabView');
        if(node) {
            LayoutManager.layout.props.model.doAction( FlexLayout.Actions.selectTab( node.getId() ) );
        }
    },
    changeLang: function () {
        if(!LayoutManager.layout) return;

        const prefab = LayoutManager.layout.props.model.getNodeById('PrefabView');
        if(prefab) {
            prefab.getModel().doAction(FlexLayout.Actions.renameTab(prefab.getId(), LocalizationManager.Instance.getText('Editor.PrefabView')));
        }

        const property = LayoutManager.layout.props.model.getNodeById('PropertyView');
        if(property) {
            property.getModel().doAction(FlexLayout.Actions.renameTab(property.getId(), LocalizationManager.Instance.getText('Editor.PropertyView')));
        }
        const hierarchy = LayoutManager.layout.props.model.getNodeById('Hierarchy');
        if(hierarchy) {
            hierarchy.getModel().doAction(FlexLayout.Actions.renameTab(hierarchy.getId(), LocalizationManager.Instance.getText('Editor.Hierarchy')));
        }
    }
}


export default function EditorLayout() {

    const ref = useRef(null);
    const layoutRef = useRef(null);
    const model = FlexLayout.Model.fromJson(json);
    const dispatch = useAppDispatch();

    function factory(node:any) {
        const component = node.getComponent();
        if (component === "button") {
            return <button>{node.getName()}</button>;
        }
        else if(component === 'Hierarchy') {
            return <Hierarchy/>;
        }
        else if(component === 'GameView') {
            return <GameView/>
        }
        else if(component === 'PropertyView') {
            return <PropertyView/>
        }
        else if(component === 'PrefabView') {
            return <PrefabView/>
        }
    }

    useEffect(function (){
        const layout = layoutRef.current as FlexLayout.Layout;
        LayoutManager.layout = layout;
        return function (){
            LayoutManager.layout = null;
        }

    }, []);


    return(<div className={'editorLayoutRoot'} ref={ref}>
        <FlexLayout.Layout
            onModelChange={function (model){
                dispatch(changeSelectTabId( model.getActiveTabset()?.getSelectedNode()?.getId() ));
            }}
            ref={layoutRef}
            model={model}
            factory={factory}
        ></FlexLayout.Layout>
    </div> )
}
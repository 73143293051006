import BaseFunction from "../baseFunction";
import {scriptTableData} from "../scriptTable";
import PrefabAsset from "../../asset/prefabAsset";
import GameObject from "../../gameObject/gameObject";
import ScriptError from "../scriptError";
import {Util} from "../../util/util";
import FunctionManager from "../functionManager";

export const PrefabFunctionTable: scriptTableData[] = [
    {
        value: `prefab.clone('prefab')`,
        caption: `prefab.clone(prefabName: string | prefab: PrefabAsset ): Instance`,
        score: 10,
        meta: 'function',
        name: 'prefab.clone',
        description: {
            ko: '프리팹의 복제하여 인스턴스를 만듭니다.',
            en: '',
        },
    },

    {
        value: `prefab.cloneChild('prefab')`,
        caption: `prefab.cloneChild(prefabName: string | prefab: PrefabAsset): Instance`,
        score: 10,
        meta: 'function',
        name: 'prefab.cloneChild',
        description: {
            ko: '프리팹의 복제하여 인스턴스로 만들고 자신의 자식으로 만듭니다.',
            en: '',
        },
    },

    {
        value: `prefab.createPrefab()`,
        caption: `prefab.createPrefab(): PrefabAsset`,
        score: 10,
        meta: 'function',
        name: 'prefab.createPrefab',
        description: {
            ko: '인스턴스의 프리팹을 만들고 반환합니다. 이미 만들어진 프리팹이 있으면 해당 프리팹을 반환합니다.',
            en: '',
        },
    },
];

export default class PrefabFunction extends BaseFunction {
    protected _name: string = 'Prefab';
    public get instance(): PrefabFunction {
        return this;
    }

    clone(prefab: PrefabAsset | string, parent : FunctionManager = null) {
        if(typeof(prefab) === 'string') {
            prefab = this.gameObject.game.assetManager.getAssetByName( prefab,  'Prefab') as PrefabAsset;
        }
        else if(!(prefab instanceof PrefabAsset)) {
            throw new ScriptError('인수 타입 오류',
                `프리팹 타입이 필요하지만 ${Util.Type.typeof(prefab)} 타입이 전달되었습니다.`,
                this.log.script?.script?.name, 'prefab.clone');
        }

        if(!(prefab instanceof PrefabAsset)) {
            throw new ScriptError('프리팹 없음',
                `${prefab} 이름의 프리팹을 찾을 수 없습니다.`,
                this.log.script?.script?.name, 'prefab.clone');
        }

        const parentGo = parent?.gameObject || this.gameObject.game.scenes.crtScene.world

        const gameObject = new GameObject(this.gameObject.game);
        parentGo.addChild( gameObject );
        gameObject.fromJson(prefab.prefab, null, false, true);
        return gameObject.functionManager;
    }

    cloneChild(prefab: PrefabAsset | string) {
        return this.clone(prefab, this.manager);
    }

    createPrefab(): PrefabAsset {
        if(this.gameObject.prefabId) {
            const prefab = this.gameObject.game.assetManager.getAsset(this.gameObject.prefabId) as PrefabAsset;
            if(prefab) {
                prefab.prefab = this.gameObject.toJson();
                return prefab;
            }
        }

        const newPrefab = new PrefabAsset(this.gameObject.game, this.gameObject.toJson());
        this.gameObject.prefabId = newPrefab.id;
        newPrefab.rename(this.gameObject.game.assetManager.getVerifiedName(this.gameObject.name, newPrefab.type));
        this.gameObject.game.assetManager.addAsset(newPrefab);
        return newPrefab;
    }
}
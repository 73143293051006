import GameObject from "../gameObject/gameObject";
import * as TWEEN from '@tweenjs/tween.js';

export default class ActionManager {

    private gameObject: GameObject;
    private group: TWEEN.Group = new TWEEN.Group();
    private time : number = 0;
    
    constructor(gameObject: GameObject) {
        this.gameObject = gameObject;
    }

    destroy() {
        this.gameObject = null;
    }

    onEnable() {
        this.time = 0;
    }

    onDisable() {
        this.group.removeAll();
    }

    allStop() {
        this.group.removeAll();
    }

    async moveX( x : number, duration: number ): Promise<void> {
        return new Promise((resolve)=>{
            const tween = new TWEEN.Tween( this.gameObject, this.group );
            tween.to({x}, duration).onComplete(()=>{
                resolve();
            }).start(this.time);
        });
    }

    async moveY( y : number, duration: number ): Promise<void> {
        return new Promise((resolve)=>{
            const tween = new TWEEN.Tween( this.gameObject, this.group );
            tween.to({y}, duration).onComplete(()=>{
                resolve();
            }).start(this.time);
        })

    }

    async wait( duration: number ): Promise<void> {
        return new Promise((resolve)=>{
            const tween = new TWEEN.Tween( this.gameObject, this.group );
            tween.duration(duration).onComplete(()=>{
                resolve();
            }).start(this.time);
        })
    }

    update(delta: number) {
        this.time += delta;
        this.group.update(this.time);
    }
}
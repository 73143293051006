// import 'antd/dist/antd.dark.css';
import './wizard.css';
import WizardPage from "./comp/wizardPage";
import { Card, Space, Typography } from 'antd';
import {useState} from "react";
import {useNavigate} from "react-router-dom";

const { Meta } = Card;
const {Text, Title } = Typography;

export default function Wizard() {

    const [selectGenre, setSelectGenre] = useState('platform');
    const navigate = useNavigate();


    return <div className={'wizardRoot'}>
        <div style={{height: '156px'}}></div>
        <WizardPage title={'장르 선택'} description={'원하는 장르를 선택하세요.'} onNext={function (){
            // window.history.pushState(null, null, `/wizard/${selectGenre}`);
            navigate("/wizard/" + selectGenre );
            switch (selectGenre) {
                case 'platform':
                    break;
                case 'shooting':
                    break;
                case 'running':
                    break;
                case 'match3':
                    break;
            }
        }}>
            <Space>
                <Card
                    className={selectGenre === 'platform' ? 'select' : ''}
                    hoverable
                    cover={<img draggable={false} height={320} src="/img/platform_theme.jpg" />}
                    onClick={function (){
                        setSelectGenre('platform');
                    }}
                >
                    <Title level={5}>
                        플랫포머 게임
                    </Title>
                    <div style={{height:'22px'}}>
                    </div>
                </Card>
                <Card
                    hoverable
                    className={selectGenre === 'shooting' ? 'select' : ''}
                    cover={<img draggable={false} height={320} src="/img/shooting_theme.jpg" />}
                    onClick={function (){
                        setSelectGenre('shooting');
                    }}
                >
                    <Title level={5}>
                        슈팅 게임
                    </Title>
                    <div style={{height:'22px'}}>
                    </div>
                </Card>
                <Card
                    hoverable
                    className={selectGenre === 'running' ? 'select' : ''}
                    cover={<img draggable={false} height={320} src="/img/running_theme.jpg" />}
                    onClick={function (){
                        setSelectGenre('running');
                    }}
                >
                    <Title level={5}>
                        엔드리스 러닝게임
                    </Title>
                    <div style={{height:'22px'}}>
                    </div>
                </Card>
                <Card
                    className={selectGenre === 'match3' ? 'select' : ''}
                    cover={<img draggable={false} height={320} src="/img/match3_theme.jpg" />}
                    onClick={function (){
                        setSelectGenre('match3');
                    }}
                >
                    <Title level={5}>
                        매치3 버블슈터
                    </Title>
                    <div style={{height:'22px'}}>
                    </div>
                </Card>
            </Space>
        </WizardPage>
    </div>
}
import EditorManager from "../editorManager";
import Sprite from "./../../game/scripts/component/sprite";
import TextComp from "./../../game/scripts/component/text";
import Shape from "./../../game/scripts/component/shape";
import Button from "./../../game/scripts/component/button";
import GameObject from "./../../game/scripts/gameObject/gameObject";
import NineSlice from "./../../game/scripts/component/nineSlice";
import TilingSprite from "./../../game/scripts/component/tilingSprite";

export function CreateSprite( x: number = undefined, y: number = undefined, isWorld: boolean = false ) {
    const gameObject = EditorManager.Instance.addGameObject(x, y, isWorld);
    if(gameObject) {
        gameObject.name = 'Sprite';
        gameObject.addComponent(Sprite.Name);
        EditorManager.Instance.createHierarchy();
    }
}

export function CreateNineSlice( x: number = undefined, y: number = undefined, isWorld: boolean = false ) {
    const gameObject = EditorManager.Instance.addGameObject(x, y, isWorld);
    if(gameObject) {
        gameObject.name = 'NineSlice';
        gameObject.addComponent(NineSlice.Name);
        EditorManager.Instance.createHierarchy();
    }
}

export function CreateTilingSprite( x: number = undefined, y: number = undefined, isWorld: boolean = false ) {
    const gameObject = EditorManager.Instance.addGameObject(x, y, isWorld);
    if(gameObject) {
        gameObject.name = 'TilingSprite';
        gameObject.addComponent(TilingSprite.Name);
        EditorManager.Instance.createHierarchy();
    }
}

export function CreateText(x: number = undefined, y: number = undefined, isWorld: boolean = false) {
    const gameObject = EditorManager.Instance.addGameObject(x, y, isWorld);
    if(gameObject) {
        gameObject.name = 'Text';
        gameObject.addComponent(TextComp.Name);
        EditorManager.Instance.createHierarchy();
    }
}

export function CreateShape(x: number = undefined, y: number = undefined, isWorld: boolean = false) {
    const gameObject = EditorManager.Instance.addGameObject(x, y, isWorld);
    if(gameObject) {
        gameObject.name = 'Shape';
        gameObject.addComponent(Shape.Name);
        EditorManager.Instance.createHierarchy();
    }
}

export function CreateButton(x: number = undefined, y: number = undefined, isWorld: boolean = false) {
    const gameObject = EditorManager.Instance.addGameObject(x, y, isWorld);
    if(gameObject) {
        gameObject.name = 'Button';
        const shape = gameObject.addComponent(Shape.Name) as Shape;
        shape.height = 50;
        gameObject.addComponent(Button.Name);
        const textGo = new GameObject( gameObject.game, 'text' );
        gameObject.addChild( textGo );
        const textComp = textGo.addComponent(TextComp.Name) as TextComp
        textComp.color = '#000000';
        textComp.updateStyle();
        textComp.string = 'Button';
        textComp.anchor.set(0.5);
        EditorManager.Instance.createHierarchy();
    }
}


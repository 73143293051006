import {Button, Col, Input, Row, Typography} from "antd";
import EditorManager from "../../../../../services/editorManager";
import TextureAsset from "../../../../../game/scripts/asset/textureAsset";
import {
    DeleteOutlined,
    DeleteTwoTone,
    DeleteFilled
} from '@ant-design/icons';
import {ReactElement} from "react";
import ViewTooltip from "../../viewTooltip";

const {Text} = Typography;

export default function AssetView({label, disabled, assetId, onClick, onRemove, onDrop, onDragEnter,  onDragLeave, tooltip, assetType}:
    {label: string, disabled?: boolean, assetId?: string, assetType: string,
        onClick: Function, onRemove: Function,
        onDrop?: (event:any)=>void, onDragEnter?: (event:any)=>void, onDragLeave?: (event:any)=>void,
        tooltip?: string }) {

    const asset = EditorManager.Instance.game.assetManager.getAsset(assetId) as TextureAsset;

    return <Row wrap={false} className={"valueContainer"}>
        <Col className={'valueLabel'}>
            {
                tooltip? <ViewTooltip title={tooltip}>
                    <Text style={{marginRight: '5px'}}>{label}</Text>
                </ViewTooltip> :  <Text style={{marginRight: '5px'}}>{label}</Text>
            }
        </Col>
        <Col className={'valueBody'} flex="auto">
            <Input
                status="warning"
                readOnly
                className={'inputRef'}
                placeholder={`None(${assetType}Asset)`}
                disabled={disabled}
                value={asset?.name}
                onClick={()=>{onClick()}}
                onDragLeave={onDragLeave}
                onDragEnter={onDragEnter}
                onDrop={onDrop}
            >
            </Input>
        </Col>
        {
            asset ? <Col>
                <Button style={{marginLeft: '5px'}} shape={'circle'} onClick={()=>{
                    onRemove();
                }}><DeleteFilled /></Button>
            </Col> : null
        }
    </Row>
}
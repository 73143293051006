import * as PIXI from 'pixi.js';
import Game from "../core/game";
import Asset from "./asset";
import GameObject from "../gameObject/gameObject";
import Component from "../core/component";
import TextureAsset from "./textureAsset";
import SoundAsset from "./soundAsset";
import SceneAsset from "./sceneAsset";
import * as uuid from "uuid";
import ScriptAsset from "./scriptAsset";
import Scene from "../gameObject/scene";
import AnimatorAsset from "./animatorAsset";
import PrefabAsset from "./prefabAsset";
import FontAsset from "./fontAsset";

export const AssetList: string[] = [
    'Texture', 'Sound', 'Animator', 'Prefab', 'Scene', 'Font',
    // 'Script',
]

export default class AssetManager {

    private assetsList : Asset[] = [];
    private assets : {[id:string] : Asset} = {};

    public addAsset(asset: Asset) {
        this.assets[asset.id] = asset;
        this.assetsList.push(asset);
    }

    public removeAsset(as: Asset | string) {
        let asset: Asset = as as Asset;
        if( as.constructor === String) {
            asset = this.getAsset(as as string);
        }

        delete this.assets[asset.id];
        const idx = this.assetsList.indexOf(asset);
        if(idx > -1) {
            this.assetsList.splice(idx, 1);
        }
        asset.destroy();
    }

    public clear() {
        for(let i = 0; i < this.assetsList.length;) {
            this.removeAsset( this.assetsList[i] );
        }
    }

    public getAsset(id: string, type: string = '') {
        if(!id) {
            return undefined;
        }
        return this.assets[id] || this.getAssetByName(id, type);
    }

    public getAssetByName(name: string, type: string = ''): Asset {
        if(type) {
            return this.assetsList.find(asset => asset.name === name && asset.type === type);
        }
        else {
            return this.assetsList.find(asset => asset.name === name);
        }
    }

    public getVerifiedName(name: string, type: string): string {
        const findAsset =  this.getAssetByName(name, type);
        if(findAsset) {
            for(let i = 0; i < 1000; i++) {
                const newName = `${name}(${i+1})`;
                const findAsset2 =  this.getAssetByName(newName, type);
                if(!findAsset2) {
                    return newName;
                }
            }
        }
        return name;
    }

    public getAssetsPackage() : {
        textures: TextureAsset[],
        sounds: SoundAsset[],
        scenes: SceneAsset[],
        scripts: ScriptAsset[],
        animators: AnimatorAsset[],
        prefabs: PrefabAsset[],
        fonts: FontAsset[],
    } {
        const assets : {
            textures: TextureAsset[],
            sounds: SoundAsset[],
            scenes: SceneAsset[],
            scripts: ScriptAsset[],
            animators: AnimatorAsset[],
            prefabs: PrefabAsset[],
            fonts: FontAsset[],
        } = {
            textures: [],
            sounds: [],
            scenes: [],
            scripts: [],
            animators: [],
            prefabs: [],
            fonts: [],
        }
        for(let i = 0; i < this.assetsList.length; i++) {
            const asset = this.assetsList[i];
            if( asset.type === 'Texture' ) {
                assets.textures.push(asset as TextureAsset);
            }
            else if( asset.type === 'Sound' ) {
                assets.sounds.push(asset as SoundAsset);
            }
            else if( asset.type === 'Scene' ) {
                assets.scenes.push(asset as SceneAsset);
            }
            else if( asset.type === 'Script' ) {
                assets.scripts.push(asset as ScriptAsset);
            }
            else if( asset.type === 'Animator' ) {
                assets.animators.push(asset as AnimatorAsset);
            }
            else if( asset.type === 'Prefab' ) {
                assets.prefabs.push(asset as PrefabAsset);
            }
            else if( asset.type === 'Font' ) {
                assets.fonts.push(asset as FontAsset);
            }
        }

        return assets;
    }

    public getPlayAssets() : any {
        const assets : any = {
            textures: [],
            sounds: [],
            scenes: [],
            scripts: [],
            animators: [],
            prefabs: [],
            fonts: [],
        }
        for(let i = 0; i < this.assetsList.length; i++) {
            const asset = this.assetsList[i];
            if( asset.type === 'Texture' ) {
                assets.textures.push(asset.toPlayData());
            }
            else if( asset.type === 'Sound' ) {
                assets.sounds.push(asset.toPlayData());
            }
            else if( asset.type === 'Scene' ) {
                assets.scenes.push(asset.toPlayData());
            }
            else if( asset.type === 'Script' ) {
                assets.scripts.push(asset.toPlayData());
            }
            else if( asset.type === 'Animator' ) {
                assets.animators.push(asset.toPlayData());
            }
            else if( asset.type === 'Prefab' ) {
                assets.prefabs.push(asset.toPlayData());
            }
            else if( asset.type === 'Font' ) {
                assets.fonts.push(asset.toPlayData());
            }
        }

        return assets;
    }

    public getScript(name: string) : ScriptAsset{
        for(let i = 0; i < this.assetsList.length; i++) {
            if(this.assetsList[i] instanceof ScriptAsset) {
                const asset = this.assetsList[i] as ScriptAsset;
                if(asset.name === name) {
                    return asset;
                }
            }
        }
        return null;
    }

    public getTextureAssetList(): TextureAsset[] {
        const assets: TextureAsset[] = [];
        for(let i = 0; i < this.assetsList.length; i++) {
            if(this.assetsList[i] instanceof TextureAsset) {
                assets.push(this.assetsList[i] as TextureAsset);
            }
        }
        return assets;
    }

    public getSoundAssetList(): SoundAsset[] {
        const assets: SoundAsset[] = [];
        for(let i = 0; i < this.assetsList.length; i++) {
            if(this.assetsList[i] instanceof SoundAsset) {
                assets.push(this.assetsList[i] as SoundAsset);
            }
        }
        return assets;
    }

    public getSceneAssetList(): SceneAsset[] {
        const assets: SceneAsset[] = [];
        for(let i = 0; i < this.assetsList.length; i++) {
            if(this.assetsList[i] instanceof SceneAsset) {
                assets.push(this.assetsList[i] as SceneAsset);
            }
        }
        return assets;
    }

    public getScriptAssetList(): ScriptAsset[] {
        const assets: ScriptAsset[] = [];
        for(let i = 0; i < this.assetsList.length; i++) {
            if(this.assetsList[i] instanceof ScriptAsset) {
                assets.push(this.assetsList[i] as ScriptAsset);
            }
        }
        return assets;
    }

    public getAnimatorAssetList(): AnimatorAsset[] {
        const assets: AnimatorAsset[] = [];
        for(let i = 0; i < this.assetsList.length; i++) {
            if(this.assetsList[i] instanceof AnimatorAsset) {
                assets.push(this.assetsList[i] as AnimatorAsset);
            }
        }
        return assets;
    }

    public getPrefabAssetList(): PrefabAsset[] {
        const assets: PrefabAsset[] = [];
        for(let i = 0; i < this.assetsList.length; i++) {
            if(this.assetsList[i] instanceof PrefabAsset) {
                assets.push(this.assetsList[i] as PrefabAsset);
            }
        }
        return assets;
    }

    public getFontAssetList(): FontAsset[] {
        const assets: FontAsset[] = [];
        for(let i = 0; i < this.assetsList.length; i++) {
            if(this.assetsList[i] instanceof FontAsset) {
                assets.push(this.assetsList[i] as FontAsset);
            }
        }
        return assets;
    }

    public static async LoadAndAddFont(game: Game, fontName: string, buffer: ArrayBuffer, uid: string = uuid.v4()) : Promise<FontAsset> {
        //@ts-ignore
        const fontFace = new FontFace(fontName, buffer);
        const font = await fontFace.load();
        //@ts-ignore
        document.fonts.add(font);

        const asset = new FontAsset(game, fontFace.family, buffer, uid );
        asset.rename(game.assetManager.getVerifiedName(fontName, asset.type));
        game.assetManager.addAsset(asset);
        return asset;

    }

    public static async LoadAndAddTexture(game: Game, url: string, uid: string = uuid.v4())  : Promise<TextureAsset>{
        const __loader = new PIXI.Loader();
        __loader.add(uid, url);
        return new Promise((resolve)=>{
            __loader.load((loader, resources)=>{
                const resource = resources[uid];
                const asset = new TextureAsset(game, resource, uid);
                game.assetManager.addAsset( asset );
                if(resource) {
                    resolve(asset);
                }
            });
        })
    }

    public static async LoadAndAddSound(game: Game, url: string, uid: string = uuid.v4()) : Promise<SoundAsset> {
        const __loader = new PIXI.Loader();
        __loader.add(uid, url);
        return new Promise((resolve)=>{
            __loader.load((loader, resources)=>{
                const resource = resources[uid];
                const asset = new SoundAsset(game, resource);
                game.assetManager.addAsset( asset );
                if(resource) {
                    resolve(asset);
                }
            });
        })
    }

    public destroy() {
        //todo: asset 들 파괴 시켜야 하나?

        this.assets = {};
    }
}
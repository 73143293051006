import Asset, {IAssetData} from "./asset";
import Game from "../core/game";
import {ISceneJson} from "../gameObject/scene";
import {IGameObjectJson} from "../gameObject/gameObject";
import * as PIXI from 'pixi.js';
import {scriptTable} from "../function/scriptTable";
import {FunctionCategoryList} from "../function/functionManager";

export interface IScriptAssetProp {
    name: string,
    type: 'Value' | 'GameObject' | 'Asset' | 'Component' | 'Instance',
    valueType?: 'String' | 'Number' | 'Boolean',
    assetType?: 'Texture' | 'Sound' | 'Scene' | 'Script' | 'Prefab' | 'Animator' | string,
    componentType?: string,
    isArray: boolean,
    tooltip?: string,
}

export interface IScriptAssetJson {
    code: string,
    props: IScriptAssetProp[],
}

export interface IScriptAssetData extends IAssetData {
    json : IScriptAssetJson
}

export default class ScriptAsset extends Asset {

    private _json : IScriptAssetJson;
    protected readonly _type : string = 'Script';
    private _func : Function = null;
    public get func(): Function {
        return this._func;
    }

    constructor(game: Game, json: IScriptAssetJson = {code:'',props:[]}, id: string = undefined) {
        super(game, id);
        if(!json.code) {
            json.code = ``
        }
        this.json = json;
    }

    public get json(): IScriptAssetJson {
        return this._json;
    }

    public set json(json: IScriptAssetJson) {
        this._json = json;
        (this as PIXI.utils.EventEmitter).emit('change');

        let props = '';
        for(let i = 0; i < this._json.props.length; i++) {
            const prop = this._json.props[i];
            props += `let ${prop.name}=__props.${prop.name};`;
        }

        let instance = '';
        for(let i = 0; i < FunctionCategoryList.length; i++) {
            const item = FunctionCategoryList[i];
            instance += `let ${item}=__instance.${item};`;
        }

        //todo 함수 유효체크 or 함수 생성
        this._func = Function(`return async function(__props, __instance) {
${props} ${instance}
try {
${this._json.code}
}
catch(e){
    window.console.error(e);
    console._catch(e);
}
}
`)();

        //const result = func();
        // console.log(result(1,2,3));

    }

    destroy() {
        super.destroy();
    }

    toPlayData(): IScriptAssetData {
        const json = super.toPlayData() as IScriptAssetData;
        json.json = this.json;
        return json;
    }
}
import * as PIXI from 'pixi.js';
import Scene from "../../gameObject/scene";
import Game from "../../core/game";

export default class LoadingScene extends Scene {

    private graphic : PIXI.Graphics = new PIXI.Graphics();

    constructor(game: Game) {
        super(game, 'loadingScene');
        this.addChild(this.graphic);
    }

    setProgress( crt: number, max: number ) {
        let width = this.game.renderer.width;
        let height = this.game.renderer.height;
        let rectHeight = 50;


        this.graphic.clear();
        this.graphic.beginFill(0x888888, 1);
        this.graphic.drawRect( 0, height/2 - rectHeight/2, width, rectHeight  );
        this.graphic.endFill();

        this.graphic.beginFill(0xffffff, 1);
        this.graphic.drawRect( 0, height/2 - rectHeight/2, width * (crt/max), rectHeight  );
        this.graphic.endFill();

    }

}
import Command from "./command";

export default class ActionCommand extends Command {

    private func : Function;
    private undoFunc : Function;

    constructor(func: Function, undoFunc: Function = null) {
        super();
        this.func = func;
        if(!undoFunc) {
            this.undoFunc = func;
        }
        else {
            this.undoFunc = undoFunc;
        }
    }

    execute() {
        this.func();
    }

    undo() {
        this.undoFunc();
    }
}
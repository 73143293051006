import BaseFunction, {ComponentFunctionError} from "../baseFunction";
import {scriptTableData} from "../scriptTable";
import Text from './../../component/text';
import ComponentFunction from "./componentFunction";
import {CheckComp, CheckValueType} from "../scriptError";


export const TextFunctionTable: scriptTableData[] = [
    {
        value: `text.string`,
        caption: `text.string`,
        score: 10,
        meta: 'getter,setter',
        name: 'text.string',
        description: {
            ko: 'Text 의 출력 문자열을 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `text.color`,
        caption: `text.color`,
        score: 10,
        meta: 'getter,setter',
        name: 'text.color',
        description: {
            ko: 'Text 의 색상를 가져오거나 설정합니다.',
            en: '',
        },
    },
]

export default class TextFunction extends ComponentFunction {
    protected _name: string = 'Text';
    protected _temp: Text = null;
    public get instance(): TextFunction {
        this._temp = this.gameObject.getComponent(this._name);
        return this;
    }

    public get string(): string {
        if(!CheckComp(this,'string')) return;
        return this._temp.string;
    }

    public set string(v: string) {
        if(!CheckComp(this,'string')) return;
        this._temp.string = v;
    }

    public get color(): number {
        if(!CheckComp(this,'color')) return;
        const c = (this._temp.color as string).replace('#', '');
        return Number(c);
    }
    public set color(v:number) {
        if(!CheckComp(this,'color')) return;
        if(!CheckValueType(this,v, 'number', 'color')) return;
        this._temp.color = `#${v.toString(16)}`;
        this._temp.updateStyle();
    }

    destroy() {
        super.destroy();
        this._temp = null;
    }
}
import {Checkbox, Typography, Row, Col} from "antd";
import {ReactElement} from "react";
import ViewTooltip from "../viewTooltip";

const {Text} = Typography;

export default function CheckboxView({ label, checked, onChange, disabled, tooltip }:
    {label:string, checked:boolean, onChange:(checked:boolean)=>void, disabled?:boolean, tooltip?:string}) {

    return <Row className={"valueContainer"}>
        <Col className={'valueLabel'}>
            {
                tooltip? <ViewTooltip title={tooltip}>
                    <Text style={{marginRight: '5px'}}>{label}</Text>
                </ViewTooltip> :  <Text style={{marginRight: '5px'}}>{label}</Text>
            }
        </Col>
        <Col flex="auto">
            <Checkbox disabled={disabled} checked={checked} onChange={function (e){
                onChange(e.target.checked);
            }}></Checkbox>
        </Col>
    </Row>
}
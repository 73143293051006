import * as PIXI from 'pixi.js';
import * as uuid from 'uuid';
import Game from "../core/game";
import ReferenceObject from "../core/ReferenceObject";

export interface IAssetData {
    name: string,
    id: string,
    type: string,
    tag?: string,
}

export default class Asset extends ReferenceObject {

    private _name: string;
    public get name(): string {
        return this._name;
    }

    protected readonly _type: string = '';
    public get type(): string {
        return this._type;
    }

    protected readonly _id: string;
    public get id(): string {
        return this._id;
    }

    public tag: string = '';

    private _game: Game;
    public get game(): Game {
        return this._game;
    }

    constructor(game: Game,id: string = uuid.v4()) {
        super();
        this._game = game;
        this._id = id;
    }

    rename(name: string) {
        this._name = name;
    }

    destroy() {
        (this as PIXI.utils.EventEmitter).emit('destroy');
        this._game = null;
    }

    change() {
        (this as PIXI.utils.EventEmitter).emit('change');
    }

    toPlayData() : IAssetData {
        return {
            name: this.name,
            id: this.id,
            type: this.type,
            tag: this.tag,
        }
    }
}
import GameObject, {IGameObjectJson} from "./gameObject";
import Game from "../core/game";

export interface ILayerJson extends IGameObjectJson {

}

export default class Layer extends GameObject {
    constructor(game : Game) {
        super(game);
    }

    toJson(): ILayerJson {
        const json = super.toJson() as ILayerJson;
        return json;
    }

    fromJson(json: ILayerJson) {
        super.fromJson(json);
    }
}
import './topMenu.css';
import {Button, Divider, Dropdown, Menu, notification, Typography, Tag} from 'antd';
import EditorManager from "../../services/editorManager";
import {useDispatch, useSelector} from "react-redux";
import {
    changeOpenAnimatorList,
    changeOpenImageList,
    changeOpenProjectSetting,
    changeOpenSceneList,
    changeOpenSoundList,
    changeOpenFontList,
    changeSelectAssetId,
    changeOpenScriptList,
    changeOnSelectAsset,
    changeOpenEditorSetting
} from "../../store/modalSlice";
import {LayoutManager} from "../editorLayout/editorLayout";
import {
    CreateButton,
    CreateNineSlice,
    CreateShape,
    CreateSprite,
    CreateText, CreateTilingSprite
} from "../../services/factory/createSample";
import {store} from "../../app/store";
import LocalizationManager from "../../services/localizationManager";
import {useNavigate, useNavigationType, useSearchParams} from "react-router-dom";
import {useAppSelector} from "../../app/hooks";
import {selectTextLocalize} from "../../store/editorSettingSlice";
import axios from "axios"
import {zipFileToZip, zipToFiles} from "../../utils/util";
import {changeSpinText} from "../../store/editorSlice";

const {Text} = Typography;

const buttonStyle = {
    display: 'flex',
    width: '220px',
}

const growStyle = {
    flexGrow: '1'
}


const tagStyle = {
    margin: 0,
}

const limitSize = 1024 * 1000 * 5000
export default function TopMenu() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const userToken = searchParams.get('key');
    // const userInfo = useSelector((state : any) => state.user)w
    const userInfo = useAppSelector(state=> state.userInfo)


    useAppSelector(selectTextLocalize);
    async function uploadToZempie(){
        const result = await EditorManager.Instance.build(true)
        const zip =await  zipFileToZip(result)

        const files = await zipToFiles(zip)
        let size: number = 0

        for (let f in files) {

            size += files[f].size
        }

        if (size > limitSize) {
            notification.error({
                message:'500MB 넘는 파일은 올릴 수 없습니다.',
                placement:'top'
            })
            return
        }
        const formData = new FormData()


        let indexFiles : File= null
        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            formData.append(`file_${i + 1}`, file)
            if( file.name === 'index.html' ){
                indexFiles = file
            }
        }

        formData.append('startFile', indexFiles)
        formData.append('size', size.toFixed(2))

        let newWindow = window.open("");

         axios.post(`/mogera/game-file`, formData,{
                baseURL: process.env.REACT_APP_STUDIO_API_URL,
                headers: {Authorization: `Bearer ${userToken}`},
                onUploadProgress: (e) => {
                 const percentage = Math.round((e.loaded * 100) / e.total)
                 store.dispatch(changeSpinText(LocalizationManager.Instance.getText('잠시만 기다려주세요')));
             }
            })
             .then(() => {
                 newWindow.location = `${process.env.REACT_APP_ZEMPIE_URL}/project/upload-mogera?direct=true`
                 store.dispatch(changeSpinText(''));
                 // window.open(`${process.env.REACT_APP_ZEMPIE_URL}/project/upload-mogera`, '_blank');
             })
             .catch((err) => {
                 if(newWindow){
                     newWindow.close()
                 }
             })



    }
    const fileMenu = (<Menu
        items={[
            {key: '1', label: (<div style={{minWidth: '100px'}} onClick={async function (){
                if(window.confirm(LocalizationManager.Instance.getText('Popup.exitWarning'))) {
                    await EditorManager.Instance.clear();
                    EditorManager.Instance.new();
                }


            }}>{LocalizationManager.Instance.getText('File.New') || 'New'}</div>)},
            {key: '2', label: (<div style={{...buttonStyle, minWidth: '100px'}} onClick={async function (){
                await EditorManager.Instance.saveProject();
            }}><Text>{LocalizationManager.Instance.getText('File.Save') || 'Save'}</Text>
                    <div style={growStyle}></div>
                    <div>
                        <Tag color="default" style={tagStyle}>Ctrl</Tag>
                        <Text>+</Text>
                        <Tag color="default" style={tagStyle}>S</Tag>
                    </div>
            </div>)},
            {key: '3', label: (<div onClick={async function (){
                    if(window.confirm(LocalizationManager.Instance.getText('Popup.exitWarning'))) {
                        await EditorManager.Instance.loadProject();
                    }
            }}>{LocalizationManager.Instance.getText('File.Load') || 'Load'}</div>)},
            {key: '4', label: (<div onClick={async function (){
                    await EditorManager.Instance.build();
            }}>{LocalizationManager.Instance.getText('File.Build') || 'Build'}</div>)},
            userToken && {key: '5', label: (<div onClick = {uploadToZempie}>
                            {LocalizationManager.Instance.getText('Zempie.upload') || 'Upload to Zempie'}</div>)}
            // userToken &&  {key: '5', label: (<a href={process.env.REACT_APP_ZEMPIE_URL + "/project/upload-mogera"} target="_blank" rel="noreferrer">
            //         {LocalizationManager.Instance.getText('Zempie.upload') || 'Upload to Zempie'}</a>)}
        ]}
    >
    </Menu>);

    let editMenuCount = 0;
    const editMenu = (<Menu
        items={[
            {key: editMenuCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                EditorManager.Instance.addGameObject(undefined,undefined,true);
                }}>{LocalizationManager.Instance.getText('GameObject.Add') || 'Add GameObject'}</div>)},
            { type: "divider" },
            {key: editMenuCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                    CreateSprite( undefined, undefined, true );
                }}>{LocalizationManager.Instance.getText('GameObject.Sprite') || 'Sprite'}</div>)},
            {key: editMenuCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                    CreateText( undefined, undefined, true );
                }}>{LocalizationManager.Instance.getText('GameObject.Text') || 'Text'}</div>)},
            {key: editMenuCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                    CreateShape( undefined, undefined, true );
                }}>{LocalizationManager.Instance.getText('GameObject.Shape') || 'Shape'}</div>)},
            {key: editMenuCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                    CreateButton( undefined, undefined, true );
                }}>{LocalizationManager.Instance.getText('GameObject.Button') || 'Button'}</div>)},
            {key: editMenuCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                    CreateNineSlice( undefined, undefined, true );
                }}>{LocalizationManager.Instance.getText('GameObject.NineSlice') || 'NineSlice'}</div>)},
            {key: editMenuCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                    CreateTilingSprite( undefined, undefined, true );
                }}>{LocalizationManager.Instance.getText('GameObject.TilingSprite') || 'TilingSprite'}</div>)},

        ]}
    >
    </Menu>);

    let assetMenuCount = 0;
    const assetMenu = (<Menu items={[
        {key: assetMenuCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
            return new Promise<string[]>((resolve)=>{
                store.dispatch(changeOpenImageList(true));
                changeOnSelectAsset((ids: string[]) => {
                    store.dispatch(changeOpenImageList(false));
                    resolve(ids);
                }, false);
            });
        }}>{LocalizationManager.Instance.getText('Asset.ImageList') || 'ImageList'}</div>)},
        {key: assetMenuCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
            dispatch(changeOpenSoundList(true));
        }}>{LocalizationManager.Instance.getText('Asset.SoundList') || 'SoundList'}</div>)},
        {key: assetMenuCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                const asset = EditorManager.Instance.assetManager.getAsset(store.getState().editor.currentScene.id, 'Font');
                dispatch(changeSelectAssetId(asset?.id));
                dispatch(changeOpenSceneList(true));
        }}>{LocalizationManager.Instance.getText('Asset.SceneList') || 'SceneList'}</div>)},
        {key: assetMenuCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                dispatch(changeOpenAnimatorList(true));
        }}>{LocalizationManager.Instance.getText('Asset.AnimatorList') || 'AnimatorList'}</div>)},
        {key: assetMenuCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                LayoutManager.initPrefabList();
        }}>{LocalizationManager.Instance.getText('Asset.PrefabList') || 'PrefabList'}</div>)},
        {key: assetMenuCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                dispatch(changeOpenFontList(true));
            }}>{LocalizationManager.Instance.getText('Asset.FontList') || 'FontList'}</div>)},
        {key: assetMenuCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                dispatch(changeOpenScriptList(true));
            }}>{LocalizationManager.Instance.getText('Asset.ScriptList') || 'ScriptList'}</div>)},
    ]}>
    </Menu>);

    let windowMenuCount=  0;
    const windowMenu = (<Menu items={[
        {key: windowMenuCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                LayoutManager.initHierarchy();
            }}>하이어라키</div>)},
        {key: windowMenuCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                LayoutManager.initProperty();
        }}>프로퍼티</div>)},

    ]}>
    </Menu>);


    const toolsMenu = (<Menu items={[
        {key: '1', label: (<div style={{...buttonStyle, minWidth: '100px'}} onClick={function (){
            EditorManager.Instance.undo();
        }}>
                <Text>{LocalizationManager.Instance.getText('Edit.Undo') || 'Undo'}</Text>
                <div style={growStyle}></div>
                <div>
                    <Tag color="default" style={tagStyle}>Ctrl</Tag>
                    <Text>+</Text>
                    <Tag color="default" style={tagStyle}>Z</Tag>
                </div>
        </div>)},
        { type: "divider" },
        {key: '2', label: (<div style={{...buttonStyle, minWidth: '100px'}} onClick={function (){
                EditorManager.Instance.play();
            }}>
                <Text>{LocalizationManager.Instance.getText('Edit.Play') || 'Play'}</Text>
                <div style={growStyle}></div>
                <div>
                    <Tag color="default" style={tagStyle}>Ctrl</Tag>
                    <Text>+</Text>
                    <Tag color="default" style={tagStyle}>P</Tag>
                </div>
            </div>)},
        { type: "divider" },
        {key: '3', label: (<div style={{minWidth: '100px'}} onClick={function (){
                dispatch(changeOpenProjectSetting(true));
            }}>{LocalizationManager.Instance.getText('Edit.ProjectSetting') || 'Project Setting'}</div>)},
        {key: '4', label: (<div style={{minWidth: '100px'}} onClick={function (){
                dispatch(changeOpenEditorSetting(true));
            }}>{LocalizationManager.Instance.getText('Edit.EditorSetting') || 'Editor Setting'}</div>)},
    ]}>
    </Menu>);


    let exampleCount = 0;
    const example = <Menu items={[
        {key: exampleCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                EditorManager.Instance.loadProjectExample('/example/example1.mogera');
            }}>Scene변경 & Button사용법</div>)},
        {key: exampleCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                EditorManager.Instance.loadProjectExample('/example/example2.mogera');
            }}>Animator사용법 & 레퍼런스참조 & Input사용법</div>)},
        {key: exampleCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                EditorManager.Instance.loadProjectExample('/example/example3.mogera');
            }}>TextAnimation사용법</div>)},
        {key: exampleCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                EditorManager.Instance.loadProjectExample('/example/example4.mogera');
            }}>Camera사용법</div>)},
        {key: exampleCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                EditorManager.Instance.loadProjectExample('/example/example5.mogera');
            }}>sound사용법</div>)},
        {key: exampleCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                EditorManager.Instance.loadProjectExample('/example/example6.mogera');
            }}>물리 사용법</div>)},
        {key: exampleCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                EditorManager.Instance.loadProjectExample('/example/example7.mogera');
            }}>바디 리사이즈</div>)},
        {key: exampleCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                EditorManager.Instance.loadProjectExample('/example/example8.mogera');
            }}>베경 스크롤링</div>)},
        {key: exampleCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                EditorManager.Instance.loadProjectExample('/example/joystick.mogera');
            }}>모바일 조이스틱</div>)},
        // {key: exampleCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
        //         EditorManager.Instance.loadProjectExample('/example/example9.mogera');
        //     }}>엔드리스 패턴생성</div>)},
        { type: "divider" },
        {key: exampleCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                EditorManager.Instance.loadProjectExample('/example/mole.mogera');
            }}>두더지잡기</div>)},
        {key: exampleCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                EditorManager.Instance.loadProjectExample('/example/visualnovel.mogera');
            }}>비주얼노벨</div>)},
        {key: exampleCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                EditorManager.Instance.loadProjectExample('/example/runningGame.mogera');
            }}>러닝게임</div>)},
        // {key: exampleCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
        //         EditorManager.Instance.loadProjectExample('/example/example2.mogera');
        // }}>example2</div>)},
        // {key: exampleCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
        //         EditorManager.Instance.loadProjectExample('/example/example3.mogera');
        //     }}>example3</div>)},
    ]}></Menu>

    const defaultMenu = (<Menu items={[
        {key: exampleCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                // navigate("/wizard/",{
                //      replace: true
                // } );
                window.location.href = `${process.env.REACT_APP_BASE_URL}/wizard/`;
            }}>{
                LocalizationManager.Instance.getText('Help.Wizard') || '게임 템플릿 위자드'
            }</div>)},
        {key: exampleCount++, label: (<div style={{minWidth: '100px'}} onClick={function (){
                open('https://abaft-restaurant-3dc.notion.site/KR-mogera-wiki-7f6d84fc3d2643c4b5d53b2cec96871d', '_blank');
            }}>에디터 위키</div>)},

    ]}>
    </Menu>);

    return(
        <div className={"topMenuRoot"}>
            <Dropdown overlay={fileMenu} placement="bottomLeft">
                <Button type={"text"}>{LocalizationManager.Instance.getText('TopMenu.File') || 'File'}</Button>
            </Dropdown>
            <Dropdown overlay={toolsMenu} placement="bottomLeft">
                <Button type={"text"}>{LocalizationManager.Instance.getText('TopMenu.Edit') || 'Edit'}</Button>
            </Dropdown>
            <Dropdown overlay={assetMenu} placement="bottomLeft">
                <Button type={"text"}>{LocalizationManager.Instance.getText('TopMenu.Asset') || 'Asset'}</Button>
            </Dropdown>
            <Dropdown overlay={editMenu} placement="bottomLeft">
                <Button type={"text"}>{LocalizationManager.Instance.getText('TopMenu.GameObject') || 'GameObject'}</Button>
            </Dropdown>
            {/*<Dropdown overlay={windowMenu} placement="bottomLeft">*/}
            {/*    <Button type={"text"}>{LocalizationManager.Instance.getText('TopMenu.Window') || 'Window'}</Button>*/}
            {/*</Dropdown>*/}
            <Dropdown overlay={example} placement="bottomLeft">
                <Button type={"text"}>{LocalizationManager.Instance.getText('TopMenu.Example') || 'Example'}</Button>
            </Dropdown>
            <Dropdown overlay={defaultMenu} placement="bottomLeft">
                <Button type={"text"}>{LocalizationManager.Instance.getText('TopMenu.Help') || 'Help'}</Button>
            </Dropdown>
        </div>
        )
}
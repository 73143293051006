import BaseFunction from "../baseFunction";
import {scriptTableData} from "../scriptTable";
import ScriptError from "../scriptError";

export const AssetFunctionTable: scriptTableData[] = [
    {
        value: `asset.get('assetName')`,
        caption: `asset.get(assetName: string)`,
        score: 10,
        meta: 'function',
        name: 'asset.get',
        description: {
            ko: '에셋을 가져옵니다.',
            en: '',
        },
    },
];

export default class AssetFunction extends BaseFunction {
    protected _name: string = 'Asset';

    public get instance(): AssetFunction {
        return this;
    }

    get(name: string) {
        const asset = this.gameObject.game.assetManager.getAsset(name);

        if(!asset) {
            throw new ScriptError('에셋 없음',
                `${name} 이름의 에셋을 찾을 수 없습니다.`,
                this.log.script?.script?.name, 'asset.get');
        }

        return asset;
    }

}
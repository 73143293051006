import BaseFunction from "../baseFunction";
import {scriptTableData} from "../scriptTable";
import ScriptError from "../scriptError";
import * as PIXI from 'pixi.js';
import {b2Vec2} from "@box2d/core";

export const UtilFunctionTable: scriptTableData[] = [
    {
        value: `util.normalizeVec2({x:0, y:0})`,
        caption: `util.normalizeVec2(vec2: {x: number, y: number}): {x: number, y: number}`,
        score: 10,
        meta: 'function',
        name: 'util.normalizeVec2',
        tooltip: 'util.normalizeVec2(vec2: {x: number, y: number}): {x: number, y: number}',
        description: {
            ko: '입력받은 벡터의 단위벡터를 구합니다.',
            en: '',
        },
    },
    {
        value: `util.lengthVec2({x:0, y:0})`,
        caption: `util.lengthVec2(vec2: {x: number, y: number}): number`,
        score: 10,
        meta: 'function',
        name: 'util.lengthVec2',
        tooltip: 'util.lengthVec2(vec2: {x: number, y: number}): number',
        description: {
            ko: '입력받은 벡터의 길이를 구합니다.',
            en: '',
        },
    },
    {
        value: `util.angleVec2({x:0, y:0})`,
        caption: `util.angleVec2(vec2: {x: number, y: number}): number`,
        score: 10,
        meta: 'function',
        name: 'util.angleVec2',
        description: {
            ko: '입력받은 벡터의 각도를 구합니다.',
            en: '',
        },
    },
    {
        value: `util.dotVec2({x:0, y:0}, {x:0, y:0})`,
        caption: `util.dotVec2(vecA: {x: number, y: number}, vecB: {x: number, y: number}): number`,
        score: 10,
        meta: 'function',
        name: 'util.dotVec2',
        description: {
            ko: '두 벡터의 내적을 구합니다.',
            en: '',
        },
    },
];

export default class UtilFunction extends BaseFunction {
    protected _name: string = 'Util';
    private _tempVec2: b2Vec2 = new b2Vec2();

    public get instance(): UtilFunction {
        return this;
    }

   public normalizeVec2(vec2: {x:number, y: number}) : {x:number, y:number} {
       this._tempVec2.Set(vec2.x, vec2.y);
       this._tempVec2.Normalize();
       return {x: this._tempVec2.x, y:this._tempVec2.y};
   }

   public lengthVec2(vec2: {x:number, y: number}): number {
       this._tempVec2.Set(vec2.x, vec2.y);
       return this._tempVec2.Length();
   }

   public angleVec2(vec2: {x:number, y: number}): number {
        return Math.atan2( vec2.x, -vec2.y );
   }

   public dotVec2( vecA: {x:number, y: number}, vecB: {x:number, y: number} ): number {
       this._tempVec2.Set(vecA.x, vecA.y);
       return this._tempVec2.Dot(vecB);
   }
}
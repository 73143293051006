import * as PIXI from 'pixi.js';
import EditorScene from "./editorScene";
import {Util} from "../../util/util";
import Shape, {EShapeType} from "../../component/shape";
import {b2Vec2} from "@box2d/core";
import {IGameObjectJson} from "../../gameObject/gameObject";
import {IComponentJson} from "../../core/component";

export default class GraphicResizer extends PIXI.Container {

    private _target : Shape;
    public get target(): Shape {
        return this._target;
    }
    public set target(tg: Shape) {
        if(tg) {
            this._target = tg;
        }
        else {
            this._target = tg;
        }
        this.update();
    }
    public data: {
        capture: IGameObjectJson,
        compCapture: IComponentJson,
        timeoutId: number,
        isChange: boolean,
    } = {
        capture: null,
        compCapture: null,
        timeoutId: 0,
        isChange: false,
    };

    private mover : PIXI.Graphics = new PIXI.Graphics();

    private lt : PIXI.Graphics = new PIXI.Graphics();
    private lb : PIXI.Graphics = new PIXI.Graphics();
    private rt : PIXI.Graphics = new PIXI.Graphics();
    private rb : PIXI.Graphics = new PIXI.Graphics();
    private l : PIXI.Graphics = new PIXI.Graphics();
    private r : PIXI.Graphics = new PIXI.Graphics();
    private t : PIXI.Graphics = new PIXI.Graphics();
    private b : PIXI.Graphics = new PIXI.Graphics();
    private c : PIXI.Graphics = new PIXI.Graphics();
    private rad : PIXI.Graphics = new PIXI.Graphics();
    private tt : PIXI.Graphics = new PIXI.Graphics();

    private ltP : PIXI.Point = new PIXI.Point();
    private lbP : PIXI.Point = new PIXI.Point();
    private rtP : PIXI.Point = new PIXI.Point();
    private rbP : PIXI.Point = new PIXI.Point();
    private lP : PIXI.Point = new PIXI.Point();
    private bP : PIXI.Point = new PIXI.Point();
    private tP : PIXI.Point = new PIXI.Point();
    private rP : PIXI.Point = new PIXI.Point();
    private cP : PIXI.Point = new PIXI.Point();
    private radP : PIXI.Point = new PIXI.Point();
    private ttP : PIXI.Point = new PIXI.Point();

    moveStartCallback : Function = null;
    moveCallback : Function = null;
    moveEndCallback : Function = null;

    private _editorScene: EditorScene;

    constructor(scene: EditorScene) {
        super();

        this._editorScene = scene;

        this.addChild(this.mover);
        this.addChild(this.c);
        this.addChild(this.lt);
        this.addChild(this.lb);
        this.addChild(this.rt);
        this.addChild(this.rb);
        this.addChild(this.l);
        this.addChild(this.b);
        this.addChild(this.t);
        this.addChild(this.r);
        this.addChild(this.rad);
        this.addChild(this.tt);

        let w = 10;
        this.lt.lineStyle(1, 0x000000, 1);
        this.lt.beginFill(0xffffff);
        this.lt.drawRect(-w/2, -w/2, w, w);
        this.lt.endFill();
        this.lb.lineStyle(1, 0x000000, 1);
        this.lb.beginFill(0xffffff);
        this.lb.drawRect(-w/2, -w/2, w, w);
        this.lb.endFill();
        this.rt.lineStyle(1, 0x000000, 1);
        this.rt.beginFill(0xffffff);
        this.rt.drawRect(-w/2, -w/2, w, w);
        this.rt.endFill();
        this.rb.lineStyle(1, 0x000000, 1);
        this.rb.beginFill(0xffffff);
        this.rb.drawRect(-w/2, -w/2, w, w);
        this.rb.endFill();

        w = 8;
        this.l.lineStyle(1, 0x000000, 1);
        this.l.beginFill(0xffffff);
        this.l.drawRect(-w/2, -w/2, w, w);
        this.l.endFill();
        this.r.lineStyle(1, 0x000000, 1);
        this.r.beginFill(0xffffff);
        this.r.drawRect(-w/2, -w/2, w, w);
        this.r.endFill();

        this.rad.lineStyle(1, 0x000000, 1);
        this.rad.beginFill(0xffffff);
        this.rad.drawRect(0, -w/2, w, w);
        this.rad.endFill();

        this.b.lineStyle(1, 0x000000, 1);
        this.b.beginFill(0xffffff);
        this.b.drawRect(-w/2, -w/2, w, w);
        this.b.endFill();

        this.t.lineStyle(1, 0x000000, 1);
        this.t.beginFill(0xffffff);
        this.t.drawRect(-w/2, -w/2, w, w);
        this.t.endFill();

        this.tt.lineStyle(1, 0x000000, 1);
        this.tt.beginFill(0xffffff);
        this.tt.drawCircle(0, 0, w);
        this.tt.endFill();


        this.c.lineStyle(1, 0x000000, 1);
        this.c.beginFill(0xffffff);
        this.c.drawCircle(0, 0, 4);
        this.c.endFill();


        this.setInteractive2(this.tt, (x1 : number, y1: number, x2: number, y2: number)=>{
            const worldPos = this.target.gameObject.toGlobal({x:0,y:0});
            const rot1 = Math.atan2( x1 - worldPos.x, y1 - worldPos.y );
            const rot2 = Math.atan2( x2 - worldPos.x, y2 - worldPos.y );
            const rot = rot1 - rot2;
            this.target.gameObject.rotation += rot;
        });
        const resizeFunc = (gmx: number, gmy: number, left: boolean, top: boolean, isX: boolean = true, isY: boolean = true)=>{
            const localMove1 = this.target.gameObject.toLocal({x:0, y:0} );
            const localMove2 = this.target.gameObject.toLocal({x:gmx, y:gmy} );
            const localMove = {
                x: isX ? (localMove1.x - localMove2.x) : 0,
                y: isY ? (localMove1.y - localMove2.y) : 0
            };

            const gx = left ? -localMove.x * 0.5
                : -localMove.x * 0.5;
            const gy = top ? -localMove.y * 0.5
                :  -localMove.y * 0.5;

            const global = this.target.gameObject.toGlobal( {
                x: gx,
                y: gy,
            } );

            const local = this.target.gameObject.parent.toLocal( global );
            const width = this.target.width + localMove.x * (left ? 1 : -1);
            const height = this.target.height + localMove.y * (top ? 1 : -1);
            this.target.gameObject.position.x = local.x;
            this.target.gameObject.position.y = local.y;
            this.target.width = width;
            this.target.height = height;
        }

        this.setInteractive( this.rad, (dx : number, dy: number)=>{
            resizeFunc(dx, dy,  false, false, true, false  );
        } );

        this.setInteractive( this.lt, (dx : number, dy: number)=>{
            resizeFunc(dx, dy,  true, true);
        } );
        this.setInteractive( this.lb, (dx : number, dy: number)=>{
            resizeFunc(dx, dy,  true, false);
        } );
        this.setInteractive( this.rt, (dx : number, dy: number)=>{
            resizeFunc(dx, dy,  false, true);
        } );
        this.setInteractive( this.rb, (dx : number, dy: number)=>{
            resizeFunc(dx, dy,  false, false);
        });
        this.setInteractive( this.l, (dx : number, dy: number)=>{
            resizeFunc(dx, dy,  true, false, true, false  );
        });
        this.setInteractive( this.r, (dx : number, dy: number)=>{
            resizeFunc(dx, dy,  false, false, true, false  );
        });
        this.setInteractive( this.t, (dx : number, dy: number)=>{
            resizeFunc(dx, dy,  false, true, false, true  );
        });
        this.setInteractive( this.b, (dx : number, dy: number)=>{
            resizeFunc(dx, dy,  false, false, false, true  );
        });

        this.setInteractive(this.mover, (dx : number, dy: number)=>{
            const localMove1 = this.target.gameObject.toLocal({x:0, y:0} );
            const localMove2 = this.target.gameObject.toLocal({x:dx, y:dy} );
            const localMove = {
                x: -(localMove1.x - localMove2.x),
                y: -(localMove1.y - localMove2.y)
            };
            const global = this.target.gameObject.toGlobal( localMove );
            const local = this.target.gameObject.parent.toLocal( global );

            this.target.gameObject.position.x = local.x
            this.target.gameObject.position.y = local.y;
            this.target.gameObject.updateTransform();
            this.update();
        }, 'move');
    }

    destroy() {
        this._editorScene = null;
        this.target = null;
        this.moveCallback = null;
        this.moveStartCallback = null;
        this.moveEndCallback = null;
        this.data.capture = null;
        this.data.compCapture = null;
    }

    setInteractive( object : any, updatePosFunc : (x:number, y:number)=>void, cursor:string = '' )
    {
        let down = false;
        let preGlobal = new PIXI.Point();
        let fixed = false;
        let grid = {x:0, y:0};

        //@ts-ignore
        object.interactive = true;
        object.on('pointerover', ()=>{
            document.body.style.cursor = cursor;
        });
        object.on('pointerout', ()=>{
            document.body.style.cursor = '';
        });
        //@ts-ignore
        object.on('pointerdown', (event)=>{
            this._editorScene.isDown = true;
            down = true;
            fixed = this._editorScene.viewPort.fixedGrid;
            grid.x = this._editorScene.viewPort.gridX;
            grid.y = this._editorScene.viewPort.gridY;
            preGlobal.copyFrom(event.data.global);
            this.moveStartCallback && this.moveStartCallback(this);

        }).on('pointerup',  ()=>{
            this._editorScene.isDown = false;
            if(down) {
                down = false;
                this.moveEndCallback && this.moveEndCallback(this);
            }
        }).on('pointerupoutside',  ()=>{
            this._editorScene.isDown = false;
            if(down) {
                down = false;
                this.moveEndCallback && this.moveEndCallback(this);
            }
        }).on('pointermove',  (event : any)=>{
            if( down )
            {
                let global = event.data.global;
                let dx = global.x - preGlobal.x;
                let dy = global.y - preGlobal.y;

                if( fixed ) {
                    const sx = this._editorScene.center.scale.x;
                    const sy = this._editorScene.center.scale.y;
                    const gridX = grid.x / sx;
                    const gridY = grid.y / sy;
                    dx = Math.floor(dx / gridX) * gridX;
                    dy = Math.floor(dy / gridY) * gridY;

                    preGlobal.x = preGlobal.x + dx;
                    preGlobal.y = preGlobal.y + dy;
                    updatePosFunc( dx, dy );
                    this.moveCallback && this.moveCallback(this);
                }
                else {
                    preGlobal.copyFrom(global);
                    updatePosFunc( dx, dy );
                    this.moveCallback && this.moveCallback(this);
                }
            }
        });
    }

    setInteractive2( object : any, updatePosFunc : (x1:number, y1:number, x2: number, y2: number)=>void, cursor:string = '' )
    {
        let down = false;
        let preGlobal = new PIXI.Point();

        //@ts-ignore
        object.interactive = true;
        object.on('pointerover', ()=>{
            document.body.style.cursor = cursor;
        });
        object.on('pointerout', ()=>{
            document.body.style.cursor = '';
        });
        //@ts-ignore
        object.on('pointerdown', (event)=>{
            this._editorScene.isDown = true;
            down = true;
            preGlobal.copyFrom(event.data.global);
            this.moveStartCallback && this.moveStartCallback(this);
        }).on('pointerup',  ()=>{
            this._editorScene.isDown = false;
            if(down) {
                down = false;
                this.moveEndCallback && this.moveEndCallback(this);
            }
        }).on('pointerupoutside',  ()=>{
            this._editorScene.isDown = false;
            if(down) {
                down = false;
                this.moveEndCallback && this.moveEndCallback(this);
            }
        }).on('pointermove',  (event : any)=>{
            if( down )
            {
                let global = event.data.global;
                updatePosFunc( preGlobal.x, preGlobal.y, global.x, global.y );
                preGlobal.copyFrom(global);
                this.moveCallback && this.moveCallback(this);
            }
        });
    }

    update() {
        if(!this.target || this.target.gameObject.locked ) {
            this.visible = false;
            return;
        }

        this.visible = true;

        if( this.target.shapeType === EShapeType.rect ) {
            this.t.visible = true;
            this.b.visible = true;
            this.r.visible = true;
            this.l.visible = true;
            this.lt.visible = true;
            this.rt.visible = true;
            this.lb.visible = true;
            this.rb.visible = true;
            this.rad.visible = false;

            this.ltP.set(
                -0.5 * (this.target.width),
                -0.5 * (this.target.height),
            );

            this.lbP.set(
                -0.5 * (this.target.width),
                0.5 * (this.target.height),
            );
            this.rtP.set(
                0.5 * (this.target.width),
                -0.5 * (this.target.height),
            );
            this.rbP.set(
                0.5 * (this.target.width),
                0.5 * (this.target.height),
            );

            this.lP.set(
                -0.5 * (this.target.width),
                0,
            );
            this.bP.set(
                0,
                0.5 * (this.target.height),
            )
            this.tP.set(
                0,
                -0.5 * (this.target.height),
            );
            this.rP.set(
                0.5 * (this.target.width),
                0,
            )

            this.ttP.set(
                0,
                -0.75 * (this.target.height),
            );

            this.cP.set( 0, 0 );
            this.ltP = this.target.gameObject.toGlobal( this.ltP );
            this.lbP = this.target.gameObject.toGlobal( this.lbP );
            this.rtP = this.target.gameObject.toGlobal( this.rtP );
            this.rbP = this.target.gameObject.toGlobal( this.rbP );
            this.lP = this.target.gameObject.toGlobal( this.lP );
            this.bP = this.target.gameObject.toGlobal( this.bP );
            this.tP = this.target.gameObject.toGlobal( this.tP );
            this.rP = this.target.gameObject.toGlobal( this.rP );
            this.cP = this.target.gameObject.toGlobal( this.cP );
            this.ttP = this.target.gameObject.toGlobal( this.ttP );

            this.lt.position.copyFrom(this.ltP);
            this.lb.position.copyFrom(this.lbP);
            this.rt.position.copyFrom(this.rtP);
            this.rb.position.copyFrom(this.rbP);
            this.l.position.copyFrom(this.lP);
            this.b.position.copyFrom(this.bP);
            this.t.position.copyFrom(this.tP);
            this.r.position.copyFrom(this.rP);
            this.c.position.set(this.cP.x, this.cP.y);
            this.tt.position.copyFrom(this.ttP);

            let dir = new b2Vec2( this.tt.x - this.c.x, this.tt.y - this.c.y );
            dir.Normalize();
            dir.Scale(40);
            this.ttP.set(
                0,
                -0.5 * (this.target.height),
            );
            this.ttP = this.target.gameObject.toGlobal( this.ttP );
            this.tt.position.set(this.ttP.x + dir.x, this.ttP.y + dir.y);


            this.mover.clear();
            this.mover.lineStyle(1, 0xffffff, 1);
            this.mover.drawPolygon( [
                this.lt.position,
                this.lb.position,
                this.rb.position,
                this.rt.position,
                this.lt.position,
            ] );

            //@ts-ignore
            this.mover.hitArea = new PIXI.Polygon([
                this.lt.position,
                this.lb.position,
                this.rb.position,
                this.rt.position,
                this.lt.position,
            ]);
        }
        else if(this.target.shapeType === EShapeType.circle) {
            this.t.visible = false;
            this.b.visible = false;
            this.r.visible = false;
            this.l.visible = false;
            this.lt.visible = false;
            this.rt.visible = false;
            this.lb.visible = false;
            this.rb.visible = false;
            this.rad.visible = true;

            this.ltP.set(
                -(this.target.width) * 0.5,
                -(this.target.width) * 0.5,
            );

            this.lbP.set(
                -(this.target.width) * 0.5,
                (this.target.width) * 0.5,
            );
            this.rtP.set(
                (this.target.width) * 0.5,
                -(this.target.width) * 0.5,
            );
            this.rbP.set(
                (this.target.width) * 0.5,
                (this.target.width) * 0.5,
            );

            this.lP.set(
                -(this.target.width) * 0.5,
                0,
            );
            this.bP.set(
                0,
                (this.target.width) * 0.5,
            )
            this.tP.set(
                0,
                -(this.target.width) * 0.5,
            );
            this.rP.set(
                (this.target.width) * 0.5,
                0,
            );
            this.radP.set(
                (this.target.width) * 0.5,
                0,
            );
            this.ttP.set(
                0,
                -0.75 * (this.target.width),
            );

            this.cP.set( 0, 0 );
            this.ltP = this.target.gameObject.toGlobal( this.ltP );
            this.lbP = this.target.gameObject.toGlobal( this.lbP );
            this.rtP = this.target.gameObject.toGlobal( this.rtP );
            this.rbP = this.target.gameObject.toGlobal( this.rbP );
            this.lP = this.target.gameObject.toGlobal( this.lP );
            this.bP = this.target.gameObject.toGlobal( this.bP );
            this.tP = this.target.gameObject.toGlobal( this.tP );
            this.rP = this.target.gameObject.toGlobal( this.rP );
            this.radP = this.target.gameObject.toGlobal( this.radP );
            this.cP = this.target.gameObject.toGlobal( this.cP );
            this.ttP = this.target.gameObject.toGlobal( this.ttP );

            this.lt.position.copyFrom(this.ltP);
            this.lb.position.copyFrom(this.lbP);
            this.rt.position.copyFrom(this.rtP);
            this.rb.position.copyFrom(this.rbP);
            this.l.position.copyFrom(this.lP);
            this.b.position.copyFrom(this.bP);
            this.t.position.copyFrom(this.tP);
            this.r.position.copyFrom(this.rP);
            this.rad.position.copyFrom(this.radP);
            this.c.position.set(this.cP.x, this.cP.y);
            this.tt.position.copyFrom(this.ttP);

            const ttLength = Util.Math.length( this.t.x, this.t.y, this.tt.x, this.tt.y );
            if( ttLength > 50 ) {
                let dir = new b2Vec2( this.tt.x - this.t.x, this.tt.y - this.t.y );
                dir.Normalize();
                dir.Scale(50);
                this.tt.position.set(this.t.x + dir.x, this.t.y + dir.y);
            }
            else if( ttLength < 10 ) {
                let dir = new b2Vec2( this.tt.x - this.t.x, this.tt.y - this.t.y );
                dir.Normalize();
                dir.Scale(10);
                this.tt.position.set(this.t.x + dir.x, this.t.y + dir.y);
            }

            this.mover.clear();
            this.mover.lineStyle(1, 0xffffff, 1);
            this.mover.drawPolygon( [
                this.lt.position,
                this.lb.position,
                this.rb.position,
                this.rt.position,
                this.lt.position,
            ] );

            //@ts-ignore
            this.mover.hitArea = new PIXI.Polygon([
                this.lt.position,
                this.lb.position,
                this.rb.position,
                this.rt.position,
                this.lt.position,
            ]);
        }



        this.updateTransform();
    }
}
import WizardSteps from "./comp/wizardSteps";
import WizardPage from "./comp/wizardPage";
import {Card, Checkbox, Radio, Select, Space, Switch, Tabs, Tooltip, Typography} from 'antd';
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {HeartOutlined, InfoCircleOutlined} from "@ant-design/icons";
import wizardSaveData from "../../services/wizard/wizardSave";
import SceneAsset from "mogera-game/scripts/asset/sceneAsset";
import GoJsonHelper from "../../services/wizard/goJsonHelper";
import { GiSandsOfTime, GiRun, GiHealthNormal } from "react-icons/gi";
import { MdOutlineSocialDistance } from "react-icons/md";
import { RiSpeedMiniFill } from "react-icons/ri";
import { BsSpeedometer2 } from "react-icons/bs";
import { FaRegGem } from "react-icons/fa";
import {BiBook, BiTimer} from "react-icons/bi";
import queryString from "query-string";

const { TabPane } = Tabs;
const {Text, Title} = Typography;
const {Option} = Select;

export default function Running() {

    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();

    let useDoubleJump = true;
    let useSlide = true;

    const qa = queryString.parse(window.location.search);
    const mode = qa?.mode;
    if(mode === 'none') {
        useDoubleJump = false;
        useSlide = false;
    }
    else if(mode === 'jump_slide') {
        useDoubleJump = true;
        useSlide = true;
    }
    else if(mode === 'jump') {
        useDoubleJump = true;
        useSlide = false;
    }
    else if(mode === 'slide') {
        useDoubleJump = false;
        useSlide = true;
    }

    const [data, setData] = useState({
        useDoubleJump: useDoubleJump,
        useSlide: useSlide,
        // isDistanceGenMode : false,
        useGem: true,
        useTimer: true,
        hp: 1,
        genTime: 2,
        genDistance: 2000,
        speed: 600,
        addSpeed: 400,
        maxSpeed: 1500,
        objects: [
            {name: 'Object1', active: true, useSlide: false, useDoubleJump: false },
            {name: 'Object2', active: true, useSlide: true, useDoubleJump: true },
            {name: 'Object3', active: true, useSlide: true, useDoubleJump: false },
            {name: 'Object4', active: true, useSlide: false, useDoubleJump: true },
        ]
    });

    function enableObject(object: any) {
        if( object.useDoubleJump && object.useSlide && !data.useSlide && !data.useDoubleJump
            || object.useDoubleJump && !object.useSlide && !data.useDoubleJump
            || !object.useDoubleJump && object.useSlide && !data.useSlide) {
            return false;
        }
        return true;
    }

    function updateObject(objects: any[]) {
        for(let i = 0; i < objects.length; i++) {
            objects[i].active = enableObject(data.objects[i]);
        }
    }

    function enableObjectCount(objects: any[]) {
        let count = 0;
        for(let i = 0; i < objects.length; i++) {
            if(objects[i].active) {
                count++;
            }
        }
        return count;
    }

    return <div className={'wizardRoot'}>
        <WizardSteps currentStep={currentStep} stepDataList={[
            {title:'게임 로직', description: '특수조작 사용 여부를 설정합니다.'},
            // {title:'게임 로직2', description: '장애물 생성 방법을 설정합니다.'},
            {title:'난이도 설정', description: '게임 난이도를 설정합니다.'},
            {title:'장애물 설정', description: '게임에 등장할 장애물 목록을 설정합니다.'},
            {title:'추가 옵션 설정', description: '기타 옵션을 설정합니다.'},
            {title:'게임 설정 완료', description: '설정한 게임을 실행합니다.'},
        ]} onChange={function (step){
            setCurrentStep(step);
        }}></WizardSteps>
        <Tabs activeKey={currentStep.toString()} >
            <TabPane  key={'0'}>
                <WizardPage title={'특수조작 설정'} description={'특수조작 사용 여부를 설정합니다.'}
                            onPrev={function (){
                                navigate("/wizard" );
                            }}
                            onNext={function (){
                                setCurrentStep(currentStep+1);
                            }}
                            prevText={'장르 다시 선택'}>
                    <Space>
                        <Card
                            hoverable
                            className={data.useDoubleJump && data.useSlide ?  'select' : ''}
                            cover={<img draggable={false} height={320} src="/img/wizard/running/running_all.gif" />}
                            onClick={function (){
                                data.useDoubleJump = true;
                                data.useSlide = true;
                                updateObject(data.objects);
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                더블점프 & 슬라이딩 사용
                            </Title>
                            <div style={{height:'60px'}}>
                                공중에서 한 번 더 점프하고, 자세를 낮추어서 이동할 수 있습니다.
                            </div>
                        </Card>
                        <Card
                            hoverable
                            className={data.useDoubleJump && !data.useSlide ?  'select' : ''}
                            cover={<img draggable={false} height={320} src="/img/wizard/running/running_doubleJump.gif" />}
                            onClick={function (){
                                data.useDoubleJump = true;
                                data.useSlide = false;
                                updateObject(data.objects);
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                더블 점프 사용
                            </Title>
                            <div style={{height:'60px'}}>
                                공중에서 한 번 더 점프합니다.
                            </div>
                        </Card>
                        <Card
                            hoverable
                            className={!data.useDoubleJump && data.useSlide ?  'select' : ''}
                            cover={<img draggable={false} height={320} src="/img/wizard/running/running_slide.gif" />}
                            onClick={function (){
                                data.useDoubleJump = false;
                                data.useSlide = true;
                                updateObject(data.objects);
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                슬라이딩 사용
                            </Title>
                            <div style={{height:'60px'}}>
                                자세를 낮추어서 이동할 수 있습니다.
                            </div>
                        </Card>
                        <Card
                            className={!data.useDoubleJump && !data.useSlide  ? 'select' : ''}
                            hoverable
                            cover={<img draggable={false} height={320} src="/img/wizard/running/running_jump.gif" />}
                            onClick={function (){
                                data.useDoubleJump = false;
                                data.useSlide = false;
                                updateObject(data.objects);
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                특수 조작 없음
                            </Title>
                            <div style={{height:'60px'}}>
                                특수한 조작법 없이 점프만 사용합니다.
                            </div>
                        </Card>
                    </Space>
                </WizardPage>
            </TabPane>
            <TabPane  key={'1'}>
                <WizardPage title={'난이도 설정'} description={'게임 난이도를 설정합니다.'}
                            onNext={function (){
                                setCurrentStep(currentStep+1);
                            }}
                            onPrev={function (){
                                setCurrentStep(currentStep-1);
                            }}>
                    <Space style={{alignItems: "baseline", marginTop: '80px'}}>
                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <GiHealthNormal style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>플레이어 체력</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="플레이어의 체력을 설정합니다. 장애물과 충돌하면 체력이 소모되며 체력이 0이 되면 게임이 종료됩니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Select style={{width: '150px'}} value={data.hp} onChange={function (value, option){
                                data.hp = value;
                                setData({...data});
                            }}>
                                <Option key={0} value={3}>3(쉬움)</Option>
                                <Option key={1} value={1}>1(중간)</Option>
                                <Option key={2} value={0}>0(어려움)</Option>
                            </Select>
                        </div>
                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <MdOutlineSocialDistance style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>장애물 생성 거리</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="장애물을 생성할 거리 간격을 설정합니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Select style={{width: '150px'}} value={data.genDistance} onChange={function (value, option){
                                data.genDistance = value;
                                setData({...data});
                            }}>
                                <Option key={0} value={3000}>3000(쉬움)</Option>
                                <Option key={1} value={2000}>2000(중간)</Option>
                                <Option key={2} value={1500}>1500(어려움)</Option>
                            </Select>
                        </div>
                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <GiRun style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>기본 이동속도</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="플레이어 캐릭터의 기본 이동속도를 설정합니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Select style={{width: '150px'}} value={data.speed} onChange={function (value, option){
                                data.speed = value;
                                setData({...data});
                            }}>
                                <Option key={0} value={600}>600(쉬움)</Option>
                                <Option key={1} value={800}>800(중간)</Option>
                                <Option key={2} value={1000}>1000(어려움)</Option>
                            </Select>
                        </div>
                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <RiSpeedMiniFill style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>증가 이동속도</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="10초마다 설정된 값만큼 이동속도가 증가합니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Select style={{width: '150px'}} value={data.addSpeed} onChange={function (value, option){
                                data.addSpeed = value;
                                setData({...data});
                            }}>
                                <Option key={0} value={400}>400(쉬움)</Option>
                                <Option key={1} value={600}>600(중간)</Option>
                                <Option key={2} value={800}>800(어려움)</Option>
                            </Select>
                        </div>
                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <BsSpeedometer2 style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>최대 이동속도</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="최대 이동속도를 설정합니다. 설정된 값보다 더 속도가 빨라지지 않습니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Select style={{width: '150px'}} value={data.maxSpeed} onChange={function (value, option){
                                data.maxSpeed = value;
                                setData({...data});
                            }}>
                                <Option key={0} value={1200}>1200(쉬움)</Option>
                                <Option key={1} value={1500}>1500(중간)</Option>
                                <Option key={2} value={1800}>1800(어려움)</Option>
                            </Select>
                        </div>
                    </Space>
                    <div className={'bottom'}>
                    </div>
                </WizardPage>
            </TabPane>
            <TabPane  key={'2'}>
                <WizardPage title={'장애물 설정'} description={'게임에 등장할 장애물 목록을 설정합니다.'}
                            nextText={'다음'}
                            onNext={function (){
                                setCurrentStep(currentStep+1);
                            }}
                            onPrev={function (){
                                setCurrentStep(currentStep-1);
                            }}>
                    <Space style={{alignItems:'baseline', marginTop: '50px'}}>
                        {
                            data.objects.map((object, index, array)=>{
                                return <div key={index}>
                                    <Card
                                        className={object.active ? 'select' : ''}
                                        cover={<img draggable={false} style={{backgroundColor:'#2b2b2c'}} height={240} src={`/img/wizard/running/${object.name}.png`} />}
                                        // cover={<img draggable={false} style={{backgroundColor:'#2b2b2c'}} height={240} src={`/img/wizard/running/Object1.png`} />}
                                        onClick={function (){
                                            if(!enableObject(object)) {
                                                return;
                                            }

                                            if(object.active && enableObjectCount(data.objects) === 1) {
                                                confirm('최소한 한 개의 오브젝트는 활성화되어 있어야 합니다.')
                                                return;
                                            }

                                            object.active = !object.active;
                                            setData({...data});
                                        }}
                                    >
                                        <Title level={5}>
                                            {object.name}
                                        </Title>
                                    </Card>
                                    <div>
                                        <Switch checked={object.active} disabled={!enableObject(object)} className={'toggle'} onChange={function (checked, event){
                                            object.active = checked;
                                            setData({...data});
                                        }}></Switch>
                                    </div>
                                    {
                                        !enableObject(object) ? <div className={'settingFooter'}>
                                            {
                                                object.useSlide && object.useDoubleJump ? '슬라이딩이나 더블 점프 조작이 필요합니다.' :
                                                    object.useDoubleJump ? '더블 점프 조작이 필요합니다.' : '슬라이딩 조작이 필요합니다.'
                                            }
                                        </div> : null
                                    }

                                </div>
                            })
                        }
                    </Space>
                </WizardPage>
            </TabPane>
            <TabPane  key={'3'}>
                <WizardPage title={'추가 옵션 설정'} description={'기타 옵션을 설정합니다.'}
                            onNext={function (){
                                setCurrentStep(currentStep+1);
                            }}
                            onPrev={function (){
                                setCurrentStep(currentStep-1);
                            }}>
                    <Space style={{alignItems: "baseline", marginTop: '80px'}}>
                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <FaRegGem style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>보석 활성화</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="보석을 활성화합니다. 보석을 획득하면 추가 점수를 획득할 수 있습니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Switch checked={data.useGem} onChange={function (checked){
                                data.useGem = checked;
                                setData({...data});
                            }}></Switch>
                        </div>
                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <BiTimer style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>플레이 시간 표시</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="게임의 플레이 시간이 표시됩니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Switch checked={data.useTimer} onChange={function (checked){
                                data.useTimer = checked;
                                setData({...data});
                            }}></Switch>
                        </div>
                    </Space>
                    <div className={'bottom'}>
                    </div>
                </WizardPage>
            </TabPane>
            <TabPane  key={'4'}>
                <WizardPage title={'게임 설정 완료'} description={'게임을 만들기 위한 모든 설정이 완료되었습니다.'}
                            nextText={'완료'}
                            onNext={function (){
                                wizardSaveData.isData = true;
                                wizardSaveData.path = '/template/running.mogera';
                                wizardSaveData.onLoadCallback = function (game) {
                                    const scene = game.assetManager.getAsset('게임', 'Scene') as SceneAsset;
                                    if(scene) {
                                        const player = GoJsonHelper.findGameObjectByName(scene.scene, 'Player');
                                        const playerScript = GoJsonHelper.findScript(player, 'Player', game);
                                        playerScript.props.speed = data.speed;
                                        playerScript.props.addSpeed = data.addSpeed;
                                        playerScript.props.maxSpeed = data.maxSpeed;
                                        playerScript.props.useSlide = data.useSlide;
                                        playerScript.props.useDoubleJump = data.useDoubleJump;
                                        playerScript.props.hp = data.hp;

                                        const objects = GoJsonHelper.findGameObjectByPath(scene.scene, 'World/Objects');
                                        const objectManager = GoJsonHelper.findScript(objects, 'ObjectManager', game);
                                        //objectManager.props.genDelay = data.genTime;
                                        objectManager.props.genDistance = data.genDistance;
                                        //objectManager.props.isGenDistanceMode = data.isDistanceGenMode;

                                        const timer = GoJsonHelper.findGameObjectByPath(scene.scene, 'UI/UIManager/Time');
                                        timer.active = data.useTimer;

                                        for(let i = 0; i < data.objects.length; i++) {
                                            const objectData = data.objects[i];
                                            const object = GoJsonHelper.findGameObjectByName(objects, objectData.name);
                                            if(!object) continue;
                                            object.active = objectData.active;
                                            if(!data.useGem) {
                                                const gems = GoJsonHelper.findGameObjectByNames(object, 'Gem');
                                                for(let j = 0; j < gems.length; j++) {
                                                    gems[j].active = false;
                                                }
                                            }
                                        }
                                    }
                                }

                                /*
                                //useDoubleJump: false,
                               //useSlide: false,
                                //isDistanceGenMode : false,
                                //useGem: true,
                                //useTimer: true,
                                //hp: 1,
                                //genTime: 2,
                                //genDistance: 2000,
                                //speed: 600,
                                //addSpeed: 400,
                                //maxSpeed: 1500,
                                objects: [
                                    {name: 'Object1', active: true, useSlide: false, useDoubleJump: false },
                                    {name: 'Object2', active: false, useSlide: true, useDoubleJump: true },
                                    {name: 'Object3', active: false, useSlide: true, useDoubleJump: false },
                                    {name: 'Object4', active: false, useSlide: false, useDoubleJump: true },
                                ]
                                 */
                                navigate("/" );
                            }}
                            onPrev={function (){
                                setCurrentStep(currentStep-1);
                            }}>
                    <Space direction={'vertical'} style={{marginTop:'80px'}}>
                        <Text>완료 버튼을 누르면 에디터가 실행됩니다.</Text>
                        <Text>더 자세한 내용은 아래 notion 링크를 통해 확인하세요.</Text>
                        <div style={{marginTop:'40px'}}>
                            <a href="https://abaft-restaurant-3dc.notion.site/768e250967bc4296aaa2337e4beb92c6" target="_blank">
                                <div>
                                    <BiBook style={{fontSize: '120px', lineHeight: '100px'}}/>
                                </div>
                                <div style={{marginTop: '40px'}}>
                                    템플릿 에디터 가이드 보러가기
                                </div>
                            </a>
                        </div>
                    </Space>
                </WizardPage>
            </TabPane>
        </Tabs>
    </div>
}
import {EEditable} from "../../../game/scripts/gameObject/gameObject";
import {EEditorViewOption} from "../../../store/editorSettingSlice";

export function isView(disableOptions: {[key:string]: EEditable }, key:string, viewOption: EEditorViewOption): boolean {
    const goOption = disableOptions['gameObject'];
    const option = disableOptions[key];
    if(viewOption === EEditorViewOption.default) {
        if(goOption & EEditable.view) {
            if(option & EEditable.view) {
                return true;
            }
        }
    }
    if(viewOption === EEditorViewOption.advanced) {
        if(goOption & EEditable.advanceView) {
            if(option & EEditable.advanceView) {
                return true;
            }
        }
    }
    return false;
}

export function isReadOnly(disableOptions: {[key:string]: EEditable }, key:string, viewOption: EEditorViewOption): boolean {
    const goOption = disableOptions['gameObject'];
    const option = disableOptions[key];

    if(viewOption === EEditorViewOption.default) {
        if(!(goOption & EEditable.edit)) {
            return true;
        }
        else if(!(option & EEditable.edit)) {
            return true;
        }
    }
    if(viewOption === EEditorViewOption.advanced) {
        if(!(goOption & EEditable.advanceEdit)) {
            return true;
        }
        else if(!(option & EEditable.advanceEdit)) {
            return true;
        }
    }

    return false;
}
import * as PIXI from 'pixi.js';
import Game from "../core/game";
import GameObject, {EEditable, IGameObjectJson} from "./gameObject";
import Layer from "./layer";
import {ISpriteJson} from "../component/sprite";
import Camera from "../component/camera";
import EditorScene from "../tools/editor/editorScene";

export interface ISceneJson extends IGameObjectJson {
    sprites : string[],
}

export interface ILayerInfo {
    name : string;
    isFixed : boolean;
}

export default class Scene extends GameObject {

    protected _camera : GameObject; //카메라 위치,크기,회전값 적용을 위한 콘테이너(트랜스폼)
    protected _center : PIXI.Container; //뷰포트 크기적용을 위한 콘테이너(트랜스폼)
    protected _world : GameObject;
    protected _fixed : GameObject;     //화면에 고정되어 있는 이미지

    protected _cameraComponent: Camera;

    public get camera() : Camera {
        return this._cameraComponent;
    }

    public get center() : PIXI.Container {
        return this._center;
    }

    public get world() : GameObject {
        return this._world;
    }
    public get fixed() : GameObject {
        return this._fixed;
    }

    constructor(game : Game, name : string = '') {
        super(game, name);

        this._camera = new GameObject(game, 'Camera');
        this._center = new PIXI.Container();
        this._world = new GameObject(game, 'World');
        this._fixed = new GameObject(game, 'UI');

        this._cameraComponent = this._camera.addComponent(Camera) as Camera;
        this._cameraComponent.width = 800;
        this._cameraComponent.height = 600;

        this.addChild(this._camera);
        this.addChild(this._center);
        this.addChild(this._world);
        this.addChild(this._fixed);

        this.getDisableOption('gameObject', true).editType = EEditable.none;

        // this._center.getDisableOption('gameObject').editType = EEditable.none;
        this._camera.getDisableOption('gameObject', true).editType = EEditable.viewOnly;
        this._camera.getDisableOption('clone', true).editType = EEditable.none;

        this._world.getDisableOption('gameObject', true).editType = EEditable.all;
        this._world.getDisableOption('remove', true).editType = EEditable.none;
        this._world.getDisableOption('name', true).editType = EEditable.viewOnly;
        this._world.getDisableOption('active', true).editType = EEditable.none;
        this._world.getDisableOption('position', true).editType = EEditable.none;
        this._world.getDisableOption('scale', true).editType = EEditable.none;
        this._world.getDisableOption('anchor', true).editType = EEditable.none;
        this._world.getDisableOption('rotation', true).editType = EEditable.none;
        this._world.getDisableOption('component', true).editType = EEditable.none;
        this._world.getDisableOption('parent', true).editType = EEditable.none;
        this._world.getDisableOption('clone', true).editType = EEditable.none;
        this._world.getDisableOption('debug', true).editType = EEditable.none;


        this._fixed.getDisableOption('gameObject', true).editType = EEditable.all;
        this._fixed.getDisableOption('remove', true).editType = EEditable.none;
        this._fixed.getDisableOption('name', true).editType = EEditable.viewOnly;
        this._fixed.getDisableOption('active', true).editType = EEditable.none;
        this._fixed.getDisableOption('position', true).editType = EEditable.none;
        this._fixed.getDisableOption('scale', true).editType = EEditable.none;
        this._fixed.getDisableOption('anchor', true).editType = EEditable.none;
        this._fixed.getDisableOption('rotation', true).editType = EEditable.none;
        this._fixed.getDisableOption('component', true).editType = EEditable.none;
        this._fixed.getDisableOption('parent', true).editType = EEditable.none;
        this._fixed.getDisableOption('clone', true).editType = EEditable.none;
        this._fixed.getDisableOption('debug', true).editType = EEditable.none;
    }

    destroy() {
        super.destroy();
        this._camera = null;
        this._cameraComponent = null;
        this._center = null;
        this._world = null;
        this._fixed = null;
    }

    update(delta: number) {
        this.game.physics?.update(delta);
        super.update(delta);
    }

    public updateCamera(renderer : PIXI.Renderer) {

        if( this.destroyed ) {
            return;
        }

        this._center.x = -renderer.width/2;      //뷰포트 크기만큼 위치 이동
        this._center.y = -renderer.height/2;

        this._center.updateTransform();
        this._camera.updateTransform();
        // this._fixed.updateTransform();

        const tr1 = this._camera.transform.worldTransform.clone();
        const tr2 = this._center.transform.worldTransform.clone();
        tr1.append( tr2 );
        tr1.invert();

        this._world.transform.setFromMatrix( tr1 );
        this._world.updateTransform();

        tr2.invert();
        this._fixed.transform.setFromMatrix( tr2 );
        this._fixed.updateTransform();
    }

    public addLayer(name : string, isFixed : boolean = false) {
        for(let i = 0; i < this._world.children.length; i++) {
            const child = this._world.children[i];
            if(child instanceof Layer && child.name === name) {
                throw new Error(`${name}레이어는 이미 존재합니다.`);
            }
        }

        for(let i = 0; i < this._fixed.children.length; i++) {
            const child = this._fixed.children[i];
            if(child instanceof Layer && child.name === name) {
                throw new Error(`${name}레이어는 이미 존재합니다.`);
            }
        }

        const layer = new Layer(this.game);
        layer.name = name;

        if(isFixed) {
            this._fixed.addChild(layer);
        }
        else {
            this._world.addChild(layer);
        }
    }

    public toJson(): ISceneJson {
        const json = super.toJson() as ISceneJson;

        const sprites : string[] = [];
        searchSprite(json);
        function searchSprite(goJson : IGameObjectJson) {
            for(let j = 0; j < goJson.children.length; j++) {
                const child = goJson.children[j];
                searchSprite(child);
            }

            for(let i = 0; i < goJson.components.length; i++ ) {
                const comp = goJson.components[i];
                if(comp.name === 'Sprite') {
                    if(sprites.indexOf(comp.name) === -1) {
                        sprites.push((comp as ISpriteJson).texture);
                    }
                }
            }
        }
        json.sprites = sprites;
        return json;
    }

    public fromJson(json: ISceneJson) {

        this._camera.destroy();
        this._world.destroy();
        this._fixed.destroy();
        this._camera = null;
        this._world = null;
        this._fixed = null;
        this._cameraComponent = null;


        super.fromJson(json);



        for(let i = 0; i < this.children.length; i++) {
            const child = this.children[i];
            if(child instanceof GameObject) {
                switch (child.name.toLowerCase()) {
                    case 'camera' :
                        this._camera = child;
                        this._cameraComponent = this._camera.getComponent(Camera.Name) as Camera;
                        break;
                    case 'world' :
                        this._world = child;
                        break;
                    case 'fixed' :
                    case 'ui':
                        this._fixed = child;
                        this._fixed.position.set(0, 0);
                        this._fixed.scale.set(1, 1);
                        break;
                }
            }
        }

        // if( !this.game.scenes.isEditorScene ) {
        //     this.game.renderer.resize(this._cameraComponent.width, this._cameraComponent.height);
        // }
    }
}
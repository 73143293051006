import Component, {IComponentJson} from "../core/component";
import {component, property} from "../util/decorator";
import {TJsonRefers} from "../gameObject/gameObject";
import TextureAsset from "../asset/textureAsset";
import * as PIXI from 'pixi.js';
import EditorScene, {ECtrlMode} from "../tools/editor/editorScene";
import {SetEditorInteraction} from "../tools/editor/editorScene";

export interface IShapeJson extends IComponentJson {
    width: number;
    height: number;
    shapeType: EShapeType;
    color: number;
    alpha: number;
}

export enum EShapeType {
    rect,
    circle,
}

@component('Shape')
export default class Shape extends Component {

    private _shapeType: EShapeType = EShapeType.rect;
    public get shapeType(): EShapeType {
        return this._shapeType;
    }
    public set shapeType(type: EShapeType | string) {
        if( typeof(type) === 'string') {
            type = type.toLowerCase() === 'circle' ? EShapeType.circle : EShapeType.rect;
        }
        this._shapeType = type;
        this.graphicUpdate();
    }

    private _width : number = 100;
    public get width(): number {
        return this._width;
    }
    public set width(w: number) {
        this._width = w;
        this.graphicUpdate();
    }

    private _height : number = 100;
    public get height(): number {
        return this._height;
    }
    public set height(h: number) {
        this._height = h;
        this.graphicUpdate();
    }

    private _color : number = 0xffffff;
    public get color(): number {
        return this._color;
    }
    public set color(c: number) {
        this._color = c;
        this.graphicUpdate();
    }

    private _alpha : number = 1;
    public get alpha(): number {
        return this._alpha;
    }
    public set alpha(a: number) {
        this._alpha = a;
        this.graphicUpdate();
    }

    private _graphic: PIXI.Graphics = new PIXI.Graphics();
    public get graphic(): PIXI.Graphics {
        return this._graphic;
    }

    create() {
        this._graphic.visible = this.gameObject.worldActive;
        (this.gameObject as PIXI.utils.EventEmitter).on('added', this.onAdded, this );
        this.gameObject.addChildAt(this._graphic, 0);
        this.graphicUpdate();

        if(this.game.scenes.crtScene instanceof EditorScene) {
            SetEditorInteraction(this._graphic, this.gameObject);
        }
    }

    private onAdded() {
        this._graphic.visible = this.gameObject.worldActive;
    }


    destroy() {
        (this.gameObject as PIXI.utils.EventEmitter).off('added', this.onAdded, this );
        this._graphic.destroy();
        this._graphic = null;
        super.destroy();
    }

    onEnable() {
        super.onEnable();
        if(this._graphic) {
            this._graphic.visible = true;
        }

    }

    onDisable() {
        super.onDisable();
        if(this._graphic) {
            this._graphic.visible = false;
        }
    }

    graphicUpdate() {
        this._graphic.clear();
        this._graphic.beginFill( this.color, this.alpha );
        if(this.shapeType === EShapeType.rect) {
            const width = this.width > 0 ? this.width : -this.width;
            const height = this.height > 0 ? this.height : -this.height;
            this._graphic.drawRect( -width/2,-height/2, width, height );
        }
        else if(this.shapeType === EShapeType.circle) {
            this._graphic.drawCircle(0,0, this.width / 2);
        }
        this._graphic.endFill();
    }

    toJson() : IShapeJson {
        const json : IShapeJson = super.toJson() as IShapeJson;
        json.width = this.width;
        json.height = this.height;
        json.shapeType = this.shapeType;
        json.color = this.color;
        json.alpha = this.alpha;
        return json;
    }

    fromJson(json : IShapeJson, refers: TJsonRefers) {
        super.fromJson(json, refers);
        this._width = json.width;
        this._height = json.height;
        this._shapeType = json.shapeType;
        this._color = json.color;
        this._alpha = json.alpha;
        this.graphicUpdate();
    }
}
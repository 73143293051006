import {PictureOutlined, ShareAltOutlined, CaretRightOutlined } from '@ant-design/icons';
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {changeSelectTool, selectSelectTool} from "../../../store/editorSlice";
import EditorManager from "../../../services/editorManager";
import {ECtrlMode} from "mogera-game/scripts/tools/editor/editorScene";
import {Tooltip} from "antd";
import TooltipManager from "../../../services/tooltipManager";
import {selectTooltipLocalize} from "../../../store/editorSettingSlice";

export default function ToolBox() {

    const selectTool = useAppSelector(selectSelectTool);
    // const selectTool : string = 'renderer';
    const dispatch = useAppDispatch();
    const lang = useAppSelector(selectTooltipLocalize);


    function onClickToolButton(tool: string) {
        if(selectTool !== tool) {
            if( tool === 'renderer' ) {
                EditorManager.Instance.changeCtrlMode(ECtrlMode.resizer)
            }
            else if(tool === 'body'){
                EditorManager.Instance.changeCtrlMode(ECtrlMode.bodyResizer)
            }
        }
    }

    function onClickPlay() {
        EditorManager.Instance.play();
    }

    return<div className={'toolBox'}>
        <div className={'buttonContainer'}>

        </div>
        <div className={'buttonContainer'}>
            <div style={{flexGrow:'1'}}>
            </div>
            <Tooltip placement={'bottom'} title={TooltipManager.Instance.getText('Toolbox.Play') || ''}>
                <div className={`button` } onClick={onClickPlay}>
                    <CaretRightOutlined/>
                </div>
            </Tooltip>
            <div style={{flexGrow:'1'}}>
            </div>
        </div>
        <div className={'buttonContainer'} style={{alignItems: 'center'}}>
            <div style={{flexGrow:'1'}}>
            </div>
            <Tooltip placement={'bottom'} title={TooltipManager.Instance.getText('Toolbox.Image') || ''}>
                <div className={`button ${selectTool === 'renderer' ? 'active' : ''}` } onClick={e=>onClickToolButton('renderer')}>
                    <PictureOutlined/>
                </div>
            </Tooltip>

            <Tooltip placement={'bottom'} title={TooltipManager.Instance.getText('Toolbox.Collider') || ''}>
                <div className={`button ${selectTool === 'body' ? 'active' : ''}` } onClick={e=>onClickToolButton('body')}>
                    <ShareAltOutlined/>
                </div>
            </Tooltip>

        </div>
    </div>
}
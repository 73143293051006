import {IComponentViewProps} from "./componentView";
import {ISpriteJson} from "../../../../game/scripts/component/sprite";
import EditorManager from "../../../../services/editorManager";
import {store} from "../../../../app/store";
import {Checkbox, Input, InputNumber, Select, Space, Typography} from "antd";
import PointView from "../valueView/pointView";
import {ITextJson} from "../../../../game/scripts/component/text";
import ColorView from "../valueView/colorView";
import InputNumberView from "../valueView/inputNumberView";
import SelectView from "../valueView/selectView";
import CheckboxView from "../valueView/checkboxView";
import TextAreaView from "../valueView/textAreaView";
import AssetView from "../valueView/ref/assetView";
import Shape from "mogera-game/scripts/component/shape";
import TextComp from "mogera-game/scripts/component/text";
import {useState} from "react";
import {CaretDownOutlined, CaretRightOutlined} from "@ant-design/icons";
import LocalizationManager from "../../../../services/localizationManager";
import TooltipManager from "../../../../services/tooltipManager";
const { Text } = Typography;
const {Option} = Select;
const {TextArea } = Input;


export default function TextView(props: IComponentViewProps) {

    const json = props.json as ITextJson;
    const jsonCopy = JSON.parse(JSON.stringify(json));
    const asset = EditorManager.Instance.game.assetManager.getAsset(json.fontAsset);
    // const [more, setMore] = useState(false);

    function onChange() {
        EditorManager.Instance.modifyComponent(json);
    }

    return(
        <>
            <TextAreaView value={json.string}
                          label={LocalizationManager.Instance.getText('Text.string') || 'string'}
                          tooltip={  TooltipManager.Instance.getText('Text.string') }
                          onChange={function (value){
                json.string = value;
                onChange();
            }}></TextAreaView>
            <AssetView label={LocalizationManager.Instance.getText('Text.fontAsset') || 'fontAsset'}
                       tooltip={  TooltipManager.Instance.getText('Text.fontAsset') }
                       assetId={asset?.id} assetType={'FontAsset'} onClick={async function () {
                const id = await EditorManager.Instance.selectFont(asset?.id);
                if(id !== null) {
                    json.fontAsset = id;
                    onChange();
                }
            }} onRemove={function () {
                json.fontAsset = '';
                onChange();
            }}></AssetView>
            <InputNumberView label={LocalizationManager.Instance.getText('Text.fontSize') || 'fontSize'}
                             tooltip={  TooltipManager.Instance.getText('Text.fontSize') }
                             value={json.fontSize} onChange={function (num){
                json.fontSize = num;
                onChange();
            }}></InputNumberView>
            <ColorView value={json.color as string}
                       name={LocalizationManager.Instance.getText('Text.fontColor') || 'fontColor'}
                       tooltip={  TooltipManager.Instance.getText('Text.fontColor') }
                       onChange={function (color){
                const comp = EditorManager.Instance.findComponent(json.id) as TextComp;
                comp.color = color as any;
                comp.updateStyle();
            }} onChangeComplete={function (color){
                json.color = color as any;
                EditorManager.Instance.modifyComponent(json, jsonCopy);
            }}></ColorView>
            <InputNumberView label={LocalizationManager.Instance.getText('Text.alpha') ||'alpha'}
                             tooltip={ TooltipManager.Instance.getText('Text.alpha')}
                             value={json.alpha} onChange={function (num){
                json.alpha = num;
                EditorManager.Instance.modifyComponent(json);
            }}/>
            <SelectView label={LocalizationManager.Instance.getText('Text.align') || 'align'}
                        tooltip={  TooltipManager.Instance.getText('Text.align') }
                        value={json.align} onChange={function (value){
                json.align = value;
                onChange();
            }}>
                <Option key={'left'}>{LocalizationManager.Instance.getText('Text.align.left') || 'left'}</Option>
                <Option key={'center'}>{LocalizationManager.Instance.getText('Text.align.center') || 'center'}</Option>
                <Option key={'right'}>{LocalizationManager.Instance.getText('Text.align.right') || 'right'}</Option>
                <Option key={'justify'}>{LocalizationManager.Instance.getText('Text.align.justify') || 'justify'}</Option>
            </SelectView>


            <div className={'fold'} onClick={function (){
                json.more = !json.more;
                EditorManager.Instance.modifyComponent(json);
            }}>
                {
                    json.more ?   (
                            <>
                                <CaretDownOutlined/>
                                <span>{LocalizationManager.Instance.getText('Component.hideOption') || 'hideOption'}</span>
                            </>) :
                        (<>
                            <CaretRightOutlined/>
                            <span> {LocalizationManager.Instance.getText('Component.showOption') || 'showOption'}</span>
                        </>)
                }
            </div>
            {
                json.more? <>
                    <PointView
                        label={LocalizationManager.Instance.getText('Text.anchor') || 'anchor'}
                        tooltip={  TooltipManager.Instance.getText('Text.anchor') }
                        point={json.anchor} disabled={false} onChange={function (x, y){
                        json.anchor.x = x;
                        json.anchor.y = y;
                        onChange();
                    }}></PointView>
                    <SelectView label={LocalizationManager.Instance.getText('Text.fontStyle') || 'fontStyle'}
                                tooltip={  TooltipManager.Instance.getText('Text.fontStyle') }
                                value={json.fontStyle} onChange={function (value){
                        json.fontStyle = value;
                        onChange();
                    }}>
                        <Option key={'normal'}>{LocalizationManager.Instance.getText('Text.fontStyle.normal') || 'normal'}</Option>
                        <Option key={'italic'}>{LocalizationManager.Instance.getText('Text.fontStyle.italic') || 'italic'}</Option>
                        <Option key={'oblique'}>{LocalizationManager.Instance.getText('Text.fontStyle.oblique') || 'oblique'}</Option>
                    </SelectView>
                    <SelectView label={LocalizationManager.Instance.getText('Text.fontVariant') || 'fontVariant'}
                                tooltip={  TooltipManager.Instance.getText('Text.fontVariant') }
                                value={json.fontVariant} onChange={function (value){
                        json.fontVariant = value;
                        onChange();
                    }}>
                        <Option key={'normal'}>{LocalizationManager.Instance.getText('Text.fontVariant.normal') || 'normal'}</Option>
                        <Option key={'small-caps'}>{LocalizationManager.Instance.getText('Text.fontVariant.small-caps') || 'small-caps'}</Option>
                    </SelectView>
                    <SelectView label={LocalizationManager.Instance.getText('Text.fontWeight') || 'fontWeight'}
                                tooltip={  TooltipManager.Instance.getText('Text.fontWeight') }
                                value={json.fontWeight} onChange={function (value){
                        json.fontWeight = value;
                        onChange();
                    }}>
                        <Option key={'normal'}>{LocalizationManager.Instance.getText('Text.fontWeight.normal') || 'normal'}</Option>
                        <Option key={'bold'}>{LocalizationManager.Instance.getText('Text.fontWeight.bold') || 'bold'}</Option>
                        <Option key={'bolder'}>{LocalizationManager.Instance.getText('Text.fontWeight.bolder') || 'bolder'}</Option>
                        <Option key={'lighter'}>{LocalizationManager.Instance.getText('Text.fontWeight.lighter') || 'lighter'}</Option>
                        <Option key={'100'}>100</Option>
                        <Option key={'200'}>200</Option>
                        <Option key={'300'}>300'</Option>
                        <Option key={'400'}>400</Option>
                        <Option key={'500'}>500</Option>
                        <Option key={'600'}>600</Option>
                        <Option key={'700'}>700</Option>
                        <Option key={'800'}>800</Option>
                        <Option key={'900'}>900</Option>
                    </SelectView>
                    <CheckboxView label={LocalizationManager.Instance.getText('Text.wordWrap') || 'wordWrap'}
                                  tooltip={  TooltipManager.Instance.getText('Text.wordWrap') }
                                  checked={json.wordWrap} onChange={function (checked){
                        json.wordWrap = checked;
                        onChange();
                    }}></CheckboxView>
                    <InputNumberView label={LocalizationManager.Instance.getText('Text.lineHeight') || 'lineHeight'}
                                     tooltip={  TooltipManager.Instance.getText('Text.lineHeight') }
                                     value={json.lineHeight} onChange={function (num){
                        json.lineHeight = num;
                        onChange();
                    }}></InputNumberView>
                    {
                        json.wordWrap?
                            (<>
                                <CheckboxView label={LocalizationManager.Instance.getText('Text.breakWords') || 'breakWords'}
                                              tooltip={  TooltipManager.Instance.getText('Text.breakWords') }
                                              checked={json.breakWords} onChange={function (checked){
                                    json.breakWords = checked;
                                    onChange();
                                }}></CheckboxView>
                                <InputNumberView label={LocalizationManager.Instance.getText('Text.wordWrapWidth') || 'wordWrapWidth'}
                                                 tooltip={  TooltipManager.Instance.getText('Text.wordWrapWidth') }
                                                 value={json.wordWrapWidth} onChange={function (num){
                                    json.wordWrapWidth = num;
                                    onChange();
                                }}></InputNumberView>
                                <InputNumberView label={LocalizationManager.Instance.getText('Text.leading') || 'leading'}
                                                 tooltip={  TooltipManager.Instance.getText('Text.leading') }
                                                 value={json.leading} onChange={function (num){
                                    json.leading = num;
                                    onChange();
                                }}></InputNumberView>
                            </>)
                            : null
                    }
                    <CheckboxView label={LocalizationManager.Instance.getText('Text.trim') || 'trim'}
                                  tooltip={  TooltipManager.Instance.getText('Text.trim') }
                                  checked={json.trim} onChange={function (checked){
                        json.trim = checked;
                        onChange();
                    }}></CheckboxView>

                    <CheckboxView label={LocalizationManager.Instance.getText('Text.dropShadow') || 'dropShadow'}
                                  tooltip={  TooltipManager.Instance.getText('Text.dropShadow') }
                                  checked={json.dropShadow} onChange={function (checked){
                        json.dropShadow = checked;
                        onChange();
                    }}></CheckboxView>

                    {
                        json.dropShadow ?
                            (<>
                                <ColorView value={json.dropShadowColor}
                                           name={LocalizationManager.Instance.getText('Text.dropShadowColor') || 'dropShadowColor'}
                                           tooltip={  TooltipManager.Instance.getText('Text.dropShadowColor') }
                                           onChange={function (color){
                                    const comp = EditorManager.Instance.findComponent(json.id) as TextComp;
                                    comp.dropShadowColor = color as any;
                                    comp.updateStyle();
                                }} onChangeComplete={function (color){
                                    json.dropShadowColor = color as any;
                                    EditorManager.Instance.modifyComponent(json, jsonCopy);
                                }}></ColorView>
                                <InputNumberView
                                    label={LocalizationManager.Instance.getText('Text.dropShadowAlpha') || 'dropShadowAlpha'}
                                    tooltip={  TooltipManager.Instance.getText('Text.dropShadowAlpha') }
                                    value={json.dropShadowAlpha} onChange={function (num){
                                    json.dropShadowAlpha = num;
                                    onChange();
                                }}></InputNumberView>
                                <InputNumberView
                                    label={LocalizationManager.Instance.getText('Text.dropShadowAngle') || 'dropShadowAngle'}
                                    tooltip={  TooltipManager.Instance.getText('Text.dropShadowAngle') }
                                    value={json.dropShadowAngle} onChange={function (num){
                                    json.dropShadowAngle = num;
                                    onChange();
                                }}></InputNumberView>
                                <InputNumberView
                                    label={LocalizationManager.Instance.getText('Text.dropShadowBlur') || 'dropShadowBlur'}
                                    tooltip={  TooltipManager.Instance.getText('Text.dropShadowBlur') }
                                    value={json.dropShadowBlur} onChange={function (num){
                                    json.dropShadowBlur = num;
                                    onChange();
                                }}></InputNumberView>
                                <InputNumberView
                                    label={LocalizationManager.Instance.getText('Text.dropShadowDistance') || 'dropShadowDistance'}
                                    tooltip={  TooltipManager.Instance.getText('Text.dropShadowDistance') }
                                    value={json.dropShadowDistance} onChange={function (num){
                                    json.dropShadowDistance = num;
                                    onChange();
                                }}></InputNumberView>
                            </>):null
                    }
                    <InputNumberView
                        label={LocalizationManager.Instance.getText('Text.strokeThickness') || 'strokeThickness'}
                        tooltip={  TooltipManager.Instance.getText('Text.strokeThickness') }
                        value={json.strokeThickness} onChange={function (num){
                        json.strokeThickness = num;
                        onChange();
                    }}></InputNumberView>
                    {
                        json.strokeThickness ?
                            (<>
                                <ColorView value={json.stroke}
                                           name={LocalizationManager.Instance.getText('Text.strokeColor') || 'strokeColor'}
                                           tooltip={  TooltipManager.Instance.getText('Text.strokeColor') }
                                           onChange={function (color){
                                    const comp = EditorManager.Instance.findComponent(json.id) as TextComp;
                                    comp.stroke = color as any;
                                    comp.updateStyle();
                                }} onChangeComplete={function (color){
                                    json.stroke = color as any;
                                    EditorManager.Instance.modifyComponent(json, jsonCopy);
                                }}></ColorView>
                                <SelectView label={LocalizationManager.Instance.getText('Text.lineJoin') || 'lineJoin'}
                                            tooltip={  TooltipManager.Instance.getText('Text.lineJoin') }
                                            value={json.lineJoin} onChange={function (value){
                                    json.lineJoin = value;
                                    onChange();
                                }}>
                                    <Option key={'miter'}>{LocalizationManager.Instance.getText('Text.lineJoin.miter') || 'miter'}</Option>
                                    <Option key={'round'}>{LocalizationManager.Instance.getText('Text.lineJoin.round') || 'round'}</Option>
                                    <Option key={'bevel'}>{LocalizationManager.Instance.getText('Text.lineJoin.bevel') || 'bevel'}</Option>
                                </SelectView>
                            </>) : null
                    }

                </> : null
            }

        </>)
}
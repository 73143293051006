import * as PIXI from "pixi.js";
import EditorScene from "./editorScene";
import {Util} from "../../util/util";
import Body from "../../component/body";
import {IGameObjectJson} from "../../gameObject/gameObject";
import {IComponentJson} from "../../core/component";

export default class BodyResizer extends PIXI.Container {

    private _target: Body;
    public get target(): Body {
        return this._target;
    }

    public set target(tg: Body) {
        if (tg) {
            this._target = tg;
        } else {
            this._target = tg;
        }
        this.update();
    }
    public data: {
        capture: IGameObjectJson,
        compCapture: IComponentJson,
        timeoutId: number,
        isChange: boolean,
    } = {
        capture: null,
        compCapture: null,
        timeoutId: 0,
        isChange: false,
    };

    private graphic: PIXI.Graphics = new PIXI.Graphics();
    private w_g : PIXI.Graphics = new PIXI.Graphics();
    private h_g : PIXI.Graphics = new PIXI.Graphics();
    private r_g : PIXI.Graphics = new PIXI.Graphics();

    private w_p : PIXI.Point = new PIXI.Point();
    private h_p : PIXI.Point = new PIXI.Point();

    private lt_p : PIXI.Point = new PIXI.Point();
    private lb_p : PIXI.Point = new PIXI.Point();
    private rt_p : PIXI.Point = new PIXI.Point();
    private rb_p : PIXI.Point = new PIXI.Point();

    private r_p : PIXI.Point = new PIXI.Point();
    private c_p : PIXI.Point = new PIXI.Point();

    private points: {
        point: PIXI.Point,
        graphic: PIXI.Graphics
    }[] = [];

    moveStartCallback : Function = null;
    moveCallback : Function = null;
    moveEndCallback : Function = null;

    private _editorScene : EditorScene;
    // private _oriWidth: number = 0;
    // private _oriHeight: number = 0;
    // private _oriRadius: number = 0;

    constructor(scene: EditorScene) {
        super();
        this._editorScene = scene;
        this.addChild(this.graphic);
        this.addChild(this.w_g);
        this.addChild(this.h_g);
        this.addChild(this.r_g);

        let w = 10;
        this.w_g.beginFill(0x00ff00);
        this.w_g.drawRect(-w/2, -w/2, w, w);
        this.w_g.endFill();
        this.h_g.beginFill(0x00ff00);
        this.h_g.drawRect(-w/2, -w/2, w, w);
        this.h_g.endFill();
        this.r_g.beginFill(0x00ff00);
        this.r_g.drawRect(-w/2, -w/2, w, w);
        this.r_g.endFill();

        this.setInteractive( this.w_g, (dx, dy)=>{

            const localMove1 = this.target.gameObject.toLocal({x:0, y:0} );
            const localMove2 = this.target.gameObject.toLocal({x:dx, y:dy} );
            const localMove = {
                x: (localMove2.x - localMove1.x),
                y: (localMove2.y - localMove1.y)
            };

            const addWidth = localMove.x * 2;
            this.target.width += addWidth;
        } );
        this.setInteractive( this.h_g, (dx, dy)=>{
            const localMove1 = this.target.gameObject.toLocal({x:0, y:0} );
            const localMove2 = this.target.gameObject.toLocal({x:dx, y:dy} );
            const localMove = {
                x: (localMove2.x - localMove1.x),
                y: (localMove2.y - localMove1.y)
            };

            const addHeight = localMove.y * 2;
            this.target.height += addHeight;
        } );
        this.setInteractive( this.r_g, (dx, dy)=>{
            const localMove1 = this.target.gameObject.toLocal({x:0, y:0} );
            const localMove2 = this.target.gameObject.toLocal({x:dx, y:dy} );
            const localMove = {
                x: (localMove2.x - localMove1.x),
                y: (localMove2.y - localMove1.y)
            };

            const addWidth = localMove.x;
            this.target.radius += addWidth;
        } );
    }

    destroy() {
        super.destroy();
        this.moveCallback = null;
        this.moveStartCallback = null;
        this.moveEndCallback = null;
        this._editorScene = null;
        this._target = null;
        this.w_g = null;
        this.r_g = null;
        this.h_g = null;
        this.data.capture = null;
        this.data.compCapture = null;
    }

    setInteractive( object : any, updatePosFunc : (dx:number, dy:number)=>void )
    {
        let down = false;
        let preGlobal = new PIXI.Point();
        let fixed = false;
        let grid = {x:0, y:0};

        //@ts-ignore
        object.interactive = true;
        //@ts-ignore
        object.on('pointerdown', (event)=>{
            this._editorScene.isDown = true;
            down = true;
            fixed = this._editorScene.viewPort.fixedGrid;
            grid.x = this._editorScene.viewPort.gridX;
            grid.y = this._editorScene.viewPort.gridY;
            // this._oriWidth = this.target.width;
            // this._oriHeight = this.target.height;
            // this._oriRadius = this.target.radius;

            preGlobal.copyFrom(event.data.global);
            this.moveStartCallback && this.moveStartCallback(this);
        }).on('pointerup',  ()=>{
            this._editorScene.isDown = false;
            if(down) {
                down = false;
                this.moveEndCallback && this.moveEndCallback(this);
            }
        }).on('pointerupoutside',  ()=>{
            this._editorScene.isDown = false;
            if(down) {
                down = false;
                this.moveEndCallback && this.moveEndCallback(this);
            }
        }).on('pointermove',  (event : any)=>{
            if( down )
            {
                let global = event.data.global;
                let dx = global.x - preGlobal.x;
                let dy = global.y - preGlobal.y;

                if( fixed ) {
                    const sx = this._editorScene.center.scale.x;
                    const sy = this._editorScene.center.scale.y;
                    const gridX = grid.x / sx;
                    const gridY = grid.y / sy;
                    dx = Math.round(dx / gridX) * gridX;
                    dy = Math.round(dy / gridY) * gridY;

                    preGlobal.x = preGlobal.x + dx;
                    preGlobal.y = preGlobal.y + dy;

                    updatePosFunc( dx, dy );
                    this.moveCallback && this.moveCallback(this);
                }
                else {
                    preGlobal.copyFrom(global);
                    updatePosFunc( dx, dy );
                    this.moveCallback && this.moveCallback(this);
                }

            }
        });
    }

    private _boxUpdate() {
        this.r_g.visible = false;
        this.w_g.visible = true;
        this.h_g.visible = true;
        for(let i = 0; i < this.points.length; i++) {
            const pData = this.points[i];
            pData.graphic.visible = false;
        }

        this.lt_p.set(-this.target.width/2, -this.target.height/2);
        this.lb_p.set(-this.target.width/2, this.target.height/2);
        this.rt_p.set(this.target.width/2, -this.target.height/2);
        this.rb_p.set(this.target.width/2, this.target.height/2);
        this.w_p.set(this.target.width/2, 0);
        this.h_p.set(0, this.target.height/2);

        this.lt_p = this.target.gameObject.toGlobal( this.lt_p );
        this.lb_p = this.target.gameObject.toGlobal( this.lb_p );
        this.rt_p = this.target.gameObject.toGlobal( this.rt_p );
        this.rb_p = this.target.gameObject.toGlobal( this.rb_p );
        this.w_p = this.target.gameObject.toGlobal( this.w_p );
        this.h_p = this.target.gameObject.toGlobal( this.h_p );

        this.w_g.position.copyFrom(this.w_p);
        this.h_g.position.copyFrom(this.h_p);

        this.graphic.clear();
        this.graphic.lineStyle(1, 0x00ff00, 1);
        this.graphic.drawPolygon( [
            this.lt_p,
            this.rt_p,
            this.rb_p,
            this.lb_p,
            this.lt_p,
        ] );
    }
    private _circleUpdate() {
        this.r_g.visible = true;
        this.w_g.visible = false;
        this.h_g.visible = false;
        for(let i = 0; i < this.points.length; i++) {
            const pData = this.points[i];
            pData.graphic.visible = false;
        }

        this.r_p.set(this.target.radius, 0);
        this.r_p = this.target.gameObject.toGlobal( this.r_p );
        this.r_g.position.copyFrom(this.r_p);

        this.c_p.set(0,0);
        this.c_p = this.target.gameObject.toGlobal( this.c_p );

        const radius = Util.Math.length( this.c_p.x, this.c_p.y, this.r_p.x, this.r_p.y );
        this.graphic.clear();
        this.graphic.lineStyle(1, 0x00ff00, 1);
        this.graphic.drawCircle(  this.c_p.x, this.c_p.y,  radius);
    }

    private _polygonUpdate( maxCount: number, loop: boolean = true ) {
        this.r_g.visible = false;
        this.w_g.visible = false;
        this.h_g.visible = false;

        this.graphic.clear();
        this.graphic.lineStyle(1, 0x00ff00, 1);

        const path = [];

        for(let i = 0; i < maxCount; i++) {
            let idx = i;
            let pData = this.points[idx];
            if( !pData ) {
                const point = new PIXI.Point();
                const graphic = new PIXI.Graphics();
                this.addChild( graphic );
                pData = {
                    point,
                    graphic,
                };
                this.points.push( pData );

                let w = 10;
                graphic.beginFill(0x00ff00);
                graphic.drawRect(-w/2, -w/2, w, w);
                graphic.endFill();
                this.setInteractive( graphic, (dx, dy)=>{

                    const localMove1 = this.target.gameObject.toLocal({x:0, y:0} );
                    const localMove2 = this.target.gameObject.toLocal({x:dx, y:dy} );
                    const localMove = {
                        x: (localMove2.x - localMove1.x),
                        y: (localMove2.y - localMove1.y)
                    };

                    this.target.points[idx] = {
                        x:this.target.points[idx].x + localMove.x,
                        y:this.target.points[idx].y + localMove.y,
                    }
                } );

            }
            pData.graphic.visible = true;
            const point = this.target.points[i] || {x:0,y:0};
            pData.point.set( point.x, point.y );
            pData.point = this.target.gameObject.toGlobal( pData.point );
            pData.graphic.position.copyFrom(pData.point);
            path.push( pData.point );
        }

        if(loop) {
            path.push( this.points[0].point );
        }

        this.graphic.drawPolygon( path );

        for(let i = this.target.pointCount; i < this.points.length; i++) {
            const pData = this.points[i];
            pData.graphic.visible = false;
        }
    }

    public update() {
        if(this._target) {
            this.visible = true;
        }
        else if( !this._target ) {
            this.visible = false;
            return;
        }

        switch (this._target.colliderType) {
            case 'box':
                this._boxUpdate();
                break;
            case 'circle':
                this._circleUpdate();
                break;
            case 'edge':
                this._polygonUpdate(2, false);
                break;
            case 'polygon':
                this._polygonUpdate(this.target.pointCount);
                break;
        }


        this.updateTransform();
    }
}


import Component from "../core/component";
import {component} from "../util/decorator";
import Game from "./game";

export const KEY_NAME : {
    ArrowLeft: string,
    ArrowRight: string,
    ArrowUp: string,
    ArrowDown: string,
    Space: string,
} = {
    ArrowLeft: 'ArrowLeft',
    ArrowRight: 'ArrowRight',
    ArrowUp: 'ArrowUp',
    ArrowDown: 'ArrowDown',
    Space: ' ',
}

export enum KEY_STATE {
    none,
    down,
    stay,
    up,
}

export default class InputManager  {

    private _keyDownBind = this._onKeyDown.bind(this);
    private _keyUpBind = this._onKeyUp.bind(this);

    private _keyMap : {[key:string]: KEY_STATE} = {};
    private _pointerState: KEY_STATE = KEY_STATE.none;
    private _pointerPosition : {x:number, y: number} = {
        x: 0,
        y: 0,
    }

    private _game: Game = null;

    private _destroyCallbacks: Function[] = [];

    public get left(): boolean {
        return this._keyMap[KEY_NAME.ArrowLeft] === KEY_STATE.stay
            || this._keyMap['a'] === KEY_STATE.stay
            || this._keyMap['A'] === KEY_STATE.stay;
    }
    public get right(): boolean {
        return this._keyMap[KEY_NAME.ArrowRight] === KEY_STATE.stay
            || this._keyMap['d'] === KEY_STATE.stay
            || this._keyMap['D'] === KEY_STATE.stay;
    }
    public get up(): boolean {
        return this._keyMap[KEY_NAME.ArrowUp] === KEY_STATE.stay
            || this._keyMap['w'] === KEY_STATE.stay
            || this._keyMap['W'] === KEY_STATE.stay;
    }
    public get down(): boolean {
        return this._keyMap[KEY_NAME.ArrowDown] === KEY_STATE.stay
            || this._keyMap['s'] === KEY_STATE.stay
            || this._keyMap['S'] === KEY_STATE.stay;
    }

    public get space(): boolean {
        return this._keyMap[KEY_NAME.Space] === KEY_STATE.stay;
    }

    constructor(game: Game) {
        this._game = game;

        window.addEventListener('keydown', this._keyDownBind);
        window.addEventListener('keyup', this._keyUpBind);

        let isDown = false;

        const pointerDown = (event: any) => {
            isDown = true;
            this._pointerState = KEY_STATE.down;
            const global = event.data.global;
            this._game.scenes.crtScene?.world?.toLocal(global, null, this._pointerPosition);
        }
        const pointerMove = (event: any) => {
            const global = event.data.global;
            this._game.scenes.crtScene?.world?.toLocal(global, null, this._pointerPosition);
        }
        const pointerUp = (event: any) => {
            isDown = false;
            this._pointerState = KEY_STATE.up;
            const global = event.data.global;
            this._game.scenes.crtScene?.world?.toLocal(global, null, this._pointerPosition);
        }

        this._game.renderer.plugins.interaction.on('pointerdown', pointerDown);
        this._game.renderer.plugins.interaction.on('pointermove', pointerMove);
        this._game.renderer.plugins.interaction.on('pointerup', pointerUp);

        this._destroyCallbacks.push(()=>{
            this._game.renderer.plugins.interaction.off('pointerdown', pointerDown);
            this._game.renderer.plugins.interaction.off('pointermove', pointerMove);
            this._game.renderer.plugins.interaction.off('pointerup', pointerUp);
        })
    }

    destroy() {
        this._game = null;
        window.removeEventListener('keydown', this._keyDownBind);
        window.removeEventListener('keyup', this._keyUpBind);
        this._destroyCallbacks.forEach(func => func());
    }

    onEnable() {

    }

    onDisable() {
        for(let key in this._keyMap) {
            this._keyMap[key] = KEY_STATE.none;
        }
    }

    private _onKeyDown(event: KeyboardEvent) {
        if(this._keyMap[event.key] !== KEY_STATE.stay) {
            this._keyMap[event.key] = KEY_STATE.down;
            this._game.signal.emit('keydown', event.key);
        }
    }

    private _onKeyUp(event: KeyboardEvent) {
        this._keyMap[event.key] = KEY_STATE.up;
        this._game.signal.emit('keyup', event.key);
    }

    getKeyDown(key: string): boolean {
        return this._keyMap[key] === KEY_STATE.down;
    }

    getKeyUp(key: string): boolean {
        return this._keyMap[key] === KEY_STATE.up;
    }

    getKey(key: string): boolean {
        return this._keyMap[key] === KEY_STATE.stay;
    }

    getPointerPosition(): {x:number, y: number} {
        return this._pointerPosition;
    }

    getPointerDown(): boolean {
        return this._pointerState === KEY_STATE.down;
    }
    getPointerStay(): boolean {
        return this._pointerState === KEY_STATE.stay;
    }
    getPointerUp(): boolean {
        return this._pointerState === KEY_STATE.up;
    }

    update() {
        for(let key in this._keyMap) {
            if(this._keyMap[key] === KEY_STATE.down) {
                this._keyMap[key] = KEY_STATE.stay;
            }
            else if(this._keyMap[key] === KEY_STATE.up) {
                this._keyMap[key] = KEY_STATE.none;
            }
            else if(this._keyMap[key] === KEY_STATE.stay) {
                this._game.signal.emit('keystay', key);
            }
        }

        if(this._pointerState === KEY_STATE.down) {
            this._pointerState = KEY_STATE.stay;
        }
        else if(this._pointerState === KEY_STATE.up) {
            this._pointerState = KEY_STATE.none;
        }
    }

}
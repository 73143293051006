import { saveAs } from 'file-saver';
import JSZip from 'jszip'
import GameObject from "../game/scripts/gameObject/gameObject";
import {HierarchyData} from "../store/hierarchySlice";
import {Key} from "antd/lib/table/interface";


export async function zipToFiles( zip : JSZip) {
    const zipObjects = zip.files;
    const files = [];
    for( let name in zipObjects ) {
        const zipObject = zipObjects[ name ] as JSZip.JSZipObject;
        if( zipObject.dir ) {
            continue;
        }

        const blob = await zipObject.async( "blob" );
        const fileName = zipObject.name;
        const file = blobToFile( blob, fileName );
        // @ts-ignore
        files.push( file );
    }

    return files;
}

export function blobToFile(theBlob: Blob, fileName:string): File {
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
}

export function zipFileToZip( zipFile:File ) : Promise<JSZip> {
    return new Promise( (resolve, reject)=>{
        const jsZip = new JSZip();
        jsZip.loadAsync( zipFile ).then( (zip)=>{
            resolve( zip );
        } )
    } );
}
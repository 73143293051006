import {Steps} from "antd";
import {useMemo, useState} from "react";

const { Step } = Steps;

export default function WizardSteps(
    {stepDataList,onChange,currentStep}:{
    stepDataList: {title:string, description: string}[],
    onChange?: (idx: number) =>void,
    currentStep?: number,
}) {
    // const [current, setCurrent] = useState(currentStep || 0);
    // useMemo(function (){
    //     setCurrent(currentStep);
    // }, [currentStep])

    const _onChange = (value: number) => {
        onChange && onChange(value);
    };

    return <div>
        <div className={'stepRoot'}>
            <Steps current={currentStep || 0} onChange={_onChange}>
                {
                    stepDataList.map((step)=>{
                        return <Step
                            title={step.title}
                            description={step.description}
                            key={step.title}
                        ></Step>
                    })
                }
            </Steps>
        </div>
    </div>
}
import {Typography, Row, Col, Input, Space} from "antd";
import {ReactElement} from "react";
import ViewTooltip from "../viewTooltip";

const {Text} = Typography;

export default function InputView({ label, value, onChange, disabled, tooltip }:
    {label:string, value:string, onChange:(value:string)=>void, disabled?:boolean, tooltip?:string,}) {

    return <Row wrap={false} className={"valueContainer"}>
        <Col className={'valueLabel'}>
            {
                tooltip? <ViewTooltip title={tooltip}>
                    <Text style={{marginRight: '5px'}}>{label}</Text>
                </ViewTooltip> :  <Text style={{marginRight: '5px'}}>{label}</Text>
            }
        </Col>
        <Col className={'valueBody'} flex="auto">
            <Input disabled={disabled} value={value} onChange={function (e){
                onChange(e.target.value);
            }}></Input>
        </Col>
    </Row>
}
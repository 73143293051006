import * as PIXI from 'pixi.js';
import Game from "./game";
import Scene, {ISceneJson} from "../gameObject/scene";
import SceneAsset from "../asset/sceneAsset";
import EditorScene from "../tools/editor/editorScene";

/*

start로 씬 시작은 하지만 따로 보관하지는 않음.
add로 추가된 씬데이터만 보관함

 */

export function isEditorScene( scene: Scene ) {
    return scene instanceof EditorScene;
}

export default class SceneManager
{
    private _game : Game;
    public get game() : Game {
        return this._game;
    }

    private _crtScene : Scene = null;
    public get crtScene() : Scene {
        return this._crtScene;
    }

    public get isEditorScene(): boolean {
        return this._crtScene instanceof EditorScene;
    }

    private _crtSceneAsset: SceneAsset = null;
    public get crtSceneAsset(): SceneAsset {
        return this._crtSceneAsset;
    }

    private sceneAssets : {[name : string] : SceneAsset} = {};

    constructor(game : Game) {
        this._game = game;
        this._crtScene = new Scene(this._game);
    }

    destroy() {
        if(this._crtScene) {
            this._crtScene.destroy();
            this._crtScene = null;
        }

        this._crtSceneAsset = null;
        this._game = null;
        this.sceneAssets = {};
    }

    stopScene() {
        if(this._crtScene) {
            this._crtScene.destroy();
            this._crtScene = null;
        }

        if(this._crtSceneAsset) {
            this._crtSceneAsset = null;
        }
    }

    addScene(sceneAsset: SceneAsset) {
        // if(!sceneJson) {
        //     throw new Error('잘못된 씬 데이터 입니다.');
        // }
        //
        // if(this.sceneJsons[name]) {
        //     throw new Error(`${name} 씬은 이미 추가되어 있습니다.`);
        // }

        this.sceneAssets[sceneAsset.id] = sceneAsset;
    }

    removeScene(id: string) {
        delete this.sceneAssets[id];
    }

    start(id : string, SceneCtor: new (game:Game)=>Scene = Scene) : Scene {
        const asset = this.sceneAssets[id];
        if(!asset) {
            throw new Error(`${id} 씬을 찾을 수 없습니다.`);
        }

        const scene = this.startFromJson(asset.scene, SceneCtor);
        if(scene) {
            this._crtSceneAsset = asset;
        }
        return scene;
    }

    startFromJson(sceneJson : ISceneJson, SceneCtor: new (game:Game)=>Scene = Scene) : Scene {
        if(this._crtScene) {
            this._crtScene.destroy();
            this._crtScene = null;
        }

        this._crtScene = new SceneCtor(this.game);
        this._crtScene.fromJson(sceneJson);
        this._crtScene.camera.width = this.game.renderer.width;
        this._crtScene.camera.height = this.game.renderer.height;
        this._crtSceneAsset = null;
        return this._crtScene;
    }

    startFromCtor<T extends Scene>(ctor : new (game : Game)=> T ) : Scene {
        if(this._crtScene) {
            this._crtScene.destroy();
            this._crtScene = null;
        }
        this._crtScene = new ctor(this.game/*, ctor.name*/);
        this._crtSceneAsset = null;
        return this._crtScene;
    }

    update(delta : number) {
        if(this._crtScene) {
            (this._crtScene as PIXI.utils.EventEmitter).emit('beforeUpdate');
            this._crtScene.update(delta);
            (this._crtScene as PIXI.utils.EventEmitter).emit('afterUpdate');
        }
    }

    render(renderer : PIXI.Renderer) {
        if(this._crtScene) {
            this._crtScene.updateCamera(renderer);
            // (this._crtScene as PIXI.utils.EventEmitter).emit('afterUpdateCamera');

            (this._crtScene as PIXI.utils.EventEmitter).emit('beforeRender');
            this.game.renderer.render(this._crtScene, {
                skipUpdateTransform : true,
            });
            (this._crtScene as PIXI.utils.EventEmitter).emit('afterRender');
        }
    }
}
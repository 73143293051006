import BaseFunction from "../baseFunction";
import {scriptTableData} from "../scriptTable";

export const ActionFunctionTable: scriptTableData[] = [
    {
        value: `action.allStop()`,
        caption: `action.allStop()`,
        score: 10,
        meta: 'function',
        name: 'action.allStop',
        description: {
            ko: '모든 액션을 정지 합니다.',
            en: '',
        },
    },
    {
        value: `await action.waitButton()`,
        caption: `await action.waitButton(): string`,
        score: 10,
        meta: 'function',
        name: 'action.waitButton',
        description: {
            ko: '버튼 이벤트가 발생할떄까지 대기하고 버튼 이벤트키를 반환받습니다.',
            en: '',
        },
    },
    {
        value: `await action.wait(1)`,
        caption: `await action.wait(duration: number)`,
        score: 10,
        meta: 'function',
        name: 'action.wait',
        description: {
            ko: '설정된 시간(초) 만큼 대기합니다.',
            en: '',
        },
    },
    {
        value: `await action.moveX(100, 1)`,
        caption: `await action.moveX(x: number, duration: number)`,
        score: 10,
        meta: 'function',
        name: 'action.moveX',
        description: {
            ko: '설정된 로컬 x좌표로 설정된 시간(초) 동안 게임오브젝트를 이동시키고 이동이 완료될 때까지 대기합니다.',
            en: '',
        },
    },
    {
        value: `await action.moveY(100, 1)`,
        caption: `await action.moveY(y: number, duration: number)`,
        score: 10,
        meta: 'function',
        name: 'action.moveY',
        description: {
            ko: '설정된 로컬 y좌표로 설정된 시간(초) 동안 게임오브젝트를 이동시키고 이동이 완료될 때까지 대기합니다.',
            en: '',
        },
    },
    {
        value: `await action.playTextAnimation('hello', false)`,
        caption: `await action.playTextAnimation(name: string, autoNext: boolean = false)`,
        score: 10,
        meta: 'function',
        name: 'action.playTextAnimation',
        description: {
            ko: '텍스트 애니메이션을 재생하고 완료될때까지 대기합니다.',
            en: '',
        },
    },
    {
        value: `await action.shakeCamera(1, 5)`,
        caption: `await action.shakeCamera(d: number, shakeDis:number = 5)`,
        score: 10,
        meta: 'function',
        name: 'action.shakeCamera',
        description: {
            ko: '카메라 흔들림 효과를 줍니다.',
            en: '',
        },
    },
];

export default class ActionFunction extends BaseFunction {
    protected _name: string = 'Action';

    public get instance(): ActionFunction {
        return this;
    }

    allStop() {
        this.gameObject.actionManager.allStop();
    }

    async moveX(x: number, d: number) {
        return this.gameObject.actionManager.moveX(x, d);
    }

    async moveY(y: number, d: number) {
        return this.gameObject.actionManager.moveY(y, d);
    }

    async wait(d: number) {
        return this.gameObject.actionManager.wait(d);
    }

    async shakeCamera(d: number, shakeDis:number = 5) {
        this.gameObject.game.scenes.crtScene.camera.setShake(5, 5);
        await this.wait(d);
        this.gameObject.game.scenes.crtScene.camera.setShake(0, 0);
    }

    async waitButton(): Promise<string> {
        return new Promise<string>((resolve)=>{
            this.gameObject.game.signal.once('button', (buttonKey: string)=>{
                resolve(buttonKey);
            });
        });
    }

    async playTextAnimation(str: string, autoNext: boolean = false) {
        return await this.manager.textAnimation.play(str, autoNext);
    }
}
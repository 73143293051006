import Component, {IComponentJson} from "../core/component";
import {component, property} from "../util/decorator";
import Sprite from "./sprite";
import TextureAsset from "../asset/textureAsset";
import GameObject, {TJsonRefers} from "../gameObject/gameObject";
import * as PIXI from 'pixi.js';
import Shape from "./shape";
import NineSlice from "./nineSlice";

export interface IButtonJson extends IComponentJson {
    transitionType: 'colorTint' | 'textureSwap' | 'none';
    normalColor: number,
    highlightedColor: number,
    pressedColor: number,
    disabledColor: number,

    highlightedTexture: string,
    pressedTexture: string,
    disabledTexture: string,

    buttonKey: string,
}

@component('Button')
export default class Button extends Component {
    // @property(Sprite)
    private target: Sprite | Shape | NineSlice = null;

    public transitionType: 'colorTint' | 'textureSwap' | 'none' = "colorTint";

    public normalColor: number = 0xffffff;
    public highlightedColor: number = 0xF5F5F5;
    public pressedColor: number = 0xC8C8C8;
    public disabledColor: number = 0xA6A6A6;

    @property(TextureAsset)
    private normalTexture: TextureAsset;

    @property(TextureAsset)
    public highlightedTexture: TextureAsset;
    @property(TextureAsset)
    public pressedTexture: TextureAsset;
    @property(TextureAsset)
    public disabledTexture: TextureAsset;

    public buttonKey: string = 'button1';

    private _destroyCallback: Function = null;

    create() {
    }

    start() {
        this.target = this.gameObject.getComponent(Sprite.Name);
        if( !this.target ) {
            this.target = this.gameObject.getComponent(NineSlice.Name);
        }
        if( !this.target ) {
            this.target = this.gameObject.getComponent(Shape.Name);
        }

        let isDown: boolean = false;

        if(this.target instanceof Sprite || this.target instanceof NineSlice ) {
            const target : NineSlice | Sprite = this.target;
            this.normalTexture = target.texture;

            //@ts-ignore
            let spriteInter = target.sprite.interactive;
            //@ts-ignore
            target.sprite.interactive = true;

            if( this.transitionType === 'colorTint') {
                target.tint = this.normalColor;
            }

            const pointerDown = ()=>{
                isDown = true;
                if(this.transitionType === 'colorTint') {
                    target.tint = this.pressedColor;
                }
                else if(this.transitionType === 'textureSwap') {
                    target.texture = this.pressedTexture || this.normalTexture;
                }
            }

            const pointerUp = ()=>{
                if(this.transitionType === 'colorTint') {
                    target.tint = this.highlightedColor;
                }
                else if(this.transitionType === 'textureSwap') {
                    target.texture = this.highlightedTexture || this.normalTexture;
                }
                if(isDown) {
                    this.game.signal.emit('button', this.buttonKey);
                }
                isDown = false;
            }

            const pointerUpOutside = () =>{
                if(this.transitionType === 'colorTint') {
                    target.tint = this.normalColor;
                }
                else if(this.transitionType === 'textureSwap') {
                    target.texture = this.normalTexture;
                }
            }

            const pointerOver = () =>{
                if(isDown) {
                    if(this.transitionType === 'colorTint') {
                        target.tint = this.pressedColor;
                    }
                    else if(this.transitionType === 'textureSwap') {
                        target.texture = this.pressedTexture || this.normalTexture;
                    }
                }
                else {
                    if(this.transitionType === 'colorTint') {
                        target.tint = this.highlightedColor;
                    }
                    else if(this.transitionType === 'textureSwap') {
                        target.texture = this.highlightedTexture || this.normalTexture;
                    }
                }
            }

            const pointerOut = () =>{
                if(this.transitionType === 'colorTint') {
                    target.tint = this.normalColor;
                }
                else if(this.transitionType === 'textureSwap') {
                    target.texture = this.normalTexture;
                }
            }

            (target.sprite as PIXI.utils.EventEmitter).on('pointerdown', pointerDown);
            (target.sprite as PIXI.utils.EventEmitter).on('pointerup', pointerUp);
            (target.sprite as PIXI.utils.EventEmitter).on('pointerupoutside', pointerUpOutside);
            (target.sprite as PIXI.utils.EventEmitter).on('pointerover', pointerOver);
            (target.sprite as PIXI.utils.EventEmitter).on('pointerout', pointerOut);


            this._destroyCallback = ()=> {
                if(target && target.sprite) {
                    //@ts-ignore
                    target.sprite.interactive = spriteInter;
                    (target.sprite as PIXI.utils.EventEmitter)?.off('pointerdown', pointerDown);
                    (target.sprite as PIXI.utils.EventEmitter)?.off('pointerup', pointerUp);
                    (target.sprite as PIXI.utils.EventEmitter)?.off('pointerupoutside', pointerUpOutside);
                    (target.sprite as PIXI.utils.EventEmitter)?.off('pointerover', pointerOver);
                    (target.sprite as PIXI.utils.EventEmitter)?.off('pointerout', pointerOut);
                }
            }
        }
        else if(this.target instanceof Shape) {
            const target = this.target as Shape;

            //@ts-ignore
            let spriteInter = target.graphic.interactive;
            //@ts-ignore
            target.graphic.interactive = true;

            if( this.transitionType === 'colorTint') {
                target.graphic.tint = this.normalColor;
            }

            const pointerDown = ()=>{
                isDown = true;
                if(this.transitionType === 'colorTint') {
                    target.graphic.tint = this.pressedColor;
                }
            }

            const pointerUp = ()=>{
                if(this.transitionType === 'colorTint') {
                    target.graphic.tint = this.highlightedColor;
                }
                if(isDown) {
                    this.game.signal.emit('button', this.buttonKey);
                }
                isDown = false;
            }

            const pointerUpOutside = () =>{
                if(this.transitionType === 'colorTint') {
                    target.graphic.tint = this.normalColor;
                }
            }

            const pointerOver = () =>{
                if(isDown) {
                    if(this.transitionType === 'colorTint') {
                        target.graphic.tint = this.pressedColor;
                    }
                }
                else {
                    if(this.transitionType === 'colorTint') {
                        target.graphic.tint = this.highlightedColor;
                    }
                }
            }

            const pointerOut = () =>{
                if(this.transitionType === 'colorTint') {
                    target.graphic.tint = this.normalColor;
                }
            }

            (target.graphic as PIXI.utils.EventEmitter).on('pointerdown', pointerDown);
            (target.graphic as PIXI.utils.EventEmitter).on('pointerup', pointerUp);
            (target.graphic as PIXI.utils.EventEmitter).on('pointerupoutside', pointerUpOutside);
            (target.graphic as PIXI.utils.EventEmitter).on('pointerover', pointerOver);
            (target.graphic as PIXI.utils.EventEmitter).on('pointerout', pointerOut);


            this._destroyCallback = ()=> {
                if(target && target.graphic) {
                    //@ts-ignore
                    target.graphic.interactive = spriteInter;
                    (target.graphic as PIXI.utils.EventEmitter)?.off('pointerdown', pointerDown);
                    (target.graphic as PIXI.utils.EventEmitter)?.off('pointerup', pointerUp);
                    (target.graphic as PIXI.utils.EventEmitter)?.off('pointerupoutside', pointerUpOutside);
                    (target.graphic as PIXI.utils.EventEmitter)?.off('pointerover', pointerOver);
                    (target.graphic as PIXI.utils.EventEmitter)?.off('pointerout', pointerOut);
                }
            }
        }

    }

    destroy() {
        this.normalTexture = null;
        this.highlightedTexture = null;
        this.pressedTexture = null;
        this.disabledTexture = null;
        this._destroyCallback && this._destroyCallback();
        this.target = null;
        super.destroy();
    }

    toJson(): IButtonJson {
        const json = super.toJson() as IButtonJson;

        json.transitionType = this.transitionType;
        json.normalColor = this.normalColor;
        json.highlightedColor = this.highlightedColor;
        json.pressedColor = this.pressedColor;
        json.disabledColor = this.disabledColor;

        json.highlightedTexture = this.highlightedTexture?.id;
        json.pressedTexture = this.pressedTexture?.id;
        json.disabledTexture = this.disabledTexture?.id;
        json.buttonKey = this.buttonKey;

        return json;
    }

    fromJson(json: IButtonJson, refers: TJsonRefers = {}) {
        super.fromJson(json, refers);

        this.transitionType = json.transitionType;
        this.normalColor = json.normalColor;
        this.highlightedColor = json.highlightedColor;
        this.pressedColor = json.pressedColor;
        this.disabledColor = json.disabledColor;

        this.highlightedTexture = this.game.assetManager.getAsset(json.highlightedTexture) as TextureAsset;
        this.pressedTexture = this.game.assetManager.getAsset(json.pressedTexture) as TextureAsset;
        this.disabledTexture = this.game.assetManager.getAsset(json.disabledTexture) as TextureAsset;

        this.buttonKey = json.buttonKey;
    }

    onCompleteJson(json: IButtonJson, refers: TJsonRefers) {
        super.onCompleteJson(json, refers);
    }
}
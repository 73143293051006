import Command from "./command";
import {Key} from "antd/es/table/interface";
import Game from "../../game/scripts/core/game";
import GameObject, {IGameObjectJson} from "../../game/scripts/gameObject/gameObject";
import EditorManager from "../editorManager";
import {store} from "../../app/store";
import {treeManager} from "../../components/panel/hierarchy/hierarchy";

export default class AddGameObjectCommand extends Command {

    private parentId: string;
    private gameObjectId: string;
    private json: IGameObjectJson;
    private isPrefab: boolean = false;

    constructor(parentId: string, json?: IGameObjectJson, isPrefab: boolean = false) {
        super();
        this.parentId = parentId;
        this.json = json;
        this.isPrefab = isPrefab;
    }

    execute(editName:boolean = false) : GameObject{
        const parent = EditorManager.Instance.findGameObject(this.parentId);
        const gameObject = new GameObject(EditorManager.Instance.game);
        this.gameObjectId = gameObject.id;
        const name = this.json?.name || 'gameObject';
        gameObject.name = name;
        // for(let i = 0; i < 1000; i++) {
        //     let rename = false;
        //     for(let i = 0; i < parent.children.length; i++) {
        //         if(parent.children[i] instanceof GameObject) {
        //             if(parent.children[i].name === gameObject.name) {
        //                 rename = true;
        //                 break;
        //             }
        //         }
        //     }
        //     if(!rename) {
        //         break;
        //     }
        //     gameObject.name = `${name}(${i+1})`;
        // }

        parent.addChild( gameObject );
        parent.expanded = true;
        if(this.json) {
            const name = gameObject.name;
            gameObject.fromJson(this.json, null, false, this.isPrefab);
            gameObject.name = name;
        }

        EditorManager.Instance.createHierarchy(()=>{
            treeManager.tree?.selectById(gameObject.id.toString());
            if(editName) {
                treeManager.tree?.edit( gameObject.id.toString() );
            }
        });

        return gameObject;
    }

    undo() {
        if( EditorManager.Instance.equalHierarchyId(this.gameObjectId.toString()) ) {
            treeManager.tree?.selectById(undefined);
        }

        const parent = EditorManager.Instance.findGameObject(this.parentId);
        const gameObject = parent.findById( this.gameObjectId );
        gameObject.destroy();
        EditorManager.Instance.createHierarchy();
    }
}
import GameObject, {TJsonRefers} from "../gameObject/gameObject";
import Game from "./game";
import * as PIXI from 'pixi.js';
import {component, createCtorName, propertiesName, property, propertyList, propertyUpgrade} from "../util/decorator";
import ReferenceObject from "./ReferenceObject";
import * as uuid from 'uuid';

export interface IComponentJson {
    name : string;
    active : boolean;
    id : string,
    expanded: boolean,
    more: boolean,
}

export interface IComponentConstructor {
    new (gameObject : GameObject): Component;
    Name : string;
    IsAddList: boolean;
}

export default class Component extends ReferenceObject {
    static readonly Name : string = 'Component';
    static readonly IsAddList : boolean = false;

    public get name() : string {
        return (this.constructor as IComponentConstructor).Name;
    };

    private _game : Game;
    public get game() : Game {
        return this._game;
    }

    private _gameObject : GameObject;
    public get gameObject() : GameObject {
        return this._gameObject;
    }

    private _active : boolean = false;

    // @property(Boolean)
    public get active() : boolean {
        return this._active;
    }

    private _expanded : boolean = true;
    // @property(Boolean)
    public get expanded() : boolean {
        return this._expanded;
    }
    public set expanded(extend : boolean) {
        this._expanded = extend;
    }

    public more: boolean = false;

    private _isStart : boolean = false;
    private _isAwake : boolean = false;

    public set active(active : boolean) {
        if (this.gameObject.worldActive && this._active === false && active === true) {
            this._active = true;
            if(!this._isAwake) {
                this._isAwake = true;
                this.awake();
            }
            this.onEnable();
        } else if (this.gameObject.worldActive && this._active === true && active === false) {
            this._active = false;
            this.onDisable();
        }
        else {
            this._active = active;
        }
    }

    private readonly _id : string;
    public get id() : string {
        return this._id;
    }

    constructor(gameObject : GameObject) {
        super();
        this._gameObject = gameObject;
        this._game = gameObject?.game;
        this._id = uuid.v4();
        // this.active = true;

        this.game?.instanceManager?.addInstance(this);
    }

    initProps() {
        //@ts-ignore
        const property = propertyList[this.constructor];
        for(let i = 0; i < property.parents.length; i++) {
            const supers = propertyList[property.parents[i]];
            if(!supers) continue;
            for(let j = 0; j < supers.props.length; j++) {
                const {name, ctor} = supers.props[j];
                propertyUpgrade(ctor, this, name);
            }
        }

        for(let i = 0; i < property.props.length; i++) {
            const {name, ctor} = property.props[i];
            propertyUpgrade(ctor, this, name);
        }

        // console.log(property);
        // const props2 = this[propertiesName];
        // for(let i = 0; i < props.length; i++) {
        //     const prop = props[i];
        //     const ctorName = createCtorName(prop);
        //     //@ts-ignore
        //     const ctor = this[ctorName];
        //     propertyUpgrade(ctor, this, prop);
        // }
    }

    //처음으로 호출될때 프리팹에서 비롯된 완전한 게임오브젝트 형성 여부를 보장하지 않음.
    public onEnable() {}
    public onDisable() {}
    public create() {}
    public awake() {}
    public start() {}
    public update(delta : number) {}
    public destroy() {
        (this as PIXI.utils.EventEmitter).emit('destroy');
        this.game?.instanceManager?.removeInstance(this);
        this._gameObject = null;
        this._game = null;
    };

    public _update( delta : number ) {
        if(!this._isStart) {
            this._isStart = true;
            this.start();
        }
        this.update(delta);
    }

    _debugRender(graphic: PIXI.Graphics) {};

    toJson() : IComponentJson {
        return {
            name : this.name,
            active : this.active,
            id : this.id,
            expanded: this.expanded,
            more: this.more,
        }
    }

    fromJson(json : IComponentJson, refers : TJsonRefers = {}) {
        this.active = json.active;
        this.expanded = json.expanded;
        this.more = json.more || false;
        refers[json.id] = this;
    }

    onCompleteJson(json: IComponentJson, refers : TJsonRefers) {

    }
}
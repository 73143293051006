import Command from "./command";
import {Key} from "antd/es/table/interface";
import Game from "../../game/scripts/core/game";
import GameObject, {
    GetGlobalPoint,
    GetGlobalRotation,
    SetGlobalPoint,
    SetGlobalRotation
} from "../../game/scripts/gameObject/gameObject";
import EditorManager from "../editorManager";
import {store} from "../../app/store";
import {treeManager} from "../../components/panel/hierarchy/hierarchy";

export default class MoveHierarchyCommand extends Command {

    private oriParentId: string;
    private parentId: string;
    private gameObjectId: string;
    private dstIdx: number;
    private srcIdx: number;

    constructor(parentId: string, gameObjectId: string, dstIdx: number) {
        super();
        this.parentId = parentId;
        this.gameObjectId = gameObjectId;
        this.dstIdx = dstIdx;
    }

    execute() {
        const parent = EditorManager.Instance.findGameObject(this.parentId);
        const target = EditorManager.Instance.findGameObject(this.gameObjectId);

        const worldPos = GetGlobalPoint(target);
        const worldRot = GetGlobalRotation(target);
        const oriParent = (target.parent as GameObject);

        this.srcIdx = 0;
        for(let i = 0; i < oriParent.children.length; i++) {
            const child = oriParent.children[i];
            if(!(child instanceof GameObject)) continue;
            if( child === target ) {
                break;
            }
            this.srcIdx++;
        }
        this.oriParentId = oriParent.id;

        let Idx = 0;
        let dstIdx = this.dstIdx;
        for(let i = 0; i < parent.children.length; i++) {
            if( dstIdx <= Idx ) {
                break;
            }
            const child = parent.children[i];
            if(!(child instanceof GameObject)) continue;
            if(child === target) {
                dstIdx--;
            }
            Idx++;
        }

        parent.addChildAt(target, Idx);
        SetGlobalPoint(target, worldPos.x, worldPos.y);
        SetGlobalRotation(target, worldRot);

        EditorManager.Instance.createHierarchy();
    }

    undo() {
        const parent = EditorManager.Instance.findGameObject(this.oriParentId);
        const target = EditorManager.Instance.findGameObject( this.gameObjectId );

        const worldPos = GetGlobalPoint(target);
        const worldRot = GetGlobalRotation(target);

        let idx = 0;
        let dstIdx = 0;
        for(let i = 0; i < parent.children.length; i++) {
            if( this.srcIdx <= idx ) {
                break;
            }
            const child = parent.children[i];
            if(!(child instanceof GameObject)) continue;
            if(child === target) {
                dstIdx--;
            }
            idx++;
        }

        parent.addChildAt(target, idx);

        SetGlobalPoint(target, worldPos.x, worldPos.y);
        SetGlobalRotation(target, worldRot);

        EditorManager.Instance.createHierarchy();
    }
}
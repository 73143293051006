import Asset, {IAssetData} from "./asset";
import Game from "../core/game";

export interface IAnimationJson {
    name: string,
    interval: number,
    frames: string[],
    loop: boolean,
}

export interface IAnimatorAssetJson {
    animations: IAnimationJson[],
}

export interface IAnimatorAssetData extends IAssetData {
    json : IAnimatorAssetJson,
}

export default class AnimatorAsset extends Asset {
    private _json: IAnimatorAssetJson;
    protected readonly _type: string = 'Animator';

    constructor(game: Game, json: IAnimatorAssetJson = undefined, id: string = undefined) {
        super(game, id);
        this.json = json || {
            animations: []
        };
    }

    public get json(): IAnimatorAssetJson {
        return this._json;
    }

    public set json(json: IAnimatorAssetJson) {
        // if(this._json && this._json.animations?.length) {
        //     for(let i = 0; i < this._json.animations.length; i++) {
        //         const ani = this._json.animations[i];
        //         for(let j = 0; j < ani.frames.length; j++) {
        //             const frame = ani.frames[j];
        //             const texAsset = game.assetManager.getAsset()
        //         }
        //     }
        // }

        this._json = json;
        // if(this._json && this._json.animations?.length) {
        //
        // }
    }

    destroy() {
        super.destroy();
        this.json = null;
    }

    toPlayData(): IAnimatorAssetData {
        const json = super.toPlayData() as IAnimatorAssetData;
        json.json = this.json;
        return json;
    }
}
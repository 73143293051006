import GameObject, {editable, EEditable} from "../game/scripts/gameObject/gameObject";
import {HierarchyData} from "../store/hierarchySlice";
import {Key} from "antd/es/table/interface";
import {EEditorViewOption} from "../store/editorSettingSlice";

export function verifyGenerateHierarchyChildren(gameObject: GameObject, editorViewOption: EEditorViewOption = EEditorViewOption.advanced) : boolean {
    const gameObjectOption = gameObject.getDisableOption('gameObject').editType;
    const childrenOption = gameObject.getDisableOption('children').editType;

    if( editorViewOption === EEditorViewOption.default ) {
        if(gameObjectOption & EEditable.view) {
            if(childrenOption & EEditable.view) {
                return true;
            }
        }
    }
    else if( editorViewOption === EEditorViewOption.advanced ) {
        if(gameObjectOption & EEditable.advanceView) {
            if (childrenOption & EEditable.advanceView) {
                return true;
            }
        }
    }

    return false;
}

export function verifyGenerateHierarchy(gameObject: GameObject, editorViewOption: EEditorViewOption = EEditorViewOption.advanced) : boolean {
    const gameObjectOption = gameObject.getDisableOption('gameObject').editType;
    if( editorViewOption === EEditorViewOption.default ) {
        if(gameObjectOption & EEditable.view) {
            return true;
        }
    }
    else if( editorViewOption === EEditorViewOption.advanced ) {
        if(gameObjectOption & EEditable.advanceView) {
            return true;
        }
    }
    return false;
}

export function generateHierarchy(objects: GameObject[], list: HierarchyData[] = [], editorViewOption: EEditorViewOption = EEditorViewOption.advanced) {
    for(let i = 0; i < objects.length; i++) {
        const child = objects[i];
        if( child instanceof GameObject && verifyGenerateHierarchy(child, editorViewOption)) {
            const children: HierarchyData[] = [];
            const editActive = editable(child, 'active');
            const editName = editable(child, 'name');
            // const key = parentKey + '-' + i.toString();
            list.push({
                id: child.id.toString(),
                name: child.name,
                prefabId: child.prefabId || undefined,
                isOpen: (child.expanded && child.childCount !== 0),
                isLock: (child.locked),
                children,
                active: child.active,
                editActive,
                editName,
            });

            if(verifyGenerateHierarchyChildren(child, editorViewOption)) {
                generateHierarchy( child.children as GameObject[], children);
            }
        }
    }
}
import {useEffect, useMemo, useRef, useState} from "react";
import './gameView.css';
import EditorManager from "../../../services/editorManager";
import {selectCurrentScene, changeCurrentScene} from "../../../store/editorSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import ZoomScale from "./zoomScale";
import {Tabs} from "antd";
import {store} from "../../../app/store";
import ToolBox from "./toolBox";
import LocalizationManager from "../../../services/localizationManager";

const { TabPane } = Tabs;

export default function GameView() {

    let ref = useRef<HTMLDivElement>(null);
    let resizeObject = new ResizeObserver(()=>{
        const element = ref.current;
        EditorManager.Instance.game.renderer.resize( element.offsetWidth, element.offsetHeight );
    });
    let mouseInfo = useRef<HTMLDivElement>(null);

    function onMouseMove(e : MouseEvent) {
        const offsetX = e.offsetX;
        const offsetY = e.offsetY;

        const pos = EditorManager.Instance.game.scenes.crtScene?.world?.toLocal( {x: offsetX, y: offsetY} );

        mouseInfo.current.innerHTML = `${ pos?.x.toFixed()}, ${pos?.y.toFixed()}`;
    }

    useEffect(() => {
        const element = ref.current;
        element.appendChild( EditorManager.Instance.game.renderer.view);
        EditorManager.Instance.game.renderer.resize( element.offsetWidth, element.offsetHeight );
        resizeObject.observe(element);

        ref.current.addEventListener('mousemove', onMouseMove);

        return function () {
            ref.current.removeEventListener('mousemove', onMouseMove);

            element.removeChild( EditorManager.Instance.game.renderer.view);
            resizeObject.disconnect();
        }
    }, []);

    let nameDiv = null;
    const currentScene = useAppSelector(selectCurrentScene);
    const dispatch = useAppDispatch();

    const asset = EditorManager.Instance.game.assetManager.getAsset(currentScene?.id) || null;
    const scenes = useMemo(()=>{
        return EditorManager.Instance.assetManager.getSceneAssetList();
    }, [currentScene]);

    if( asset ) {
        nameDiv = <div className={'sceneName'}>
            {asset.name}
        </div>
    }


    const onChange = (newActiveKey: string) => {
        // setActiveKey(newActiveKey);
        if( newActiveKey !== currentScene.id ) {
            EditorManager.Instance.saveScene();
            EditorManager.Instance.startScene(newActiveKey);
            dispatch(changeCurrentScene({id: newActiveKey}));
        }


    };

    const add = () => {
        const name = prompt(LocalizationManager.Instance.getText('Popup.inputNewScene'));
        if(!name) {
            return;
        }

        if( EditorManager.Instance.assetManager.getAsset(name, 'Scene') ) {
            window.confirm(LocalizationManager.Instance.getText('Popup.failChangeName'));
            return;
        }

        const asset = EditorManager.Instance.addScene(name);
        if( asset ) {
            dispatch(changeCurrentScene({...store.getState().editor.currentScene}));
        }
    };

    const remove = (targetKey: string) => {

    };

    const onEdit = (targetKey: any, action: 'add' | 'remove') => {
        if (action === 'add') {
            add();
        } else {
            remove(targetKey);
        }
    };


    return(
        <div className={'gameViewRoot'} id={'gameViewRoot'}>
            <Tabs type="editable-card"
                  className={'sceneTabs'}
                  style={{
                  }}
                  onChange={onChange} activeKey={asset?.id} onEdit={onEdit}>
                {scenes.map((scene : any) => (
                    <TabPane tab={scene.name} key={scene.id} closable={false}>
                    </TabPane>
                ))}
            </Tabs>
            <ToolBox></ToolBox>
            <div className={"gameView"} ref={ref} onDrop={function (event){

                const prefabId = event.dataTransfer.getData('prefabId');
                if(prefabId) {
                    EditorManager.Instance.clonePrefab( prefabId, event.nativeEvent.offsetX, event.nativeEvent.offsetY );
                }

            }}>
                {/*{nameDiv}*/}
                <div className={'mouseInfo'} ref={mouseInfo}></div>
                <ZoomScale></ZoomScale>
                {/*<div style={{position:'absolute', left: '50%', top:"50%", transformOrigin: '50% 50%', color: 'black'}}>★</div>*/}
            </div>
        </div>

    )
}
import BaseFunction from "../baseFunction";
import {scriptTableData} from "../scriptTable";
import SoundAsset from "../../asset/soundAsset";
import ScriptError from "../scriptError";

export const SoundFunctionTable: scriptTableData[] = [
    {
        value: `sound.play('sound', false)`,
        caption: `sound.play(name: string, loop: boolean = false volume: number = 1)`,
        score: 10,
        meta: 'function',
        name: 'sound.play',
        description: {
            ko: '사운드를 재생합니다. 두번째 인자로 루프 여부를 설정할수 있습니다.',
            en: '',
        },
    },
];

export default class SoundFunction extends BaseFunction {
    protected _name: string = 'Sound';
    public get instance(): SoundFunction {
        return this;
    }

    play(name: string, loop: boolean = false, volume : number = 1) {
        const sound = this.gameObject.game.assetManager.getAsset(name, 'Sound') as SoundAsset;
        if(!sound) {
            throw new ScriptError('사운드 없음',
                `${name} 이름의 사운드를 찾을 수 없습니다.`,
                this.log.script?.script?.name, 'sound.play');
        }
        else {
            sound.play(loop, volume);
        }
    }
}
import Asset, {IAssetData} from "./asset";
import Game from "../core/game";
import {ISceneJson} from "../gameObject/scene";
import {IGameObjectJson} from "../gameObject/gameObject";

export interface IFontAssetData extends IAssetData {
    fontFamily: string;
    arrayBuffer: ArrayBuffer;
}

export default class FontAsset extends Asset {

    protected readonly _type : string = 'Font';

    private _arrayBuffer: ArrayBuffer = null;
    private _fontFamily: string = '';

    constructor(game: Game, fontFamily: string, buffer: ArrayBuffer, id: string = undefined) {
        super(game, id);
        this._arrayBuffer = buffer;
        this._fontFamily = fontFamily;
    }

    public get fontFamily(): string{
        return this._fontFamily;
    }

    public get arrayBuffer(): ArrayBuffer{
        return this._arrayBuffer;
    }

    destroy() {
        super.destroy();
    }

    toPlayData(): IFontAssetData {
        const json = super.toPlayData() as IFontAssetData;
        json.fontFamily = this._fontFamily;
        json.arrayBuffer = this._arrayBuffer;
        return json;
    }
}
import {useEffect, useRef} from "react";
import Game from "../game/scripts/core/game";
import TextureAsset from "../game/scripts/asset/textureAsset";
import AssetManager from "../game/scripts/asset/assetManager";
import SceneAsset from "../game/scripts/asset/sceneAsset";
import ScriptAsset from "../game/scripts/asset/scriptAsset";
import AnimatorAsset from "../game/scripts/asset/animatorAsset";
import PrefabAsset from "../game/scripts/asset/prefabAsset";
import LoadingScene from "mogera-game/scripts/tools/editor/loadingScene";
import {ScriptsLoader} from "mogera-game/scripts/util/scriptLoader";

const playRootStyle = {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden'
};

const canvasStyle = {

};

let created: boolean = false;

export default function Play() {
    const playRootRef = useRef<HTMLDivElement>(null);

    async function createGame () {
        const assets = window.opener.bridgeMainToPlay();
        const gameSetting = assets.gameSetting;
        const projectSetting = assets.projectSetting;


        const cdnList = projectSetting.cdnList?.split(',');
        if(cdnList?.length > 0) {
            await ScriptsLoader(cdnList);
        }

        const game = new Game({
            width: gameSetting.width,
            height: gameSetting.height
        });
        window.opener.bridgeMessage({ type: 'create' });

        playRootRef.current.appendChild( game.renderer.view );

        const loading = game.scenes.startFromCtor(LoadingScene) as LoadingScene;
        loading.setProgress(0, 1);

        const textures = assets.textures;
        const sounds = assets.sounds;
        const scenes = assets.scenes;
        const scripts = assets.scripts;
        const animators = assets.animators;
        const prefabs = assets.prefabs;
        const fonts = assets.fonts;

        let count = 0;
        let maxCount = textures.length + sounds.length
            + scenes.length + scripts.length + animators.length
            + prefabs.length + fonts.length;

        window.opener.bridgeMessage({ type: 'load resource' });



        await new Promise<void>((resolve, reject)=>{

            async function _loader(func: Function, callback: Function) {
                await func();
                callback();
            }

            function _onLoad() {
                if(count === maxCount) {
                    resolve();
                }
            }

            for(let i = 0; i < textures.length; i++) {
                _loader(async function (){
                    const texture = textures[i];
                    const asset = await AssetManager.LoadAndAddTexture(game, texture.dataUrl, texture.id);
                    asset.rename( texture.name );
                }, ()=>{
                    count++;
                    loading.setProgress(count, maxCount);
                    _onLoad();
                });
            }
            for(let i = 0; i < sounds.length; i++) {
                _loader(async function (){
                    const sound = sounds[i];
                    const asset = await AssetManager.LoadAndAddSound(game, sound.dataUrl, sound.id);
                    asset.rename( sound.name );
                }, ()=>{
                    count++;
                    loading.setProgress(count, maxCount);
                    _onLoad();
                });
            }
            for(let i = 0; i < scenes.length; i++) {
                _loader(async function (){
                    const scene = scenes[i];
                    const asset = new SceneAsset(game, scene.json, scene.id);
                    asset.rename(scene.name);
                    game.assetManager.addAsset(asset);
                    await new Promise<void>((resolve)=>{
                        setTimeout(()=>{
                            resolve();
                        }, 1) ;
                    });
                }, ()=>{
                    count++;
                    loading.setProgress(count, maxCount);
                    _onLoad();
                });
            }
            for(let i = 0; i < scripts.length; i++) {
                _loader(async function (){
                    const script = scripts[i];
                    const asset = new ScriptAsset(game, script.json, script.id);
                    asset.rename(script.name);
                    game.assetManager.addAsset(asset);
                    await new Promise<void>((resolve)=>{
                        setTimeout(()=>{
                            resolve();
                        }, 1) ;
                    });
                }, ()=>{
                    count++;
                    loading.setProgress(count, maxCount);
                    _onLoad();
                });
            }
            for(let i = 0; i < animators.length; i++) {
                _loader(async function (){
                    const animator = animators[i];
                    const asset = new AnimatorAsset(game, animator.json, animator.id);
                    asset.rename(animator.name);
                    game.assetManager.addAsset(asset);
                    await new Promise<void>((resolve)=>{
                        setTimeout(()=>{
                            resolve();
                        }, 1) ;
                    });
                }, ()=>{
                    count++;
                    loading.setProgress(count, maxCount);
                    _onLoad();
                });

            }
            for(let i = 0; i < prefabs.length; i++) {
                _loader(async function (){
                    const prefab = prefabs[i];
                    const asset = new PrefabAsset(game, prefab.json, prefab.id);
                    asset.rename(prefab.name);
                    game.assetManager.addAsset(asset);
                    await new Promise<void>((resolve)=>{
                        setTimeout(()=>{
                            resolve();
                        }, 1) ;
                    });
                }, ()=>{
                    count++;
                    loading.setProgress(count, maxCount);
                    _onLoad();
                });
            }
            for(let i = 0; i < fonts.length; i++) {
                _loader(async function (){
                    const font = fonts[i];
                    const asset = await AssetManager.LoadAndAddFont(game, font.name, font.arrayBuffer, font.id);
                    asset.rename( font.name );
                    await new Promise<void>((resolve)=>{
                        setTimeout(()=>{
                            resolve();
                        }, 1) ;
                    });
                }, ()=>{
                    count++;
                    loading.setProgress(count, maxCount);
                    _onLoad();
                });
            }
        });

        game.scenes.start( projectSetting.currentSceneAssetId );
        window.opener.bridgeMessage({ type: 'start game' });

        const canvas = game.renderer.view;
        document.body.style.overflow = 'hidden';
        resize();

        window.addEventListener('resize', resize );
        function resize() {
            const width = game.renderer.width;
            const height = game.renderer.height;


            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            canvas.style.position = 'absolute';

            if(windowWidth/windowHeight > width/height) {
                //윈도우의 가로가 더 김
                const canvasWidth = width * (windowHeight/height);
                canvas.style.height = `${windowHeight}px`;
                canvas.style.width = `${canvasWidth}px`;
                canvas.style.top = `0`;
                canvas.style.left = `${(windowWidth - canvasWidth)/2}px`;
            }
            else {
                const canvasHeight = height * (windowWidth/width);
                canvas.style.width = `${windowWidth}px`;
                canvas.style.height = `${canvasHeight}px`;
                canvas.style.top = `${(windowHeight - canvasHeight)/2}px`;
                canvas.style.left = '0';
            }
        }

        window.addEventListener('beforeunload', function (){
            window.opener.bridgeMessage({
                type: 'close'
            })
        });
    }

    useEffect(()=>{
        if(!created) {
            created = true;
            createGame();
        }
    },[]);

    return <div ref={playRootRef} style={playRootStyle}>

    </div>;
}
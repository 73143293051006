import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
// import Theme from "./features/theme/theme";

// import { setChonkyDefaults } from 'chonky';
// import { ChonkyIconFA } from 'chonky-icon-fontawesome';
// // Somewhere in your `index.ts`:
// setChonkyDefaults({ iconComponent: ChonkyIconFA });

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

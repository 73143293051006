import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {RootState} from "../app/store";
import EditorManager from "../services/editorManager";
import LocalizationManager from "../services/localizationManager";
import TooltipManager from "../services/tooltipManager";
import {LayoutManager} from "../components/editorLayout/editorLayout";
import {DEFAULT_LANG} from "../services/constSetting";

export enum EEditorViewOption {
    default,
    advanced ,
}

export type LocalizeLang = 'ko' | 'en';

export interface EditorSettingState {
    editorViewOption: EEditorViewOption,
    tooltipLocalize: LocalizeLang,
    textLocalize: LocalizeLang,
}


const initialState: EditorSettingState = {
    editorViewOption: EEditorViewOption.advanced,
    tooltipLocalize: localStorage.getItem('tooltipLocalize') as LocalizeLang || DEFAULT_LANG,
    textLocalize: localStorage.getItem('textLocalize') as LocalizeLang || DEFAULT_LANG,
};

export const selectEditorViewOption = (state: RootState) => state.editorSetting.editorViewOption;
export const selectTooltipLocalize = (state: RootState) => state.editorSetting.tooltipLocalize;
export const selectTextLocalize = (state: RootState) => state.editorSetting.textLocalize;

export const editorSettingSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        changeEditorViewOption: (state, action: PayloadAction<EEditorViewOption>) => {
            state.editorViewOption = action.payload;
            EditorManager.Instance.editViewOption = action.payload;
        },
        changeTooltipLocalize: (state, action: PayloadAction<LocalizeLang>) => {
            state.tooltipLocalize = action.payload;
            TooltipManager.Instance.setLang(action.payload);
        },
        changeTextLocalize: (state, action: PayloadAction<LocalizeLang>) => {
            state.textLocalize = action.payload;
            LocalizationManager.Instance.setLang(action.payload);
            //LayoutManager.changeLang();
        },
    },
});

export const {
    changeEditorViewOption,
    changeTooltipLocalize,
    changeTextLocalize
} = editorSettingSlice.actions;

export default editorSettingSlice.reducer;
import Asset, {IAssetData} from "./asset";
import Game from "../core/game";
import {ISceneJson} from "../gameObject/scene";
import {IGameObjectJson} from "../gameObject/gameObject";
import {ISceneAssetData} from "./sceneAsset";

export interface IPrefabAssetData extends IAssetData {
    json: IGameObjectJson,
    color: string,
    order: number,
}

export default class PrefabAsset extends Asset {

    private _json : IGameObjectJson;

    protected readonly _type: string = 'Prefab';

    public color: string = '#ffffff';
    public order: number = 0;

    constructor(game: Game, json: IGameObjectJson, id?: string) {
        super(game, id);
        this._json = json;
    }

    public get prefab(): IGameObjectJson {
        return this._json;
    }

    public set prefab(json: IGameObjectJson) {
        this._json = json;
        this.change();
    }

    destroy() {
        super.destroy();
        this._json = null;
    }

    toPlayData(): IPrefabAssetData {
        const json = super.toPlayData() as IPrefabAssetData;
        json.json = this.prefab;
        json.color = this.color;
        json.order = this.order;
        return json;
    }
}
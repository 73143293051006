import BaseFunction from "../baseFunction";
import {scriptTableData} from "../scriptTable";
import Script from "../../component/script";
import ScriptError from "../scriptError";
import FunctionManager from "../functionManager";
import GameObject from "../../gameObject/gameObject";
import {EPostUpdateProperty} from "../../core/postUpdate";

export const ObjectFunctionTable: scriptTableData[] = [
    {
        value: `instance.active`,
        caption: `instance.active`,
        score: 10,
        meta: 'getter,setter',
        name: 'instance.active',
        tooltip: 'const active = instance.active\ninstance.active = true',
        description: {
            ko: '인스턴스의 활성화 여부를 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `instance.hasScript('scriptName')`,
        caption: `instance.hasScript(scriptName: string): boolean`,
        score: 10,
        meta: 'function',
        name: 'instance.hasScript',
        description: {
            ko: '인스턴스가 설정된 이름의 스크립트를 가지고 있는지 확인합니다.',
            en: '',
        },
    },
    {
        value: `instance.setScriptActive('scriptName', true)`,
        caption: `instance.setScriptActive(scriptName: string, active: boolean)`,
        score: 10,
        meta: 'function',
        name: 'instance.setScriptActive',
        description: {
            ko: '스크립트를 활성화/비활성화 시킵니다.',
            en: '',
        },
    },
    {
        value: `instance.getScriptValue('scriptName', 'valueName')`,
        caption: `instance.getScriptValue(scriptName: string, valueName: string): any`,
        score: 10,
        meta: 'function',
        name: 'instance.getValue',
        description: {
            ko: '인스턴스가 가지고 있는 스크립트 변수의 값을 반환받습니다.',
            en: '',
        },
    },
    {
        value: `instance.getParent()`,
        caption: `instance.getParent(): Instance`,
        score: 10,
        meta: 'function',
        name: 'instance.getParent',
        description: {
            ko: '인스턴스의 보모 인스턴스를 가져옵니다.',
            en: '',
        },
    },
    {
        value: `instance.getChildren()`,
        caption: `instance.getChildren(): Instance[]`,
        score: 10,
        meta: 'function',
        name: 'instance.getChildren',
        description: {
            ko: '인스턴스의 자식목록을 가져옵니다.',
            en: '',
        },
    },
    {
        value: `instance.addChild(child)`,
        caption: `instance.addChild(child: Instance)`,
        score: 10,
        meta: 'function',
        name: 'instance.addChild',
        description: {
            ko: '인스턴스에 자식 인스턴스를 추가합니다.',
            en: '',
        },
    },
    {
        value: `instance.removeChild(child)`,
        caption: `instance.removeChild(child: Instance)`,
        score: 10,
        meta: 'function',
        name: 'instance.removeChild',
        description: {
            ko: '인스턴스에 자식 인스턴스를 제거합니다.',
            en: '',
        },
    },
    {
        value: `instance.name`,
        caption: `instance.name`,
        score: 10,
        meta: 'getter,setter',
        name: 'instance.name',
        description: {
            ko: '인스턴스의 이름을 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `instance.kill();`,
        caption: `instance.kill()`,
        score: 10,
        meta: 'function',
        name: 'instance.kill',
        description: {
            ko: '인스턴스의 파괴시킵니다.',
            en: '',
        },
    },
];

export default class ObjectFunction extends BaseFunction {
    protected _name: string = 'Object';

    public get instance(): ObjectFunction {
        return this;
    }

    public get active(): boolean {
        return this.gameObject.active;
    }

    public set active(v: boolean) {
        this.gameObject.active = v;
    }

    public get name(): string {
        return this.gameObject.name;
    }

    public set name(_name: string) {
        this.gameObject.name = _name;
    }

    public kill() {
        this.manager.kill();
    }

    public hasScript(scriptName: string): boolean {
        const script = this.gameObject.getComponent(scriptName) as Script;
        return !!script;
    }

    public setScriptActive(scriptName: string, active: boolean) {
        const script = this.gameObject.getComponent(scriptName) as Script;
        if(!script) {
            throw new ScriptError('스크립트 없음',
                `${scriptName} 이름의 스크립트를 찾을 수 없습니다.`,
                this.log.script?.script?.name, 'object.getValue');
        }
        script.active = active;
    }

    public getValue(scriptName: string, valueName: string): any {
        const script = this.gameObject.getComponent(scriptName) as Script;
        if(!script) {
            throw new ScriptError('스크립트 없음',
                `${scriptName} 이름의 스크립트를 찾을 수 없습니다.`,
                this.log.script?.script?.name, 'object.getValue');
        }
        const value = script.getProp(valueName);
        if(value === undefined ) {
            throw new ScriptError('스크립트 변수 없음',
                `${valueName} 이름의 스크립트 변수를 찾을 수 없습니다.`,
                this.log.script?.script?.name, 'object.getValue');
        }
        return value;
    }

    public getScriptValue(scriptName: string, valueName: string): any {
        const script = this.gameObject.getComponent(scriptName) as Script;
        if(!script) {
            throw new ScriptError('스크립트 없음',
                `${scriptName} 이름의 스크립트를 찾을 수 없습니다.`,
                this.log.script?.script?.name, 'object.getScriptValue');
        }
        const value = script.getProp(valueName);
        if(value === undefined ) {
            throw new ScriptError('스크립트 변수 없음',
                `${valueName} 이름의 스크립트 변수를 찾을 수 없습니다.`,
                this.log.script?.script?.name, 'object.getScriptValue');
        }
        return value;
    }
    public getChildren(): FunctionManager[] {
        const children : FunctionManager[] = [];
        for(let i = 0; i < this.gameObject.children.length; i++) {
            const child = this.gameObject.children[i];
            if(child instanceof GameObject) {
                children.push(child.functionManager);
            }
        }
        return children;
    }

    public getParent(): FunctionManager {
        const parent = this.gameObject.parent as GameObject;
        if(!parent || !(parent instanceof GameObject)) {
            return null;
        }
        return parent.functionManager;
    }

    public addChild(child: FunctionManager) {
        if(child instanceof FunctionManager) {
            this.gameObject.addChild(child.gameObject);
        }
    }

    public removeChild(child: FunctionManager) {
        if(child instanceof FunctionManager) {
            this.gameObject.removeChild(child.gameObject);
        }
    }

}
import Component, {IComponentJson} from "../core/component";
import {component, property} from "../util/decorator";
import Text from './text';
import Sprite from "./sprite";
import {TJsonRefers} from "../gameObject/gameObject";

export interface ITextAnimationJson extends IComponentJson{
    speed: number,
    showCursor: boolean,
}

@component('TextAnimation')
export default class TextAnimation extends Component {
    @property(Text)
    public text: Text;

    public speed: number = 10;
    public showCursor: boolean = false;

    private _string: string = '';
    private _index: number = 0;
    private _time: number = 0;
    private _finish: boolean = false;
    private _finishCallback: Function;

    private _isDown: boolean = false;

    private _mouseCallback: (e:MouseEvent)=>void = null;
    private _start: boolean = false;


    private _cursor: boolean = false;

    start() {
        this.text = this.gameObject.getComponent(Text.Name);
        this._start = true;
        this._addMouseCallback();
    }

    hasText(): boolean {
        if(this.text) return true;
        this.text = this.gameObject.getComponent(Text.Name);
        return !!this.text;
    }

    private _addMouseCallback() {
        if(this._start && !this._mouseCallback) {
            this._mouseCallback = (e)=>{
                if(!this._finishCallback || this._isDown) {
                    return;
                }
                if(!this._finish) {
                    this._finish = true;
                    this.text.string = this._string;
                    if(this._isDown) {
                        this._finishCallback && this._finishCallback();
                        this._finishCallback = null;
                    }
                }
                else {
                    this._finishCallback && this._finishCallback();
                    this._finishCallback = null;
                }
            }
            window.addEventListener('mousedown', this._mouseCallback);
        }
    }

    private _removeMouseCallback() {
        if(this._mouseCallback) {
            window.removeEventListener('mousedown', this._mouseCallback);
        }
    }

    onEnable() {
        this._addMouseCallback();
    }

    onDisable() {
        this._removeMouseCallback();
    }

    destroy() {
        this._removeMouseCallback();
        this._finishCallback = null;
        this._finish = true;
        this.text = null;
        super.destroy();
    }

    async setString(str: string, downNext: boolean = false) {
        return new Promise<void>((resolve)=>{
            this._string = str;
            this._index = 0;
            this._time = 0;
            this._finish = false;
            this.text.string = '';
            this._finishCallback = ()=>{
                resolve();
            };

            this._isDown = !downNext;
        });
    }

    update(delta: number) {

        this._time += delta;
        if(this._time >= 1 / (this.speed || 1)) {
            this._time -= 1 / (this.speed || 1);

            if(!this._finish) {
                this._index++;
                if( this._index >= this._string.length ) {
                    this.text.string = this._string;
                    this._finish = true;
                    if(this._isDown) {
                        this._finishCallback && this._finishCallback();
                        this._finishCallback = null;
                    }
                }
                else {
                    this.text.string = this._string.substring(0, this._index);
                }
            }
            else if(this.showCursor){
                if(this._cursor) {
                    this._cursor = !this._cursor;
                    this.text.string = this._string;
                }
                else {
                    this._cursor = !this._cursor;
                    this.text.string = this._string + ' |';
                }
            }
        }
    }

    toJson(): ITextAnimationJson {
        const json = super.toJson() as ITextAnimationJson;
        json.showCursor = this.showCursor;
        json.speed = this.speed;
        return json;
    }

    fromJson(json: ITextAnimationJson, refers: TJsonRefers = {}) {
        super.fromJson(json, refers);
        this.showCursor = !!json.showCursor;
        this.speed = json.speed || 10;
    }

}
import {Badge, Card, Image, Menu, Popconfirm, Row, Typography, Dropdown} from "antd";
import EditorManager from "../../../services/editorManager";
import PrefabAsset from "../../../game/scripts/asset/prefabAsset";
import {changeRefreshPrefabView} from "../../../store/refeshSlice";
import {useDispatch} from "react-redux";
import Previewer from "../../../game/scripts/tools/editor/previewer";
import {useDrop} from "react-dnd";
import {DragItem} from "react-arborist/dist/types";
import {DropResult} from "react-arborist/dist/dnd/drop-hook";
import {treeManager} from "../hierarchy/hierarchy";
import AssetItem from "../../modal/assetItem";
import * as React from "react";
import {
    DeleteFilled,
    BgColorsOutlined,
    EditFilled,
    UpCircleOutlined,
    DownCircleOutlined,
    CaretDownOutlined, CaretRightOutlined
} from "@ant-design/icons";
import {ReactElement, useEffect, useState} from "react";
import {changeCurrentScene, changeSelectPrefabIds, selectSelectPrefabIds} from "../../../store/editorSlice";
import {useAppSelector} from "../../../app/hooks";
import {prefabSelector} from "./prefabView";
import {store} from "../../../app/store";
import LocalizationManager from "../../../services/localizationManager";
const {Text} = Typography;

const caches:{[id:string]: string} = {}

export function getPrefabImg(item: PrefabAsset, isNew: boolean = false) {

    if(isNew) {
        const data = Previewer.PreviewRender(EditorManager.Instance.game,item.prefab );
        caches[item.id] = data;
        return data;
    }

    if(caches[item.id]) {
        return caches[item.id];
    }
    else {
        const data = Previewer.PreviewRender(EditorManager.Instance.game,item.prefab );
        caches[item.id] = data;
        return data;
    }
}


export default function TagListView( {tagData, onColorChange, onHideChange, onRemove,onMove,idx,onRename} :
    {
        tagData:{ tag:{tag:string,color:string,hide:boolean}, list:PrefabAsset[] },
        onColorChange : (color:string)=>void,
        onHideChange: ()=>void,
        onRemove: ()=>void,
        onMove: (idx:number, dir: number)=>void,
        idx: number,
        onRename: ()=>void,
    }){

    const dispatch = useDispatch();
    const itemList = [];
    // const [selectId, setSelectId] = useState('');
    const selectIds = useAppSelector(selectSelectPrefabIds);


    tagData.list.sort( (a, b) => a.order - b.order );

    for(let j = 0; j < tagData.list.length; j++){
        const asset = tagData.list[j];
        const img = getPrefabImg(asset);

        itemList.push(<AssetItem

            selectable={true}
            menu={<Menu items={[
                {
                    key: '1', label: <Popconfirm
                        title={LocalizationManager.Instance.getText('Popup.confirmRemove', asset.name)}
                        onConfirm={function (event){
                            event.preventDefault();
                            event.stopPropagation();
                            EditorManager.Instance.assetManager.removeAsset( asset.id );
                            dispatch(changeRefreshPrefabView(Math.random()));
                        }}
                        onCancel={function (event){
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                        okText="제거"
                        cancelText="취소"
                    >
                        <div><DeleteFilled style={{marginRight:'10px'}}/>
                            {LocalizationManager.Instance.getText('ContextMenu.remove') || 'Remove'}
                        </div>
                    </Popconfirm>
                },
                {
                    key: '2', label: <div onClick={function (event){
                        event.preventDefault();
                        event.stopPropagation();
                        const name = prompt(LocalizationManager.Instance.getText('Popup.changeName'), asset.name);
                        if(name === asset.name || !name) {
                            return;
                        }

                        if( EditorManager.Instance.assetManager.getAsset(name, asset.type) ) {
                            window.confirm(LocalizationManager.Instance.getText('Popup.failChangeName'));
                        }
                        else {
                            asset.rename(name);
                            EditorManager.Instance.createHierarchy();
                            dispatch(changeRefreshPrefabView(Math.random()));
                        }
                    }}><EditFilled style={{marginRight:'10px'}}/>
                        {LocalizationManager.Instance.getText('ContextMenu.rename') || 'Rename'}
                    </div>
                },
                {
                    key: '3', label: <div onClick={function (){
                        const input = document.createElement('input') as HTMLInputElement;
                        input.type = 'color';
                        input.value = tagData.tag.color;
                        input.onchange = function (e) {
                            asset.color = (e.target as HTMLInputElement).value;
                            EditorManager.Instance.createHierarchy();
                            dispatch(changeRefreshPrefabView(Math.random()));
                        }
                        input.click();
                    }}><BgColorsOutlined style={{marginRight:'10px'}}/>
                        {LocalizationManager.Instance.getText('ContextMenu.changeColor') || 'Color'}
                    </div>
                },
                {
                    key: '4', label: <div onClick={function (){
                        if(j > 0) {
                            const asset2 = tagData.list[j - 1];
                            asset.order = asset2.order - 1;
                            dispatch(changeRefreshPrefabView(Math.random()));
                        }

                    }}><UpCircleOutlined style={{marginRight:'10px'}}/>
                        {LocalizationManager.Instance.getText('ContextMenu.moveUp') || 'Up'}
                    </div>
                },
                {
                    key: '5', label: <div onClick={function (){
                        if(j < tagData.list.length - 1) {
                            const asset2 = tagData.list[j + 1];
                            asset.order = asset2.order + 1;
                            dispatch(changeRefreshPrefabView(Math.random()));
                        }
                    }}><DownCircleOutlined style={{marginRight:'10px'}}/>
                        {LocalizationManager.Instance.getText('ContextMenu.moveDown') || 'Down'}
                    </div>
                },
            ]}/>}
            key={asset.id}
            select={selectIds.indexOf(asset.id) > -1}
            onClick={function (){
                if(prefabSelector.selectId === asset.id) {
                    prefabSelector.select(null);
                }
                else {
                    prefabSelector.select(asset.id);
                }

            }}
            asset={asset}
            nameColor={asset.prefab.active? '' : '#959595'}
            // nameBackColor={asset.color}
            draggable={true}
            onDragStart={function (event){
                event.dataTransfer.setData('assetId', asset.id);
                event.dataTransfer.setData('prefabId', asset.id);
            }}
        >
            <Badge dot style={{
                width: '10px',
                height: '10px',
            }} size={"default"} color={asset.color}>
                <img src={img}
                     className={'image'}
                     style={{
                         pointerEvents: 'auto',
                         height: '80px',
                         width: '80px',
                         objectFit: 'cover',
                     }}
                />
            </Badge>

        </AssetItem>)

        // itemList.push(<Card.Grid
        //     style={{padding:0}} key={asset.id} draggable={true}
        //     onDragStart={function (event){
        //         event.dataTransfer.setData('prefabId', asset.id);
        //     }}
        // >
        //     <Image preview={false} src={img}></Image>
        //     <Text style={{
        //         display: 'inline-block',
        //         width: '100%',
        //         whiteSpace:'nowrap',
        //         textOverflow: 'ellipsis',
        //         overflow: "hidden",
        //     }}
        //     >{tagData.list[j].name}</Text>
        // </Card.Grid>)
    }
    return <div style={{
        marginBottom: '20px',
    }} onDragEnter={function (event){
        if(treeManager.map[treeManager.dragId]?.isDragging) {
            event.preventDefault();
            event.stopPropagation();
        }
    }} onDragOver={function (event){
        if(treeManager.map[treeManager.dragId]?.isDragging) {
            event.preventDefault();
            event.stopPropagation();
        }
    }} onDrop={function (event){
        const id = event.dataTransfer.getData('prefabId');
        if(id) {
            const dragAsset = EditorManager.Instance.assetManager.getAsset(id) as PrefabAsset;
            if(dragAsset.tag !== tagData.tag.tag ) {
                dragAsset.tag = tagData.tag.tag;
                dispatch(changeRefreshPrefabView(Math.random()));
            }
        }
        else if(treeManager.map[treeManager.dragId]?.isDragging) {
            const asset = EditorManager.Instance.createPrefab(treeManager.dragId)
            if(asset) {
                asset.tag = tagData.tag.tag;
            }
        }
    }}>
        <div style={{
            marginBottom: '15px',
        }}>
            {
                tagData.tag.tag ? <Dropdown overlay={<Menu items={[
                    {
                        key: '1', label: <Popconfirm
                            title={LocalizationManager.Instance.getText('Popup.confirmRemove', tagData.tag.tag)}
                            onConfirm={function (event){
                                event.preventDefault();
                                event.stopPropagation();
                                onRemove();
                            }}
                            onCancel={function (event){
                                event.preventDefault();
                                event.stopPropagation();
                            }}
                            okText="제거"
                            cancelText="취소"
                        >
                            <div><DeleteFilled style={{marginRight:'10px'}}/>
                                {LocalizationManager.Instance.getText('ContextMenu.remove') || 'Remove'}
                            </div>
                        </Popconfirm>
                    },
                    {
                        key: '2', label: <div onClick={function (event){
                            event.preventDefault();
                            event.stopPropagation();
                            onRename();
                        }}><EditFilled style={{marginRight:'10px'}}/>
                            {LocalizationManager.Instance.getText('ContextMenu.rename') || 'Rename'}
                        </div>
                    },
                    {
                        key: 'item_up', label:  <div onClick={function (){
                            onMove(idx, -1);
                        }}><UpCircleOutlined style={{marginRight:'10px'}}/>
                            {LocalizationManager.Instance.getText('ContextMenu.moveUp') || 'Up'}
                        </div>
                    },
                    {
                        key: 'item_down', label:  <div onClick={function (){
                            onMove(idx, 1);
                        }}><DownCircleOutlined style={{marginRight:'10px'}}/>
                            {LocalizationManager.Instance.getText('ContextMenu.moveDown') || 'Down'}
                        </div>
                    }
                ]}/>} trigger={['contextMenu']}>
                    <div style={{
                        // height: '24px',
                        backgroundColor: '#313131',
                        lineHeight: '24px',
                        color: '#aaaaaa',
                        display: 'flex',
                    }} onClick={function (){
                        onHideChange();
                    }}>
                        {
                            <div style={{
                                marginLeft: '10px'
                            }}>{tagData.tag.hide ?   <CaretRightOutlined/> :  <CaretDownOutlined/>}</div>
                        }
                        <div style={{
                            textAlign: 'center',
                            flexGrow:1,
                        }}>
                            {tagData.tag.tag}
                        </div>
                    </div>
                </Dropdown> : <div style={{
                    // height: '24px',
                    backgroundColor: '#313131',
                    lineHeight: '24px',
                    color: '#aaaaaa',
                    display: 'flex',
                }} onClick={function (){
                    onHideChange();
                }}>
                    {
                        <div style={{
                            marginLeft: '10px'
                        }}>{tagData.tag.hide ?   <CaretRightOutlined/> :  <CaretDownOutlined/>}</div>
                    }
                    <div style={{
                        textAlign: 'center',
                        flexGrow:1,
                    }}>
                        {LocalizationManager.Instance.getText('PrefabList.noTag') || 'No Tag'}
                    </div>

                </div>
            }

        </div>
        {
            !tagData.tag.hide ? <Row wrap={true} gutter={[12,12]} justify={'center'} style={{minHeight:'80px'}}>
                {itemList}
            </Row> : null
        }


    </div>
}
import Command from "./command";
import Component, {IComponentJson} from "../../game/scripts/core/component";
import Game from "../../game/scripts/core/game";
import EditorManager from "../editorManager";
import {store} from "../../app/store";
import {treeManager} from "../../components/panel/hierarchy/hierarchy";
// import {changeHierarchySelectKeys} from "../../store/hierarchySlice";

export default class ModifyComponentCommand extends Command {

    private prevJson : IComponentJson;
    private json : IComponentJson;
    private gameObjectId : string;

    constructor(gameObjectId: string, prev:IComponentJson, json:IComponentJson) {
        super();
        this.gameObjectId = gameObjectId;
        this.prevJson = prev;
        this.json = json;
    }

    execute() {
        const gameObject = EditorManager.Instance.findGameObject( this.gameObjectId );
        const comp = gameObject.findById(this.json.id) as Component;
        comp.fromJson( this.json );
        treeManager.tree?.selectById(gameObject.id);
    }

    undo() {
        const gameObject = EditorManager.Instance.findGameObject( this.gameObjectId );
        const comp = gameObject.findById(this.json.id) as Component;
        comp.fromJson( this.prevJson );
        treeManager.tree?.selectById(gameObject.id);
    }
}
import * as PIXI from 'pixi.js';
import Game from "./core/game";

export default class Factory {

    private _game : Game;
    public get game() : Game {
        return this._game;
    }

    constructor(game : Game) {
        this._game = game;
    }

    destroy() {
        this._game = null;
    }

}
import BaseFunction, {ComponentFunctionError} from "../baseFunction";
import {scriptTableData} from "../scriptTable";
import Text from './../../component/text';
import TextAnimation from "../../component/textAnimation";
import ComponentFunction from "./componentFunction";
import ScriptError, {CheckComp, CheckValueType} from "../scriptError";


export const TextAnimationFunctionTable: scriptTableData[] = [
    {
        value: `await textAnimation.play('hello', false);`,
        caption: `await textAnimation.play(name: string, autoNext: boolean = false)`,
        score: 10,
        meta: 'function',
        name: 'textAnimation.play',
        description: {
            ko: '텍스트 애니메이션을 재생하고 완료될때까지 대기합니다.',
            en: '',
        },
    },
    {
        value: `textAnimation.setSpeed(10);`,
        caption: `textAnimation.setSpeed(speed: number)`,
        score: 10,
        meta: 'function',
        name: 'textAnimation.setSpeed',
        description: {
            ko: 'TextAnimation 의 텍스트 출력속도를 설정합니다.',
            en: '',
        },
    },
    {
        value: `textAnimation.getSpeed();`,
        caption: `textAnimation.getSpeed()`,
        score: 10,
        meta: 'function',
        name: 'textAnimation.getSpeed',
        description: {
            ko: 'TextAnimation 의 텍스트 출력속도를 가져옵니다.',
            en: '',
        },
    },
]

export default class TextAnimationFunction extends ComponentFunction {
    protected _name: string = 'TextAnimation';
    protected _temp: TextAnimation = null;
    public get instance(): TextAnimationFunction {
        this._temp = this.gameObject.getComponent(this._name);
        return this;
    }

    destroy() {
        super.destroy();
        this._temp = null;
    }

    async play(str: string, autoNext: boolean = false) {
        if(!CheckComp(this,'play')) return;
        if(!this._temp.hasText()) {
            throw new ScriptError('컴포넌트 없음',
                'TextAnimation 컴포넌트를 사용하기 위해서는 Text 컴포넌트가 필요합니다.',
                this.log.script?.script?.name, 'textAnimation.play');
        }
        if(!CheckValueType(this,str, 'string', 'play')) return;
        if(!CheckValueType(this,autoNext, 'boolean', 'play')) return;
        return await this._temp.setString(str, !autoNext);
    }

    setSpeed(v: number) {
        if(!CheckComp(this,'setSpeed')) return;
        if(!CheckValueType(this,v, 'number', 'setSpeed')) return;
        this._temp.speed = v;
    }
    getSpeed(): number {
        if(!CheckComp(this,'setSpeed')) return;
        return this._temp.speed;
    }
}
import {IComponentViewProps} from "./componentView";
import EditorManager from "../../../../services/editorManager";
import {store} from "../../../../app/store";
import {Button, Input, InputNumber, Space, Typography, Tooltip, Col, Row, Select} from "antd";
import PointView from "../valueView/pointView";
import SizeView from "../valueView/sizeView";
import TextureAssetView from "../valueView/ref/textureAssetView";
import TextureAsset from "../../../../game/scripts/asset/textureAsset";
import {CaretDownOutlined, CaretRightOutlined, ClearOutlined} from "@ant-design/icons";
import CheckboxView from "../valueView/checkboxView";
import ColorView from "../valueView/colorView";
import * as PIXI from 'pixi.js';
import InputNumberView from "../valueView/inputNumberView";
import SelectView from "../valueView/selectView";
import BorderView from "../valueView/borderView";
import Shape from "mogera-game/scripts/component/shape";
import TilingSprite, {ITilingSpriteJson} from "../../../../game/scripts/component/tilingSprite";
import {useState} from "react";
import LocalizationManager from "../../../../services/localizationManager";
import TooltipManager from "../../../../services/tooltipManager";


const { Text } = Typography;
const { Option } = Select;


export default function TilingSpriteView(props: IComponentViewProps) {

    const json = props.json as ITilingSpriteJson;
    const copyJson = JSON.parse(JSON.stringify(json));
    // const [more, setMore] = useState(false);

    return(<>
        <TextureAssetView
            label={LocalizationManager.Instance.getText('TilingSprite.texture') || 'texture'}
            tooltip={  TooltipManager.Instance.getText('TilingSprite.texture') }
            texture={json.texture} onClick={async () => {
            const imgKey = await EditorManager.Instance.selectImage( json.texture );
            if(imgKey !== null) {
                json.texture = imgKey;
                EditorManager.Instance.modifyComponent(json);
            }
        }} onRemove={function (){
            json.texture = '';
            EditorManager.Instance.modifyComponent(json);
        }}></TextureAssetView>

        <SizeView
            label={LocalizationManager.Instance.getText('TilingSprite.size') || 'size'}
            tooltip={  TooltipManager.Instance.getText('TilingSprite.size') }
            size={json} disabled={false} onChange={(width, height) => {
            json.width = width;
            json.height = height;
            EditorManager.Instance.modifyComponent(json);
        }}></SizeView>

        <PointView label={LocalizationManager.Instance.getText('TilingSprite.tilePosition') || 'tilePosition'}
                   tooltip={  TooltipManager.Instance.getText('TilingSprite.tilePosition') }
                   step={1} disabled={false} point={{x:json.tilePosition.x, y:json.tilePosition.y}}
                   onChange={(x,y)=>{
                       json.tilePosition.x = x;
                       json.tilePosition.y = y;
                       EditorManager.Instance.modifyComponent(json)
                   }}/>
        <PointView label={LocalizationManager.Instance.getText('TilingSprite.tileScale') || 'tileScale'}
                   tooltip={  TooltipManager.Instance.getText('TilingSprite.tileScale') }
                   step={0.01} disabled={false} point={{x:json.tileScale.x, y:json.tileScale.y}}
                   onChange={(x,y)=>{
                       json.tileScale.x = x;
                       json.tileScale.y = y;
                       EditorManager.Instance.modifyComponent(json)
                   }}/>
        <div className={'fold'} onClick={function (){
            json.more = !json.more;
            EditorManager.Instance.modifyComponent(json)
        }}>
            {
                json.more ?   (
                        <>
                            <CaretDownOutlined/>
                            <span>{LocalizationManager.Instance.getText('Component.hideOption') || 'hideOption'}</span>
                        </>) :
                    (<>
                        <CaretRightOutlined/>
                        <span> {LocalizationManager.Instance.getText('Component.showOption') || 'showOption'}</span>
                    </>)
            }
        </div>
        {
            json.more ? <>
                <ColorView  key={json.tint}
                            name={LocalizationManager.Instance.getText('TilingSprite.tint') || 'tint'}
                            tooltip={  TooltipManager.Instance.getText('TilingSprite.tint') }
                            value={PIXI.utils.hex2string(json.tint)} onChange={function (color){
                    const colorStr = color.replace('#', '');
                    const comp = EditorManager.Instance.findComponent(json.id) as TilingSprite;
                    comp.tint = PIXI.utils.string2hex(colorStr);

                }} onChangeComplete={function (color){
                    const colorStr = color.replace('#', '');
                    json.tint = PIXI.utils.string2hex(colorStr);
                    EditorManager.Instance.modifyComponent(json, copyJson);
                }}/>
                <InputNumberView
                    label={LocalizationManager.Instance.getText('TilingSprite.alpha') || 'alpha'}
                    tooltip={  TooltipManager.Instance.getText('TilingSprite.alpha') }
                    value={json.alpha} onChange={function (num){
                    json.alpha = num;
                    EditorManager.Instance.modifyComponent(json);
                }}/>
                <PointView
                    label={LocalizationManager.Instance.getText('TilingSprite.anchor') || 'anchor'}
                    tooltip={  TooltipManager.Instance.getText('TilingSprite.anchor') }
                    step={0.01} disabled={false} point={{x:json.anchor.x, y:json.anchor.y}}
                           onChange={(x,y)=>{
                               json.anchor.x = x;
                               json.anchor.y = y;
                               EditorManager.Instance.modifyComponent(json)
                           }}/>
            </> : null
        }
    </>)
}
import {Typography, Row, Col, Input, Space, InputNumber} from "antd";
import {ReactElement} from "react";
import {IPointViewProps} from "./pointView";
import ViewTooltip from "../viewTooltip";

const {Text} = Typography;

export default function BorderView({label, border, disabled, onChange, tooltip, onClick}: {
    label: string,
    border: {l:number, t:number, r:number, b: number},
    disabled?: boolean,
    onChange: (l:number, t:number, r:number, b: number)=>void;
    tooltip?:string,
    onClick?: ()=>void
}) {
    const localBorder = {l:border.l, t:border.t, r:border.r, b: border.b};

    return (<Row wrap={false}  className={"valueContainer"}>
        <Col className={'valueLabel'}>
            {
                tooltip? <ViewTooltip title={tooltip}>
                    <Text style={{marginRight: '5px'}}>{label}</Text>
                </ViewTooltip> :  <Text style={{marginRight: '5px'}}>{label}</Text>
            }
        </Col>
        <Col className={'valueBody'} flex={'auto'}>
            <Row wrap={false}>
                <Col style={{flex: '0 0 20px', display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={{color:'#f5222d'}}>L</Text>
                </Col>
                <Col flex={'auto'}>
                    <InputNumber style={{width:'100%'}} disabled={disabled} value={Number(border?.l?.toFixed(4))}  onChange={((num)=>{
                        localBorder.l = num;
                        onChange && onChange(localBorder.l, localBorder.t, localBorder.r, localBorder.b);
                    })} onClick={onClick}></InputNumber>
                </Col>
                <Col style={{flex: '0 0 20px', display: "flex", justifyContent: 'center', alignItems: 'center', marginLeft: '5px'}}>
                    <Text style={{color:'#a0d911'}}>T</Text>
                </Col>
                <Col flex={'auto'}>
                    <InputNumber style={{width:'100%'}} disabled={disabled} value={Number(border?.t?.toFixed(4))}  onChange={((num)=>{
                        localBorder.t = num;
                        onChange && onChange(localBorder.l, localBorder.t, localBorder.r, localBorder.b);
                    })} onClick={onClick}></InputNumber>
                </Col>
                <Col style={{flex: '0 0 20px', display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={{color:'#f5222d'}}>R</Text>
                </Col>
                <Col flex={'auto'}>
                    <InputNumber style={{width:'100%'}} disabled={disabled} value={Number(border?.r?.toFixed(4))}  onChange={((num)=>{
                        localBorder.r = num;
                        onChange && onChange(localBorder.l, localBorder.t, localBorder.r, localBorder.b);
                    })} onClick={onClick}></InputNumber>
                </Col>
                <Col style={{flex: '0 0 20px', display: "flex", justifyContent: 'center', alignItems: 'center', marginLeft: '5px'}}>
                    <Text style={{color:'#a0d911'}}>B</Text>
                </Col>
                <Col flex={'auto'}>
                    <InputNumber style={{width:'100%'}} disabled={disabled} value={Number(border?.b?.toFixed(4))}  onChange={((num)=>{
                        localBorder.b = num;
                        onChange && onChange(localBorder.l, localBorder.t, localBorder.r, localBorder.b);
                    })} onClick={onClick}></InputNumber>
                </Col>
            </Row>
        </Col>
    </Row>);
}
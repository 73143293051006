import Component, {IComponentConstructor} from "../core/component";
import GameObject from "../gameObject/gameObject";
import ReferenceObject from "../core/ReferenceObject";

const preset = 'hgPgfWv1';
export const propertiesName = `${preset}List`;


export function component(name : string, isAddList: boolean = true) {
    return function (constructor: IComponentConstructor) {
        //@ts-ignore
        propertyList[constructor] = propertyList[constructor] || {
            parents: [],
            props: [],
        };

        let parent = constructor;
        while ( parent !== Component) {
            parent = Object.getPrototypeOf(parent);
            //@ts-ignore
            propertyList[constructor].parents.push(parent);
        }
        // Object.getPrototypeOf(Object.getPrototypeOf(foo))

        constructor.Name = name;
        constructor.IsAddList = isAddList;

    }
}

export function createCtorName( propName : string ) {
    return `${preset}_ctor_${propName}`;
}

export let propertyList : {
    [name:string]:
        {
            parents: string[],
            props: {
                name:string,
                ctor:new (...args:any)=>any
            }[],
        }
} = {};

export function property( ctor : new (...args:any)=>any = null ) {
    function t(target: any, propName: string) {
        // const _ctorName = createCtorName( propName );
        const name = target.constructor;
        propertyList[name] = propertyList[name] || {
            parents: [],
            props: [],
        };
        propertyList[name].props.push({
            name: propName,
            ctor,
        });


        // target.constructor[ _ctorName ] = ctor;
        // target.constructor[ propertiesName ] = target.constructor[ propertiesName ] || [];
        // target.constructor[ propertiesName ].push( propName );
    }
    return t;
}

export function propertyUpgrade(ctor:any, target: any, propName: string) {
    let instance = new ctor();
    if(instance instanceof GameObject ||instance instanceof ReferenceObject) {
        instance = null;

        const _name = `_${propName}`;

        //@ts-ignore
        function onDestroy() {
            //@ts-ignore
            this[propName] = null;
        }

        Object.defineProperty(target, propName, {
            set: function(this, newVal) {
                if( newVal && newVal.constructor === ctor ) {
                    if(this[_name]) {
                        this[_name].off('destroy', onDestroy, this);
                    }
                    this[_name] = newVal;
                    this[_name].on('destroy', onDestroy, this);

                } else {
                    if(this[_name]) {
                        this[_name].off('destroy', onDestroy, this);
                    }
                    this[_name] = null;
                }
            },
            get: function() {
                return this[_name];
            },
        });
    }
}

//@ts-ignore
export function createIsCheck( instance ) {
    return function ( v: new ()=>any, propName : string) {
        const _ctor = createCtorName( propName );
        return v.constructor === instance[ _ctor ];
    }
}

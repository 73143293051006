import {Col, Input, Row, Select, Space, Typography} from "antd";
import {ChangeEventHandler, CSSProperties, ReactElement, useEffect, useState} from "react";
import { PhotoshopPicker, SketchPicker, ChromePicker   } from 'react-color'
import ViewTooltip from "../viewTooltip";

const { Text } = Typography;

export default function ColorView({name, value, onChange, tooltip, onChangeComplete}:
{name:string, value:string, onChange:(color:String)=>void, tooltip?:string, onChangeComplete?: (color:string)=>void}) {

    const [color, setColor] = useState(value);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [isChange, setIsChange] = useState(false);

    const handleClick = () => {
        setDisplayColorPicker( !displayColorPicker );
    };

    const handleClose = () => {
        if(isChange) {
            onChangeComplete && onChangeComplete(color);
        }
        setDisplayColorPicker(false);
    };

    const popover : CSSProperties = {
        position: 'fixed',
        zIndex: '10000',
        color: "black"
    }
    const cover: CSSProperties = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    return <Row className={"valueContainer"} wrap={false}>
        <Col className={'valueLabel'}>
            {
                tooltip? <ViewTooltip title={tooltip}>
                    <Text style={{marginRight: '5px'}}>{name}</Text>
                </ViewTooltip> :  <Text style={{marginRight: '5px'}}>{name}</Text>
            }
        </Col>
        <Col flex="auto">
            <Input readOnly onClick={handleClick} style={{padding: '2px', background: color}} value={color}></Input>
            { displayColorPicker ?
                <div style={ popover }>
                    <div style={ cover } onClick={ handleClose }/>
                    <SketchPicker color={color} onChange={function (color){
                        setColor(color.hex);
                        onChange && onChange(color.hex);
                    }} onChangeComplete={function (color){
                        setColor(color.hex);
                        setIsChange(true);
                        //
                    }} />
                </div> : null }
        </Col>
    </Row>
}
import {useAppSelector} from "../../../app/hooks";
import {selectOpenImageList} from "../../../store/modalSlice";
import {useEffect, useState} from "react";

export default function ImageSelect(allSelectCallback: Function) {

    let [ctrl, setCtrl] = useState(false);
    let [shift, setShift] = useState(false);
    let [lastData, setLastData] = useState(null);

    const _keydown = (e: KeyboardEvent) => {
        switch (e.key) {
            case 'Control':
                setCtrl(true);
                break;
            case 'Shift':
                setShift(true);
                break;
            case 'a':
            case 'A':
                if(ctrl) {
                    allSelectCallback();
                }
                break;
        }
    }

    const _keyup = (e: KeyboardEvent) => {
        switch (e.key) {
            case 'Control':
                setCtrl(false);
                break;
            case 'Shift':
                setShift(false);
                break;
        }
    }

    useEffect(function (){
        window.addEventListener('keydown', _keydown);
        window.addEventListener('keyup', _keyup);
        return function (){
            window.removeEventListener('keydown', _keydown);
            window.removeEventListener('keyup', _keyup);
        }
    }, [_keydown, _keyup])


    return {
        isDownCtrl: function () {
            return ctrl;
        },
        isDownShift: function () {
            return shift;
        },
        setLastData: function (data: any) {
            setLastData(data);
        },
        getLastData: function (): any {
            return lastData;
        },

    }
}
import ReferenceObject from "../core/ReferenceObject";
import GameObject from "../gameObject/gameObject";
import LogManager from "./logManager";
import * as PIXI from 'pixi.js';
import Component from "../core/component";
import SpriteFunction from "./component/spriteFunction";
import * as uuid from 'uuid';
import Script from "../component/script";
import TextFunction from "./component/textFunction";
import InputFunction from "./function/inputFunction";
import TransformFunction from "./function/transformFunction";
import SystemFunction from "./function/systemFunction";
import EventFunction from "./function/eventFunction";
import SceneFunction from "./function/sceneFunction";
import PrefabFunction from "./function/prefabFunction";
import AssetFunction from "./function/assetFunction";
import ShapeFunction from "./component/shapeFunction";
import NineSliceFunction from "./component/nineSliceFunction";
import AnimatorFunction from "./component/animatorFunction";
import TextAnimationFunction from "./component/textAnimationFunction";
import BodyFunction from "./component/bodyFunction";
import {scriptTableData} from "./scriptTable";
import ActionFunction from "./function/actionFunction";
import SoundFunction from "./function/soundFunction";
import ObjectFunction from "./function/objectFunction";
import ScriptError from "./scriptError";
import {EPostUpdateProperty} from "../core/postUpdate";
import TilingSprite from "../component/tilingSprite";
import TilingSpriteFunction from "./component/tilingSpriteFunction";
import RandomFunction from "./function/randomFunction";
import UtilFunction from "./function/utilFunction";

export const FunctionCategoryList : string[] = [
    'PIXI',
    'name', 'kill',
    'input', 'global', 'console', 'random', 'util',
    'instance', 'object', 'transform', 'system', 'event', 'scene', 'prefab', 'asset', 'sound', 'action',
    'sprite', 'tilingSprite', 'nineSlice', 'shape', 'text',  'animator', 'textAnimation', 'body',
    // 'button', 'camera', 'script',
];

export const ConsoleFunctionTable: scriptTableData[] = [
    {
        value: `console.log('hello');`,
        caption: `console.log(text: string)`,
        score: 10,
        meta: 'function',
        name: 'console.log',
        description: {
            ko: '콘솔창에 로그 메세지를 출력합니다.',
            en: '',
        },
    },
    {
        value: `console.error('error');`,
        caption: `console.error(text: string)`,
        score: 10,
        meta: 'function',
        name: 'console.error',
        description: {
            ko: '콘솔창에 에러 메세지를 출력합니다.',
            en: '',
        },
    },
]

export const GlobalFunctionTable: scriptTableData[] = [
    {
        value: `global.`,
        caption: `global.`,
        score: 10,
        meta: 'variable',
        name: 'global',
        description: {
            ko: '글로벌 변수를 지정하거나 가져옵니다.',
            en: '',
        },
    },
]

export const InstanceFunctionTable: scriptTableData[] = [
    // {
    //     value: `name`,
    //     caption: `name`,
    //     score: 10,
    //     meta: 'getter,setter',
    //     name: 'name',
    //     description: {
    //         ko: '인스턴스의 이름을 가져오거나 설정합니다.',
    //         en: '',
    //     },
    // },
    // {
    //     value: `kill();`,
    //     caption: `kill()`,
    //     score: 10,
    //     meta: 'function',
    //     name: 'kill',
    //     description: {
    //         ko: '인스턴스의 파괴시킵니다.',
    //         en: '',
    //     },
    // },
];

export default class FunctionManager extends ReferenceObject {

    private _id: string = uuid.v4();
    public get id(): string {
        return this._id;
    }
    private _owner: GameObject | Component = null;
    private _log : LogManager = null;

    private _isKill: boolean = false;

    public get gameObject() : GameObject {
        if(this._owner instanceof  GameObject) {
            return this._owner;
        }
        return this._owner.gameObject;
    }

    public get name(): string {
        return this.gameObject.name;
    }

    public set name(_name: string) {
        this.gameObject.name = _name;
    }

    public get enable(): boolean {
        return this.gameObject.active && this._owner.active;
    }

    public kill() {

        if(this._isKill) {
            console.log('kill2');
            return;
        }

        this._isKill = true;
        this.gameObject.active = false;
        this.gameObject.game.postUpdate.addPostUpdate(()=>{
            try {
                this.gameObject.destroy();
            }
            catch (e : any) {
                this._console._catch(e);
            }

        }, EPostUpdateProperty.destroyObject);
    }

    public get global(): any {
        return this.gameObject.game.globalVariables;
    }

    private _input: InputFunction;
    public get input(): InputFunction {
        return this._input.instance;
    }


    private _random: RandomFunction;
    public get random(): RandomFunction {
        return this._random.instance;
    }

    private _util: UtilFunction;
    public get util(): UtilFunction {
        return this._util.instance;
    }

    private _console: {
        log: (text:string)=>void,
        error: (text:string)=>void,
        _catch: (e: Error | ScriptError)=>void,
    } = {
        log: (text : string)=>{
            this._log.log(text);
        },
        error: (text: string)=>{
            this._log.error(text);
        },
        _catch: (e: Error | ScriptError)=>{
            const name = e.name;
            const message = e.message;
            const stack = e.stack;
            if(stack) {
                const reg = /<anonymous>:[\d]+:[\d]+/;
                const arr: string[] = reg.exec(stack) || [];
                const item = arr[0];
                let line : number = undefined;
                let col: number = undefined;
                if(item) {
                    const spits = item.replace('<anonymous>:','').split(':');
                    line = Number(spits[0]) - 5;
                    col = Number(spits[1]);
                }
                this._log.error(`${name}: ${message}`, (e as ScriptError)?.func, line, col);
            }

            //<anonymous>:29:1   -5
        }
    };
    public get console(): any {
        return this._console;
    }

    private _object: ObjectFunction;
    public get instance(): ObjectFunction {
        return this._object.instance;
    }
    public get object(): ObjectFunction {
        return this._object.instance;
    }

    private _transform: TransformFunction;
    public get transform(): TransformFunction {
        return this._transform.instance;
    }

    private _system: SystemFunction;
    public get system(): SystemFunction {
        return this._system.instance;
    }

    private _event: EventFunction;
    public get event(): EventFunction {
        return this._event.instance;
    }

    private _scene: SceneFunction;
    public get scene(): SceneFunction {
        return this._scene.instance;
    }

    private _prefab: PrefabFunction;
    public get prefab(): PrefabFunction {
        return this._prefab.instance;
    }

    private _asset: AssetFunction;
    public get asset(): AssetFunction {
        return this._asset.instance;
    }

    private _sound: SoundFunction;
    public get sound(): SoundFunction {
        return this._sound;
    }

    private _action: ActionFunction;
    public get action(): ActionFunction {
        return this._action.instance;
    }

    private _sprite: SpriteFunction;
    public get sprite(): SpriteFunction {
        return this._sprite.instance;
    }

    private _tilingSprite: TilingSpriteFunction;
    public get tilingSprite(): TilingSpriteFunction {
        return this._tilingSprite.instance;
    }

    private _text: TextFunction;
    public get text(): TextFunction {
        return this._text.instance;
    }

    private _shape: ShapeFunction;
    public get shape(): ShapeFunction {
        return this._shape.instance;
    }

    private _nineSlice: NineSliceFunction;
    public get nineSlice(): NineSliceFunction {
        return this._nineSlice.instance;
    }

    private _animator: AnimatorFunction;
    public get animator(): AnimatorFunction {
        return this._animator.instance;
    }

    private _textAnimation: TextAnimationFunction;
    public get textAnimation(): TextAnimationFunction {
        return this._textAnimation.instance;
    }

    private _body: BodyFunction;
    public get body(): BodyFunction {
        return this._body.instance;
    }

    constructor(owner: GameObject | Script) {
        super();
        this._owner = owner;
        const script = owner instanceof Script ? owner : null;
        this._log = new LogManager( this.gameObject, script as Script);

        this._input = new InputFunction(this._log, this.gameObject, this);
        this._random = new RandomFunction(this._log, this.gameObject, this);
        this._util = new UtilFunction(this._log, this.gameObject, this);

        this._object = new ObjectFunction( this._log, this.gameObject, this );
        this._transform = new TransformFunction( this._log, this.gameObject, this );
        this._system = new SystemFunction( this._log, this.gameObject, this );
        this._event = new EventFunction( this._log, this.gameObject, this );
        this._scene = new SceneFunction( this._log, this.gameObject, this );
        this._prefab = new PrefabFunction( this._log, this.gameObject, this );
        this._asset = new AssetFunction( this._log, this.gameObject, this );
        this._action = new ActionFunction( this._log, this.gameObject, this );
        this._sound = new SoundFunction(this._log, this.gameObject, this);

        this._sprite = new SpriteFunction( this._log, this.gameObject, this );
        this._tilingSprite = new TilingSpriteFunction(this._log, this.gameObject, this);
        this._text = new TextFunction( this._log, this.gameObject, this );
        this._shape = new ShapeFunction( this._log, this.gameObject, this );
        this._nineSlice = new NineSliceFunction( this._log, this.gameObject, this );
        this._animator = new AnimatorFunction( this._log, this.gameObject, this );
        this._textAnimation = new TextAnimationFunction( this._log, this.gameObject, this );
        this._body = new BodyFunction( this._log, this.gameObject, this );
    }

    //스크립트에서 호출하기위해 사용
    public get functions() {
        return {
            PIXI: PIXI,
            name: this.name,
            kill: this.kill.bind(this),

            global: this.global,
            input: this.input,
            console: this.console,
            random: this.random,
            util: this.util,

            instance: this.instance,
            object: this.instance,
            transform: this.transform,
            system: this.system,
            event: this.event,
            scene: this.scene,
            prefab: this.prefab,
            asset: this.asset,
            action: this.action,
            sound: this.sound,

            sprite: this.sprite,
            tilingSprite: this.tilingSprite,
            text: this.text,
            shape: this.shape,
            nineSlice: this.nineSlice,
            animator: this.animator,
            textAnimation: this.textAnimation,
            body: this.body,
            // ...this,
        }
    }

    destroy() {
        (this as PIXI.utils.EventEmitter).emit('destroy');

        this._input.destroy();
        this._input = null;
        this._random.destroy();
        this._random = null;
        this._util.destroy();
        this._util = null;

        this._object.destroy();
        this._object = null;
        this._transform.destroy();
        this._transform = null;
        this._system.destroy();
        this._system = null;
        this._event.destroy();
        this._event = null;
        this._scene.destroy();
        this._scene = null;
        this._prefab.destroy();
        this._prefab = null;
        this._asset.destroy();
        this._asset = null;
        this._sound.destroy();
        this._sound = null;
        this._action.destroy();
        this._action = null;

        this._sprite.destroy();
        this._sprite = null;
        this._tilingSprite.destroy();
        this._tilingSprite = null;
        this._text.destroy();
        this._text = null;
        this._shape.destroy();
        this._shape = null;
        this._nineSlice.destroy();
        this._nineSlice = null;
        this._animator.destroy();
        this._animator = null;
        this._textAnimation.destroy();
        this._textAnimation = null;
        this._body.destroy();
        this._body = null;

        this._owner = null;
        this._log.destroy();
        this._log = null;
    }
}

/*

  log, error 에서
  [ 게임오브젝트, 호출한 스크립트 이름, 호출한 함수이름(error 발생시) ]
  을 알아내는 방법



1. 다른 스크립트에서 호출되는 경우
 - 호출한 스크립트 이름 없을수 있음.


2. 자기 스크립트에서 호출되는 경우
 - 호출한 스크립트 이름 표시

 */
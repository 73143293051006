import {useEffect, useRef, useState} from "react";
import {scriptTable, scriptTable_v2, scriptTableData} from "../../../game/scripts/function/scriptTable";

export interface scriptTooltipInfo {
    x: number,
    y: number,
    name: string,
}
export type scriptTooltipChange = (info?:scriptTooltipInfo)=>void;

export const scriptTooltip : {
    callback: scriptTooltipChange,
} = {
    callback: null,
}

export default function ScriptTooltip () {
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [title, setTitle] = useState('');
    const [des, setDes] = useState('');
    const [display, setDisplay] = useState('none');

    const ref = useRef();

    useEffect(function (){
        scriptTooltip.callback = function (info?: scriptTooltipInfo){
            const div = ref.current as HTMLDivElement;
            if(div) {
                if(info) {
                    const parent = div.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
                    const bounds = parent.getBoundingClientRect();
                    const table: scriptTableData = scriptTable_v2.find( (item:scriptTableData)=>info.name.includes(item.name));
                    if(table) {
                        setX(info.x - bounds.x + 20);
                        setY(info.y - bounds.y + 20);
                        setDisplay('');
                        setTitle(table.tooltip || table.caption);
                        setDes(table.description.ko);
                        return;
                    }
                }
            }
            setDisplay('none');
        }
        return function (){
            scriptTooltip.callback = null;
        }
    },[])


    return <div ref={ref} style={{
        display: `${display}`,
        position:'absolute',
        backgroundColor: 'rgb(60,63,65)',
        top: `${y}px`,
        left: `${x}px`,
        zIndex: 1000,
        padding: '20px',
    }}>
        <div style={{
            fontSize: '18px',
            color: '#bababa',
            marginBottom: '10px',
        }}><pre>{title}</pre></div>
        <div>{des}</div>
    </div>
}
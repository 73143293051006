import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {RootState} from "../app/store";

// export type OnClickImgType = (imgKey: string) => void
// let _onClickImg : OnClickImgType = null;
// export function changeOnClickImg(callback : OnClickImgType) {
//     _onClickImg = callback;
// }
//
// export type OnClickSoundType = (soundKey: string) => void
// let _onClickSound : OnClickSoundType = null;
// export function changeOnClickSound(callback : OnClickSoundType) {
//     _onClickSound = callback;
// }

export type OnClickAssetType = (uuid: string) => void
let _onClickAsset : OnClickAssetType = null;
export function changeOnClickAsset(callback : OnClickAssetType) {
    _onClickAsset = callback;
}

export type OnSelectAssetType = (ids: string[]) => void
let _onSelectAsset : OnSelectAssetType = null;
let _onSelectOk: boolean = false;
export function changeOnSelectAsset(callback : OnSelectAssetType, selectOk: boolean = true) {
    _onSelectAsset = callback;
    _onSelectOk = selectOk;
}

export interface ModalState {
    openImageList: boolean,
    openSoundList: boolean,
    openSceneList: boolean,
    openPrefabList: boolean,
    openFontList: boolean,
    openScriptList: boolean,
    openProjectSetting: boolean,
    openEditorSetting: boolean,
    openChatBot: boolean,
    selectScriptId: string,
    selectAnimatorIds: string[],
    selectPreviewAnimationInfo: {
        animatorId: string,
        animationIdx: number
    },
    openAnimatorList: boolean,
    selectAssetId: string,
}


const initialState: ModalState = {
    openImageList: false,
    openSoundList: false,
    openSceneList: false,
    openPrefabList: false,
    openFontList: false,
    openScriptList: false,
    openProjectSetting: false,
    openEditorSetting: false,
    openChatBot: false,
    selectScriptId: '',
    selectAnimatorIds: [],
    selectPreviewAnimationInfo: null,
    openAnimatorList: false,
    selectAssetId: '',
};

export const selectOpenImageList = (state: RootState) => state.modal.openImageList;
// export const selectOnClickImg = ():OnClickImgType=>_onClickImg;
//
export const selectOpenSoundList = (state: RootState) => state.modal.openSoundList;
// export const selectOnClickSound = ():OnClickImgType=>_onClickSound;

export const selectOpenSceneList = (state: RootState) => state.modal.openSceneList;
export const selectOpenPrefabList = (state: RootState) => state.modal.openPrefabList;
export const selectOpenFontList = (state: RootState) => state.modal.openFontList;
export const selectOpenScriptList = (state: RootState) => state.modal.openScriptList;

export const selectSelectScriptId = (state: RootState) => state.modal.selectScriptId;

export const selectSelectAnimatorIds = (state: RootState) => state.modal.selectAnimatorIds;
export const selectOpenAnimatorList = (state: RootState) => state.modal.openAnimatorList;
export const selectSelectPreviewAnimationInfo = (state: RootState) => state.modal.selectPreviewAnimationInfo;

export const selectOpenProjectSetting = (state: RootState) => state.modal.openProjectSetting;
export const selectOpenEditorSetting = (state: RootState) => state.modal.openEditorSetting;

export const selectOpenChatBot = (state: RootState) => state.modal.openChatBot;


export const selectSelectAssetId = (state: RootState) => state.modal.selectAssetId;

export const selectOnClickAsset = ():OnClickAssetType=>_onClickAsset;
export const selectOnSelectAsset = ():OnSelectAssetType=>_onSelectAsset;
export const selectOnSelectOk = ():boolean=>_onSelectOk;

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        changeOpenImageList: (state, action: PayloadAction<boolean>) => {
            state.openImageList = action.payload;
        },
        changeOpenSoundList: (state, action: PayloadAction<boolean>) => {
            state.openSoundList = action.payload;
        },
        changeOpenSceneList: (state, action: PayloadAction<boolean>) => {
            state.openSceneList = action.payload;
        },
        changeOpenPrefabList: (state, action: PayloadAction<boolean>) => {
            state.openPrefabList = action.payload;
        },
        changeOpenFontList: (state, action: PayloadAction<boolean>) => {
            state.openFontList = action.payload;
        },
        changeOpenScriptList: (state, action: PayloadAction<boolean>) => {
            state.openScriptList = action.payload;
        },
        changeSelectScriptId: (state, action: PayloadAction<string>) => {
            state.selectScriptId = action.payload;
        },
        changeSelectAnimatorIds: (state, action: PayloadAction<string[]>) => {
            state.selectAnimatorIds = action.payload;
        },
        changeOpenAnimatorList: (state, action: PayloadAction<boolean>) => {
            state.openAnimatorList = action.payload;
        },
        changeOpenChatBot: (state, action: PayloadAction<boolean>) => {
            state.openChatBot = action.payload;
        },
        changeSelectPreviewAnimationInfo: (state, action: PayloadAction<{ animatorId:string, animationIdx:number }>) => {
            state.selectPreviewAnimationInfo = action.payload;
        },
        changeOpenProjectSetting: (state, action: PayloadAction<boolean>) => {
            state.openProjectSetting = action.payload;
        },
        changeOpenEditorSetting: (state, action: PayloadAction<boolean>) => {
            state.openEditorSetting = action.payload;
        },
        changeSelectAssetId: (state, action: PayloadAction<string>) => {
            state.selectAssetId = action.payload;
        },
    },
});

export const {
    changeOpenImageList,
    changeOpenSoundList,
    changeOpenSceneList,
    changeOpenPrefabList,
    changeOpenFontList,
    changeOpenScriptList,
    changeSelectScriptId,
    changeSelectAnimatorIds,
    changeOpenAnimatorList,
    changeOpenChatBot,
    changeSelectPreviewAnimationInfo,
    changeOpenProjectSetting,
    changeOpenEditorSetting,
    changeSelectAssetId,
} = modalSlice.actions;

export default modalSlice.reducer;
import {Checkbox, Typography, Row, Col, Select, Space} from "antd";
import EditorManager from "../../../../services/editorManager";
import {ReactElement} from "react";
import ViewTooltip from "../viewTooltip";

const {Text} = Typography;

export default function SelectView<T>({ label, value, onChange, disabled, children, tooltip }:
    {label:string, value:T, onChange:(checked:any)=>void, disabled?:boolean, children: any, tooltip?:string,}) {

    return <Row className={"valueContainer"} wrap={false}>
        <Col className={'valueLabel'}>
            {
                tooltip? <ViewTooltip title={tooltip}>
                    <Text style={{marginRight: '5px'}}>{label}</Text>
                </ViewTooltip> :  <Text style={{marginRight: '5px'}}>{label}</Text>
            }
        </Col>
        <Col flex="auto">
            <Select style={{width: '100%'}} value={value} onChange={onChange}>
                {children}
            </Select>
        </Col>
    </Row>
}
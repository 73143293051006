import {Button, Checkbox, Input, Modal, Space, Image, InputNumber, Divider, Popconfirm} from "antd";
import {useEffect, useRef, useState} from "react";
import {
    DeleteFilled,
    DeleteOutlined,
    FileOutlined,
    CaretRightOutlined,
} from '@ant-design/icons';
import {useAppSelector} from "../../app/hooks";
import {
    changeOpenAnimatorList,
    changeSelectAnimatorIds,
    changeSelectPreviewAnimationInfo,
    selectSelectAnimatorIds
} from "../../store/modalSlice";
import EditorManager from "../../services/editorManager";
import AnimatorAsset, {IAnimationJson} from "../../game/scripts/asset/animatorAsset";
import TextureAsset from "../../game/scripts/asset/textureAsset";
import {useDispatch} from "react-redux";
import AssetItem from "./assetItem";
import LocalizationManager from "../../services/localizationManager";

function AnimationClip({animation, onPreview, changeCallback, deleteCallback} :
                           {
                               animation:IAnimationJson,
                               onPreview:Function,
                               changeCallback:(animation: IAnimationJson)=>void,
                               deleteCallback: Function
                           }) {

    const itemsRef = useRef([]);
    let selectIdx = -1;
    let items : number[] = [];

    const dispatch = useDispatch();

    for(let i = 0; i < animation.frames.length; i++ ){
        items.push(i);
    }

    const list = [];
    for(let i = 0; i < animation.frames.length; i++) {

        const uid = animation.frames[i];
        const textureAsset = EditorManager.Instance.assetManager.getAsset(uid) as TextureAsset;
        const url = textureAsset?.resource.url || '';
        let width = 0;
        let height = 0;
        const texWidth = textureAsset?.resource.texture.width || 0;
        const texHeight = textureAsset?.resource.texture.height || 0;
        if(textureAsset) {
            if( textureAsset.resource.texture.width > textureAsset.resource.texture.height ) {
                width = 100;
                height = 100 * texHeight / texWidth;
            }
            else {
                width = 100 * texWidth / texHeight;
                height = 100;
            }
        }



        list.push(
            <div key={Math.random()} style={{ width:100, height:100, marginLeft:5, display: "inline-block"}}
            >
                <div style={{
                    position:"absolute",
                    width: '100px', height: '100px',
                    // transition: 'left 0.2s',
                    backgroundImage: 'url(/img/bg_pattern.png)',
                    textAlign: 'center',
                    margin: 0,
                    padding: 0,
                    lineHeight: '100px',
                    fontSize: 0,
                }}
                     ref={el => itemsRef.current[i] = el}
                     draggable={true}
                     onDragStart={function (event){
                         selectIdx = i;
                         event.dataTransfer.setData('text', i.toString())
                     }}
                     onDragEnter={function (event){
                         const itemIdx = items.indexOf(selectIdx);
                         const itemIdx2 = items.indexOf(i);


                         const temp = items[itemIdx];
                         items[itemIdx] = items[itemIdx2];
                         items[itemIdx2] = temp;

                         for(let j = 0; j < items.length; j++) {
                             itemsRef.current[items[j]].style.left = `${5 + 105*j}px`;
                         }
                     }}
                     onDragEnd={function (){
                         const frameList = [];
                         for(let j = 0; j < items.length; j++) {
                             frameList.push( animation.frames[items[j]] );
                         }
                         animation.frames = frameList;
                         changeCallback(animation);
                     }}
                ><Image width={width} height={height} preview={false} src={url}/>
                    <div
                        style={{
                            backgroundImage: 'url(/img/bg_pattern.png)',
                            // backgroundColor: 'black',
                        }}
                    >
                        <div style={{
                            fontSize: '14px',
                            position: 'absolute',
                            left: '0',
                            bottom: '0',
                            width: '100%',
                            height: '10%'
                        }}>
                            <div style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                backgroundColor: 'black',
                                opacity: '0.8',
                            }}></div>
                            <div style={{
                                width: '100%',
                                position: 'absolute',
                                lineHeight: 'normal',
                            }}>
                                {textureAsset?.name || ''}
                            </div>
                        </div>
                        <Button shape={'circle'}
                                style={{
                                    color: '#ffffff',
                                    position: 'absolute',
                                    right: 0,
                                    top: 0,
                                    backgroundColor: '#2d2d2d'
                                }}
                                onClick={function (event){

                                    animation.frames.splice( i, 1 );
                                    changeCallback(animation);

                                    event.preventDefault();
                                    event.stopPropagation();
                                }}
                                size="small" ><DeleteFilled></DeleteFilled>
                        </Button>
                    </div>
                </div>
            </div>)
    }

    return <div style={{marginBottom: '20px'}}>
        <div style={{display:'flex', marginBottom: '5px'}}>
            <Space style={{marginBottom: '5px'}}>
                <span>{LocalizationManager.Instance.getText('AnimationEditor.animationName')}</span>
                <Input value={animation.name} onChange={function (e){
                    animation.name = e.target.value;
                    changeCallback(animation);
                }}
            ></Input>
            </Space>
            <div style={{flexGrow:1}}>
            </div>
            <Space>
                <Button onClick={function (){
                    onPreview();
                }}><CaretRightOutlined></CaretRightOutlined>{LocalizationManager.Instance.getText('AnimationEditor.preview')}</Button>
            </Space>
            <Space style={{marginLeft: '20px'}}>
                <span>{LocalizationManager.Instance.getText('AnimationEditor.interval')}</span>
                <InputNumber onChange={function (num){
                    animation.interval = num;
                    changeCallback(animation);
                }} value={animation.interval} style={{width:80}} min={0.01} step={0.01}></InputNumber>
            </Space>
            <Space style={{marginLeft: '20px'}}>
                <Checkbox checked={animation.loop} onChange={function (e){
                    animation.loop = e.target.checked;
                    changeCallback(animation);
                }}>{LocalizationManager.Instance.getText('AnimationEditor.loop')}</Checkbox>
            </Space>
            <Space style={{marginLeft: '5px'}}>
                <Button shape="circle" onClick={()=>deleteCallback()}>
                    <DeleteOutlined />
                </Button>
            </Space>
        </div>
        <div style={{position:"relative", whiteSpace: 'nowrap', overflowY: 'hidden', width:'100%'}}>
            <div style={{height: '100px', marginBottom: '10px'}}>
                {list}
                <div style={{ marginLeft: '5px', position:"absolute", width:100, height: 100,
                    boxSizing: "border-box", border: '2px solid #252525',
                    display: "inline-block", textAlign: 'center', lineHeight: '100px'}}
                >
                    <Button onClick={async function (){
                        const ids = await EditorManager.Instance.selectImages();
                        if(ids !== null) {
                            for(let i = 0; i < ids.length; i++) {
                                animation.frames.push(ids[i]);
                            }
                            changeCallback(animation);
                        }

                    }}>{LocalizationManager.Instance.getText('AnimationEditor.addFrame')}</Button>
                </div>
            </div>
        </div>
        <Divider />
    </div>
}

export default function AnimationEditor() {

    const selectIds = useAppSelector(selectSelectAnimatorIds);
    const isOpen = !!selectIds.length;
    const asset = EditorManager.Instance.assetManager.getAsset(selectIds[0]) as AnimatorAsset;
    const json = asset?.json;
    // const [animations, setAnimations] = useState(json.animations);
    const animations = json?.animations;
    const dispatch = useDispatch();

    const animationList = [];
    for(let i = 0; i < animations?.length; i++) {
        const ani = animations[i];
        animationList.push( <AnimationClip
            key={i}
            animation={ani}
            deleteCallback={function (){
                animations.splice(i, 1);
                asset.json = json;
                dispatch(changeSelectAnimatorIds([...selectIds]));
            }}
            changeCallback={function (animation: IAnimationJson){
                animations[i] = animation;
                asset.json = json;
                dispatch(changeSelectAnimatorIds([...selectIds]));
            }}
            onPreview={function (){
                dispatch(changeSelectPreviewAnimationInfo({
                    animatorId: selectIds[0],
                    animationIdx: i,
                }));
            }}
        ></AnimationClip> );
    }

    const handleOk = () => {
        dispatch(changeSelectAnimatorIds([]));
        EditorManager.Instance.refreshProperty();
    };

    const handleCancel = () => {
        dispatch(changeSelectAnimatorIds([]));
        EditorManager.Instance.refreshProperty();
    };

    return(<Modal
        title={LocalizationManager.Instance.getText('AnimationEditor.title')}
        visible={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{
            maxWidth: '800px',
        }}
        width={'80%'}
        zIndex={1001}
    >
        <Space>
            <span>{LocalizationManager.Instance.getText('AnimationEditor.animatorName')}</span>
            <Input value={asset?.name} onChange={function (e){
                asset.rename(EditorManager.Instance.assetManager.getVerifiedName(e.target.value, asset.type));
                dispatch(changeSelectAnimatorIds([...selectIds]));
            }}></Input>
        </Space>
        <Divider />
        {animationList}
        <div>
            <Button onClick={function (){
                animations.push({
                    name: 'animation' + animations.length,
                    loop: false,
                    interval: 0.1,
                    frames: [],
                })
                asset.json = json;
                dispatch(changeSelectAnimatorIds([...selectIds]));
            }}>{LocalizationManager.Instance.getText('AnimationEditor.addAnimation')}</Button>
        </div>
    </Modal>);
}
import {Button, Modal, Image, Space, Row, Col, Menu, Popconfirm} from 'antd';
import {useState} from "react";
import {useAppSelector} from "../../app/hooks";
import {
    changeOnClickAsset,
    changeOpenSoundList, changeSelectAssetId,
    selectOnClickAsset,
    selectOpenSoundList, selectSelectAssetId
} from "../../store/modalSlice";
import {useDispatch} from "react-redux";
import EditorManager from "../../services/editorManager";
import {
    DeleteFilled,
    DeleteOutlined, EditFilled, FileOutlined,
    SoundOutlined
} from '@ant-design/icons';

import {list} from "postcss";
import AssetItem from "./assetItem";
import * as React from "react";
import {BYTE_TO_MEGABYTE, UPLOAD_IMAGE_MAX_SIZE, UPLOAD_SOUND_MAX_SIZE} from "../../services/constSetting";
import LocalizationManager from "../../services/localizationManager";

export default function SoundList() {

    const isOpen = useAppSelector(selectOpenSoundList);
    const selectId = useAppSelector(selectSelectAssetId);
    const onClickFunc = selectOnClickAsset();

    const dispatch = useDispatch();

    const handleOk = () => {
        dispatch(changeOpenSoundList(false));
    };

    const handleCancel = () => {
        dispatch(changeOpenSoundList(false));
    };

    const afterClose = () => {
        const onClickFunc = selectOnClickAsset()
        onClickFunc && onClickFunc(null);
        changeOnClickAsset(null);

        const list = EditorManager.Instance.assetManager.getSoundAssetList();
        list.forEach( sound => sound.sound?.stop() );

        dispatch(changeSelectAssetId(''));
    }

    const soundList = EditorManager.Instance.assetManager.getSoundAssetList();
    const [, setSoundList] = useState(soundList);

    const addSound = () => {
        const input = document.createElement('input') as HTMLInputElement;
        input.type = 'file';
        input.accept = 'audio/mp3,audio/wav,audio/ogg';
        input.multiple = true;
        input.click();
        let overflowSize = false;
        input.onchange = async ()=> {
            const files = input.files
            for(let i = 0; i < files.length; i++) {
                const file = files[i];
                if(file.size > UPLOAD_SOUND_MAX_SIZE) {
                    overflowSize = true;
                    continue;
                }
                if(file) {
                    await new Promise<void>((resolve)=>{
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL( file );
                        fileReader.addEventListener('load', async ()=>{
                            const dataUrl = fileReader.result as string;
                            const name = file.name.split('.')[0];
                            const resource = await EditorManager.Instance.addSound(dataUrl);
                            if(!resource.resource.sound) {
                                EditorManager.Instance.assetManager.removeAsset(resource);
                                window.confirm('사용할수 없는 사운드파일 입니다.');
                                return;
                            }
                            resource.rename(EditorManager.Instance.assetManager.getVerifiedName(name, resource.type));
                            const sound = resource.sound;
                            const list = EditorManager.Instance.assetManager.getSoundAssetList();
                            setSoundList([...list]);
                            resolve();
                        });
                    });
                }
            }
            if(overflowSize) {
                confirm( LocalizationManager.Instance.getText('Popup.failUploadSound', Math.round(UPLOAD_SOUND_MAX_SIZE/BYTE_TO_MEGABYTE).toString()))
            }
        };
    };

    let listRender = [];
    for(let i = 0; i < soundList.length; i++) {
        const asset = soundList[i];

        listRender.push(<AssetItem key={asset.id}
                                   span={6}
                                   asset={asset}
                                   menu={<Menu items={[
                                       {
                                           key: '1', label: <Popconfirm
                                               title={LocalizationManager.Instance.getText('Popup.confirmRemove', asset.name)}
                                               onConfirm={function (event){
                                                   event.preventDefault();
                                                   event.stopPropagation();
                                                   soundList.splice( soundList.indexOf(asset), 1 );
                                                   EditorManager.Instance.assetManager.removeAsset( asset.id );
                                                   setSoundList([...soundList]);
                                               }}
                                               onCancel={function (event){
                                                   event.preventDefault();
                                                   event.stopPropagation();
                                               }}
                                               okText="제거"
                                               cancelText="취소"
                                           >
                                               <div><DeleteFilled  style={{marginRight:'10px'}}/>
                                                   {LocalizationManager.Instance.getText('ContextMenu.remove') || 'Remove'}
                                               </div>
                                           </Popconfirm>
                                       },
                                       {
                                           key: '2', label: <div onClick={function (){
                                               const name = prompt(LocalizationManager.Instance.getText('Popup.changeName'), asset.name);
                                               if(name === asset.name || !name) {
                                                   return;
                                               }

                                               if( EditorManager.Instance.assetManager.getAsset(name, asset.type) ) {
                                                   window.confirm(LocalizationManager.Instance.getText('Popup.failChangeName'));
                                               }
                                               else {
                                                   asset.rename(name);
                                                   setSoundList([...soundList]);
                                               }
                                           }}><EditFilled style={{marginRight:'10px'}}/>
                                               {LocalizationManager.Instance.getText('ContextMenu.rename') || 'Rename'}
                                           </div>,
                                       },
                                   ]}/>}
                                   select={ selectId === asset.id}
                                   onClick={function (){
            if(onClickFunc) {
                onClickFunc(asset.id);
            }
            else {
                if(asset.sound?.isPlaying) {
                    asset.sound?.stop();
                }
                else {
                    asset.sound?.play();
                }
            }
        }}>
            <div  style={{height:'80px', fontSize: '30px'}}>
                <SoundOutlined></SoundOutlined>
            </div>
        </AssetItem>)

    }

    const blankLength = listRender.length % 4;
    if(blankLength) {
        for(let i = 0; i < 4- blankLength; i++) {
            listRender.push(<Col
                key={(listRender.length + i).toString()}
                className="gutter-row" span={6}/>)
        }
    }


    return(<>
        <Modal title={LocalizationManager.Instance.getText('AssetList.Sound.title') || 'Sound List'}
               visible={isOpen}
               onOk={handleOk}
               onCancel={handleCancel}
               afterClose={afterClose}
               footer={[
                   <Button key="back" onClick={addSound}>
                       {LocalizationManager.Instance.getText('AssetList.Sound.add') || 'Add Sound'}
                   </Button>,
               ]}
        >
            <Row gutter={[12,12]} justify={'center'}>
                {soundList.length ? listRender : <div>{LocalizationManager.Instance.getText('AssetList.Sound.empty') || 'empty'}</div>}
            </Row>
        </Modal>
    </>)
}
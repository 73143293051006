import {IComponentViewProps} from "./componentView";
import {ISpriteJson} from "../../../../game/scripts/component/sprite";
import EditorManager from "../../../../services/editorManager";
import {store} from "../../../../app/store";
import {Button, Input, InputNumber, Space, Typography} from "antd";
import PointView from "../valueView/pointView";
import {IAnimatorJson} from "../../../../game/scripts/component/animator";
import AnimatorAsset from "../../../../game/scripts/asset/animatorAsset";
import {changeOpenAnimatorList, changeSelectAnimatorIds} from "../../../../store/modalSlice";
import {useDispatch} from "react-redux";
import AnimatorAssetView from "../valueView/ref/animatorAssetView";
import {
    DeleteOutlined,
    DeleteTwoTone,
    DeleteFilled,
    SettingFilled
} from '@ant-design/icons';
import InputNumberView from "../valueView/inputNumberView";
import {ITextAnimationJson} from "../../../../game/scripts/component/textAnimation";
import CheckboxView from "../valueView/checkboxView";
import LocalizationManager from "../../../../services/localizationManager";
import TooltipManager from "../../../../services/tooltipManager";

const { Text } = Typography;


export default function TextAnimationView(props: IComponentViewProps) {

    const json = props.json as ITextAnimationJson;
    const dispatch = useDispatch();

    return(
        <>
            <InputNumberView label={LocalizationManager.Instance.getText('TextAnimation.speed') || 'speed'}
                             tooltip={  TooltipManager.Instance.getText('TextAnimation.speed') }
                             value={json.speed} onChange={function (num){
                json.speed = num;
                EditorManager.Instance.modifyComponent(json);
            }}></InputNumberView>
            <CheckboxView label={LocalizationManager.Instance.getText('TextAnimation.showCursor') || 'showCursor'}
                          tooltip={  TooltipManager.Instance.getText('TextAnimation.showCursor') }
                          checked={json.showCursor} onChange={function (checked){
                json.showCursor = checked;
                EditorManager.Instance.modifyComponent(json);
            }}></CheckboxView>
        </>)
}
import * as PIXI from 'pixi.js';
import EditorScene, {ECtrlMode} from "./editorScene";
import Resizer from "./resizer";
import TilingSpriteResizer from "./tilingSpriteResizer";
import NineSliceResizer from "./nineSliceResizer";
import GraphicResizer from "./graphicResizer";
import TextResizer from "./textResizer";
import BodyResizer from "./bodyResizer";
import GameObject, {IGameObjectJson} from "../../gameObject/gameObject";
import Sprite from "../../component/sprite";
import Component, {IComponentJson} from "../../core/component";
import TilingSprite from "../../component/tilingSprite";
import NineSlice from "../../component/nineSlice";
import Shape from "../../component/shape";
import Text from "../../component/text";
import Body from "../../component/body";

export interface IResizerBase extends PIXI.Container {
    target: Component,
    data: {
        capture: IGameObjectJson,
        compCapture: IComponentJson,
        timeoutId: number,
        isChange: boolean,
    }
}

export default class ResizerManager extends PIXI.Container {

    private scene: EditorScene;
    private resizerArr : Resizer[] = [];
    private tilingSpriteResizerArr : TilingSpriteResizer[] = [];
    private nineSlicerResizerArr : NineSliceResizer[] = [];
    private graphicResizerArr: GraphicResizer[] = [];
    private textResizerArr : TextResizer[] = [];
    private bodyResizerArr : BodyResizer[] = [];

    public resizerMoveCallback : (resizer: IResizerBase)=>void = null;
    public resizerMoveStartCallback : (resizer: IResizerBase)=>void = null;
    public resizerMoveEndCallback : (resizer: IResizerBase)=>void = null;

    constructor(scene: EditorScene) {
        super();
        this.scene = scene;
    }

    private _getEnableResizer( arr: IResizerBase[], creator: ()=> IResizerBase) : IResizerBase {
        for(let i = 0; i < arr.length; i++) {
            if(arr[i].target === null) {
                return arr[i];
            }
        }

        const resizer = creator.bind(this)();
        this.addChild(resizer);
        arr.push(resizer);
        return resizer;
    }

    private _createResizer(): Resizer {
        const resizer = new Resizer(this.scene);
        resizer.visible = false;
        resizer.moveCallback = this.resizerMoveCallback;
        resizer.moveStartCallback = this.resizerMoveStartCallback;
        resizer.moveEndCallback = this.resizerMoveEndCallback;
        return resizer;
    }
    private _createTilingSpriteResizer(): TilingSpriteResizer {
        const resizer = new TilingSpriteResizer(this.scene);
        resizer.visible = false;
        resizer.moveCallback = this.resizerMoveCallback;
        resizer.moveStartCallback = this.resizerMoveStartCallback;
        resizer.moveEndCallback = this.resizerMoveEndCallback;
        return resizer;
    }
    private _createNineSliceResizer(): NineSliceResizer {
        const resizer = new NineSliceResizer(this.scene);
        resizer.visible = false;
        resizer.moveCallback = this.resizerMoveCallback;
        resizer.moveStartCallback = this.resizerMoveStartCallback;
        resizer.moveEndCallback = this.resizerMoveEndCallback;
        return resizer;
    }
    private _createGraphicResizer(): GraphicResizer {
        const resizer = new GraphicResizer(this.scene);
        resizer.visible = false;
        resizer.moveCallback = this.resizerMoveCallback;
        resizer.moveStartCallback = this.resizerMoveStartCallback;
        resizer.moveEndCallback = this.resizerMoveEndCallback;
        return resizer;
    }
    private _createTextResizer(): TextResizer {
        const resizer = new TextResizer(this.scene);
        resizer.visible = false;
        resizer.moveCallback = this.resizerMoveCallback;
        resizer.moveStartCallback = this.resizerMoveStartCallback;
        resizer.moveEndCallback = this.resizerMoveEndCallback;
        return resizer;
    }
    private _createBodyResizer(): BodyResizer {
        const resizer = new BodyResizer(this.scene);
        resizer.visible = false;
        resizer.moveCallback = this.resizerMoveCallback;
        resizer.moveStartCallback = this.resizerMoveStartCallback;
        resizer.moveEndCallback = this.resizerMoveEndCallback;
        return resizer;
    }


    clear() {
        for(let i = 0; i < this.resizerArr.length; i++) {
            this.resizerArr[i].target = null;
        }
        for(let i = 0; i < this.tilingSpriteResizerArr.length; i++) {
            this.tilingSpriteResizerArr[i].target = null;
        }
        for(let i = 0; i < this.nineSlicerResizerArr.length; i++) {
            this.nineSlicerResizerArr[i].target = null;
        }
        for(let i = 0; i < this.graphicResizerArr.length; i++) {
            this.graphicResizerArr[i].target = null;
        }
        for(let i = 0; i < this.textResizerArr.length; i++) {
            this.textResizerArr[i].target = null;
        }
        for(let i = 0; i < this.bodyResizerArr.length; i++) {
            this.bodyResizerArr[i].target = null;
        }
    }

    setTarget(gameObjects: GameObject[], editorScene: EditorScene) {
        this.clear();
        if(!gameObjects.length) {
            return;
        }

        for(let i = 0; i < gameObjects.length; i++) {
            const gameObject = gameObjects[i];
            if( gameObject && gameObject.hasComponent(Sprite.Name) ) {
                const component = gameObject.getComponent(Sprite.Name) as Sprite;
                const resizer = this._getEnableResizer(this.resizerArr, this._createResizer);
                resizer.target = component;
            }
            if( gameObject && gameObject.hasComponent(TilingSprite.Name) ) {
                const component = gameObject.getComponent(TilingSprite.Name) as TilingSprite;
                const resizer = this._getEnableResizer(this.tilingSpriteResizerArr, this._createTilingSpriteResizer);
                resizer.target = component;
            }
            if( gameObject && gameObject.hasComponent(NineSlice.Name) ) {
                const component = gameObject.getComponent(NineSlice.Name) as NineSlice;
                const resizer = this._getEnableResizer(this.nineSlicerResizerArr, this._createNineSliceResizer);
                resizer.target = component;
            }
            if( gameObject && gameObject.hasComponent(Shape.Name) ) {
                const component = gameObject.getComponent(Shape.Name) as Shape;
                const resizer = this._getEnableResizer(this.graphicResizerArr, this._createGraphicResizer);
                resizer.target = component;
            }
            if( gameObject && gameObject.hasComponent(Text.Name) ) {
                const component = gameObject.getComponent(Text.Name) as Text;
                const resizer = this._getEnableResizer(this.textResizerArr, this._createTextResizer);
                resizer.target = component;
            }
            if( gameObject && gameObject.hasComponent(Body.Name) ) {
                const component = gameObject.getComponent(Body.Name) as Body;
                const resizer = this._getEnableResizer(this.bodyResizerArr, this._createBodyResizer);
                resizer.target = component;
            }
            else {
                editorScene.ctrlMode = ECtrlMode.resizer;
            }
        }

        this.onChangeCtrlMove(editorScene.ctrlMode)
    }

    addTarget(gameObject: GameObject) {

    }

    removeTarget(gameObject:GameObject) {

    }

    onChangeCtrlMove(ctrlMode: ECtrlMode) {
        switch (ctrlMode) {
            case ECtrlMode.resizer:
                for(let i = 0; i < this.bodyResizerArr.length; i++) {
                    this.bodyResizerArr[i].visible = false;
                }
                break;
            case ECtrlMode.bodyResizer:
                for(let i = 0; i < this.resizerArr.length; i++) {
                    this.resizerArr[i].visible = false;
                }
                for(let i = 0; i < this.tilingSpriteResizerArr.length; i++) {
                    this.tilingSpriteResizerArr[i].visible = false;
                }
                for(let i = 0; i < this.graphicResizerArr.length; i++) {
                    this.graphicResizerArr[i].visible = false;
                }
                for(let i = 0; i < this.textResizerArr.length; i++) {
                    this.textResizerArr[i].visible = false;
                }
                for(let i = 0; i < this.nineSlicerResizerArr.length; i++) {
                    this.nineSlicerResizerArr[i].visible = false;
                }
                break;
        }
    }

    update(ctrlMode: ECtrlMode) {
        switch (ctrlMode) {
            case ECtrlMode.resizer: {
                for(let i = 0; i < this.resizerArr.length; i++) {
                    this.resizerArr[i].update();
                }
                for(let i = 0; i < this.tilingSpriteResizerArr.length; i++) {
                    this.tilingSpriteResizerArr[i].update();
                }
                for(let i = 0; i < this.graphicResizerArr.length; i++) {
                    this.graphicResizerArr[i].update();
                }
                for(let i = 0; i < this.textResizerArr.length; i++) {
                    this.textResizerArr[i].update();
                }
                for(let i = 0; i < this.nineSlicerResizerArr.length; i++) {
                    this.nineSlicerResizerArr[i].update();
                }
                break;
            }

            case ECtrlMode.bodyResizer:
            {
                for(let i = 0; i < this.bodyResizerArr.length; i++) {
                    this.bodyResizerArr[i].update();
                }
                break;
            }
        }
    }

    destroy() {
        super.destroy();

        this.resizerMoveCallback = null;
        this.resizerMoveStartCallback = null;
        this.resizerMoveEndCallback = null;

        this.scene = null;
        this.resizerArr.length = 0;
        this.tilingSpriteResizerArr.length = 0;
        this.nineSlicerResizerArr.length = 0;
        this.graphicResizerArr.length = 0;
        this.textResizerArr.length = 0;
        this.bodyResizerArr.length = 0;
    }
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {RootState} from "../app/store";
import {Key} from "antd/es/table/interface";
import EditorManager from "../services/editorManager";
import EventEmitter from 'events';

export interface HierarchyData {
    id: string,
    isOpen: boolean;
    isLock: boolean;
    name: string;
    prefabId?: string,
    active: boolean,
    children: HierarchyData[];
    editName: boolean;
    editActive: boolean;
}

export interface HierarchyState {
    list: HierarchyData;
    selectIds: string[];
}

const initialState: HierarchyState = {
    list: undefined,
    selectIds: [],
};

export const selectHierarchyList = (state: RootState) => state.hierarchy.list;
export const selectHierarchySelectIds = (state: RootState) => state.hierarchy.selectIds;


export const hierarchySlice = createSlice({
    name: 'hierarchy',
    initialState,
    reducers: {
        changeHierarchyList: (state, action: PayloadAction<HierarchyData>) => {
            state.list = action.payload;
            EditorManager.Instance.hierarchyList = action.payload;
        },
        changeHierarchySelectIds: (state, action: PayloadAction<string[]>) => {
            state.selectIds = action.payload;
        },
    },
});

export const {
    changeHierarchyList,
    changeHierarchySelectIds } = hierarchySlice.actions;

export default hierarchySlice.reducer;
import WizardSteps from "./comp/wizardSteps";
import WizardPage from "./comp/wizardPage";
import {Card, Radio, Select, Space, Switch, Tabs, Tooltip, Typography} from 'antd';
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {GiHealthNormal, GiHamburgerMenu, GiGlassBall} from "react-icons/gi";
import { RiSpeedMiniFill } from "react-icons/ri";
import { MdExposurePlus1 } from "react-icons/md";
import {InfoCircleOutlined} from "@ant-design/icons";
import { BiBook } from "react-icons/bi";
import {FaRegGem} from "react-icons/fa";
import {BiTimer} from "react-icons/bi";
import wizardSaveData from "../../services/wizard/wizardSave";
import SceneAsset from "mogera-game/scripts/asset/sceneAsset";
import GoJsonHelper from "../../services/wizard/goJsonHelper";
import queryString from "query-string";

const { TabPane } = Tabs;
const {Text, Title} = Typography;
const {Option} = Select;

export default function Match3() {

    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();

    let _endless = false;
    const qa = queryString.parse(window.location.search);
    const mode = qa?.mode;
    if(mode === 'clear') {
        _endless = false;
    }
    else if(mode === 'endless') {
        _endless = true;
    }

    const [data, setData] = useState({
        endless: _endless,
        shootCountRole: 'normal',
        startLineCount: 9,
        typeCount: 6,
        isIncreaseGenLine: true,
        guideLine: 'all',
        ballSpeed: 2200,
    });



    return <div className={'wizardRoot'}>
        <WizardSteps currentStep={currentStep} stepDataList={[
            {title:'게임 로직', description: '게임 클리어 조건을 설정합니다.'},
            {title:'발사 횟수 규칙', description: '공 발사 횟수 규칙을 설정합니다.'},
            {title:'게임 난이도', description: '게임 난이도를 설정합니다.'},
            {title:'가이드 라인 설정', description: '가이드 라인의 표시 방법을 설정합니다.'},
            {title:'추가 옵션 설정', description: '기타 옵션을 설정합니다.'},
            {title:'게임 설정 완료', description: '게임 설정 완료'}
        ]} onChange={function (step){
            setCurrentStep(step);
        }}></WizardSteps>
        <Tabs activeKey={currentStep.toString()} >
            <TabPane  key={'0'}>
                <WizardPage title={'게임 로직'} description={'게임 클리어 조건을 설정합니다.'}
                            onPrev={function (){
                                navigate("/wizard" );
                            }}
                            onNext={function (){
                                setCurrentStep(currentStep+1);
                            }}
                            prevText={'장르 다시 선택'}>
                    <Space>
                        <Card
                            hoverable
                            className={data.endless ?  '' : 'select'}
                            cover={<img draggable={false} height={240} src="/img/wizard/match3/match3_clear.gif" />}
                            onClick={function (){
                                data.endless = false;
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                공 모두 없애기
                            </Title>
                            <div style={{height:'88px'}}>
                                모든 공을 제거했을 때 게임이 클리어됩니다.
                            </div>
                        </Card>
                        <Card
                            hoverable
                            className={data.endless ?  'select' : ''}
                            cover={<img draggable={false} height={240} src="/img/wizard/match3/match3_endless.gif" />}
                            onClick={function (){
                                data.endless = true;
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                무한 모드
                            </Title>
                            <div style={{height:'88px'}}>
                                게임이 끝나기 전까지 무한히 공이 생성됩니다.
                            </div>
                        </Card>
                    </Space>
                </WizardPage>
            </TabPane>
            <TabPane  key={'1'}>
                <WizardPage title={'발사 횟수 규칙'} description={'공 발사 횟수 규칙을 설정합니다.'}
                            onNext={function (){
                                setCurrentStep(currentStep+1);
                            }}
                            onPrev={function (){
                                setCurrentStep(currentStep-1);
                            }}>
                    <Space>
                        <Card
                            hoverable
                            className={data.shootCountRole === 'normal' ?  'select' : ''}
                            cover={<img draggable={false} height={240} src="/img/wizard/match3/654321.gif" />}
                            onClick={function (){
                                data.shootCountRole = 'normal';
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                기본 규칙
                            </Title>
                            <div style={{height:'88px'}}>
                                6번 동안 공이 터지지 않으면 공 라인이 생성됩니다. 이후 라인 생성에 필요한 횟수가 점점 줄어듭니다.
                            </div>
                        </Card>
                        <Card
                            className={data.shootCountRole === 'reverse' ?  'select' : ''}
                            hoverable
                            cover={<img draggable={false} height={240} src="/img/wizard/match3/12345.gif" />}
                            onClick={function (){
                                data.shootCountRole = 'reverse';
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                리버스 규칙
                            </Title>
                            <div style={{height:'88px'}}>
                                1번 동안 공이 터지지 않으면 공 라인이 생성됩니다. 이후 라인 생성에 필요한 횟수가 점점 늘어납니다.
                            </div>
                        </Card>
                        <Card
                            hoverable
                            className={data.shootCountRole === 'even' ?  'select' : ''}
                            cover={<img draggable={false} height={240} src="/img/wizard/match3/642.gif" />}
                            onClick={function (){
                                data.shootCountRole = 'even';
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                짝수 규칙
                            </Title>
                            <div style={{height:'88px'}}>
                                짝수로 발사 횟수를 모두 소모할 때마다 공 라인이 생성됩니다.
                            </div>
                        </Card>
                        <Card
                            className={data.shootCountRole === 'fixed' ?  'select' : ''}
                            hoverable
                            cover={<img draggable={false} height={240} src="/img/wizard/match3/3.gif" />}
                            onClick={function (){
                                data.shootCountRole = 'fixed';
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                고정 규칙
                            </Title>
                            <div style={{height:'88px'}}>
                                3번의 발사 횟수를 모두 소모할 때마다 공 라인이 생성됩니다.
                            </div>
                        </Card>
                    </Space>
                </WizardPage>
            </TabPane>
            <TabPane  key={'2'}>
                <WizardPage title={'게임 난이도'} description={'게임 난이도를 설정합니다.'}
                            onNext={function (){
                                setCurrentStep(currentStep+1);
                            }}
                            onPrev={function (){
                                setCurrentStep(currentStep-1);
                            }}>
                    <Space style={{marginTop: '80px'}}>
                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <GiHamburgerMenu style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>시작 공 라인수</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="게임 시작 시 생성된 공 라인 수를 설정합니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Select style={{width: '150px'}} value={data.startLineCount} onChange={function (value, option){
                                data.startLineCount = value;
                                setData({...data});
                            }}>
                                <Option key={0} value={6}>6(쉬움)</Option>
                                <Option key={1} value={9}>9(중간)</Option>
                                <Option key={2} value={12}>12(어려움)</Option>
                            </Select>
                        </div>
                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <GiGlassBall style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>시작 공 색상수</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="게임 시작 시 화면에 보이는 공 색상 수를 설정합니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Select style={{width: '150px'}} value={data.typeCount} onChange={function (value, option){
                                data.typeCount = value;
                                setData({...data});
                            }}>
                                <Option key={0} value={4}>4(쉬움)</Option>
                                <Option key={1} value={5}>5(중간)</Option>
                                <Option key={2} value={6}>6(어려움)</Option>
                            </Select>
                        </div>
                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <MdExposurePlus1 style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>라인 생성 추가 규칙</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="처음 설정보다 공 색상 수가 줄어들수록 생성되는 공 라인 수가 하나씩 증가합니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Select style={{width: '150px'}} value={data.isIncreaseGenLine} onChange={function (value, option){
                                data.isIncreaseGenLine = value;
                                setData({...data});
                            }}>
                                <Option key={0} value={false}>사용안함(쉬움)</Option>
                                <Option key={1} value={true}>사용(어려움)</Option>
                            </Select>
                        </div>
                    </Space>
                    <div className={'bottom'}>
                    </div>
                </WizardPage>

            </TabPane>
            <TabPane key={'3'}>
                <WizardPage title={'가이드 라인 설정'} description={'가이드 라인의 표시 방법을 설정합니다.'}
                            onNext={function (){
                                setCurrentStep(currentStep+1);
                            }}
                            onPrev={function (){
                                setCurrentStep(currentStep-1);
                            }}>
                    <Space>
                        <Card
                            hoverable
                            className={data.guideLine === 'all' ?  'select' : ''}
                            cover={<img draggable={false} height={240} src="/img/wizard/match3/g1.jpg" />}
                            onClick={function (){
                                data.guideLine = 'all';
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                충돌 전까지 보이기
                            </Title>
                            <div>
                                공에 충돌하기 전까지 가이드 라인이 계속 표시됩니다.
                            </div>
                        </Card>
                        <Card
                            className={data.guideLine === 'fade' ?  'select' : ''}
                            hoverable
                            cover={<img draggable={false} height={240} src="/img/wizard/match3/g3.jpg" />}
                            onClick={function (){
                                data.guideLine = 'fade';
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                점점 흐리게 표시
                            </Title>
                            <div>
                                높이가 높아질수록 가이드 라인이 점점 흐리게 표시됩니다.
                            </div>
                        </Card>
                        <Card
                            hoverable
                            className={data.guideLine === 'none' ?  'select' : ''}
                            cover={<img draggable={false} height={240} src="/img/wizard/match3/g2.jpg" />}
                            onClick={function (){
                                data.guideLine = 'none';
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                가이드 라인 표시 안함
                            </Title>
                            <div >
                                가이드 라인을 표시하지 않습니다.
                            </div>
                        </Card>
                    </Space>
                </WizardPage>
            </TabPane>
            <TabPane  key={'4'}>
                <WizardPage title={'추가 옵션 설정'} description={'기타 옵션을 설정합니다.'}
                            onNext={function (){
                                setCurrentStep(currentStep+1);
                            }}
                            onPrev={function (){
                                setCurrentStep(currentStep-1);
                            }}>
                    <Space style={{alignItems: "baseline", marginTop: '80px'}}>
                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <RiSpeedMiniFill style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>공 이동속도</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="발사하는 공의 이동속도를 설정합니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Select style={{width: '150px'}} value={data.ballSpeed} onChange={function (value, option){
                                data.ballSpeed = value;
                                setData({...data});
                            }}>
                                <Option key={0} value={1200}>1200(느림)</Option>
                                <Option key={1} value={1800}>1800(중간)</Option>
                                <Option key={2} value={2200}>2200(빠름)</Option>
                            </Select>
                        </div>
                    </Space>
                    <div className={'bottom'}>
                    </div>
                </WizardPage>
            </TabPane>
            <TabPane  key={'5'}>
                <WizardPage title={'게임 설정 완료'} description={'게임을 만들기 위한 모든 설정이 완료되었습니다.'}
                            nextText={'완료'}
                            onNext={function (){
                                wizardSaveData.isData = true;
                                wizardSaveData.path = '/template/match3.mogera';
                                wizardSaveData.onLoadCallback = function (game) {
                                    const scene = game.assetManager.getAsset('게임', 'Scene') as SceneAsset;
                                    if(scene) {
                                        // endless: false,
                                        //     shootCountRole: 'normal',
                                        //     startLineCount: 9,
                                        //     typeCount: 6,
                                        //     isIncreaseGenLine: true,
                                        //     guideLine: 'all',
                                        //     ballSpeed: 2200,
                                        const ballManager = GoJsonHelper.findGameObjectByName(scene.scene, 'BallManager');
                                        const ballManagerScript = GoJsonHelper.findScript(ballManager, 'BallManager', game);
                                        ballManagerScript.props.endless = data.endless;
                                        ballManagerScript.props.startLineCount = data.startLineCount;
                                        ballManagerScript.props.typeCount = data.typeCount;
                                        ballManagerScript.props.increaseGenLine = data.isIncreaseGenLine;
                                        ballManagerScript.props.ballSpeed = data.ballSpeed;

                                        const gameState = GoJsonHelper.findScript(ballManager, 'GameState', game);
                                        switch (data.shootCountRole) {
                                            case 'normal':
                                                gameState.props.turnArrayStr = '[6,5,4,3,2,1]';
                                                break;
                                            case 'reverse':
                                                gameState.props.turnArrayStr = '[1,2,3,4,5,6]';
                                                break;
                                            case 'even':
                                                gameState.props.turnArrayStr = '[6,4,2]';
                                                break;
                                            case 'fixed':
                                                gameState.props.turnArrayStr = '[3]';
                                                break;
                                        }

                                        const guide = GoJsonHelper.findGameObjectByName(scene.scene, 'Guide');
                                        const guideScript = GoJsonHelper.findScript(guide, 'Guide', game);
                                        switch (data.guideLine) {
                                            case 'all':
                                                guideScript.props.height = -1;
                                                break;
                                            case 'fade':
                                                guideScript.props.height = 450;
                                                break;
                                            case 'none':
                                                guideScript.props.height = 0;
                                                break;
                                        }
                                    }
                                }
                                navigate("/" );
                            }}
                            onPrev={function (){
                                setCurrentStep(currentStep-1);
                            }}>
                    <Space direction={'vertical'} style={{marginTop:'80px'}}>
                        <Text>완료 버튼을 누르면 에디터가 실행됩니다.</Text>
                        <Text>더 자세한 내용은 아래 notion 링크를 통해 확인하세요.</Text>

                        <div style={{marginTop:'40px'}}>
                            <a href="https://abaft-restaurant-3dc.notion.site/f158f58634804499b5df6a3b970b4bbf" target="_blank">
                                <div>
                                    <BiBook style={{fontSize: '120px', lineHeight: '100px'}}/>
                                </div>
                                <div style={{marginTop: '40px'}}>
                                    템플릿 에디터 가이드 보러가기
                                </div>
                            </a>
                        </div>
                    </Space>
                </WizardPage>
            </TabPane>
        </Tabs>
    </div>
}
import {IComponentViewProps} from "./componentView";
import Button, {IButtonJson} from "../../../../game/scripts/component/button";
import SelectView from "../valueView/selectView";
import {Select} from "antd";
import TextureAssetView from "../valueView/ref/textureAssetView";
import EditorManager from "../../../../services/editorManager";
import ColorView from "../valueView/colorView";
import * as PIXI from 'pixi.js';
import InputView from "../valueView/inputView";
import GoRefView from "../valueView/ref/goRefView";
import {useDrop} from "react-dnd";
import {DragItem} from "react-arborist/dist/types";
import {DropResult} from "react-arborist/dist/dnd/drop-hook";
import {useAppSelector} from "../../../../app/hooks";
import {selectSelectScriptId} from "../../../../store/modalSlice";
import LocalizationManager from "../../../../services/localizationManager";
import TooltipManager from "../../../../services/tooltipManager";

const { Option } = Select;

let dropProp: string;

export default function ButtonView(props: IComponentViewProps) {
    const json = props.json as IButtonJson;

    // const [, drop] = useDrop<DragItem, DropResult | null, { isOver: boolean }>(
    //     () => ({
    //         accept: "NODE",
    //         canDrop: (item: DragItem, monitor: any) => {
    //             if (dropProp === 'GameObject') {
    //                 const go = EditorManager.Instance.findGameObject(Number(item.id));
    //                 return editable(go, 'position');
    //             }
    //             return false;
    //         },
    //         hover: (item: DragItem, monitor: any) => {
    //         },
    //         drop: (item: DragItem, monitor: any) => {
    //             if( dropProp === 'GameObject' ){
    //                 const selectGo = EditorManager.Instance.getSelectGameObject();
    //                 const buttonComp = selectGo.getComponent(Button.Name);
    //                 const go = EditorManager.Instance.findGameObject(Number(item.id));
    //                 const newJson = buttonComp.toJson() as IButtonJson;
    //                 EditorManager.Instance.modifyComponent(newJson);
    //             }
    //             return null;
    //         }
    //     })
    // );


    return (<div>
        <InputView label={LocalizationManager.Instance.getText('Button.buttonKey') || 'buttonKey'}
                   tooltip={  TooltipManager.Instance.getText('Button.buttonKey') }
                   value={json.buttonKey} onChange={function (value){
            json.buttonKey = value;
            EditorManager.Instance.modifyComponent(json);
        }}></InputView>
        <SelectView label={LocalizationManager.Instance.getText('Button.transitionType') || 'transitionType'}
                    tooltip={  TooltipManager.Instance.getText('Button.transitionType') }
                    value={json.transitionType} onChange={function (value){
            json.transitionType = value;
            EditorManager.Instance.modifyComponent(json);
        }}>
            <Option key={'colorTint'} value={'colorTint'}>{LocalizationManager.Instance.getText('Button.transitionType.colorTint') || 'colorTint'}</Option>
            <Option key={'textureSwap'} value={'textureSwap'}>{LocalizationManager.Instance.getText('Button.transitionType.textureSwap') || 'textureSwap'}</Option>
            <Option key={'none'} value={'none'}>{LocalizationManager.Instance.getText('Button.transitionType.none') || 'none'}</Option>
        </SelectView>
        {
            json.transitionType === 'colorTint' ? <>
                <ColorView key={json.normalColor + '_normalColor'}
                           name={LocalizationManager.Instance.getText('Button.transitionType.colorTint.normalColor') || 'normalColor'}
                           tooltip={TooltipManager.Instance.getText('Button.transitionType.colorTint.normalColor') }
                           value={PIXI.utils.hex2string(json.normalColor)} onChange={function (color){}} onChangeComplete={function (color){
                    const colorStr = color.replace('#', '');
                    json.normalColor = PIXI.utils.string2hex(colorStr);
                    EditorManager.Instance.modifyComponent(json);
                }}></ColorView>
                <ColorView key={json.highlightedColor+ '_highlightedColor'}
                           name={LocalizationManager.Instance.getText('Button.transitionType.colorTint.highlightedColor') || 'highlightedColor'}
                           tooltip={  TooltipManager.Instance.getText('Button.transitionType.colorTint.highlightedColor') }
                           value={PIXI.utils.hex2string(json.highlightedColor)} onChange={function (color){}} onChangeComplete={function (color){
                    const colorStr = color.replace('#', '');
                    json.highlightedColor = PIXI.utils.string2hex(colorStr);
                    EditorManager.Instance.modifyComponent(json);
                }}></ColorView>
                <ColorView key={json.pressedColor+ '_pressedColor'}
                           name={LocalizationManager.Instance.getText('Button.transitionType.colorTint.pressedColor') || 'pressedColor'}
                           tooltip={  TooltipManager.Instance.getText('Button.transitionType.colorTint.pressedColor') }
                           value={PIXI.utils.hex2string(json.pressedColor)} onChange={function (color){}} onChangeComplete={function (color){
                    const colorStr = color.replace('#', '');
                    json.pressedColor = PIXI.utils.string2hex(colorStr);
                    EditorManager.Instance.modifyComponent(json);
                }}></ColorView>
                <ColorView key={json.disabledColor+ '_disabledColor'}
                           name={LocalizationManager.Instance.getText('Button.transitionType.colorTint.disabledColor') || 'disabledColor'}
                           tooltip={TooltipManager.Instance.getText('Button.transitionType.colorTint.disabledColor') }
                           value={PIXI.utils.hex2string(json.disabledColor)} onChange={function (color){}} onChangeComplete={function (color){
                    const colorStr = color.replace('#', '');
                    json.disabledColor = PIXI.utils.string2hex(colorStr);
                    EditorManager.Instance.modifyComponent(json);
                }}></ColorView>
            </> : null
        }
        {
            json.transitionType === 'textureSwap' ? <>
                <TextureAssetView label={LocalizationManager.Instance.getText('Button.transitionType.textureSwap.highlightedTexture') || 'highlightedTexture'}
                                  tooltip={TooltipManager.Instance.getText('Button.transitionType.textureSwap.highlightedTexture') }
                                  texture={json.highlightedTexture} onClick={async () => {
                    const imgKey = await EditorManager.Instance.selectImage( json.highlightedTexture );
                    if(imgKey !== null) {
                        json.highlightedTexture = imgKey;
                        EditorManager.Instance.modifyComponent(json);
                    }
                }} onRemove={function (){
                    json.highlightedTexture = '';
                    EditorManager.Instance.modifyComponent(json);
                }}></TextureAssetView>
                <TextureAssetView label={LocalizationManager.Instance.getText('Button.transitionType.textureSwap.pressedTexture') || 'pressedTexture'}
                                  tooltip={TooltipManager.Instance.getText('Button.transitionType.textureSwap.pressedTexture') }
                                  texture={json.pressedTexture} onClick={async () => {
                    const imgKey = await EditorManager.Instance.selectImage(json.pressedTexture);
                    if(imgKey !== null) {
                        json.pressedTexture = imgKey;
                        EditorManager.Instance.modifyComponent(json);
                    }
                }} onRemove={function (){
                    json.pressedTexture = '';
                    EditorManager.Instance.modifyComponent(json);
                }}></TextureAssetView>
                <TextureAssetView label={LocalizationManager.Instance.getText('Button.transitionType.textureSwap.disabledTexture') || 'disabledTexture'}
                                  tooltip={TooltipManager.Instance.getText('Button.transitionType.textureSwap.disabledTexture') }
                                  texture={json.disabledTexture} onClick={async () => {
                    const imgKey = await EditorManager.Instance.selectImage(json.disabledTexture);
                    if(imgKey !== null) {
                        json.disabledTexture = imgKey;
                        EditorManager.Instance.modifyComponent(json);
                    }
                }} onRemove={function (){
                    json.disabledTexture = '';
                    EditorManager.Instance.modifyComponent(json);
                }}></TextureAssetView>
            </> : null
        }
    </div>)
}
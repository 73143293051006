import Command from "./command";
import {IGameObjectJson} from "../../game/scripts/gameObject/gameObject";
import EditorManager from "../editorManager";
import Game from "../../game/scripts/core/game";
import {store} from "../../app/store";
import {treeManager} from "../../components/panel/hierarchy/hierarchy";
// import {changeHierarchySelectKeys} from "../../store/hierarchySlice";

export default class ModifyGameObjectCommand extends Command {
    private prevJson : IGameObjectJson;
    private json : IGameObjectJson;


    constructor(prevJson:IGameObjectJson, json: IGameObjectJson) {
        super();
        this.prevJson = prevJson;
        this.json = json;
    }

    execute() {
        const gameObject = EditorManager.Instance.findGameObject(this.json.id);
        gameObject.fromJson(this.json, null, true);
        //treeManager.tree?.selectById(gameObject.id);
    }

    undo() {
        const gameObject = EditorManager.Instance.findGameObject(this.json.id);
        gameObject.fromJson(this.prevJson, null, true);
        //treeManager.tree?.selectById(gameObject.id);
    }
}
import BaseFunction from "../baseFunction";
import {scriptTableData} from "../scriptTable";
import * as PIXI from "pixi.js";
import NineSlice from "../../component/nineSlice";
import ScriptError from "../scriptError";
import {ICollisionInfo} from "../../core/physics";

export const EventFunctionTable: scriptTableData[] = [
    {
        value: `event.onButton('buttonKey', function(){\n\n})`,
        caption: `event.onButton(key: string, callback: Function)`,
        score: 10,
        meta: 'function',
        name: 'event.onButton',
        description: {
            ko: '설정된 키의 버튼이벤트가 발생할 경우 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `event.onUpdate(function(delta){\n\n})`,
        caption: `event.onUpdate(callback: (delta: number)=>void)`,
        score: 10,
        meta: 'function',
        name: 'event.onUpdate',
        description: {
            ko: '매 프레임 호출시킬 이벤트를 등록합니다.\n업데이트 이벤트 발생시 이전 프레임과의 간격(ms)을 인자로 받습니다. ',
            en: '',
        },
    },
    {
        value: `event.onEnable(function(){\n\n})`,
        caption: `event.onEnable(callback: Function)`,
        score: 10,
        meta: 'function',
        name: 'event.onEnable',
        description: {
            ko: '게임오브젝트가 활성화될때 호출할 함수를 등록합니다..',
            en: '',
        },
    },
    {
        value: `event.onDisable(function(){\n\n})`,
        caption: `event.onDisable(callback: Function)`,
        score: 10,
        meta: 'function',
        name: 'event.onDisable',
        description: {
            ko: '게임오브젝트가 비활성화될때 호출할 함수를 등록합니다..',
            en: '',
        },
    },
    {
        value: `event.onDestroy(function(){\n\n})`,
        caption: `event.onDestroy(callback: Function)`,
        score: 10,
        meta: 'function',
        name: 'event.onDestroy',
        description: {
            ko: '게임오브젝트가 제거될때 호출할 함수를 등록합니다..',
            en: '',
        },
    },
    {
        value: `event.onClick(function(){\n\n})`,
        caption: `event.onClick(callback: Function)`,
        score: 10,
        meta: 'function',
        name: 'event.onClick',
        description: {
            ko: '오브젝트가 클릭됬을때 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `event.onClickDown(function(){\n\n})`,
        caption: `event.onClickDown(callback: (localPos: {x:number, y: number)=>void))`,
        score: 10,
        meta: 'function',
        name: 'event.onClickDown',
        description: {
            ko: '오브젝트가 클릭다운 되었을때 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `event.onClickMove(function(){\n\n})`,
        caption: `event.onClickMove(callback: (localPos: {x:number, y: number)=>void))`,
        score: 10,
        meta: 'function',
        name: 'event.onClickMove',
        description: {
            ko: '오브젝트가 클릭된 상태에서 움직였을때 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `event.onClickUp(function(){\n\n})`,
        caption: `event.onClickUp(callback: (localPos: {x:number, y: number)=>void))`,
        score: 10,
        meta: 'function',
        name: 'event.onClickUp',
        description: {
            ko: '오브젝트가 클릭됬다가 때어졌을때 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `event.onMessage('messageKey', function(){\n\n})`,
        caption: `event.onMessage(key: string, callback: (...args:any[])=>void))`,
        score: 10,
        meta: 'function',
        name: 'event.onMessage',
        description: {
            ko: '설정된 키의 글로벌 메세지가 발생했을때 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `event.sendMessage('messageKey')`,
        caption: `event.sendMessage(key: string, ...args: any[])`,
        score: 10,
        meta: 'function',
        name: 'event.sendMessage',
        description: {
            ko: '설정된 키의 글로벌 메세지를 발생 시킵니다.',
            en: '',
        },
    },
    {
        value: `event.onLocalMessage('messageKey', function(){\n\n})`,
        caption: `event.onLocalMessage(key: string, callback: (...args:any[])=>void))`,
        score: 10,
        meta: 'function',
        name: 'event.onLocalMessage',
        description: {
            ko: '설정된 키의 로컬 메세지가 발생했을때 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `event.sendLocalMessage('messageKey')`,
        caption: `event.sendLocalMessage(key: string, ...args: any[])`,
        score: 10,
        meta: 'function',
        name: 'event.sendLocalMessage',
        description: {
            ko: '설정된 키의 로컬 메세지를 발생 시킵니다.',
            en: '',
        },
    },
    {
        value: `event.onCollisionStart(function(info){\n\n})`,
        caption: `event.onCollisionStart(callback: (info: collisionInfo)=>void)`,
        score: 10,
        meta: 'function',
        name: 'event.onCollisionStart',
        description: {
            ko: '충돌이 시작됬을때 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    {
        value: `event.onCollisionEnd(function(info){\n\n})`,
        caption: `event.onCollisionEnd(callback: (info: collisionInfo)=>void)`,
        score: 10,
        meta: 'function',
        name: 'event.onCollisionEnd',
        description: {
            ko: '충돌이 끝났을때 호출할 함수를 등록합니다.',
            en: '',
        },
    },
    // {
    //     value: `event.onKeyDown('ArrowLeft', function(){\n\n})`,
    //     caption: `event.onKeyDown(key: string, function(){\n\n})`,
    //     score: 10,
    //     meta: 'input',
    //     name: 'event.onKeyDown',
    //     description: {
    //         ko: '설정된 키가 눌러졌을때 호출할 함수를 등록합니다.',
    //         en: '',
    //     },
    // },
    // {
    //     value: `event.onKeyUp('ArrowLeft', function(){\n\n})`,
    //     caption: `event.onKeyUp(key: string, function(){\n\n})`,
    //     score: 10,
    //     meta: 'input',
    //     name: 'event.onKeyUp',
    //     description: {
    //         ko: '설정된 키가 눌러졌다가 때어졌을때 호출할 함수를 등록합니다.',
    //         en: '',
    //     },
    // },
    // {
    //     value: `event.onKey('ArrowLeft', function(){\n\n})`,
    //     caption: `event.onKey(key: string, function(){\n\n})`,
    //     score: 10,
    //     meta: 'input',
    //     name: 'event.onKey',
    //     description: {
    //         ko: '설정된 키가 눌러진 상태일때 호출할 함수를 등록합니다.',
    //         en: '',
    //     },
    // },
];

export default class EventFunction extends BaseFunction {
    protected _name: string = 'Event';

    private _lifeCycleCallbackList: {[key:string] : PIXI.utils.EventEmitter.ListenerFn[]} = {
        update: [],
        enable: [],
        disable: [],
        destroy: [],
    };

    public get instance(): EventFunction {
        return this;
    }

    public onButton(key: string, callback: Function) {
        const manager = this.manager;
        const func = function (k: string) {
            if(key === k && manager.enable) {
                try {
                    callback();
                }
                catch (e) {
                    manager.console._catch(e);
                }
            }
        }
        this._lifeCycleCallbackList.destroy.push(()=>{
            this.gameObject.game.signal.off('button', func);
        });
        this.gameObject.game.signal.on('button', func);
    }

    public onKeyDown(key: string, callback: Function) {
        const manager = this.manager;
        const func = function (k: string) {
            if(key === k && manager.enable) {
                try {
                    callback();
                }
                catch (e) {
                    manager.console._catch(e);
                }
            }
        }
        this._lifeCycleCallbackList.destroy.push(()=>{
            this.gameObject.game.signal.off('keydown', func);
        });
        this.gameObject.game.signal.on('keydown', func);
    }
    public onKeyUp(key: string, callback: Function) {
        const manager = this.manager;
        const func = function (k: string) {
            if(key === k && manager.enable) {
                try {
                    callback();
                }
                catch (e) {
                    manager.console._catch(e);
                }
            }
        }
        this._lifeCycleCallbackList.destroy.push(()=>{
            this.gameObject.game.signal.off('keyup', func);
        });
        this.gameObject.game.signal.on('keyup', func);
    }
    public onKey(key: string, callback: Function) {
        const manager = this.manager;
        const func = function (k: string) {
            if(key === k && manager.enable) {
                try {
                    callback();
                }
                catch (e) {
                    manager.console._catch(e);
                }
            }
        }
        this._lifeCycleCallbackList.destroy.push(()=>{
            this.gameObject.game.signal.off('keystay', func);
        });
        this.gameObject.game.signal.on('keystay', func);
    }

    public onUpdate(callback: (delta: number)=>void) {
        const manager = this.manager;
        const func = function (delta: number) {
            try {
                if(manager.enable) {
                    callback(delta);
                }
            }
            catch (e) {
                manager.console._catch(e);
            }
        }
        this._lifeCycleCallbackList.update.push(func);
    }

    public onEnable(callback: PIXI.utils.EventEmitter.ListenerFn) {
        const log = this.manager.console;
        const func = function () {
            try {
                callback();
            }
            catch (e) {
                log._catch(e);
            }
        }
        this._lifeCycleCallbackList.enable.push(func);
    }

    public onDisable(callback: PIXI.utils.EventEmitter.ListenerFn) {
        const log = this.manager.console;
        const func = function () {
            try {
                callback();
            }
            catch (e) {
                log._catch(e);
            }
        }
        this._lifeCycleCallbackList.disable.push(func);
    }

    public onDestroy(callback: PIXI.utils.EventEmitter.ListenerFn) {
        const log = this.manager.console;
        const func = function () {
            try {
                callback();
            }
            catch (e) {
                log._catch(e);
            }
        }
        this._lifeCycleCallbackList.destroy.push(func);
    }

    public onClickUp(callback: PIXI.utils.EventEmitter.ListenerFn) {
        let target: any = null;
        let comp = this.gameObject.getComponent('Sprite');

        if(comp) {
            target = (comp as any).sprite;
        }
        if(!target) {
            comp = this.gameObject.getComponent('Shape');
            if(comp) {
                target = (comp as any).graphic;
            }
        }
        if(!target) {
            comp = this.gameObject.getComponent('Text');
            if(comp) {
                target = (comp as any).text;
            }
        }
        if(!target) {
            comp = this.gameObject.getComponent('NineSlice');
            if(comp) {
                target = (comp as NineSlice).sprite;
            }
        }

        if(target) {
            const manager = this.manager;
            const _callback = function (event: any) {
                try {
                    if(manager.enable){
                        const localPos = manager.gameObject.toLocal( event.data.global );
                        callback(localPos);
                    }
                }
                catch (e) {
                    manager.console._catch(e);
                }
            }
            target.interactive = true;
            target.on('pointerup', _callback);
            target.on('pointerupoutside', _callback);
            this._lifeCycleCallbackList.destroy.push(function (){
                target.off('pointerup', _callback);
                target.off('pointerupoutside', _callback);
            });
        }
        else {
            throw new ScriptError('컴포넌트 없음',
                'onClickUp 함수는 렌더타입의 컴포넌트가 있어야 사용할 수 있습니다.',
                this.log.script?.script?.name, 'event.onClickUp' );
        }
    }

    public onClickDown(callback: PIXI.utils.EventEmitter.ListenerFn) {
        let target: any = null;
        let comp = this.gameObject.getComponent('Sprite');

        if(comp) {
            target = (comp as any).sprite;
        }
        if(!target) {
            comp = this.gameObject.getComponent('Shape');
            if(comp) {
                target = (comp as any).graphic;
            }
        }
        if(!target) {
            comp = this.gameObject.getComponent('Text');
            if(comp) {
                target = (comp as any).text;
            }
        }
        if(!target) {
            comp = this.gameObject.getComponent('NineSlice');
            if(comp) {
                target = (comp as NineSlice).sprite;
            }
        }

        if(target) {
            const manager = this.manager;
            const _callback = function (event: any) {
                try {
                    if(manager.enable){
                        const localPos = manager.gameObject.toLocal( event.data.global );
                        callback(localPos);
                    }
                }
                catch (e) {
                    manager.console._catch(e);
                }
            }
            target.interactive = true;
            target.on('pointerdown', _callback);
            this._lifeCycleCallbackList.destroy.push(function (){
                target.off('pointerdown', _callback);
            });
        }
        else {
            throw new ScriptError('컴포넌트 없음',
                'onClickDown 함수는 렌더타입의 컴포넌트가 있어야 사용할 수 있습니다.',
                this.log.script?.script?.name, 'event.onClickDown' );
        }
    }

    public onClickMove(callback: PIXI.utils.EventEmitter.ListenerFn) {
        let target: any = null;
        let comp = this.gameObject.getComponent('Sprite');

        if(comp) {
            target = (comp as any).sprite;
        }
        if(!target) {
            comp = this.gameObject.getComponent('Shape');
            if(comp) {
                target = (comp as any).graphic;
            }
        }
        if(!target) {
            comp = this.gameObject.getComponent('Text');
            if(comp) {
                target = (comp as any).text;
            }
        }
        if(!target) {
            comp = this.gameObject.getComponent('NineSlice');
            if(comp) {
                target = (comp as NineSlice).sprite;
            }
        }

        if(target) {
            const manager = this.manager;
            let isDown = false;
            const _callback = function (event: any) {
                try {
                    if(manager.enable && isDown){
                        const localPos = manager.gameObject.toLocal( event.data.global );
                        callback(localPos);
                    }
                }
                catch (e) {
                    manager.console._catch(e);
                }
            }

            const _downCallback = function (event: any) {
                try {
                    if(manager.enable){
                        isDown = true;
                    }
                }
                catch (e) {
                    manager.console._catch(e);
                }
            }

            const _upCallback = function (event: any) {
                try {
                    if(manager.enable){
                        isDown = false;
                    }
                }
                catch (e) {
                    manager.console._catch(e);
                }
            }

            target.interactive = true;
            target.on('pointerdown', _downCallback);
            target.on('pointermove', _callback);
            target.on('pointerup', _upCallback);
            target.on('pointerupoutside', _upCallback);
            this._lifeCycleCallbackList.destroy.push(function (){
                target.off('pointerdown', _downCallback);
                target.off('pointermove', _callback);
                target.off('pointerup', _upCallback);
                target.off('pointerupoutside', _upCallback);
            });
        }
        else {
            throw new ScriptError('컴포넌트 없음',
                'onClickMove 함수는 렌더타입의 컴포넌트가 있어야 사용할 수 있습니다.',
                this.log.script?.script?.name, 'event.onClickMove' );
        }
    }

    public onClick(callback: PIXI.utils.EventEmitter.ListenerFn) {
        let target: any = null;
        let comp = this.gameObject.getComponent('Sprite');

        if(comp) {
            target = (comp as any).sprite;
        }
        if(!target) {
            comp = this.gameObject.getComponent('Shape');
            if(comp) {
                target = (comp as any).graphic;
            }
        }
        if(!target) {
            comp = this.gameObject.getComponent('Text');
            if(comp) {
                target = (comp as any).text;
            }
        }
        if(!target) {
            comp = this.gameObject.getComponent('NineSlice');
            if(comp) {
                target = (comp as NineSlice).sprite;
            }
        }

        if(target) {
            const manager = this.manager;
            const _callback = function (event: any) {
                try {
                    if(manager.enable){
                        callback()
                    }
                }
                catch (e) {
                    manager.console._catch(e);
                }
            }
            target.interactive = true;
            target.on('pointerdown', _callback);
            this._lifeCycleCallbackList.destroy.push(function (){
                target.off('pointerdown', _callback);
            });
        }
        else {
            throw new ScriptError('컴포넌트 없음',
                'onClick 함수는 렌더타입의 컴포넌트가 있어야 사용할 수 있습니다.',
                this.log.script?.script?.name, 'event.onClick' );
        }
    }

    onMessage(key: string, callback: PIXI.utils.EventEmitter.ListenerFn) {
        const manager = this.manager;
        function onM(sendKey: string, ...arg: Array<any>) {
            if( sendKey === key /*&& manager.enable*/) {
                try {
                    callback( ...arg );
                }
                catch (e) {
                    manager.console._catch(e);
                }
            }
        }
        this.gameObject.game.signal.on('message', onM);
        this._lifeCycleCallbackList.destroy.push( ()=>{
            this.gameObject.game.signal.off('message', onM);
        } );
    }

    sendMessage(...args: any[]) {
        const manager = this.manager;
        if(manager.enable){
            this.gameObject.game.signal.emit('message', ...args);
        }
    }

    onLocalMessage(key: string, callback: PIXI.utils.EventEmitter.ListenerFn) {
        const manager = this.manager;
        function onM(sendKey: string, ...arg: Array<any>) {
            if( sendKey === key && manager.enable) {
                try {
                    callback( ...arg );
                }
                catch (e) {
                    manager.console._catch(e);
                }
            }
        }
        (this.gameObject as PIXI.utils.EventEmitter).on('message', onM);
        this._lifeCycleCallbackList.destroy.push( ()=>{
            (this.gameObject as PIXI.utils.EventEmitter).off('message', onM);
        } );
    }

    sendLocalMessage(...args: any[]) {
        const manager = this.manager;
        if(manager.enable){
            (this.gameObject as PIXI.utils.EventEmitter).emit('message', ...args);
        }
    }

    onCollisionStart(callback: PIXI.utils.EventEmitter.ListenerFn) {
        const manager = this.manager;
        function func(info: ICollisionInfo) {
            try {
                if(manager.enable){
                    callback( info );
                }
            }
            catch (e) {
                manager.console._catch(e);
            }
        }

        this._lifeCycleCallbackList.destroy.push(()=>{
            (this.gameObject as PIXI.utils.EventEmitter).off('collisionStart', func);
        });
        (this.gameObject as PIXI.utils.EventEmitter).on('collisionStart', func);
    }
    onCollisionEnd(callback: PIXI.utils.EventEmitter.ListenerFn) {
        const manager = this.manager;
        function func(info: ICollisionInfo) {
            try {
                if(manager.enable){
                    callback( info );
                }
            }
            catch (e) {
                manager.console._catch(e);
            }
        }
        this._lifeCycleCallbackList.destroy.push(()=>{
            (this.gameObject as PIXI.utils.EventEmitter).off('collisionEnd', func);
        });
        (this.gameObject as PIXI.utils.EventEmitter).on('collisionEnd', func);
    }

    destroy() {
        for(let i = 0; i < this._lifeCycleCallbackList.destroy.length; i++) {
            this._lifeCycleCallbackList.destroy[i]();
        }

        this._lifeCycleCallbackList.update.length = 0;
        this._lifeCycleCallbackList.enable.length = 0;
        this._lifeCycleCallbackList.disable.length = 0;
        this._lifeCycleCallbackList.destroy.length = 0;
        super.destroy();
    }

    public _update(delta: number) {
        for(let i = 0; i < this._lifeCycleCallbackList.update.length; i++) {
            this._lifeCycleCallbackList.update[i](delta);
        }
    }
    public _disable() {
        for(let i = 0; i < this._lifeCycleCallbackList.disable.length; i++) {
            this._lifeCycleCallbackList.disable[i]();
        }
    }
    public _enable() {
        for(let i = 0; i < this._lifeCycleCallbackList.enable.length; i++) {
            this._lifeCycleCallbackList.enable[i]();
        }
    }
}
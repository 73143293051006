import Asset, {IAssetData} from "./asset";
import Game from "../core/game";
import {ISceneJson} from "../gameObject/scene";
import {EPostUpdateProperty} from "../core/postUpdate";

export interface ISceneAssetData extends IAssetData {
    json : ISceneJson,
}

export default class SceneAsset extends Asset {

    private _json : ISceneJson;
    protected readonly _type : string = 'Scene';

    constructor(game: Game, json: ISceneJson, id: string = undefined) {
        super(game, id);
        this._json = json;
        this.game.scenes.addScene(this);
    }

    public get scene(): ISceneJson {
        return this._json;
    }

    public set scene(json: ISceneJson) {
        this._json = json;
    }

    public start() {
        this.game.postUpdate.addPostUpdate( ()=>{
            this.game.scenes.start(this.id);
        }, EPostUpdateProperty.changeScene );
    }

    destroy() {
        this.game.scenes.removeScene(this.id);
        super.destroy();
        this._json = null;
    }

    toPlayData(): ISceneAssetData {
        const json = super.toPlayData() as ISceneAssetData;
        json.json = this.scene;
        return json;
    }
}
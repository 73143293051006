import {Button, Switch, Popconfirm, Dropdown, Menu, Checkbox} from "antd";
import EditorManager from "../../../../services/editorManager";
import {ReactElement} from "react";
import {
    CaretDownOutlined,
    CaretRightOutlined, DeleteFilled,
    MoreOutlined,
    UpCircleOutlined,DownCircleOutlined,

} from '@ant-design/icons';
import * as React from "react";
import LocalizationManager from "../../../../services/localizationManager";

export default function ComponentBox({json, name, children, onChange,
                                         onChangeActive, onRemove, icon, dropDown, idx}:
    {json: {expanded: boolean, active?: boolean,}, name: string, children:any,
        onChange: (checked:boolean)=>void, onRemove?: Function,
        onChangeActive?: Function,
        icon?: ReactElement, dropDown?: boolean, idx?: number}) {
    return <div className={'componentBox'}>
        <div className={'componentNameContainer'} style={{display:"flex"}} onClick={function (){
            onChange(!json.expanded);
        }}>
            <div style={{
                marginLeft:10,
                lineHeight: '32px'
            }}>
                {
                    json.expanded ?   <CaretDownOutlined/> :  <CaretRightOutlined/>
                }
            </div>
            <div style={{
                marginLeft:10,
                lineHeight: '32px'
            }}>
                {icon}
                {
                    onChangeActive ?  <Switch style={{
                        marginLeft: '5px',
                        marginRight: '10px',
                    }} checked={json.active} onClick={function (checked, e){
                        e.preventDefault();
                        e.stopPropagation();
                        onChangeActive();
                    }}></Switch> : null
                }
                <span>
                    {LocalizationManager.Instance.getText(`Component.${name}`) || name}
                </span>
            </div>
            <div style={{flexGrow:1}}>
            </div>
            <div  onClick={function (event){
                event.preventDefault();
                event.stopPropagation();
            }}>
                {
                    dropDown ? <Dropdown overlay={<Menu items={[
                        {
                            key: '1', label: <Popconfirm
                                title={LocalizationManager.Instance.getText('Popup.confirmRemoveComp', name)}
                                onConfirm={()=>{onRemove()}}
                                onCancel={function (){

                                }}
                                okText="제거"
                                cancelText="취소"
                            >
                                <div><DeleteFilled  style={{marginRight:'10px'}}/>
                                    {LocalizationManager.Instance.getText('ContextMenu.remove') || 'Remove'}
                                </div>
                            </Popconfirm>
                        },
                        {
                            key: '2', label:  <div onClick={function (){
                                if(idx > 0) {
                                    EditorManager.Instance.moveComponent(idx, idx-1)
                                }
                            }}><UpCircleOutlined style={{marginRight:'10px'}}/>
                                {LocalizationManager.Instance.getText('ContextMenu.moveUp') || 'Up'}
                            </div>
                        },
                        {
                            key: '3', label:  <div onClick={function (){
                                EditorManager.Instance.moveComponent(idx, idx+1)
                            }}><DownCircleOutlined style={{marginRight:'10px'}}/>
                                {LocalizationManager.Instance.getText('ContextMenu.moveDown') || 'Down'}
                            </div>
                        }
                    ]}/>}>
                        <Button type={"text"}><MoreOutlined/></Button>
                    </Dropdown> : null
                }

            </div>
        </div>
        {
            children? <div className={'componentBodyContainer'}>
                {children}
            </div> : null
        }

    </div>
}
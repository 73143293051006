import LogManager from "./logManager";
import GameObject from "../gameObject/gameObject";
import FunctionManager from "./functionManager";
import {Simulate} from "react-dom/test-utils";

export default class BaseFunction {
    protected _name: string = '';
    public get name() : string {
        return this._name;
    }
    public get camelName(): string {
        return this._name.charAt(0).toLowerCase() + this._name.slice(1)
    }

    private _log: LogManager;
    private _gameObject: GameObject;
    protected _manager: FunctionManager;

    public get manager(): FunctionManager {
        return this._manager;
    }

    public get log(): LogManager {
        return this._log;
    }
    public get gameObject(): GameObject {
        return this._gameObject;
    }

    constructor(log: LogManager, gameObject: GameObject, _manager: FunctionManager) {
        this._log = log;
        this._gameObject = gameObject;
        this._manager = _manager;
    }

    destroy() {
        this._log = null;
        this._gameObject = null;
        this._manager = null;
    }


}

export const ComponentFunctionError : {[key:string]:string} = {
    notFoundComponent: '컴포넌트를 찾을 수 없습니다.',
    invalidValue: '잘못된 설정값 입니다.',
}
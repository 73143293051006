import WizardSteps from "./comp/wizardSteps";
import WizardPage from "./comp/wizardPage";
import {Card, Checkbox, Radio, Select, Space, Switch, Tabs, Tooltip, Typography} from 'antd';
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {HeartOutlined, InfoCircleOutlined, DoubleRightOutlined, ArrowUpOutlined   } from "@ant-design/icons";
import wizardSaveData from "../../services/wizard/wizardSave";
import SceneAsset from "mogera-game/scripts/asset/sceneAsset";
import GoJsonHelper from "../../services/wizard/goJsonHelper";
import { GiSpikes, GiHealthNormal, GiRun, GiJumpAcross } from "react-icons/gi";
import {BiBook, BiTimer} from "react-icons/bi";
import queryString from "query-string";


const { TabPane } = Tabs;
const {Text, Title} = Typography;
const {Option} = Select;

export default function Platform() {

    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();

    let useKey = true;
    let useMonster = true;

    const qa = queryString.parse(window.location.search);
    const mode = qa?.mode;
    if(mode === 'key') {
        useKey = true;
        useMonster = true;
    }
    else if(mode === 'monster') {
        useKey = false;
        useMonster = true;
    }
    else if(mode === 'none') {
        useKey = false;
        useMonster = false;
    }

    const [data, setData] = useState({
        useKey: useKey,
        useMonster: useMonster,
        playerSpeed: 300,
        playerHp: 1,
        playerJumpPower: 1000,
        useThorn: true,
        useTimer: true,
        monsters : [
            {name: 'Monster1', active: true, speed: 100},
        ]
    });




    return <div className={'wizardRoot'}>
        <WizardSteps currentStep={currentStep} stepDataList={[
            {title:'게임 로직', description: '게임 클리어 조건을 설정합니다.'},
            {title:'플레이어 설정', description: '플레이어 기본정보를 설정합니다.'},
            {title:'몬스터 설정', description: '몬스터의 기본정보를 설정합니다.'},
            {title:'추가 옵션 설정', description: '추가 옵션을 설정합니다.'},
            {title:'게임 설정 완료', description: '설정한 게임을 실행합니다.'}
        ]} onChange={function (step){
            setCurrentStep(step);
        }}></WizardSteps>
        <Tabs activeKey={currentStep.toString()} >
            <TabPane  key={'0'}>
                <WizardPage title={'게임 로직'} description={'게임 클리어 조건을 설정합니다.'}
                            onPrev={function (){
                                navigate("/wizard" );
                            }}
                            onNext={function (){
                                setCurrentStep(currentStep+1);
                            }}
                            prevText={'장르 다시 선택'}>
                    <Space style={{alignItems:'baseline'}}>
                        <Card
                            className={data.useKey ? 'select' : ''}
                            hoverable
                            cover={<img draggable={false} height={240} src="/img/wizard/platformer/getKey.gif" />}
                            onClick={function (){
                                data.useKey = true;
                                data.useMonster = true;
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                열쇠 모으기
                            </Title>
                            <div style={{height:'90px'}}>
                                열쇠를 모두 획득하고 목적지에 도착하면 게임이 클리어됩니다.
                            </div>
                        </Card>
                        <Card
                            hoverable
                            className={!data.useKey && data.useMonster?  'select' : ''}
                            cover={<img draggable={false} height={240} src="/img/wizard/platformer/killMonster.gif" />}
                            onClick={function (){
                                data.useKey = false;
                                data.useMonster = true;
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                몬스터 처지
                            </Title>
                            <div style={{height:'90px'}}>
                                몬스터를 모두 처치하고 목적지에 도착하면 게임이 클리어됩니다. 몬스터는 밟아서 처지할 수 있습니다.
                            </div>
                        </Card>
                        <Card
                            hoverable
                            className={!data.useKey && !data.useMonster?  'select' : ''}
                            cover={<img draggable={false} height={240} src="/img/wizard/platformer/open.gif" />}
                            onClick={function (){
                                data.useKey = false;
                                data.useMonster = false;
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                목적지 도착
                            </Title>
                            <div style={{height:'90px'}}>
                                열쇠와 몬스터 없이 목적지에 도착하면 게임이 클리어됩니다.
                            </div>
                        </Card>
                    </Space>
                </WizardPage>
            </TabPane>
            <TabPane  key={'1'}>
                <WizardPage title={'플레이어 설정'} description={'플레이어 기본정보를 설정합니다.'}
                            onNext={function (){
                                setCurrentStep(currentStep+1);
                            }}
                            onPrev={function (){
                                setCurrentStep(currentStep-1);
                            }}>
                    <Space style={{alignItems: "baseline", marginTop: '80px'}}>
                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <GiHealthNormal style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>체력</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="플레이어의 체력을 설정합니다. 몬스터나 장애물과 충돌하면 체력이 소모되며 모두 소모될 시 게임이 종료됩니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Select style={{width: '150px'}} value={data.playerHp} onChange={function (value, option){
                                data.playerHp = value;
                                setData({...data});
                            }}>
                                <Option key={3} value={3}>3(쉬움)</Option>
                                <Option key={1} value={1}>1(중간)</Option>
                                <Option key={0} value={0}>0(어려움)</Option>
                            </Select>
                        </div>

                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <GiRun  style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>이동 속도</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="플레이어의 이동속도를 설정합니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Select style={{width: '150px'}} value={data.playerSpeed} onChange={function (value, option){
                                data.playerSpeed = value;
                                setData({...data});
                            }}>
                                <Option key={0} value={350}>350(쉬움)</Option>
                                <Option key={1} value={300}>300(중간)</Option>
                                <Option key={2} value={250}>250(어려움)</Option>
                            </Select>
                        </div>

                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <GiJumpAcross style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>점프 세기</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="플레이어의 점프 세기를 설정합니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Select style={{width: '150px'}} value={data.playerJumpPower} onChange={function (value, option){
                                data.playerJumpPower = value;
                                setData({...data});
                            }}>
                                <Option key={0} value={1100}>1100(쉬움)</Option>
                                <Option key={1} value={1000}>1000(중간)</Option>
                                <Option key={2} value={950}>950(어려움)</Option>
                            </Select>
                        </div>
                    </Space>
                </WizardPage>
            </TabPane>
            <TabPane  key={'2'}>
                <WizardPage title={'몬스터 설정'} description={'몬스터의 기본정보를 설정합니다.'}
                            onNext={function (){
                                setCurrentStep(currentStep+1);
                            }}
                            onPrev={function (){
                                setCurrentStep(currentStep-1);
                            }}>
                    <Space style={{alignItems:'baseline', marginTop: '50px'}}>
                        {
                            data.monsters.map((monster, index, array)=>{
                                return  <div key={index}>
                                    <Card
                                        className={data.useMonster ? 'select' : ''}
                                        cover={<img draggable={false} style={{backgroundColor:'#2b2b2c'}} height={240} src={`/img/wizard/platformer/${monster.name}.png`} />}
                                        onClick={function (){
                                        }}
                                    >
                                        <Title level={5}>
                                            {monster.name}
                                        </Title>
                                    </Card>
                                    <div style={{marginTop: '10px'}}>
                                        <Text style={{marginRight: '10px', display: "inline-block", width: '80px', fontSize: '12px'}}>이동속도</Text>
                                        <Select disabled={!data.useMonster || !monster.active} style={{width: '100px'}} value={monster.speed} onChange={function (value, option){
                                            monster.speed = value;
                                            setData({...data});
                                        }}>
                                            <Option key={0} value={50}>50</Option>
                                            <Option key={1} value={100}>100</Option>
                                            <Option key={2} value={200}>200</Option>
                                        </Select>
                                    </div>

                                </div>
                            })
                        }
                    </Space>
                    {
                        !data.useMonster ?  <div className={'bottom'}>
                            게임 클리어 조건이 목적지 도착일 때는 몬스터가 등장하지 않습니다.
                        </div> : null
                    }

                </WizardPage>
            </TabPane>
            <TabPane  key={'3'}>
                <WizardPage title={'추가 옵션 설정'} description={'추가 옵션을 설정합니다.'}
                            onNext={function (){
                                setCurrentStep(currentStep+1);
                            }}
                            onPrev={function (){
                                setCurrentStep(currentStep-1);
                            }}>
                    <Space style={{alignItems: "baseline", marginTop: '80px'}}>
                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <GiSpikes style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>가시 장애물 활성화</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="가시 장애물을 활성화합니다. 플레이어가 가시와 충돌하면 체력이 소모됩니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Switch checked={data.useThorn} onChange={function (checked){
                                data.useThorn = checked;
                                setData({...data});
                            }}></Switch>
                        </div>
                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <BiTimer style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>플레이 시간 표시</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="게임의 플레이 시간이 표시됩니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Switch checked={data.useTimer} onChange={function (checked){
                                data.useTimer = checked;
                                setData({...data});
                            }}></Switch>
                        </div>
                    </Space>
                </WizardPage>
            </TabPane>
            <TabPane  key={'4'}>
                <WizardPage title={'게임 설정 완료'} description={'게임을 만들기 위한 모든 설정이 완료되었습니다.'}
                            nextText={'완료'}
                            onNext={function (){
                                wizardSaveData.isData = true;
                                wizardSaveData.path = '/template/platform.mogera';
                                wizardSaveData.onLoadCallback = function (game) {
                                    const scene = game.assetManager.getAsset('게임', 'Scene') as SceneAsset;
                                    if (scene) {
                                        const player = GoJsonHelper.findGameObjectByPath(scene.scene, 'World/Player');
                                        const playerScript = GoJsonHelper.findScript(player, 'Player', game);
                                        playerScript.props.speed = data.playerSpeed;
                                        playerScript.props.jumpPower = data.playerJumpPower;
                                        playerScript.props.life = data.playerHp;

                                        if(!data.useKey) {
                                            const keys = GoJsonHelper.findGameObjectByPath(scene.scene, 'World/Keys');
                                            keys.active = false;
                                            const keyCount = GoJsonHelper.findGameObjectByPath(scene.scene, 'UI/UIManager/KeyCount');
                                            keyCount.active = false;

                                            if(data.useMonster) {
                                                const enemyCount = GoJsonHelper.findGameObjectByPath(scene.scene, 'UI/UIManager/EnemyCount');
                                                enemyCount.active = true;
                                            }

                                        }
                                        else {
                                            const keyCount = GoJsonHelper.findGameObjectByPath(scene.scene, 'UI/UIManager/KeyCount');
                                            keyCount.active = true;
                                        }

                                        if(!data.useMonster) {
                                            const enemies = GoJsonHelper.findGameObjectByPath(scene.scene, 'World/Enemies');
                                            enemies.active = false;
                                            const enemyCount = GoJsonHelper.findGameObjectByPath(scene.scene, 'UI/UIManager/EnemyCount');
                                            enemyCount.active = false;
                                        }
                                        else {
                                            const enemies = GoJsonHelper.findGameObjectByPath(scene.scene, 'World/Enemies');
                                            const enemyArr = GoJsonHelper.findGameObjectByNames(enemies, 'Enemy');
                                            for(let i = 0; i < enemyArr.length; i++) {
                                                const enemyScript = GoJsonHelper.findScript(enemyArr[i], 'Enemy', game);
                                                enemyScript.props.speed = data.monsters[0].speed;
                                            }
                                        }

                                        if(!data.useThorn) {
                                            const thorns = GoJsonHelper.findGameObjectByPath(scene.scene, 'World/Thorns');
                                            thorns.active = false;
                                        }

                                        const timer = GoJsonHelper.findGameObjectByPath(scene.scene, 'UI/UIManager/Time');
                                        timer.active = data.useTimer;
                                    }


                                }
                                navigate("/" );
                            }}
                            onPrev={function (){
                                setCurrentStep(currentStep-1);
                            }}>
                    <Space direction={'vertical'} style={{marginTop: '80px'}}>
                        <Text>완료 버튼을 누르면 에디터가 실행됩니다.</Text>
                        <Text>더 자세한 내용은 아래 notion 링크를 통해 확인하세요.</Text>
                        <div style={{marginTop:'40px'}}>
                            <a href="https://abaft-restaurant-3dc.notion.site/02a8b869042d470fb16f5960264d6f0f" target="_blank">
                                <div>
                                    <BiBook style={{fontSize: '120px', lineHeight: '100px'}}/>
                                </div>
                                <div style={{marginTop: '40px'}}>
                                    템플릿 에디터 가이드 보러가기
                                </div>
                            </a>
                        </div>
                    </Space>
                </WizardPage>
            </TabPane>
        </Tabs>
    </div>
}
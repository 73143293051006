import {useAppSelector} from "../../../app/hooks";
import {selectZoomScale} from "../../../store/editorSlice";
import LocalizationManager from "../../../services/localizationManager";

export default function ZoomScale() {
    const scale = useAppSelector(selectZoomScale);

    return <>
        <div className={'zoomScale'}>{`${LocalizationManager.Instance.getText('GameView.screeRate')} ${Math.round((1/scale ) * 100)}%`}</div>
    </>
}
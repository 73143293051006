import WizardSteps from "./comp/wizardSteps";
import WizardPage from "./comp/wizardPage";
import {Card, Checkbox, Radio, Select, Space, Switch, Tabs, Tooltip, Typography} from 'antd';
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import wizardSaveData from "../../services/wizard/wizardSave";
import SceneAsset from "mogera-game/scripts/asset/sceneAsset";
import GoJsonHelper from "../../services/wizard/goJsonHelper";
import {
    AimOutlined,
    HeartOutlined, InfoCircleOutlined, RocketOutlined, TagsOutlined, FieldTimeOutlined, ForwardOutlined,
} from '@ant-design/icons';
import { GiHealthNormal, GiSupersonicBullet } from "react-icons/gi";
import { IoMdRocket } from "react-icons/io";
import {BiBook, BiStopwatch} from "react-icons/bi";
import queryString from 'query-string';

import {ToggleButton} from "@mui/material";

const { TabPane } = Tabs;
const {Text, Title} = Typography;
const {Option} = Select;

export default function Shooting() {



    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();

    let isBoss = true;
    const qa = queryString.parse(window.location.search);
    const mode = qa?.mode;
    if(mode === 'boss') {
        isBoss = true;
    }
    else if(mode === 'endless') {
        isBoss = false;
    }

    const [data, setData] = useState({
        isBoss: isBoss,
        playerSpeed: 600,
        playerHp: 5,
        playerBulletDelay: 0.1,
        playerBulletSpeed: 1000,
        playerAutoFire: true,
        enemies: [
            { name: 'Enemy1', active: true, hp: 2, moveSpeed:200, bulletDelay: 1, bulletSpeed: 1000, followBullet: false },
            { name: 'Enemy2', active: true, hp: 4, moveSpeed:100, bulletDelay: 1, bulletSpeed: 1000, followBullet: false },
            { name: 'Enemy3', active: true, hp: 4, moveSpeed:100, bulletDelay: 2, bulletSpeed: 1000, followBullet: true },
        ],
        bossPattens: [
            { name: 'Pattern1', active: true },
            { name: 'Pattern2', active: true },
            { name: 'Pattern3', active: true },
            { name: 'Pattern4', active: true },
            { name: 'Pattern5', active: true },
        ],
        bossTime: 30,
        bossHp: 100,

    });



    function enemyCount(enemies: any[]): number {
        let count = 0;
        for(let i = 0; i < enemies.length; i++) {
            if(enemies[i].active) {
                count++;
            }
        }

        return count;
    }

    return <div className={'wizardRoot'}>
        <WizardSteps currentStep={currentStep} stepDataList={[
            {title:'게임 로직', description: '게임 클리어 조건을 설정합니다.'},
            {title:'플레이어 설정', description: '플레이어의 기본 정보를 설정합니다.'},
            {title:'적 설정', description: '게임에 등장할 적 목록을 설정합니다.'},
            {title:'보스 패턴 설정', description: '보스의 패턴을 설정합니다.'},
            {title:'조작법 설정', description: '게임 조작 방법을 설정합니다.'},
            {title:'게임 설정 완료', description: '설정한 게임을 실행합니다.'}
        ]} onChange={function (step){
            setCurrentStep(step);
        }}></WizardSteps>
        <Tabs activeKey={currentStep.toString()} >
            <TabPane key={'0'}>
                <WizardPage title={'게임 로직'} description={'게임 클리어 조건을 설정합니다.'}
                            onPrev={function (){
                                navigate("/wizard" );
                            }}
                            onNext={function (){
                                setCurrentStep(currentStep+1);
                            }}
                            prevText={'장르 다시 선택'}
                >
                    <Space>
                        <Card
                            hoverable
                            className={data.isBoss ?  'select' : ''}
                            cover={<img draggable={false} height={320} src="/img/wizard/shooting/boss.jpg" />}
                            onClick={function (){
                                data.isBoss = true;
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                보스 모드
                            </Title>
                            <div style={{height:'60px'}}>
                                보스와의 전투에서 승리하면 게임을 클리어합니다.
                            </div>
                        </Card>
                        <Card
                            className={!data.isBoss ? 'select' : ''}
                            hoverable
                            cover={<img draggable={false} height={320} src="/img/wizard/shooting/endless.jpg" />}
                            onClick={function (){
                                data.isBoss = false;
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                무한 모드
                            </Title>
                            <div style={{height:'60px'}}>
                                플레이어 체력이 0이 되어 게임이 끝나기 전까지 무한히 적이 생성됩니다.
                            </div>
                        </Card>
                    </Space>
                </WizardPage>
            </TabPane>
            <TabPane key={'1'}>
                <WizardPage title={'플레이어 설정'} description={'플레이어의 기본 정보를 설정합니다.'}
                            onNext={function (){
                                setCurrentStep(currentStep+1);
                            }}
                            onPrev={function (){
                                setCurrentStep(currentStep-1);
                            }}>
                    <Space style={{marginTop: '80px'}}>
                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <GiHealthNormal style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>최대 체력</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="플레이어의 최대 체력을 설정합니다. 적이나 적의 총알과 충돌 시 체력이 소모되며 0이 되면 게임이 종료됩니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Select style={{width: '150px'}} value={data.playerHp} onChange={function (value, option){
                                data.playerHp = value;
                                setData({...data});
                            }}>
                                <Option key={0} value={20}>5(쉬움)</Option>
                                <Option key={1} value={5}>3(중간)</Option>
                                <Option key={2} value={1}>1(어려움)</Option>
                            </Select>
                        </div>
                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <IoMdRocket style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>이동 속도</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="플레이어의 이동 속도를 설정합니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Select style={{width: '150px'}} value={data.playerSpeed} onChange={function (value, option){
                                data.playerSpeed = value;
                                setData({...data});
                            }}>
                                <Option key={0} value={600}>600(쉬움)</Option>
                                <Option key={1} value={500}>500(중간)</Option>
                                <Option key={2} value={400}>400(어려움)</Option>
                            </Select>
                        </div>
                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <BiStopwatch style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>총알 발사 간격</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="플레이어의 총알 발사 간격을 설정합니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Select style={{width: '150px'}} value={data.playerBulletDelay} onChange={function (value, option){
                                data.playerBulletDelay = value;
                                setData({...data});
                            }}>
                                <Option key={0} value={0.06}>0.06초(쉬움)</Option>
                                <Option key={1} value={0.1}>0.1초(중간)</Option>
                                <Option key={2} value={0.2}>0.2초(어려움)</Option>
                            </Select>
                        </div>
                        <div style={{width:'150px', margin: '0px 15px'}}>
                            <div style={{height:'100px', marginTop:'80px', textAlign: 'center'}}>
                                <GiSupersonicBullet  style={{fontSize: '50px', lineHeight: '100px'}}/>
                            </div>
                            <div style={{marginBottom: '20px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div>총알 이동 속도</div>
                                <Tooltip className={'tooltip tooltipIcon'} title="플레이어의 총알 이동 속도를 설정합니다.">
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            </div>
                            <Select style={{width: '150px'}} value={data.playerBulletSpeed} onChange={function (value, option){
                                data.playerBulletSpeed = value;
                                setData({...data});
                            }}>
                                <Option key={0} value={1500}>1500(쉬움)</Option>
                                <Option key={1} value={1200}>1200(중간)</Option>
                                <Option key={2} value={1000}>1000(어려움)</Option>
                            </Select>
                        </div>
                    </Space>
                </WizardPage>
            </TabPane>
            <TabPane key={'2'}>
                <WizardPage title={'적 설정'} description={'게임에 등장할 적 목록을 설정합니다.'}
                            onNext={function (){
                                setCurrentStep(currentStep+1);
                            }}
                            onPrev={function (){
                                setCurrentStep(currentStep-1);
                            }}>
                    <Space style={{alignItems:'baseline'}}>
                        {
                            data.enemies.map((enemy, index, array)=>{
                                return <div key={index}>
                                    <Card
                                        className={  !data.isBoss && enemy.active || (data.isBoss && data.bossTime > 0 && enemy.active ) ? 'select' : ''}
                                        cover={<img draggable={false} style={{backgroundColor:'#2b2b2c'}} height={240} src={`/img/wizard/shooting/${enemy.name}.png`} />}
                                        onClick={function (){

                                            if(enemy.active && enemyCount(data.enemies) === 1) {
                                                confirm('최소한 한 개의 적이 활성화되어 있어야 합니다.')
                                                return;
                                            }

                                            enemy.active = !enemy.active;
                                            setData({...data});
                                        }}
                                    >
                                        <Title level={5}>
                                            {enemy.name}
                                        </Title>
                                    </Card>
                                    <div>
                                        <Switch checked={   !data.isBoss && enemy.active || (data.isBoss && data.bossTime > 0 && enemy.active) } className={'toggle'} onChange={function (checked, event){
                                            enemy.active = checked;
                                            setData({...data});
                                        }}></Switch>
                                    </div>
                                    {/*{ name: 'Enemy1', active: false, hp: 2, moveSpeed:200, bulletDelay: 1, bulletSpeed: 1000, followBullet: false },*/}
                                    <div style={{marginTop: '10px'}}>
                                        <Text style={{textAlign: 'left', marginRight: '10px', display: "inline-block", width: '80px', fontSize: '12px'}}>체력</Text>
                                        <Select disabled={data.isBoss && data.bossTime === 0 || !enemy.active} style={{width: '100px'}} value={enemy.hp} onChange={function (value, option){
                                            enemy.hp = value;
                                            setData({...data});
                                        }}>
                                            <Option key={0} value={1}>1</Option>
                                            <Option key={1} value={2}>2</Option>
                                            <Option key={2} value={4}>4</Option>
                                            <Option key={4} value={6}>6</Option>
                                        </Select>
                                    </div>
                                    <div style={{marginTop: '10px'}}>
                                        <Text style={{textAlign: 'left', marginRight: '10px', display: "inline-block", width: '80px', fontSize: '12px'}}>이동 속도</Text>
                                        <Select disabled={data.isBoss && data.bossTime === 0 || !enemy.active} style={{width: '100px'}} value={enemy.moveSpeed} onChange={function (value, option){
                                            enemy.moveSpeed = value;
                                            setData({...data});
                                        }}>
                                            <Option key={0} value={100}>100</Option>
                                            <Option key={1} value={200}>200</Option>
                                            <Option key={2} value={300}>300</Option>
                                        </Select>
                                    </div>
                                    <div style={{marginTop: '10px'}}>
                                        <Text style={{textAlign: 'left', marginRight: '10px', display: "inline-block", width: '80px',fontSize: '12px'}}>총알 발사 간격</Text>
                                        <Select disabled={data.isBoss && data.bossTime === 0 || !enemy.active} style={{width: '100px'}} value={enemy.bulletDelay} onChange={function (value, option){
                                            enemy.bulletDelay = value;
                                            setData({...data});
                                        }}>
                                            <Option key={0} value={1}>1초</Option>
                                            <Option key={1} value={2}>2초</Option>
                                        </Select>
                                    </div>
                                    <div style={{marginTop: '10px'}}>
                                        <Text style={{textAlign: 'left', marginRight: '10px', display: "inline-block", width: '80px',fontSize: '12px'}}>총알 이동 속도</Text>
                                        <Select disabled={data.isBoss && data.bossTime === 0 || !enemy.active} style={{width: '100px'}} value={enemy.bulletSpeed} onChange={function (value, option){
                                            enemy.bulletSpeed = value;
                                            setData({...data});
                                        }}>
                                            <Option key={0} value={1000}>1000</Option>
                                            <Option key={1} value={900}>900</Option>
                                        </Select>
                                    </div>
                                    {/*<div style={{marginTop: '10px'}}>*/}
                                    {/*    <Text style={{marginRight: '10px', display: "inline-block", width: '80px',fontSize: '12px'}}>생성 빈도</Text>*/}
                                    {/*    <Select disabled={data.isBoss && data.bossTime === 0 || !enemy.active} style={{width: '100px'}} defaultValue={1} onChange={function (value, option){*/}
                                    {/*        */}
                                    {/*    }}>*/}
                                    {/*        <Option key={0} value={1}>빈번</Option>*/}
                                    {/*        <Option key={1} value={2}>보통</Option>*/}
                                    {/*        <Option key={1} value={2}>적음</Option>*/}
                                    {/*    </Select>*/}
                                    {/*</div>*/}
                                    <div style={{marginTop: '10px', textAlign: 'left'}}>
                                        <Text style={{textAlign: 'left',marginLeft: '24px',  marginRight: '10px', display: "inline-block", fontSize: '12px'}}>총알 플레이어추적</Text>
                                        <Checkbox disabled={data.isBoss && data.bossTime === 0 || !enemy.active} checked={enemy.followBullet} onChange={function (e){
                                            enemy.followBullet = e.target.checked;
                                            setData({...data});
                                        }}></Checkbox>
                                    </div>
                                    {
                                        data.isBoss && data.bossTime === 0 ? <div className={'settingFooter'}>
                                            보스 바로 등장 시 설정할 수 없습니다.
                                        </div> : null
                                    }

                                </div>
                            })
                        }
                        <div>
                            <Card
                                className={data.isBoss ? 'select' : ''}
                                cover={<img draggable={false} style={{backgroundColor:'#2b2b2c'}} height={240} src='/img/wizard/shooting/EnemyBoss.png' />}
                            >
                                <Title level={5}>
                                    Boss
                                </Title>
                            </Card>
                            <div style={{height: '37px'}}></div>
                            <div style={{marginTop: '10px'}}>
                                <Text style={{textAlign:'left', marginRight: '10px', display: "inline-block", width: '80px', fontSize: '12px'}}>보스 체력</Text>
                                <Select disabled={!data.isBoss} style={{width: '100px'}} value={data.bossHp} onChange={function (value, option){
                                    data.bossHp = value;
                                    setData({...data});
                                }}>
                                    <Option key={0} value={20}>20</Option>
                                    <Option key={1} value={50}>50</Option>
                                    <Option key={2} value={100}>100</Option>
                                    <Option key={3} value={200}>200</Option>
                                </Select>
                            </div>
                            <div style={{marginTop: '10px'}}>
                                <Text style={{textAlign:'left', marginRight: '10px', display: "inline-block", width: '80px', fontSize: '12px'}}>보스 등장 시간</Text>
                                <Select disabled={!data.isBoss} style={{width: '100px'}} value={data.bossTime} onChange={function (value, option){
                                    data.bossTime = value;
                                    setData({...data});
                                }}>
                                    <Option key={0} value={0}>바로 등장</Option>
                                    <Option key={1} value={30}>30초</Option>
                                    <Option key={2} value={60}>60초</Option>
                                    <Option key={3} value={90}>1분 30초</Option>
                                </Select>
                            </div>
                            {
                                !data.isBoss ? <div className={'settingFooter'}>
                                    보스 모드일 때만 사용할 수 있습니다.
                                </div> : null
                            }
                        </div>
                    </Space>
                </WizardPage>
            </TabPane>
            <TabPane key={'3'}>
                <WizardPage title={'보스 패턴 설정'} description={'보스의 패턴을 설정합니다.'}
                            onNext={function (){
                                setCurrentStep(currentStep+1);
                            }}
                            onPrev={function (){
                                setCurrentStep(currentStep-1);
                            }}>
                    <Space style={{alignItems:'baseline', marginTop: '50px'}}>
                        {
                            data.bossPattens.map((pattern, index, array)=>{
                                return <div key={index}>
                                    <Card
                                        className={data.isBoss && pattern.active ? 'select' : ''}
                                        cover={<img draggable={false} style={{backgroundColor:'#2b2b2c'}} height={240} src={`/img/wizard/shooting/${pattern.name}.gif`} />}
                                        onClick={function (){
                                            if(!data.isBoss) {
                                                return;
                                            }
                                            pattern.active = !pattern.active;
                                            setData({...data});
                                        }}
                                    >
                                        <Title level={5}>
                                            {pattern.name}
                                        </Title>
                                    </Card>
                                    <div>
                                        <Switch checked={data.isBoss && pattern.active} className={'toggle'} disabled={!data.isBoss} onChange={function (checked, event){
                                            pattern.active = checked;
                                            setData({...data});
                                        }}></Switch>
                                    </div>
                                </div>
                            })
                        }
                    </Space>
                    {
                        !data.isBoss ?  <div className={'bottom'}>
                            보스 모드일 때만 사용할 수 있습니다.
                        </div> : null
                    }

                </WizardPage>
            </TabPane>
            <TabPane key={'4'}>
                <WizardPage title={'컨트롤 설정'} description={'게임 조작 방법을 설정합니다.'}
                            onNext={function (){
                                setCurrentStep(currentStep+1);
                            }}
                            onPrev={function (){
                                setCurrentStep(currentStep-1);
                            }}>
                    <Space>
                        <Card
                            className={data.playerAutoFire ? 'select' : ''}
                            hoverable
                            cover={<img draggable={false} height={320} src="/img/wizard/shooting/autoFire.gif" />}
                            onClick={function (){
                                data.playerAutoFire = true;
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                자동 발사
                            </Title>
                            <div style={{}}>
                                플레이어가 총알을 자동으로 발사하며, 화면을 클릭하여 드래그하면 마우스를 따라 플레이어가 이동합니다.
                            </div>
                        </Card>
                        <Card
                            hoverable
                            className={!data.playerAutoFire ?  'select' : ''}
                            cover={<img draggable={false} height={320} src="/img/wizard/shooting/autoFire2.gif" />}
                            onClick={function (){
                                data.playerAutoFire = false;
                                setData({...data});
                            }}
                        >
                            <Title level={5}>
                                클릭 발사
                            </Title>
                            <div style={{height:'88px'}}>
                                화면을 클릭하고 있는 동안만 플레이어가 총알을 발사하며, 마우스 포인트 방향을 따라 플레이어가 이동합니다.
                            </div>
                        </Card>
                    </Space>
                </WizardPage>
            </TabPane>
            <TabPane key={'5'}>
                <WizardPage title={'게임 설정 완료'} description={'게임을 만들기 위한 모든 설정이 완료되었습니다.'}
                            nextText={'완료'}
                            onNext={function (){

                                wizardSaveData.isData = true;
                                wizardSaveData.path = '/template/shooting.mogera';
                                wizardSaveData.onLoadCallback = function (game) {
                                    const scene = game.assetManager.getAsset('게임', 'Scene') as SceneAsset;
                                    if(scene) {
                                        const player = GoJsonHelper.findGameObjectByName(scene.scene, 'Player');
                                        const playerScript = GoJsonHelper.findScript(player, 'Player', game);
                                        playerScript.props.moveSpeed = data.playerSpeed;
                                        playerScript.props.maxHp = data.playerHp;
                                        playerScript.props.autoFire = data.playerAutoFire;
                                        playerScript.props.bulletSpeed = data.playerBulletSpeed;
                                        playerScript.props.bulletInterval = data.playerBulletDelay;

                                        const boss = GoJsonHelper.findGameObjectByName(scene.scene, 'Boss');
                                        boss.active = data.isBoss;
                                        const bossScript = GoJsonHelper.findScript(boss, 'Boss', game);
                                        bossScript.props.appearanceTime = data.bossTime;
                                        bossScript.props.maxHp = data.bossHp;

                                        for(let i = 0; i < data.enemies.length; i++) {
                                            const enemyData = data.enemies[i];
                                            //{ name: 'Enemy1', active: true, hp: 2, moveSpeed:200, bulletDelay: 1, bulletSpeed: 1000, followBullet: false },
                                            const enemy = GoJsonHelper.findGameObjectByPath( scene.scene, `World/Enemies/${enemyData.name}`);
                                            if(!enemy) continue;
                                            enemy.active = enemyData.active;
                                            const enemyScript = GoJsonHelper.findScript(enemy, 'Enemy', game);
                                            if(!enemyScript) continue;
                                            enemyScript.props.maxHp = enemyData.hp;
                                            enemyScript.props.moveSpeed = enemyData.moveSpeed;
                                            const enemyFireScript = GoJsonHelper.findScript(enemy, 'EnemyFire', game);
                                            if(!enemyFireScript) continue;
                                            enemyFireScript.props.bulletInterval = enemyData.bulletDelay;
                                            enemyFireScript.props.targetPlayer = enemyData.followBullet;
                                            enemyFireScript.props.bulletSpeed = enemyData.bulletSpeed;
                                        }

                                        for(let i = 0; i < data.bossPattens.length; i++) {
                                            const patternData = data.bossPattens[i];
                                            const pattern = GoJsonHelper.findGameObjectByPath( scene.scene, `World/Boss/${patternData.name}`);
                                            if(!pattern) continue;
                                            pattern.active = patternData.active;
                                        }

                                        
                                    }
                                }

                                navigate("/" );
                            }}
                            onPrev={function (){
                                setCurrentStep(currentStep-1);
                            }}>
                    <Space direction={'vertical'} style={{marginTop:'80px'}}>
                        <Text>완료 버튼을 누르면 에디터가 실행됩니다.</Text>
                        <Text>더 자세한 내용은 아래 notion 링크를 통해 확인하세요.</Text>
                        <div style={{marginTop:'40px'}}>
                            <a href="https://abaft-restaurant-3dc.notion.site/67fc74a80005435ab03ebd38839f5cd6" target="_blank">
                                <div>
                                    <BiBook style={{fontSize: '120px', lineHeight: '100px'}}/>
                                </div>
                                <div style={{marginTop: '40px'}}>
                                    템플릿 에디터 가이드 보러가기
                                </div>
                            </a>
                        </div>
                    </Space>
                </WizardPage>
            </TabPane>
        </Tabs>
    </div>
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {RootState} from "../app/store";
import EditorManager from "../services/editorManager";


export interface EditorState {
    projectName: string,
    currentScene: { id: string },   // asset id
    cameraWidth: number,
    cameraHeight: number,
    prefabTagList: {tag:string, color:string, hide: boolean}[],

    textureTagList: string[],
    soundTagList: string[],

    selectTool: string,

    spinText: string,
    selectTabId: string,
    grid: {x:number,y:number},
    fixedGridMove: boolean,
    zoomScale: number,
    openPlayConsole: boolean,
    selectPrefabIds: string[],

    cdnList: string,
}


const initialState: EditorState = {
    projectName: 'mogeraGame',
    currentScene: {id:''},
    cameraWidth: 400,
    cameraHeight: 300,
    prefabTagList: [{tag:'', color:'#757575', hide: false}],
    textureTagList: [],
    soundTagList: [],

    selectTool: 'renderer',

    spinText: '',
    selectTabId: '',
    grid: {x:50, y:50},
    fixedGridMove: false,
    zoomScale: 1,
    openPlayConsole: false,
    selectPrefabIds: [],

    cdnList: '',
};

export const selectProjectName = (state: RootState) => state.editor.projectName;
export const selectCurrentScene = (state: RootState) => state.editor.currentScene;
export const selectPrefabTagList = (state: RootState) => state.editor.prefabTagList;
export const selectTextureTagList = (state: RootState) => state.editor.textureTagList;
export const selectSoundTagList = (state: RootState) => state.editor.soundTagList;

export const selectSelectTool = (state: RootState) => state.editor.selectTool;

export const selectSpinText = (state: RootState) => state.editor.spinText;
export const selectSelectTabId = (state: RootState) => state.editor.selectTabId;
export const selectGrid = (state: RootState) => state.editor.grid;
export const selectFixedGridMove = (state: RootState) => state.editor.fixedGridMove;
export const selectZoomScale = (state: RootState) => state.editor.zoomScale;
export const selectCameraWidth = (state: RootState) => state.editor.cameraWidth;
export const selectCameraHeight = (state: RootState) => state.editor.cameraHeight;
export const selectOpenPlayConsole = (state: RootState) => state.editor.openPlayConsole;
export const selectSelectPrefabIds = (state: RootState) => state.editor.selectPrefabIds;

export const selectCDNList = (state: RootState) => state.editor.cdnList;

export const editorSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        changeProjectName: (state, action: PayloadAction<string>) => {
            state.projectName = action.payload;
        },
        changeCurrentScene: (state, action: PayloadAction<{ id: string}>) => {
            state.currentScene = action.payload;
        },
        changeCameraWidth: (state, action: PayloadAction<number>) => {
            state.cameraWidth = action.payload;
        },
        changeCameraHeight: (state, action: PayloadAction<number>) => {
            state.cameraHeight = action.payload;
        },
        changePrefabTagList: (state, action: PayloadAction<{tag:string, color:string, hide:boolean}[]>) => {
            state.prefabTagList = action.payload;
        },
        changeTextureTagList: (state, action: PayloadAction<string[]>) => {
            state.textureTagList = action.payload;
        },
        changeSoundTagList: (state, action: PayloadAction<string[]>) => {
            state.soundTagList = action.payload;
        },

        changeSelectTool: (state, action: PayloadAction<string>) => {
            state.selectTool = action.payload;
        },

        changeSpinText: (state, action: PayloadAction<string>) => {
            state.spinText = action.payload;
        },
        changeSelectTabId: (state, action: PayloadAction<string>) => {
            state.selectTabId = action.payload;
        },
        changeGrid: (state, action: PayloadAction<{ x:number,y:number }>) => {
            state.grid = action.payload;
        },
        changeFixedGridMove: (state, action: PayloadAction< boolean>) => {
            state.fixedGridMove = action.payload;
        },
        changeZoomScale: (state, action: PayloadAction<number>) => {
            state.zoomScale = action.payload;
        },
        changeOpenPlayConsole: (state, action: PayloadAction<boolean>) => {
            state.openPlayConsole = action.payload;
        },
        changeSelectPrefabIds: (state, action: PayloadAction<string[]>) => {
            state.selectPrefabIds = action.payload;
        },

        changeCDNList: (state, action: PayloadAction<string>) => {
            state.cdnList = action.payload;
        },
    },
});

export const {
    changeProjectName,
    changeCurrentScene,
    changeCameraWidth,
    changeCameraHeight,
    changePrefabTagList,
    changeTextureTagList,
    changeSoundTagList,
    changeSelectTool,
    changeSpinText,
    changeSelectTabId,
    changeGrid,
    changeFixedGridMove,
    changeZoomScale,
    changeOpenPlayConsole,
    changeSelectPrefabIds,
    changeCDNList,
} = editorSlice.actions;

export default editorSlice.reducer;
import BaseFunction, {ComponentFunctionError} from "../baseFunction";
import Sprite from "../../component/sprite";
import * as PIXI from 'pixi.js';
import TextureAsset from "../../asset/textureAsset";
import {scriptTableData} from "../scriptTable";
import ScriptError, {CheckComp, CheckValueType} from "../scriptError";
import {Util} from "../../util/util";
import ComponentFunction from "./componentFunction";
import TilingSprite from "../../component/tilingSprite";

export const TilingSpriteFunctionTable: scriptTableData[] = [
    {
        value: `tilingSprite.width`,
        caption: `tilingSprite.width`,
        score: 10,
        meta: 'getter,setter',
        name: 'tilingSprite.width',
        description: {
            ko: 'TilingSprite 의 넓이를 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `tilingSprite.height`,
        caption: `tilingSprite.height`,
        score: 10,
        meta: 'getter,setter',
        name: 'tilingSprite.height',
        description: {
            ko: 'TilingSprite 의 높이를 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `tilingSprite.tint`,
        caption: `tilingSprite.tint`,
        score: 10,
        meta: 'getter,setter',
        name: 'tilingSprite.tint',
        description: {
            ko: 'TilingSprite 의 틴트색상를 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `tilingSprite.alpha`,
        caption: `tilingSprite.alpha`,
        score: 10,
        meta: 'getter,setter',
        name: 'tilingSprite.alpha',
        description: {
            ko: 'TilingSprite 의 투명도를 가져오거나 설정합니다.',
            en: '',
        },
    },
    {
        value: `tilingSprite.tileScale`,
        caption: `tilingSprite.tileScale`,
        score: 10,
        meta: 'getter,setter',
        name: 'sprite.tileScale',
        description: {
            ko: 'TilingSprite 의 타일 스케일 정보를 가져옵니다.',
            en: '',
        },
    },
    {
        value: `tilingSprite.tilePosition`,
        caption: `tilingSprite.tilePosition`,
        score: 10,
        meta: 'getter,setter',
        name: 'sprite.tilePosition',
        description: {
            ko: 'TilingSprite 의 타일 오프셋 위치 정보를 가져옵니다.',
            en: '',
        },
    },
    {
        value: `tilingSprite.anchor`,
        caption: `tilingSprite.anchor`,
        score: 10,
        meta: 'getter',
        name: 'tilingSprite.anchor',
        description: {
            ko: 'TilingSprite 의 앵커 정보를 가져옵니다.',
            en: '',
        },
    },
    {
        value: `tilingSprite.texture`,
        caption: `tilingSprite.texture`,
        score: 10,
        meta: 'getter,setter',
        name: 'tilingSprite.texture',
        description: {
            ko: 'TilingSprite 의 텍스처를 가져오거나 설정합니다.',
            en: '',
        },
    },
];

export default class TilingSpriteFunction extends ComponentFunction {
    protected _name: string = 'TilingSprite';
    protected _temp: TilingSprite = null;
    public get instance(): TilingSpriteFunction {
        this._temp = this.gameObject.getComponent(this._name);
        return this;
    }

    destroy() {
        super.destroy();
        this._temp = null;
    }

    public get width(): number {
        if(!CheckComp(this,'width')) return;
        return this._temp.width;
    }
    public get height(): number {
        if(!CheckComp(this,'height')) return;
        return this._temp.height;
    }
    public set width(v:number) {
        if(!CheckComp(this,'width')) return;
        if(!CheckValueType(this, v, 'number', 'width')) return;
        this._temp.width = v;
    }
    public set height(v:number) {
        if(!CheckComp(this,'height')) return;
        if(!CheckValueType(this, v, 'number', 'height')) return;
        this._temp.height = v;
    }

    public get tint(): number {
        if(!CheckComp(this,'tint')) return;
        return this._temp.tint;
    }
    public set tint(v:number) {
        if(!CheckComp(this,'tint')) return;
        if(!CheckValueType(this, v, 'number', 'tint')) return;
        this._temp.tint = v;
    }

    public get alpha(): number {
        if(!CheckComp(this,'alpha')) return;
        return this._temp.alpha;
    }
    public set alpha(v:number) {
        if(!CheckComp(this,'alpha')) return;
        if(!CheckValueType(this, v, 'number', 'alpha')) return;
        this._temp.alpha = v;
    }
    public get anchor(): PIXI.Point {
        if(!CheckComp(this,'anchor')) return;
        return this._temp.anchor;
    }
    public get tileScale(): {x:number, y:number} {
        if(!CheckComp(this,'tileScale')) return;
        return this._temp.tileScale;
    }
    public set tileScale(scale: {x:number, y:number}) {
        if(!CheckComp(this,'tileScale')) return;
        this._temp.tileScale = scale;
    }
    public get tilePosition(): {x:number, y:number} {
        if(!CheckComp(this,'tilePosition')) return;
        return this._temp.tilePosition;
    }
    public set tilePosition(pos: {x:number, y:number}) {
        if(!CheckComp(this,'tilePosition')) return;
        this._temp.tilePosition = pos;
    }
    public get texture(): TextureAsset {
        if(!CheckComp(this,'texture')) return;
        return this._temp.texture;
    }
    public set texture(v: TextureAsset) {
        if(!CheckComp(this,'texture')) return;
        if(!CheckValueType(this, v, 'Texture', 'texture')) return;
        this._temp.texture = v;
    }

}
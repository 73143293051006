import GameObject from "../gameObject/gameObject";
import Component from "./component";


export default class InstanceManager {
    private _instances : {[id:string]:GameObject|Component} = {};

    public addInstance(instance : GameObject | Component) {
        this._instances[instance.id] = instance;
    }

    public removeInstance(instance: GameObject | Component) {
        delete this._instances[instance.id];
    }

    public getInstance(id: number) {
        return this._instances[id];
    }

    public destroy() {
        this._instances = {};
    }
}
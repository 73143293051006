import * as PIXI from 'pixi.js';
import Asset, {IAssetData} from "./asset";
import Game from "../core/game";

export interface ITextureAssetData extends IAssetData {
    dataUrl : string,
}

export default class TextureAsset extends Asset {
    private _resource: PIXI.LoaderResource;
    protected readonly _type : string = 'Texture';

    constructor(game: Game, loaderResource: PIXI.LoaderResource, uid?: string) {
        super(game, uid || loaderResource?.name);
        this._resource = loaderResource;
    }

    get resource(): PIXI.LoaderResource {
        return this._resource;
    }

    get texture(): PIXI.Texture {
        return this.resource.texture;
    }


    destroy() {
        const resources = this.game.loader.resources;
        super.destroy();

        this._resource.texture.destroy(true);
        delete resources[this.id];
        this._resource = null;
    }

    toPlayData() : ITextureAssetData {
        const json = super.toPlayData() as ITextureAssetData;
        json.dataUrl = this.resource.url;
        return json;
    }
}
import {Button, Checkbox, Spin} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {useAppSelector} from "../../app/hooks";
import {changeOpenPlayConsole, selectOpenPlayConsole, selectSpinText} from "../../store/editorSlice";
import { LazyLog, ScrollFollow } from 'react-lazylog';
import LinePart from 'react-lazylog/build/LinePart';
import {useDispatch} from "react-redux";
import {store} from "../../app/store";

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: any, value: any) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};


export default function PlayConsole() {
    const isOpen = useAppSelector(selectOpenPlayConsole);

    const dispatch = useDispatch();
    let logStack : string[] = [];
    let time = 10;
    let logs = '\n';
    const [log, setText] = useState(logs);
    let timer : number = 0;
    const [scrollFollow, setScrollFollow] = useState(true);


    function onMessage(data : {type: string, data?: any}) {
        switch (data.type) {
            case 'close':
                // window.bridgeMainToPlay = null;
                // window.playWindow = null;
                dispatch(changeOpenPlayConsole(false));
                logs = '\n';
                setText(logs);
                break;
            case 'open':
            case 'create':
            case 'load resource':
            case 'start game':
            case 'log':
            default:
                if(data.type === 'create' ) {
                    dispatch(changeOpenPlayConsole(true));
                }
                else if(!store.getState().editor.openPlayConsole) {
                    break;
                }

                // logs += '\n' + JSON.stringify(data);
                logStack.push(JSON.stringify(data, getCircularReplacer()));
                if(!timer) {
                    timer = window.setTimeout(function (){
                        time = logStack.length * 10;
                        if(time >= 500) {
                            time = 500;
                        }
                        for(let i = 0; i < logStack.length; i++) {
                            logs += '\n' + logStack[i];
                        }
                        logStack.length = 0;
                        setText(logs);
                        timer = 0;
                    }, time);
                }
                break;
        }
    }

    useEffect(function (){
        window.bridgeMessage = onMessage;
        return function (){
            window.bridgeMessage = null;
        }
    }, [])

    function formatPart(text: string) {
        if(!text || text === '\n') {
            return text;
        }
        const {type, data} = JSON.parse(text);


        switch (type)
        {
            case 'log': {
                const gameObject = data.gameObject;
                const msg = data.message;
                const func = data.func;
                const script = data.script;
                return <>
                    <div style={{
                        display: 'inline-flex',
                        width: 'calc(100% - 85px)'
                    }}>
                        <span style={{color:'#52c41a'}}>[LOG] </span>
                        {

                            msg === null?
                                <span style={{color:'#7d7d7d'}}>null</span> :
                                msg === undefined ?
                                    <span style={{color:'#7d7d7d'}}>undefined</span> :
                                    typeof msg === 'number' ?
                                        <span style={{color:'#848da7'}}>{msg}</span> :
                                        typeof msg === 'string' ?
                                            <span style={{color:'#ffebdc'}}>'{msg}'</span> :
                                            typeof msg === 'boolean' ?
                                                <span style={{color:'#b3b3b3'}}>{JSON.stringify(msg)}</span> :
                                                <span>{JSON.stringify(msg)}</span>

                        }
                        <span style={{display: 'inline-block', flexGrow:'1'}}></span>
                        <span style={{color:'#096dd9'}}>[{gameObject}]</span>
                        {script?<span style={{color: '#075dbb'}}>[{script}]</span>: null}
                        {func?<span style={{color: '#0f5aac'}}>[{func}]</span> : null}
                        <span> </span>
                    </div>
                </>
            }
            case 'error': {
                const gameObject = data.gameObject;
                const msg = data.message;
                const func = data.func;
                const script = data.script;
                const row = data.row;
                const column = data.column;

                const rowEl = row? <span style={{color:'#35a9ea'}}>{row}번째줄</span> : null;

                return <>
                    <div style={{
                        display: 'inline-flex',
                        width: 'calc(100% - 85px)'
                    }}>
                        <span style={{color:'#ff4d4f'}}>[ERROR] </span>
                        {
                            msg === null?
                                <span style={{color:'#7d7d7d'}}>null</span> :
                                msg === undefined ?
                                    <span style={{color:'#7d7d7d'}}>undefined</span> :
                                    typeof msg === 'number' ?
                                        <span style={{color:'#848da7'}}>{msg}</span> :
                                        typeof msg === 'string' ?
                                            <span style={{color:'#ffebdc'}}>'{msg}'</span> :
                                            typeof msg === 'boolean' ?
                                                <span style={{color:'#b3b3b3'}}>{JSON.stringify(msg)}</span> :
                                                <span>{JSON.stringify(msg)}</span>
                        }
                        <span style={{display: 'inline-block', flexGrow:'1'}}></span>
                        <span style={{color:'#096dd9'}}>[{gameObject}]</span>
                        {script?<span style={{color: '#075dbb'}}>[{script}:{rowEl}]</span>: null}
                        {func?<span style={{color: '#0f5aac'}}>[{func}]</span> : null}
                        <span> </span>
                    </div>
                </>
            }
            case 'create':{
                return <>
                    <span>{type}</span>
                </>
            }
            case 'load resource':{
                return <>
                    <span>{type}</span>
                </>
            }
            case 'start game':{
                return <>
                    <span>{type}</span>
                </>
            }
            default: {
                return <>
                    <span>{type}</span>
                </>
            }
        }
    }

    return <div className={'playConsoleRoot'} style={{display: !!isOpen ? '' : 'none'}}>
        <div className={'bg'}>
            <div style={{ height: '80%', width: '80%', maxWidth: '1200px', maxHeight: '900px', margin: "auto", paddingTop: '10%', textAlign: 'left' }}>
                <h4>Play Log Console</h4>
                <Checkbox checked={scrollFollow} onChange={function (e){
                    setScrollFollow(e.target.checked);
                }}>자동 스크롤</Checkbox>
                <ScrollFollow
                    startFollowing={true}
                    render={({ follow, onScroll }) => (
                        <LazyLog
                            style={{
                                fontSize: '18px',
                            }}
                            rowHeight={24}
                            lineClassName={'lineStyle'}
                            follow={scrollFollow ? follow : false}
                            //@ts-ignore
                            onScroll={onScroll}
                            extraLines={1}
                            enableSearch
                            caseInsensitive
                            text={log}

                            formatPart={formatPart}
                        />
                    )}/>
            </div>
            <Button style={{marginTop: '5%'}} type="primary" onClick={function (){
                window.playWindow?.close();
                dispatch(changeOpenPlayConsole(false));
            }}>종료</Button>
        </div>
    </div>
}
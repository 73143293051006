import {isReadOnly} from "./isView";
import {Button, Dropdown, Menu, Row} from "antd";
import EditorManager from "../../../services/editorManager";
import {IComponentConstructor} from "../../../game/scripts/core/component";
import {ItemType} from "antd/lib/menu/hooks/useItems";
import ScriptAsset from "../../../game/scripts/asset/scriptAsset";
import {DownOutlined} from "@ant-design/icons";
import {EEditable} from "../../../game/scripts/gameObject/gameObject";
import {EEditorViewOption} from "../../../store/editorSettingSlice";
import {renderTypeComps} from "../../../game/scripts/tools/renderTypeComps";
import LocalizationManager from "../../../services/localizationManager";

export default function AddComponentButton({name, disableOptions, editorViewOption}:
    {name:string, disableOptions : {[key:string]: EEditable }, editorViewOption: EEditorViewOption }) {

    const compItems : any = [{
        key: '1',
        label: LocalizationManager.Instance.getText(`Component.Render`) || 'Render',
        children: [],
    }, {
            type: 'divider',
    }, {
        key: '2',
        label: LocalizationManager.Instance.getText(`Component.Script`) || 'Script',
        children: [
            {
                key: '2_1_newScript',
                label: <div
                    style={{minWidth: '200px'}} onClick={function (){
                    const scriptName = prompt(LocalizationManager.Instance.getText('Popup.inputScriptName') , name);
                    if(!scriptName) return;
                    const comp = EditorManager.Instance.game.components.getComponent(scriptName);
                    if(comp) {
                        alert(LocalizationManager.Instance.getText('Popup.failAddScript'))
                        return;
                    }

                    const scripts = EditorManager.Instance.game.assetManager.getScriptAssetList();
                    for(let i = 0; i < scripts.length; i++) {
                        if(scripts[i].name === scriptName) {
                            alert(LocalizationManager.Instance.getText('Popup.failAddScript2'))
                            return;
                        }
                    }

                    const scriptAsset = new ScriptAsset(EditorManager.Instance.game, {
                        code: '',
                        props: [],
                    });
                    scriptAsset.rename(scriptName);
                    EditorManager.Instance.game.assetManager.addAsset(scriptAsset)
                    EditorManager.Instance.addScriptComponent( scriptName );
                }}
                >
                    {LocalizationManager.Instance.getText('Component.newScript') || 'New Script'}
                </div>,
            }
        ],
    },{
        type: 'divider',
    }];
    //const renderComps = ['Sprite', 'Shape', 'Text', 'NineSlice'];

    const components =  EditorManager.Instance.game.components.components;
    for(let k in components) {
        const comp = components[k] as IComponentConstructor;
        if(comp.IsAddList && comp.Name !== 'Script' && renderTypeComps.indexOf( comp.Name ) > -1 ) {
            compItems[0].children.push( {key: k, label: (<div style={{minWidth: '100px'}} onClick={function (event){
                    EditorManager.Instance.addComponent(comp);
            }}>{
                LocalizationManager.Instance.getText(`Component.${comp.Name}`) || comp.Name
            }</div>)});
        }
        else if( comp.IsAddList && comp.Name === 'Script' ) {

        }
        else if(comp.IsAddList) {
            compItems.push( {key: k, label: (<div style={{minWidth: '100px'}} onClick={function (event){
                EditorManager.Instance.addComponent(comp);
            }}>{
                    LocalizationManager.Instance.getText(`Component.${comp.Name}`) || comp.Name
            }</div>)});
        }
    }

    const scripts = EditorManager.Instance.game.assetManager.getScriptAssetList();
    for(let i = 0; i < scripts.length; i++) {
        const script = scripts[i];
        compItems[2].children.push({
            key: script.id,
            label: (<div style={{minWidth: '200px'}} onClick={function (event){
                EditorManager.Instance.addScriptComponent(script.name);
            }}>{script.name}</div>)
        });
    }

    const compMenu = (<Menu onClick={function (info){
    }}
        items={compItems}
    >
    </Menu>);

    let addComp = null;

    if( !isReadOnly(disableOptions, 'component', editorViewOption) ){
        addComp = <Row justify={"center"}>
            <Dropdown trigger={['click']}  overlay={compMenu} placement="bottomLeft">
                <Button>{LocalizationManager.Instance.getText('Component.add')|| 'Add Component'}</Button>
            </Dropdown>
        </Row>
    }

    return <>
        {addComp}
    </>
}
import {NodeRendererProps} from "react-arborist/dist/types";
import classNames from "classnames";
import React, {FocusEvent, KeyboardEvent} from "react";
import { NodeHandlers } from "react-arborist";
import {
    FolderOutlined,
    FileTextOutlined,
    DownOutlined,
    RightOutlined,
    VideoCameraTwoTone,
    ReconciliationTwoTone ,
    RocketTwoTone,
    RocketFilled,
    EyeFilled,
    EyeInvisibleFilled,
    EyeInvisibleOutlined,
    EditOutlined,
    EditTwoTone,
    EditFilled,
    LinkOutlined,
    LockFilled,
    UnlockOutlined,
    LineOutlined,

} from '@ant-design/icons';
import { BsRecordFill } from "react-icons/bs";

import {HierarchyData} from "../../../store/hierarchySlice";
import {treeManager} from "./hierarchy";
import {useAppSelector} from "../../../app/hooks";
import {selectSelectTabId} from "../../../store/editorSlice";
import {Button, Dropdown, Menu} from "antd";
import EditorManager from "../../../services/editorManager";
import HierarchyContextMenu from "./hierarchyContextMenu";
import PrefabAsset from "mogera-game/scripts/asset/prefabAsset";

const size = 16;
const color = "#999";

function MaybeToggleButton(props: any) {
    const { toggle, isOpen, isFolder, isSelected, children } = props;

    if (children?.length) {
        const Icon = isOpen ? DownOutlined : RightOutlined;
        return (
            <button tabIndex={-1} onClick={toggle}>
                <Icon size={12}
                      // stroke={isSelected ? "white" : color}
                />
            </button>
        );
    } else {
        return <div className="spacer" />;
    }
}

function Icon({ isFolder, isSelected, name }: any) {
    if (name === 'World' || name === 'world') {
        return (
            <RocketTwoTone
                // stroke={isSelected ? "white" : "cornflowerblue"}
                // fillOpacity="0.5"
                // fill={isSelected ? "white" : "cornflowerblue"}
                size={size}
            />
        );
    }
    else if (name === 'Camera' || name === 'camera') {
        return (
            <VideoCameraTwoTone
                // stroke={isSelected ? "white" : "cornflowerblue"}
                // fillOpacity="0.5"
                // fill={isSelected ? "white" : "cornflowerblue"}
                size={size}
            />
        );
    }
    else if (name === 'UI' || name === 'fixed') {
        return (
            <ReconciliationTwoTone
                // stroke={isSelected ? "white" : "cornflowerblue"}
                // fillOpacity="0.5"
                // fill={isSelected ? "white" : "cornflowerblue"}
                size={size}
            />
        );
    }
    else {
        return null;
    }
}




export default function HierarchyNode (props:NodeRendererProps<HierarchyData>){

    const {innerRef,
        data,
        styles,
        state,
        handlers,
        tree,} = props;
    const folder = Array.isArray(data.children);
    const open = state.isOpen;
    const name = data.name;
    const selectTabId = useAppSelector(selectSelectTabId);
    const selectClass = selectTabId === 'Hierarchy' ? 'selectTab' : '';
    const prefab = data.prefabId ?
        (EditorManager.Instance.assetManager.getAsset(data.prefabId) as PrefabAsset || null)
        : null;

    if(state.isDragging) {
        treeManager.dragId = data.id;
    }
    treeManager.map[data.id] = state;

    const treeWidth = props.tree.props.width;
    const leftPadding = Number(styles.indent.paddingLeft.toString().replace('px', ''));
    const width = treeWidth - leftPadding - (24 + 4) - 55;
    //treeWidth - leftPadding - (마진 + 보더) - 리네임아이콘

    return (
        <div
            ref={innerRef}
            style={{
                ...styles.row,
                width: '100%',
            }}

            className={classNames("row", state, selectClass)}
            onLoad={(el)=>{

            }}

            onDoubleClick={function (e){
                EditorManager.Instance.centerSelectObject();
            }}
            onClick={(e) =>{
                e.metaKey = e.ctrlKey;

                //@ts-ignore
                handlers.select(e,
                    // {selectOnClick: !data.state.isSelected}
                );
            }} onContextMenu={(e)=>{
                e.metaKey = e.ctrlKey;

                //@ts-ignore
                handlers.select(e,
                    // {selectOnClick: !data.state.isSelected}
                );
            }}
        >
            <div className="row-contents" style={styles.indent}>
                <MaybeToggleButton
                    children={data.children}
                    toggle={handlers.toggle}
                    isOpen={open}
                    isFolder={folder}
                    isSelected={state.isSelected}
                />
                <div style={{marginRight:'5px'}}>
                    <Icon name={name} isFolder={folder} isSelected={state.isSelected} />
                </div>

                <div style={{flexGrow: 1}}>
                    {state.isEditing ? (
                        <RenameForm defaultValue={name} {...handlers} />
                    ) : (
                        <div style={{
                            display: 'flex',
                        }}>
                            <div style={{
                                display: 'inline-block',
                                flexDirection: 'column',
                                textOverflow: 'ellipsis',
                                overflow: "hidden",
                                maxWidth:`${width}px`,
                                color: data.active? '#ffffff': '#959595',
                            }}>
                                {name}
                                {
                                    prefab ?  <span style={{
                                        color: prefab.color,
                                    }}> ■</span> : null
                                }

                            </div>{" "}
                            {state.isSelected && data.editName && (
                                <button style={{ display: "inline" }} onClick={handlers.edit}>
                                    <EditFilled />
                                </button>)}
                    </div>
                    )}
                </div>

                <div style={{
                    display: 'flex'
                }}>
                    {
                        data.editActive? <>
                                {
                                    data.active ?
                                        <Button style={{marginRight:'8px'}} onClick={function (e) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            EditorManager.Instance.modifyGameObjectActive(data.id, false);
                                        }}><EyeFilled></EyeFilled></Button> :
                                        <Button style={{marginRight:'8px'}} onClick={function (e) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            EditorManager.Instance.modifyGameObjectActive(data.id, true);
                                        }}><EyeInvisibleOutlined style={{color: '#626262',}}></EyeInvisibleOutlined></Button>
                                }
                                {
                                    data.isLock ?
                                        <Button onClick={function (e) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            EditorManager.Instance.modifyGameObjectLocked(data.id, false);
                                        }}><LockFilled></LockFilled></Button> :
                                        <Button onClick={function (e) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            EditorManager.Instance.modifyGameObjectLocked(data.id, true);
                                        }}><BsRecordFill style={{ color: '#626262', fontSize:'8px', minWidth: '14px'}}></BsRecordFill></Button>
                                }</> : null
                    }
                </div>
            </div>
        </div>
    );
}

type FormProps = { defaultValue: string } & NodeHandlers;
function RenameForm({ defaultValue, submit, reset }: FormProps) {
    const inputProps = {
        defaultValue,
        autoFocus: true,
        onFocus:(e: FocusEvent<HTMLInputElement>)=>{
              e.currentTarget.select();
        },
        onBlur: (e: FocusEvent<HTMLInputElement>) => {
            submit(e.currentTarget.value);
        },
        onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => {
            switch (e.key) {
                case "Enter":
                    submit(e.currentTarget.value);
                    break;
                case "Escape":
                    reset();
                    break;
            }
        },
    };

    return <input style={{
        color:'white',
        width:'100%',
        backgroundColor: 'rgba(0,0,0,0)',
        display: "inline-block",
        border: '1px solid #bbbbbb'
    }} type="text" {...inputProps} />;
}

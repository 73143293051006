import {store} from "../app/store";
import JSZip from "jszip";
import * as uuid from 'uuid';
import Game from "../game/scripts/core/game";
import GameObject, {editable, EEditable, IGameObjectJson, SetGlobalPoint} from "../game/scripts/gameObject/gameObject";
import {changeHierarchyList, changeHierarchySelectIds, HierarchyData} from "../store/hierarchySlice";
import Component, {IComponentConstructor, IComponentJson} from "../game/scripts/core/component";
import Scene, {ISceneJson} from "../game/scripts/gameObject/scene";
import {
    changeOnClickAsset,
    changeOnSelectAsset,
    changeOpenAnimatorList,
    changeOpenImageList, changeOpenSceneList, changeOpenSoundList, changeSelectAssetId,
    changeOpenFontList,
} from "../store/modalSlice";
import {notification} from 'antd';
import {editorSettingSlice, EEditorViewOption} from "../store/editorSettingSlice";
import {generateHierarchy} from "./generateHierarchy";
import {saveAs} from "file-saver";
import Command from "./command/command";
import AddGameObjectCommand from "./command/addGameObjectCommand";
import RemoveGameObjectCommand from "./command/removeGameObjectCommand";
import ModifyGameObjectCommand from "./command/modifyGameObjectCommand";
import ActionCommand from "./command/actionCommand";
import ModifyComponentCommand from "./command/modifyComponentCommand";
import TextureAsset, {ITextureAssetData} from "../game/scripts/asset/textureAsset";
import SoundAsset, {ISoundAssetData} from "../game/scripts/asset/soundAsset";
import AddComponentCommand from "./command/addComponentCommand";
import SceneAsset, {ISceneAssetData} from "../game/scripts/asset/sceneAsset";
import {
    changeProjectName,
    changeCameraHeight,
    changeCameraWidth,
    changeCurrentScene, changeGrid, changeOpenPlayConsole,
    changePrefabTagList,
    changeSpinText,
    changeTextureTagList, changeSoundTagList, selectSelectPrefabIds,
    changeSelectPrefabIds, changeFixedGridMove, changeZoomScale, changeCDNList,
} from "../store/editorSlice";
import {OnHierarchyTree, treeManager} from "../components/panel/hierarchy/hierarchy";
import MoveHierarchyCommand from "./command/moveHierarchyCommand";
import Camera, {ICameraJson} from "../game/scripts/component/camera";
import EditorScene, {ECtrlMode} from "../game/scripts/tools/editor/editorScene";
import AssetManager from "../game/scripts/asset/assetManager";
import {initEditorScene} from "./initEditorScene";
import RemoveComponentCommand from "./command/removeComponentCommand";
import ScriptAsset from "../game/scripts/asset/scriptAsset";
import Script, {IScriptJson} from "../game/scripts/component/script";
import AnimatorAsset from "../game/scripts/asset/animatorAsset";
import PrefabAsset from "../game/scripts/asset/prefabAsset";
import {changeRefreshPrefabView} from "../store/refeshSlice";
import {renderTypeComps} from "../game/scripts/tools/renderTypeComps";
import FontAsset, {IFontAssetData} from "../game/scripts/asset/fontAsset";
import FunctionManager from "mogera-game/scripts/function/functionManager";
import {getPrefabImg} from "../components/panel/prefabView/tagListView";
import MoveComponent from "./command/moveComponent";
import {prefabSelector} from "../components/panel/prefabView/prefabView";
import wizardSaveData from "./wizard/wizardSave";
import GoJsonHelper from "./wizard/goJsonHelper";
import LocalizationManager from "./localizationManager";
import {blobToFile, zipToFiles} from "../utils/util";

declare global {
    interface Window {
        bridgeMainToPlay: ()=>{
            textures: ITextureAssetData[],
            sounds: ISoundAssetData[],
            scenes: ISceneAssetData[],
            scripts: IScriptJson,
            animators: [],
            prefabs: [],
            fonts: [],
            projectSetting: {},
            gameSetting: {},
        };
        bridgeMessage: (message: {type:string, data:any})=>void,
        playWindow: Window
    }
}

export interface IProjectSettingJson {
    projectName: string,
    currentSceneAssetId : string;
    prefabTagList?: { tag:string, color:string, hide: boolean }[],
    textureTagList?: string[],
    soundTagList?: string[],
    grid?: {x:number,y:number},
    fixedGrid?: boolean,
    zoomScale?: number,
    width?: number,
    height?: number,
    cdnList: string,
}

export default class EditorManager {

    private static _instance : EditorManager = null;
    public static get Instance() {
        if(!EditorManager._instance) {
            EditorManager._instance = new EditorManager();
        }

        return EditorManager._instance;
    }

    private readonly _game : Game;
    public get game() {
        return this._game;
    }

    public get assetManager() : AssetManager {
        return this.game.assetManager;
    }

    public get scene() :EditorScene{
        const scene = this._game.scenes.crtScene;
        return scene as EditorScene;
    }

    private _hierarchyList: HierarchyData;
    public set hierarchyList(list: HierarchyData) {
        this._hierarchyList = list;
    }

    private _selectIds: string[];
    private _changePrefabList: string[] = [];

    private _editViewOption: EEditorViewOption = EEditorViewOption.advanced;
    public set editViewOption(option: EEditorViewOption) {
        this._editViewOption = option;
    }

    private commandMax : number = 50;
    private commands : Command[][] = [];

    private copy: {
        type: string,
        data: IGameObjectJson | IComponentJson,
        isOnce: boolean,
    } = {
        type :'',
        data: null,
        isOnce: false,
    }


    constructor() {
        console.log('create command Manager');
        this._game = new Game({
            backgroundColor: 0x313030,
        });
        this.game.scenes.stopScene();

        this._editViewOption = editorSettingSlice.getInitialState().editorViewOption;
    }



    createHierarchy( callback : (...args: any[]) => void = null ) {
        const root = this.game.scenes.crtScene;
        const rootData : HierarchyData = {
            id: root.id.toString(),
            // key: '0',
            name: root.name,
            isOpen: true,
            isLock: false,
            children: [],
            active: true,
            editName: false,
            editActive: false,
        };

        generateHierarchy(root.children as GameObject[], rootData.children, store.getState().editorSetting.editorViewOption);
        store.dispatch(changeHierarchyList(rootData));
        callback && OnHierarchyTree.once( 'init', callback );

    }


    onSelect(ids: string[]) {
        if(!this.scene) {
            return;
        }

        if(!ids || !this._selectIds || this._selectIds.length !== ids.length) {
            this.onChangeSelectIds();
        }
        else {
            for(let i = 0; i < this._selectIds.length; i++) {
                if(this._selectIds[i] !== ids[i]) {
                    this.onChangeSelectIds();
                    break;
                }
            }
        }


        this._selectIds = ids;
        if(ids.length) {
            const gameObjects = [];
            for(let i = 0; i < ids.length; i++) {
                const target = this.getSelectGameObject([ids[i]]) as GameObject;
                if(target) {
                    gameObjects.push(target);
                }
            }

            if(gameObjects.length === 1) {
                this.scene?.setSelect( [gameObjects[0]] );
            }
            else {
                this.scene?.setSelect( null );
            }


        }
        else {
            this.scene?.setSelect( null );
        }
    }

    onChangeSelectIds() {
        // console.log('onChangeSelectIds');
    }

    addGameObject( x: number = undefined, y: number = undefined, isWorld:boolean = false) : GameObject{
        let parent = null;
        let centerPos : {x:number, y: number};

        if(!isWorld) {
            parent = this.getSelectGameObject() as GameObject;
        }

        if(!parent) {
            parent = this.game.scenes.crtScene.world;
            centerPos = this.scene.viewPort.centerPosition;
        }

        if(editable(parent, 'children')
        ) {
            const command = new AddGameObjectCommand( parent.id );
            const gameObject = command.execute(true);

            if( x === undefined && centerPos ) {
                x = centerPos.x;
            }
            if( y === undefined && centerPos ) {
                y = centerPos.y;
            }

            gameObject.position.set( x, y );

            this.addCommand([command]);
            return gameObject;
        }
        else {
            notification.warning({
                message: LocalizationManager.Instance.getText('Popup.failAddGo'),
                description: LocalizationManager.Instance.getText('Popup.failAddGoDesc', parent.name),
                placement: 'bottomLeft',
            });
            return null;
        }
    }

    removeGameObject() {
        const target = this.getSelectGameObject() as GameObject;

        if(!target) {
            notification.warning({
                message: LocalizationManager.Instance.getText('Popup.failRemoveGo'),
                description: LocalizationManager.Instance.getText('Popup.failRemoveGoDesc'),
                placement: 'bottomLeft',
            });
            return;
        }


        if( editable(target, 'remove')) {
            const command = new RemoveGameObjectCommand( target.id );
            command.execute();
            this.addCommand([command]);
        }
        else {
            notification.warning({
                message: LocalizationManager.Instance.getText('Popup.failRemoveGo'),
                description: LocalizationManager.Instance.getText('Popup.failRemoveGo2',target.name),
                placement: 'bottomLeft',
            });
        }
    }

    cloneGameObject(disconnectPrefab: boolean = false) {
        const target = this.getSelectGameObject(this._selectIds);

        if(!target) {
            notification.warning({
                message: LocalizationManager.Instance.getText('Popup.failCloneGo'),
                description: LocalizationManager.Instance.getText('Popup.failCloneGoDesc'),
                placement: 'bottomLeft',
            });
            return;
        }

        if( editable(target, 'clone') ) {
            let json = target.toJson();
            if(disconnectPrefab) {
                json.prefabId = '';
            }
            const command = new AddGameObjectCommand( (target.parent as GameObject).id, json );
            command.execute();
            this.addCommand([command]);
        }
        else {
            notification.warning({
                message: LocalizationManager.Instance.getText('Popup.failCloneGo'),
                description: LocalizationManager.Instance.getText('Popup.failCloneGoDesc2', target.name),
                placement: 'bottomLeft',
            });
        }
    }

    createPrefab(id?: string) : PrefabAsset{
        const target = this.findGameObject(id) || this.getSelectGameObject(this._selectIds) as GameObject;

        if(!target) {
            notification.warning({
                message: LocalizationManager.Instance.getText('Popup.failCreatePrefab'),
                description: LocalizationManager.Instance.getText('Popup.failCreatePrefabDesc'),
                placement: 'bottomLeft',
            });
            return;
        }

        if( editable(target, 'clone') ) {
            const asset = new PrefabAsset(this.game, target.toJson());
            asset.prefab.prefabId = asset.id;
            asset.rename(EditorManager.Instance.assetManager.getVerifiedName(target.name, asset.type));
            asset.color = `#${Math.floor(Math.random()*16777215).toString(16)}`;
            asset.order = this.assetManager.getPrefabAssetList().length;
            target.prefabId = asset.id;
            this.assetManager.addAsset(asset);
            store.dispatch(changeRefreshPrefabView(Math.random()));
            this.createHierarchy();
            return asset;
        }
        else {
            notification.warning({
                message: LocalizationManager.Instance.getText('Popup.failCreatePrefab'),
                description: LocalizationManager.Instance.getText('Popup.failCreatePrefabDesc2', target.name),
                placement: 'bottomLeft',
            });
        }
    }

    clonePrefab(id: string, x: number, y: number) {
        // const target = this.getSelectGameObject(this._selectIds);
        //
        // if(target && !(editable(target,'gameObject'))) {
        //     return;
        // }

        const parent = this.game.scenes.crtScene.world;
        const prefab = this.assetManager.getAsset(id) as PrefabAsset;
        const command = new AddGameObjectCommand( parent.id, prefab.prefab, true );
        const gameObject = command.execute();
        gameObject.prefabId = id;
        this.addCommand([command]);

        SetGlobalPoint( gameObject, x, y );

        if(store.getState().editor.fixedGridMove) {
            const gridX = store.getState().editor.grid.x;
            const gridY = store.getState().editor.grid.y;
            const pos = gameObject.worldPosition;
            pos.x = gridX + Math.round(pos.x / gridX) * gridX;
            pos.y = gridY + Math.round(pos.y / gridY) * gridY;
            gameObject.worldPosition = pos;
        }
    }

    updatePrefab( gameObject: GameObject ) {
        if(!gameObject.prefabId) {
            window.confirm(LocalizationManager.Instance.getText('Popup.failUpdatePrefab'));
            return;
        }

        const prefab = this.assetManager.getAsset(gameObject.prefabId) as PrefabAsset;
        if(!prefab) {
            gameObject.prefabId = '';
            window.confirm(LocalizationManager.Instance.getText('Popup.failUpdatePrefab2'));
            this.createHierarchy();
            return;
        }
        prefab.prefab = gameObject.toJson();
        getPrefabImg(prefab, true);

        const json = prefab.prefab;
        const children = json.children;
        const components = json.components;
        const disableList = json.disableList;

        const world = this.scene.world;
        const fixed = this.scene.fixed;

        _search(world);
        _search(fixed);

        function _search( go: GameObject ) {
            if(go instanceof GameObject) {
                if( go.prefabId === prefab.id) {
                    const parent = go.parent;
                    const idx = parent.children.indexOf(go);
                    const goJson = go.toJson(true);
                    goJson.children = children;
                    goJson.components = components;
                    goJson.disableList = disableList;
                    const newGo = new GameObject(go.game);
                    go.destroy();
                    newGo.fromJson(goJson);
                    parent.addChildAt(newGo, idx);
                }
                else {
                    for(let i = 0; i < go.children.length; i++) {
                        _search( go.children[i] as GameObject );
                    }
                }
            }
        }
        this.createHierarchy();
    }

    equalHierarchyId(id: string): boolean {
        if(this._selectIds.length && this._selectIds[0] === id) {
            return true;
        }

        return false;
    }


    getSelectGameObject(ids : string[] = this._selectIds): GameObject {
        let target = null;
        if( ids.length ) {
            target = this.findGameObject( ids[0] );
        }
        return target;
    }

    getSelectPropertyTarget(): GameObject | PrefabAsset {
        let target = null;
        if(this._selectIds?.length) {
            target = this.findGameObject( this._selectIds[0] );
        }
        else {
            target = this.assetManager.getAsset(prefabSelector.selectId) as PrefabAsset;
        }
        return target;
    }

    findInstance(id: string) : FunctionManager{
        return this.game.scenes.crtScene?.findById( id ) as FunctionManager;
    }


    findGameObject(id: string) : GameObject{
        return this.game.scenes.crtScene?.findById( id ) as GameObject;
    }

    findComponent(id: string) : Component {
        return this.game.scenes.crtScene?.findById( id ) as Component;
    }

    modifyGameObjectActive(id: string, active : boolean) {
        const go = this.findGameObject(id);
        const preActive = go.active;
        if( preActive != active ) {
            const command = new ActionCommand(()=>{
                go.active = active;
                this.createHierarchy();
            }, ()=>{
                go.active = preActive;
                this.createHierarchy();
            });
            command.execute();
            this.addCommand( [command] );
        }
    }

    modifyGameObjectLocked(id: string, locked : boolean) {
        const go = this.findGameObject(id) as GameObject;
        const preLocked = go.locked;
        if( preLocked != locked ) {
            const command = new ActionCommand(()=>{
                go.locked = locked;
                this.createHierarchy();
            }, ()=>{
                go.locked = locked;
                this.createHierarchy();
            });
            command.execute();
            this.addCommand( [command] );
        }
    }

    modifyGameObject(goJson : IGameObjectJson, createHierarchy: boolean = false) {
        const target = this.findGameObject( goJson.id );

        if( target ) {
            const command = new ModifyGameObjectCommand( target.toJson(true), goJson );
            command.execute();

            const commands : Command[] = [command];

            if(createHierarchy) {
                const command2 = new ActionCommand(()=>{
                    this.createHierarchy();
                });
                command2.execute();
                commands.push(command2);
            }

            this.addCommand( commands );
        }
    }

    moveHierarchy(arr:{srcId: string, dstId: string, dstIdx: number}[]) {

        const commands : Command[] = [];

        for(let i = 0; i < arr.length; i++ ) {
            const {srcId, dstId, dstIdx} = arr[i]

            const src = this.findGameObject(srcId);
            if( !editable(src, 'parent') ) {
                continue;
            }
            const dst = this.findGameObject(dstId);
            if( !editable(dst, 'children') ) {
                continue;
            }

            const srcPrefabs = src.getChildPrefabs();
            const dstPrefabs = dst.getParentPrefabs();
            const overlapPrefabs = srcPrefabs.concat(dstPrefabs).filter(item=>srcPrefabs.includes(item) && dstPrefabs.includes(item));
            if(overlapPrefabs.length) {
                notification.warning({
                    message: LocalizationManager.Instance.getText('Popup.failMoveNode'),
                    description: LocalizationManager.Instance.getText('Popup.failMoveNodeDesc'),
                    placement: 'bottomLeft',
                });
                continue;
            }

            const command = new MoveHierarchyCommand(dst.id, src.id, dstIdx);
            command.execute();
            commands.push(command);
        }

        if(commands.length) {
            this.addCommand(commands.reverse());
        }
    }

    addCommand(commands: Command[]) {
        if(this.commands.length >= this.commandMax) {
            this.commands.shift();
        }
        this.commands.push(commands);
    }

    async addImage(dataUrl: string, uid: string = uuid.v4() ) : Promise<TextureAsset> {
        return await AssetManager.LoadAndAddTexture(this.game, dataUrl, uid);
    }

    removeImage(uuid: string) {
        this.game.assetManager.removeAsset( uuid )
    }

    async addSound(dataUrl: string, uid: string = uuid.v4()) : Promise<SoundAsset> {
        return await AssetManager.LoadAndAddSound(this.game, dataUrl, uid);
    }

    addScene(name: string, uuid: string = undefined, json: ISceneJson = undefined): SceneAsset {
        const sceneList = this.game.assetManager.getSceneAssetList();
        if(sceneList.find(scene=>scene.name === name)) {
            return null;
        }

        if(!json) {
            const scene = new Scene(this.game, name);
            json = scene.toJson();
            scene.destroy();
        }

        const asset = new SceneAsset(this.game, json, uuid);
        asset.rename(name);
        this.game.assetManager.addAsset(asset);

        return asset;
    }

    async addFont(fontName: string, buffer: ArrayBuffer, id: string = undefined): Promise<FontAsset> {
        return AssetManager.LoadAndAddFont(this.game, fontName, buffer, id);
    }

    //현재 씬 상태를 저장
    saveScene( skipFeedback: boolean = true ) {
        this.game.scenes.crtSceneAsset.scene = this.game.scenes.crtScene.toJson();
        if(!skipFeedback) {
            notification.warning({
                message: LocalizationManager.Instance.getText('Popup.saveScene'),
                description: LocalizationManager.Instance.getText('Popup.saveSceneDesc', this.game.scenes.crtSceneAsset.scene.name),
                placement: 'bottomLeft',
            });
        }
    }

    startScene(id: string) {
        this.commands = [];
        treeManager.tree?.selectById(undefined);
        store.dispatch(changeHierarchyList(undefined));

        this.game.scenes.start(id, EditorScene);

        initEditorScene(this);

        store.dispatch(changeCurrentScene({id: this.game.scenes.crtSceneAsset.id}));

        this.refreshViewport(true);
        this.refreshGrid(store.getState().editor.grid);
        this.createHierarchy();
    }

    removeScene(uuid: string) : boolean {
        if( this.game.scenes.crtSceneAsset.id !== uuid ) {
            this.game.assetManager.removeAsset( uuid );
            return true;
        }

        notification.warning({
            message: LocalizationManager.Instance.getText('Popup.failRemoveScene'),
            description: LocalizationManager.Instance.getText('Popup.failRemoveSceneDesc'),
            placement: 'bottomLeft',
        });

        return  false;
    }

    addScriptComponent( scriptName: string ) {
        const target = this.getSelectGameObject(this._selectIds) as GameObject;
        if(target) {

            const command = new AddComponentCommand(target.id, scriptName);
            command.execute();
            this.addCommand([command]);

            // target.addComponent(scriptName);
            // treeManager.tree?.selectById( target.id );
        }
    }

    addComponent(ctor: IComponentConstructor ) {
        const target = this.getSelectGameObject(this._selectIds);
        if(!target ) {
            return;
        }

        if(!(ctor !== Script)) {
            notification.warning({
                message: LocalizationManager.Instance.getText('Popup.failAddComp'),
                description: LocalizationManager.Instance.getText('Popup.failAddComp3', ctor.Name),
                placement: 'bottomLeft',
            });
        }

        for(let i = 0; i < renderTypeComps.length; i++) {
            const rc = renderTypeComps[i];
            if( rc === ctor.Name ) {
                for(let j = 0; j < renderTypeComps.length; j++) {
                    if(i === j) continue;
                    const rc2 = renderTypeComps[j];
                    if(target.getComponent(rc2)) {
                        notification.warning({
                            message: LocalizationManager.Instance.getText('Popup.failAddComp'),
                            description: LocalizationManager.Instance.getText('Popup.failAddCompDesc2'),
                            placement: 'bottomLeft',
                        });
                        return;
                    }
                }
            }
        }

        if( !target.existsComponent(ctor)) {
            const command = new AddComponentCommand(target.id, ctor.Name);
            command.execute();
            this.addCommand([command]);
        }
        else {
            notification.warning({
                message: LocalizationManager.Instance.getText('Popup.failAddComp'),
                description: LocalizationManager.Instance.getText('Popup.failAddCompDesc', ctor.Name),
                placement: 'bottomLeft',
            });
        }
    }

    moveComponent(idx: number, targetIdx: number) {
        const target = this.getSelectGameObject(this._selectIds);
        if(target) {
            const comps = target.components;
            if( comps[idx] && comps[targetIdx] ) {
                const command = new MoveComponent(target.id, idx, targetIdx);
                command.execute();
                this.addCommand([command]);
            }
        }
    }

    modifyComponent(compJson : IComponentJson, prevJson: IComponentJson = null) {
        const target = this.getSelectGameObject(this._selectIds);
        if(target) {
            const comp = target.findById(compJson.id) as Component;
            const command = new ModifyComponentCommand(target.id, prevJson || comp.toJson(), compJson );
            command.execute();
            this.addCommand([command]);
        }
    }

    removeComponent(compJson: IComponentJson) {
        const target = this.getSelectGameObject(this._selectIds);
        if(target) {

            const comp = target.findById(compJson.id) as Component;
            const command = new RemoveComponentCommand(target.id, comp.toJson() );
            command.execute();
            this.addCommand([command]);
        }
    }

    modifyCamera(cameraJson : ICameraJson) {
        const target = this.getSelectGameObject(this._selectIds);
        if(target) {
            const comp = target.getComponent(cameraJson.name) as Camera;
            const command = new ModifyComponentCommand(target.id,comp.toJson(), cameraJson );
            command.execute();
            // const command2 = new ActionCommand(()=>{
            //     this.refreshViewport();
            // } );
            // command2.execute();
            this.addCommand([command, /*command2*/]);
        }
    }

    copyHierarchyNode() {
        const target = this.getSelectGameObject(this._selectIds);
        if(target) {
            if( editable(target, 'clone') ) {
                this.copy.type = 'gameObject';
                this.copy.data = target.toJson();
                this.copy.data.prefabId = '';
                this.copy.isOnce = false;

                notification.warning({
                    message: LocalizationManager.Instance.getText('Popup.copyGo'),
                    description: LocalizationManager.Instance.getText('Popup.copyGoDesc',target.name),
                    placement: 'bottomLeft',
                });
            }
            else {
                notification.warning({
                    message: LocalizationManager.Instance.getText('Popup.failCopyGo'),
                    description: LocalizationManager.Instance.getText('Popup.failCopyGoDesc', target.name),
                    placement: 'bottomLeft',
                });
            }
        }
    }

    paste() {
        if(this.copy.type === 'gameObject' && this.copy.data) {
            const target = this.getSelectGameObject(this._selectIds);
            if(target.parent && editable(target.parent as GameObject, 'children')) {
                const command = new AddGameObjectCommand( (target.parent as GameObject).id, this.copy.data as IGameObjectJson );
                command.execute();
                this.addCommand([command]);

                notification.warning({
                    message: LocalizationManager.Instance.getText('Popup.pasteGo'),
                    description: LocalizationManager.Instance.getText('Popup.pasteGoDesc', this.copy.data.name),
                    placement: 'bottomLeft',
                });
            }
        }
    }

    changeCtrlMode( mode : ECtrlMode ) {
        if( this.scene.ctrlMode === mode ) {
            this.scene.ctrlMode = ECtrlMode.resizer;
        }
        else {
            this.scene.ctrlMode = mode;
        }
    }

    refreshViewport(isCenter: boolean = false) {
        const width = store.getState().editor.cameraWidth;
        const height = store.getState().editor.cameraHeight;
        const scale = store.getState().editor.zoomScale;

        const viewport = this.scene.viewPort;
        this.scene.camera.width = width;
        this.scene.camera.height = height;
        if(isCenter) {
            this.scene.center.scale.set(scale);
        }
        viewport.setCenter({x:0,y:0});
        viewport.cameraWidth = width;
        viewport.cameraHeight = height;
        viewport.fixedGrid = store.getState().editor.fixedGridMove;
    }

    refreshGrid(grid:{x: number, y: number}) {
        const viewport = this.scene.viewPort;
        viewport.gridX = grid.x;
        viewport.gridY = grid.y;
        store.dispatch(changeGrid(grid));
    }

    refreshProperty() {
        store.dispatch( changeHierarchySelectIds( [...store.getState().hierarchy.selectIds] ));
        EditorManager.Instance.onSelect(store.getState().hierarchy.selectIds);
    }

    getSelectObject(): GameObject {
        return this.findGameObject( this._selectIds[0] );
    }

    centerSelectObject() {
        this.scene.viewPort.setCenter(this.getSelectObject().worldPosition);
    }

    async selectImage(selectId: string = '') {
        return new Promise<string>((resolve)=>{
            store.dispatch(changeSelectAssetId(selectId));
            store.dispatch(changeOpenImageList(true));
            changeOnClickAsset((id:string) => {
                store.dispatch(changeOpenImageList(false));
                store.dispatch(changeSelectAssetId(''));
                resolve(id);
            });
        });
    }

    async selectSound(selectId: string = '') {
        return new Promise<string>((resolve)=>{
            store.dispatch(changeSelectAssetId(selectId));
            store.dispatch(changeOpenSoundList(true));
            changeOnClickAsset((id:string) => {
                store.dispatch(changeOpenSoundList(false));
                store.dispatch(changeSelectAssetId(''));
                resolve(id);
            });
        });
    }

    async selectScene(selectId: string = '') {
        return new Promise<string>((resolve)=>{
            store.dispatch(changeOpenSceneList(true));
            store.dispatch(changeSelectAssetId(selectId));
            changeOnClickAsset((id:string) => {
                store.dispatch(changeOpenSceneList(false));
                store.dispatch(changeSelectAssetId(''));
                resolve(id);
            });
        });
    }

    async selectFont(selectId: string = '') {
        return new Promise<string>((resolve)=>{
            store.dispatch(changeOpenFontList(true));
            store.dispatch(changeSelectAssetId(selectId));
            changeOnClickAsset((id:string) => {
                store.dispatch(changeOpenFontList(false));
                store.dispatch(changeSelectAssetId(''));
                resolve(id);
            });
        });
    }

    async selectImages() {
        return new Promise<string[]>((resolve)=>{
            store.dispatch(changeOpenImageList(true));
            changeOnSelectAsset((ids: string[]) => {
                store.dispatch(changeOpenImageList(false));
                resolve(ids);
            });
        });
    }

    async selectAnimator(selectId: string = '') {
        return new Promise<string>((resolve)=>{
            store.dispatch(changeOpenAnimatorList(true));
            store.dispatch(changeSelectAssetId(selectId));
            changeOnClickAsset((animatorId:string) => {
                store.dispatch(changeOpenAnimatorList(false));
                store.dispatch(changeSelectAssetId(''));
                resolve(animatorId);
            });
        });
    }


    new() {

        try {
            store.dispatch(changeSpinText(LocalizationManager.Instance.getText('Popup.loading.new')));

            const sceneAsset = this.addScene('default');
            this.game.scenes.start(sceneAsset.id, EditorScene);
            store.dispatch(changeCameraWidth(800));
            store.dispatch(changeCameraHeight(600));
            store.dispatch(changeZoomScale(1));
            this.refreshViewport(true);

            initEditorScene(this);

            store.dispatch(changeCurrentScene({id:this.game.scenes.crtSceneAsset.id}));

            this.refreshGrid( {x:50, y:50} );
            this.createHierarchy();

            store.dispatch(changeSpinText(''));
        }
        catch (e) {
            console.error(e);
            store.dispatch(changeSpinText(''));
        }


    }


    async clear() {
        try {
            store.dispatch(changeSpinText(LocalizationManager.Instance.getText('Popup.loading.clear')));

            this.commands = [];
            treeManager.tree?.selectById(undefined);
            store.dispatch(changeHierarchyList(undefined));

            store.dispatch(changePrefabTagList([{tag:'', color: '#757575', hide: false}]));
            store.dispatch(changeTextureTagList([]));
            store.dispatch(changeSoundTagList([]));
            store.dispatch(changeProjectName('mogeraGame'));
            prefabSelector?.select && prefabSelector.select(null);

            this.assetManager.clear();
            this.game.scenes.stopScene();

            store.dispatch(changeSpinText(''));
        }
        catch (e) {
            console.error(e);
            store.dispatch(changeSpinText(''));
        }


    }

    async saveProject() {
        try {
            const saveProjectName = prompt(LocalizationManager.Instance.getText('Popup.inputName'), store.getState().editor.projectName);
            if(!saveProjectName) {
                return;
            }

            store.dispatch(changeSpinText(LocalizationManager.Instance.getText('Popup.loading.save')));
            this.saveScene();

            const assets = this.assetManager.getAssetsPackage();
            const projectSetting : IProjectSettingJson = {
                projectName: store.getState().editor.projectName,
                currentSceneAssetId: this.game.scenes.crtSceneAsset.id,
                prefabTagList: store.getState().editor.prefabTagList,
                textureTagList: store.getState().editor.textureTagList,
                soundTagList: store.getState().editor.soundTagList,
                grid: store.getState().editor.grid,
                fixedGrid: store.getState().editor.fixedGridMove,
                zoomScale: store.getState().editor.zoomScale,
                width: store.getState().editor.cameraWidth,
                height: store.getState().editor.cameraHeight,
                cdnList: store.getState().editor.cdnList,
            };

            const files : any= {
                images: [],
                sounds: [],
                scenes: [],
                scripts: [],
                animators: [],
                prefabs: [],
                fonts: [],
            };

            const jsZip = new JSZip();
            const imagesFolder = jsZip.folder('images');
            const soundsFolder = jsZip.folder('sounds');
            const scenesFolder = jsZip.folder('scenes');
            const scriptsFolder = jsZip.folder('scripts');
            const animatorFolder = jsZip.folder('animators');
            const prefabFolder = jsZip.folder('prefabs');
            const fontFolder = jsZip.folder('fonts');

            for(let i = 0; i < assets.textures.length; i++) {
                const asset = assets.textures[i];
                const resource = asset.resource;
                if(resource.isDataUrl) {
                    const name = `${asset.id}`;
                    const dataUrl = resource.url;
                    files.images.push({
                        name: asset.name,
                        uuid: asset.id,
                        extension: resource.extension,
                        tag: asset.tag,
                    });
                    const res = await fetch(dataUrl);
                    const blob = await res.blob();
                    imagesFolder.file(
                        name,
                        blob,
                    );
                }
            }

            for(let i = 0; i < assets.sounds.length; i++) {
                const asset = assets.sounds[i];
                const resource = asset.resource;
                if(resource.isDataUrl) {
                    const name = `${asset.id}`;
                    const dataUrl = resource.url;
                    files.sounds.push({
                        name: asset.name,
                        uuid: asset.id,
                        extension: resource.extension,
                        tag: asset.tag,
                    });
                    const res = await fetch(dataUrl);
                    const blob = await res.blob();
                    soundsFolder.file(
                        name,
                        blob,
                    );
                }
            }

            for(let i = 0; i < assets.scenes.length; i++) {
                const asset = assets.scenes[i];
                const sceneJson = JSON.stringify(asset.scene);
                files.scenes.push({
                    uuid: asset.id,
                    name: asset.name,
                    tag: asset.tag,
                });
                scenesFolder.file(asset.id, sceneJson);
            }

            for(let i = 0; i < assets.scripts.length; i++) {
                const asset = assets.scripts[i];
                const json = JSON.stringify(asset.json);
                files.scripts.push({
                    uuid: asset.id,
                    name: asset.name,
                    tag: asset.tag,
                });
                scriptsFolder.file(asset.id, json);
            }

            for(let i = 0; i < assets.animators.length; i++) {
                const asset = assets.animators[i];
                const json = JSON.stringify(asset.json);
                files.animators.push({
                    uuid: asset.id,
                    name: asset.name,
                    tag: asset.tag,
                });
                animatorFolder.file(asset.id, json);
            }

            for(let i = 0; i < assets.prefabs.length; i++) {
                const asset = assets.prefabs[i];
                const json = JSON.stringify(asset.prefab);
                files.prefabs.push({
                    uuid: asset.id,
                    name: asset.name,
                    tag: asset.tag,
                    color: asset.color,
                    order: asset.order,
                });
                prefabFolder.file(asset.id, json);
            }

            for(let i = 0; i < assets.fonts.length; i++) {
                const asset = assets.fonts[i];
                files.fonts.push({
                    uuid: asset.id,
                    name: asset.name,
                    tag: asset.tag,
                });
                fontFolder.file(asset.id, asset.arrayBuffer);
            }


            jsZip.file('projectSetting', JSON.stringify(projectSetting));
            jsZip.file('files', JSON.stringify(files));
            const result = await jsZip.generateAsync({type:"blob"});



            saveAs(result, `${saveProjectName}.mogera` || 'project.mogera');
            store.dispatch(changeSpinText(''));
        }
        catch (e) {
            console.error(e);
            store.dispatch(changeSpinText(''));
        }


    }

    async loadProjectExample(path:string) {
        if(window.confirm(LocalizationManager.Instance.getText('Popup.exitWarning'))) {
            store.dispatch(changeSpinText(LocalizationManager.Instance.getText('Popup.loading.load')));
            const blob = await (await fetch(path)).blob();
            await this.loadProjectZipFile(blob);
        }
    }

    async loadWizardProject(path: string) {
        store.dispatch(changeSpinText(LocalizationManager.Instance.getText('Popup.loading.load')));
        const blob = await (await fetch(path)).blob();
        await this.loadProjectZipFile(blob);

        if(wizardSaveData.onLoadCallback) {
            wizardSaveData.onLoadCallback(this.game);
        }

        this.startScene(this.game.scenes.crtSceneAsset.id);

        // const scenes = this.assetManager.getSceneAssetList() as SceneAsset[];
        // const scene = scenes[0];
        // const gm = GoJsonHelper.findGameObjectByName(scene.scene, 'gameManager');
        // console.log(gm);
        // if(gm) {
        //     gm.active = false;
        // }

    }

    async loadProjectZipFile( file: File | Blob) {
        const jsZip = new JSZip();
        await jsZip.loadAsync(file);

        try {
            await this.clear();
            store.dispatch(changeSpinText(LocalizationManager.Instance.getText('Popup.loading.load')));

            const projectSetting : IProjectSettingJson = JSON.parse(await jsZip.file('projectSetting').async('string'));
            const width = projectSetting.width || 800;
            const height = projectSetting.height || 600;
            store.dispatch(changeCDNList(projectSetting.cdnList || ''));
            store.dispatch(changeCameraWidth(width));
            store.dispatch(changeCameraHeight(height));
            store.dispatch(changeZoomScale(projectSetting.zoomScale || 1));
            if(projectSetting.projectName ) {
                store.dispatch(changeProjectName(projectSetting.projectName || 'mogeraGame'));
            }


            const filesFile = await jsZip.file('files').async('string');
            const files = JSON.parse(filesFile);
            const images = files.images;
            const sounds = files.sounds;
            const scenes = files.scenes;
            const scripts = files.scripts;
            const animators = files.animators;
            const prefabs = files.prefabs;
            const fonts = files.fonts;

            for(let i = 0;i < images.length; i++) {
                const name = images[i].name;
                const uuid = images[i].uuid;
                const tag = images[i].tag || '';
                const type = `image/${images[i].extension}`
                const path = `images/${uuid}`;
                const zipFile = jsZip.file(path);
                const blob = await zipFile.async('blob');
                const file = new File([blob], name, {type});
                const fileReader = new FileReader();
                await new Promise<void>((resolve)=>{
                    fileReader.addEventListener('load', ()=> {
                        resolve()
                    })
                    fileReader.readAsDataURL(file);
                });
                const dataUrl = fileReader.result as string;
                const asset = await this.addImage(dataUrl, uuid);
                asset.rename(name);
                asset.tag = tag
            }

            for(let i = 0;i < sounds.length; i++) {
                const name = sounds[i].name;
                const uuid = sounds[i].uuid;
                const tag = sounds[i].tag || '';
                const type = `audio/${sounds[i].extension}`
                const path = `sounds/${uuid}`;
                const zipFile = jsZip.file(path);
                const blob = await zipFile.async('blob');
                const file = new File([blob], name, {type});
                const fileReader = new FileReader();
                await new Promise<void>((resolve)=>{
                    fileReader.addEventListener('load', ()=> {
                        resolve()
                    })
                    fileReader.readAsDataURL(file);
                });
                const dataUrl = fileReader.result as string;
                const asset = await this.addSound(dataUrl, uuid);
                asset.rename(name);
                asset.tag = tag
            }

            for(let i = 0; i < scenes.length; i++) {
                const name = scenes[i].name;
                const uuid = scenes[i].uuid;
                const tag = scenes[i].tag || '';
                const scene = await jsZip.file(`scenes/${uuid}`).async('string');
                const sceneJSon = JSON.parse(scene);
                const asset = this.addScene(name, uuid, sceneJSon);
                asset.rename(name);
                asset.tag = tag;
            }

            for(let i = 0; i < scripts.length; i++) {
                const name = scripts[i].name;
                const uuid = scripts[i].uuid;
                const tag = scripts[i].tag || '';
                const script = await jsZip.file(`scripts/${uuid}`).async('string');
                const scriptJson = JSON.parse(script);
                try {
                    const scriptAsset = new ScriptAsset(EditorManager.Instance.game, scriptJson, uuid);
                    scriptAsset.rename(name);
                    EditorManager.Instance.game.assetManager.addAsset(scriptAsset)
                    scriptAsset.tag = tag;
                }
                catch (e) {
                    console.log( `${name} 스크립트 로드 실패` );
                }
            }

            for(let i = 0; i < animators.length; i++) {
                const name = animators[i].name;
                const uuid = animators[i].uuid;
                const tag = animators[i].tag || '';
                const strData = await jsZip.file(`animators/${uuid}`).async('string');
                const json = JSON.parse(strData);
                const asset = new AnimatorAsset(EditorManager.Instance.game, json, uuid);
                asset.rename(name);
                asset.tag = tag;
                EditorManager.Instance.game.assetManager.addAsset(asset)
            }

            for(let i = 0; i < prefabs.length; i++) {
                const name = prefabs[i].name;
                const uuid = prefabs[i].uuid;
                const strData = await jsZip.file(`prefabs/${uuid}`).async('string');
                const json = JSON.parse(strData);
                const asset = new PrefabAsset(EditorManager.Instance.game, json, uuid);
                asset.rename(name);
                asset.tag = prefabs[i].tag || '';
                asset.color = prefabs[i].color || `#${Math.floor(Math.random()*16777215).toString(16)}`;
                asset.order = prefabs[i].order !== undefined ? prefabs[i].order : i;
                EditorManager.Instance.game.assetManager.addAsset(asset)
            }

            for(let i = 0; i < fonts?.length; i++) {
                const name = fonts[i].name;
                const uuid = fonts[i].uuid;
                const tag = fonts[i].tag || '';
                const buffer = await jsZip.file(`fonts/${uuid}`).async('arraybuffer');
                const asset = await this.addFont( name, buffer, uuid );
                asset.tag = tag;
            }

            this.startScene(projectSetting.currentSceneAssetId);

            store.dispatch(changePrefabTagList(projectSetting.prefabTagList || [{tag:'', color: '#757575', hide: false}]));
            store.dispatch(changeTextureTagList(projectSetting.textureTagList || []));
            store.dispatch(changeSoundTagList(projectSetting.soundTagList || []));

            this.refreshGrid( projectSetting.grid || {x:50, y:50} );
            store.dispatch(changeFixedGridMove(projectSetting.fixedGrid || false));

            this.refreshViewport(true);

            store.dispatch(changeSpinText(''));
        }
        catch (e) {
            console.error(e);
            store.dispatch(changeSpinText(''));
        }

    }

    async loadProject() {
        const input = document.createElement('input') as HTMLInputElement;
        input.type = 'file';
        input.accept = '.mogera';
        input.onchange = ()=>{
            const file = input.files[0];
            if( file ) {
                this.loadProjectZipFile( file );
            }
        };
        input.click();
    }

    undo() {
        if(this.commands.length) {
            const commands = this.commands.pop();
            for(let i = 0; i < commands.length; i++) {
                const command = commands[i];
                command.undo();
            }
        }
        else {
            notification.warning({
                message: LocalizationManager.Instance.getText('Popup.failUndo'),
                description: LocalizationManager.Instance.getText('Popup.failUndoDesc'),
                placement: 'bottomLeft',
            });
        }
    }

    play() {
        this.saveScene();

        const projectSetting : IProjectSettingJson = {
            projectName: store.getState().editor.projectName,
            currentSceneAssetId: this.game.scenes.crtSceneAsset.id,
            cdnList: store.getState().editor.cdnList,
        };
        const gameSetting = {
            width: store.getState().editor.cameraWidth,
            height: store.getState().editor.cameraHeight,
        };

        const assets : {
            textures: ITextureAssetData[],
            sounds: ISoundAssetData[],
            scenes: ISceneAssetData[],
            scripts: IScriptJson,
            animators: [],
            prefabs: [],
            fonts: [],
            projectSetting: {},
            gameSetting: {},
        } = {
            ...this.game.assetManager.getPlayAssets(),
            projectSetting,
            gameSetting
        }


        const win = window.open('/play', 'player', `width=${gameSetting.width} height=${gameSetting.height}`);
        window.bridgeMainToPlay = () => {
            return assets;
        }
        window.playWindow = win;

        store.dispatch(changeOpenPlayConsole(true));
    }

    async build(isUpload ?: boolean) {
        const publicFilePaths = [
            'css/css.css',
            'js/game.js',
            'index.html',
            'version.json'
        ];

        const publicFiles : {
            path: string,
            text: string
        }[] = [];

        for(let i = 0; i < publicFilePaths.length; i++) {
            const file = await fetch(`game/${publicFilePaths[i]}`);
            const text = await file.text();
            publicFiles.push({path: publicFilePaths[i], text});
        }


        const assets = this.assetManager.getAssetsPackage();

        // const crtScene = this.game.scenes.crtScene.toJson();
        const files : any= {
            images: [],
            sounds: [],
            scenes: [],
            scripts: [],
            animators: [],
            prefabs: [],
            fonts: [],
            gameSetting: {
                width : store.getState().editor.cameraWidth,
                height: store.getState().editor.cameraHeight,
                cdnList: store.getState().editor.cdnList,
            },
        };
        // const scenes = [];
        // scenes.push( this.game.scenes.crtScene.toJson() );

        const jsZip = new JSZip();
        const assetFolder = jsZip.folder('asset');
        const imagesFolder = assetFolder.folder('images');
        const soundsFolder = assetFolder.folder('sounds');
        const fontFolder = assetFolder.folder('fonts');
        // const scenesFolder = jsZip.folder('scenes');
        // const scriptsFolder = jsZip.folder('scripts');
        // const animatorFolder = jsZip.folder('animators');
        // const prefabFolder = jsZip.folder('prefabs');

        for(let i = 0; i < assets.textures.length; i++) {
            const asset = assets.textures[i];
            const resource = asset.resource;
            if(resource.isDataUrl) {
                const name = `${asset.id}.${resource.extension}`;
                const dataUrl = resource.url;
                files.images.push({
                    name: asset.name,
                    uuid: asset.id,
                    extension: resource.extension,
                });
                const res = await fetch(dataUrl);
                const blob = await res.blob();
                imagesFolder.file(
                    name,
                    blob,
                );
            }
        }

        for(let i = 0; i < assets.sounds.length; i++) {
            const asset = assets.sounds[i];
            const resource = asset.resource;
            if(resource.isDataUrl) {
                const name = `${asset.id}.${resource.extension}`;
                const dataUrl = resource.url;
                files.sounds.push({
                    name: asset.name,
                    uuid: asset.id,
                    extension: resource.extension,
                });
                const res = await fetch(dataUrl);
                const blob = await res.blob();
                soundsFolder.file(
                    name,
                    blob,
                );
            }
        }

        for(let i = 0; i < assets.scenes.length; i++) {
            const asset = assets.scenes[i];
            const sceneJson = JSON.stringify(asset.scene);
            files.scenes.push({
                uuid: asset.id,
                name: asset.name,
                json: sceneJson,
            });
        }

        for(let i = 0; i < assets.scripts.length; i++) {
            const asset = assets.scripts[i];
            const json = JSON.stringify(asset.json);
            files.scripts.push({
                uuid: asset.id,
                name: asset.name,
                json: json,
            });
        }

        for(let i = 0; i < assets.animators.length; i++) {
            const asset = assets.animators[i];
            const json = JSON.stringify(asset.json);
            files.animators.push({
                uuid: asset.id,
                name: asset.name,
                json: json,
            });
        }

        for(let i = 0; i < assets.prefabs.length; i++) {
            const asset = assets.prefabs[i];
            const json = JSON.stringify(asset.prefab);
            files.prefabs.push({
                uuid: asset.id,
                name: asset.name,
                json,
            });
        }

        for(let i = 0; i < assets.fonts.length; i++) {
            const asset = assets.fonts[i] as FontAsset;
            files.fonts.push({
                uuid: asset.id,
                name: asset.name,
            });
            fontFolder.file(asset.id, asset.arrayBuffer);
        }

        for(let i = 0; i < publicFiles.length; i++) {
            const publicFile = publicFiles[i];
            jsZip.file(publicFile.path, publicFile.text);
        }

        assetFolder.file('files', JSON.stringify(files));
        const result = await jsZip.generateAsync({type:"blob"});
        if(isUpload) {
            return blobToFile(result, `mogera_${Date.now()}.zip`)
        }else{
            saveAs(result, 'deploy.zip');
        }
    }
}

// const EditorManager = new EditorManager();
//
// export default EditorManager;
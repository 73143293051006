import ScriptAsset, {IScriptAssetProp} from "../asset/scriptAsset";
import GameObject, {TJsonRefers} from "../gameObject/gameObject";
import Component from "../core/component";
import Game from "../core/game";
import {IScriptJson} from "../component/script";
import Asset from "../asset/asset";
import FunctionManager from "../function/functionManager";

export interface ScriptPropData  {
    name: string,
    type: 'Value' | 'GameObject' | 'Asset' | 'Component' | 'Instance',
    valueType?: 'String' | 'Number' | 'Boolean',
    assetType?: 'Texture' | 'Sound' | 'Scene' | 'Script' | 'Prefab' | string,
    componentType?: string,
    value: Prop,
    isArray: boolean,
}

class Prop {
    protected _value : any;
    public getValue() : any {
        return this._value;
    }
    public SetValue(v: any) {
        this._value = v;
    }
    destroy() {
    }
    public getJson(): any {
        return this._value;
    }
}

class ValueProp extends Prop {
    public getValue() : string | number | boolean {
        return this._value;
    }
    public setValue(v: string | number | boolean) {
        this._value = v;
    }
    destroy() {
    }
}

class ValueArrayProp extends Prop {
    protected _value: string[] | number[] | boolean[] = [];

    public getValue() : string[] | number[] | boolean[] {
        return this._value;
    }
    public setValue(v: string[] | number[] | boolean[]) {
        this._value = v;
    }
    destroy() {
        this._value.length = 0;
    }
}


class RefProp extends Prop {
    public getValue() : Asset | Component | GameObject | FunctionManager {
        return this._value;
    }
    public setValue(v: Asset | Component | GameObject | FunctionManager) {
        //@ts-ignore
        function onDestroy() {
            //@ts-ignore
            this._value = null;
        }

        this._value?.off('destroy', onDestroy, this);
        this._value = v;
        this._value?.on('destroy', onDestroy, this);
    }
    destroy() {
        this.setValue(null);
    }
    public getJson() : string {
        return this._value?.id;
    }
}

class RefArrayProp extends Prop {
    protected _value: RefProp[] = [];

    public getValue() : Asset[] | Component[] | GameObject[] | FunctionManager[] {
        const value : Asset[] | Component[] | GameObject[] | FunctionManager[] = [];
        for(let i = 0; i < this._value?.length; i++) {
            value[i] = this._value[i].getValue();
        }
        return value;
    }
    public setValue(v: Asset[] | Component[] | GameObject[] | FunctionManager[]) {
        for(let i = 0; i < this._value.length; i++) {
            this._value[i].setValue(null);
        }
        this._value.length = 0;
        for(let i = 0; i < v.length; i++) {
            this._value[i].setValue(v[i]);
        }
    }
    destroy() {
        for(let i = 0; i < this._value.length; i++) {
            this._value[i].setValue(null);
        }
        this._value.length = 0;
    }
    public getJson(): string[] {
        const arr : string[] = [];
        for(let i = 0; i < this._value?.length; i++) {
            arr[i] = this._value[i]?.getJson();
        }
        return arr;
    }
}


export default class ScriptProp {
    private _props : {[name:string]:ScriptPropData} = {};

    private get props(): {[name:string]:ScriptPropData} {
        return this._props;
    }


    getProp(name:string): ScriptPropData {
        return this.props[name];
    }

    get funcArgs() : {[name:string]:any} {
        const args: {[name:string]:any} = {};
        for(let k in this.props) {
            args[k] = this.props[k].value?.getValue();
        }
        return args;
    }

    private game: Game = null;

    constructor(game: Game) {
        this.game = game;
    }

    private initProp( prop: IScriptAssetProp, value: any | any[]) {
        this.props[prop.name]?.value?.destroy();

        if(prop.type === 'Value' && !prop.isArray) {
            const newValue = new ValueProp();
            newValue.setValue( value );
            if(newValue.getValue() === undefined) {
                if(prop.valueType === "Boolean") {
                    newValue.setValue(false);
                }
                else if(prop.valueType === "Number") {
                    newValue.setValue(0);
                }
                else if(prop.valueType === "String") {
                    newValue.setValue('');
                }
            }
            this.props[prop.name] = {
                name: prop.name,
                type: prop.type,
                valueType: prop.valueType,
                value: newValue,
                isArray: prop.isArray,
            };
        }
        else if(prop.type === 'Asset' && !prop.isArray) {
            let asset = this.game.assetManager.getAsset(value);
            if(asset && prop.assetType !== asset.type) {
                asset = null;
            }

            const newValue = new RefProp();
            newValue.setValue( asset );
            this.props[prop.name] = {
                name: prop.name,
                type: prop.type,
                assetType: prop.assetType,
                value: newValue,
                isArray: prop.isArray,
            };
        }
        else if(prop.type === 'GameObject' && !prop.isArray) {
            const go = this.game.scenes.crtScene.findById(value) as GameObject;

            const newValue = new RefProp();
            newValue.setValue( go );
            this.props[prop.name] = {
                name: prop.name,
                type: prop.type,
                value: newValue,
                isArray: prop.isArray,
            };
        }
        else if(prop.type === 'Instance' && !prop.isArray) {
            const instance = this.game.scenes.crtScene.findById(value) as FunctionManager;

            const newValue = new RefProp();
            newValue.setValue( instance );
            this.props[prop.name] = {
                name: prop.name,
                type: prop.type,
                value: newValue,
                isArray: prop.isArray,
            };
        }
        else if(prop.type === 'Component' && !prop.isArray) {
            let comp = this.game.scenes.crtScene.findById(value) as Component;
            if(comp && comp.name !== prop.componentType) {
                comp = null;
            }

            const newValue = new RefProp();
            newValue.setValue( comp );
            this.props[prop.name] = {
                name: prop.name,
                type: prop.type,
                componentType: prop.componentType,
                value: newValue,
                isArray: prop.isArray,
            };
        }
        else if(prop.type === 'Value' && prop.isArray) {
            const newValue = new ValueArrayProp();
            newValue.setValue( value );
            this.props[prop.name] = {
                name: prop.name,
                type: prop.type,
                valueType: prop.valueType,
                value: newValue,
                isArray: prop.isArray,
            };
        }
        else if(prop.type === 'Asset' && prop.isArray) {
            let arr = [];
            for(let i = 0; i < value?.length; i++) {
                let asset = this.game.assetManager.getAsset(value);
                if(asset && prop.assetType !== asset.type) {
                   asset = null;
                }
                arr.push( asset );
            }

            const newValue = new RefArrayProp();
            newValue.setValue( arr );
            this.props[prop.name] = {
                name: prop.name,
                type: prop.type,
                assetType: prop.assetType,
                value: newValue,
                isArray: prop.isArray,
            };
        }
        else if(prop.type === 'GameObject' && prop.isArray) {
            let arr = [];
            for(let i = 0; i < value?.length; i++) {
                const go = this.game.scenes.crtScene.findById(value) as GameObject;
                arr.push( go );
            }


            const newValue = new RefArrayProp();
            newValue.setValue( arr );
            this.props[prop.name] = {
                name: prop.name,
                type: prop.type,
                value: newValue,
                isArray: prop.isArray,
            };
        }
        else if(prop.type === 'Instance' && prop.isArray) {
            let arr = [];
            for(let i = 0; i < value?.length; i++) {
                const go = this.game.scenes.crtScene.findById(value) as FunctionManager;
                arr.push( go );
            }


            const newValue = new RefArrayProp();
            newValue.setValue( arr );
            this.props[prop.name] = {
                name: prop.name,
                type: prop.type,
                value: newValue,
                isArray: prop.isArray,
            };
        }
        else if(prop.type === 'Component' && prop.isArray) {
            let arr = [];
            for(let i = 0; i < value?.length; i++) {
                let comp = this.game.scenes.crtScene.findById(value) as Component;
                if(comp && comp.name !== prop.componentType) {
                    comp = null;
                }
                arr.push( comp );
            }

            const newValue = new RefArrayProp();
            newValue.setValue( arr );
            this.props[prop.name] = {
                name: prop.name,
                type: prop.type,
                componentType: prop.componentType,
                value: newValue,
                isArray: prop.isArray,
            };
        }
    }


    toJson(ScriptProps:IScriptAssetProp[]) : {[name: string]: any} {
        const props: {[name:string]:any} = {};
        for(let i = 0; i < ScriptProps.length; i++) {
            const prop = ScriptProps[i];

            if( this.props[prop.name] && prop.type === this.props[prop.name].type) {
                props[prop.name] = this.props[prop.name]?.value?.getJson();
            }
            else {
                props[prop.name] = undefined;
            }
        }
        return props;
    }

    fromJson(scriptProps:IScriptAssetProp[], jsonProps: {[name: string]: any}) {
        if(scriptProps) {
            const props = scriptProps;
            const propsMap: {[key:string]: boolean} = {};
            for(let i = 0; i < props.length; i++) {
                const prop = props[i];
                this.initProp(prop, jsonProps[prop.name]);
                propsMap[prop.name] = true;
            }

            for(let k in this.props) {
                if( !propsMap[k] ) {
                    this.props[k]?.value?.destroy();
                    delete this.props[k];
                }
            }
        }
        else {
            for(let k in this.props) {
                this.props[k]?.value?.destroy();
                delete this.props[k];
            }
        }
    }

    onCompleteJson(scriptProps:IScriptAssetProp[], json: IScriptJson, refers : TJsonRefers) {
        if(scriptProps) {
            for(let i = 0; i < scriptProps.length; i++) {
                const prop = scriptProps[i];
                if(prop.type === 'GameObject') {
                    const go = refers[json.props[prop.name]] as GameObject;
                    this.props[prop.name]?.value?.SetValue( go );
                }
                else if(prop.type === 'Instance') {
                    const go = refers[json.props[prop.name]] as FunctionManager;
                    this.props[prop.name]?.value?.SetValue( go );
                }
                else if(prop.type === 'Component') {
                    let comp = refers[json.props[prop.name]] as  Component;
                    if(comp && comp.name !== prop.componentType) {
                        comp = null;
                    }
                    this.props[prop.name]?.value?.SetValue( comp );
                }
            }
        }
    }


    destroy() {
        for(let k in this.props) {
            this.props[k].value?.destroy();
            delete this.props[k];
        }
        this.game = null;
    }
}
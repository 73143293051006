import GameObject, {IGameObjectJson} from "../game/scripts/gameObject/gameObject";
import {IComponentJson} from "../game/scripts/core/component";
import {treeManager} from "../components/panel/hierarchy/hierarchy";
import ModifyGameObjectCommand from "./command/modifyGameObjectCommand";
import ModifyComponentCommand from "./command/modifyComponentCommand";
import {ECtrlMode} from "../game/scripts/tools/editor/editorScene";
import EditorManager from "./editorManager";
import {store} from "../app/store";
import {changeSelectTool, changeZoomScale} from "../store/editorSlice";
import {changeHierarchySelectIds} from "../store/hierarchySlice";
import {IResizerBase} from "mogera-game/scripts/tools/editor/resizerManager";
import Animator from "mogera-game/scripts/component/animator";
import {changeSelectAnimatorIds} from "../store/modalSlice";


function resizerMoveCallback(resizer: IResizerBase) {
    if (!resizer.data.timeoutId && resizer.target?.gameObject) {
        resizer.data.timeoutId = window.setTimeout(() => {
            //todo: resizer 갱신
            store.dispatch(changeHierarchySelectIds([resizer.target.gameObject.id.toString()]));
            //EditorManager.Instance.onSelect([resizer.target.gameObject.id]);
            resizer.data.timeoutId = null;
        }, 200);
    }
    resizer.data.isChange = true;
}

function resizerMoveStartCallback(resizer: IResizerBase) {
    resizer.data.capture = resizer.target.gameObject.toJson(true);
    resizer.data.compCapture = resizer.target.toJson();
    resizer.data.isChange = false;
}

let firstClickTime = 0;
function resizerMoveEndCallback(resizer: IResizerBase, dbClick: boolean = false) {
    if(resizer.data.timeoutId) {
        window.clearTimeout(resizer.data.timeoutId);
        resizer.data.timeoutId = null;
    }
    if(resizer.target?.gameObject && resizer.data.isChange) {
        const command = new ModifyGameObjectCommand( resizer.data.capture, resizer.target.gameObject.toJson(true));
        command.execute();
        const command2 = new ModifyComponentCommand( resizer.target.gameObject.id, resizer.data.compCapture, resizer.target.toJson() );
        command2.execute();
        EditorManager.Instance.addCommand([command, command2]);
    }
    else if( dbClick && !resizer.data.isChange) {
        const time = Date.now();
        if(time - firstClickTime <= 250) {
            const animatorComp = resizer.target.gameObject.getComponent(Animator.Name) as Animator;
            const animator = animatorComp?.getAnimator();
            if(animatorComp && animator) {
                store.dispatch(changeSelectAnimatorIds([animator.id]));
            }
        }
        firstClickTime = time;
    }

}

function bodyResizerMoveCallback(resizer: IResizerBase){
    if (!resizer.data.timeoutId && resizer.target.gameObject) {
        resizer.data.timeoutId = window.setTimeout(() => {
            //todo: resizer 갱신
            store.dispatch(changeHierarchySelectIds([resizer.target.gameObject.id.toString()]));
            EditorManager.Instance.onSelect([resizer.target.gameObject.id]);
            resizer.data.timeoutId = null;
        }, 200);
    }
}
function bodyResizerMoveStartCallback(resizer: IResizerBase){
    resizer.data.compCapture = resizer.target.toJson();
}
function bodyResizerMoveEndCallback(resizer: IResizerBase){
    if(resizer.data.timeoutId) {
        window.clearTimeout(resizer.data.timeoutId);
        resizer.data.timeoutId = null;
    }
    if(resizer.target?.gameObject) {
        const command = new ModifyComponentCommand( resizer.target?.gameObject.id, resizer.data.compCapture,
            resizer.target.toJson() );
        command.execute();
        EditorManager.Instance.addCommand([command]);
    }
}

export function initEditorScene(editorManager : EditorManager) {
    editorManager.scene.onChangeCtrlModeCallback = (mode)=>{

        if(mode === ECtrlMode.resizer) {
            store.dispatch( changeSelectTool('renderer') );
        }
        else if(mode === ECtrlMode.bodyResizer) {
            store.dispatch( changeSelectTool('body') );
        }
    }

    editorManager.scene.onSelectCallback = (id: string)=>{
        const gameObject = editorManager.scene.findById( id ) as GameObject;
        if(gameObject) {
            let parent = gameObject.parent;
            while (parent) {
                if(parent instanceof GameObject) {
                    parent.expanded = true;
                }
                parent = parent.parent;
            }
            editorManager.createHierarchy(()=>{
                treeManager.tree?.selectById(id);
            });
            //todo ctrl 버튼눌러졌을때 멀티선택
        }
        else {
            treeManager.tree?.selectById(id);
        }
    };

    editorManager.scene.resizerManager.resizerMoveCallback = resizerMoveCallback;
    editorManager.scene.resizerManager.resizerMoveStartCallback = resizerMoveStartCallback;
    editorManager.scene.resizerManager.resizerMoveEndCallback = resizerMoveEndCallback;

    editorManager.scene.viewPort.wheelCallback = (scale: number)=>{
        store.dispatch(changeZoomScale(scale));
    }
}
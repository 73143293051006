import {Button, Modal, Image, Space, Row, Col, Select, Tooltip} from 'antd';
import {useState} from "react";
import {useAppSelector} from "../../app/hooks";
import { changeOpenProjectSetting, selectOpenProjectSetting } from "../../store/modalSlice";
import {useDispatch} from "react-redux";
import EditorManager from "../../services/editorManager";
import {
    DeleteOutlined,
    FileOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import AssetItem from "./assetItem";
import PointView from "../panel/propertyView/valueView/pointView";
import {
    changeCameraHeight,
    changeCameraWidth,
    changeGrid,
    selectCameraHeight,
    selectCameraWidth, selectFixedGridMove,
    selectGrid,
    changeFixedGridMove, selectProjectName, changeProjectName, selectCDNList, changeCDNList
} from "../../store/editorSlice";
import {changeEditorViewOption, selectEditorViewOption} from "../../store/editorSettingSlice";
import SelectView from "../panel/propertyView/valueView/selectView";
import ViewTooltip from "../panel/propertyView/viewTooltip";
import SizeView from "../panel/propertyView/valueView/sizeView";
import CheckboxView from "../panel/propertyView/valueView/checkboxView";
import InputView from "../panel/propertyView/valueView/inputView";
import {store} from "../../app/store";
import LocalizationManager from "../../services/localizationManager";
import TooltipManager from "../../services/tooltipManager";
import TextAreaView from "../panel/propertyView/valueView/textAreaView";
const { Option } = Select;

export default function ProjectSetting() {

    const isOpen = useAppSelector(selectOpenProjectSetting);
    const grid = useAppSelector(selectGrid);
    const fixedGridMove = useAppSelector(selectFixedGridMove);
    const cdnList = useAppSelector(selectCDNList);
    const viewOption = useAppSelector(selectEditorViewOption);
    const width = useAppSelector(selectCameraWidth);
    const height = useAppSelector(selectCameraHeight);
    const projectName = useAppSelector(selectProjectName);

    const dispatch = useDispatch();

    const handleOk = () => {
        dispatch(changeOpenProjectSetting(false));
    };

    const handleCancel = () => {
        dispatch(changeOpenProjectSetting(false));
    };

    const afterClose = () => {

    }

    return(<>
        <Modal title={LocalizationManager.Instance.getText('Edit.ProjectSetting') || 'Project Setting'}
               visible={isOpen}
               onOk={handleOk}
               onCancel={handleCancel}
               afterClose={afterClose}
               footer={[]}
        >
            <>
                <InputView
                    label={LocalizationManager.Instance.getText('ProjectSetting.title') || 'title'}
                    tooltip={  TooltipManager.Instance.getText('ProjectSetting.title') }
                    value={projectName} onChange={function (value){
                    dispatch(changeProjectName(value));
                }}></InputView>
                <PointView
                    label={LocalizationManager.Instance.getText('ProjectSetting.grid') || 'grid'}
                    tooltip={  TooltipManager.Instance.getText('ProjectSetting.grid') }
                    point={grid} onChange={function (x, y){
                    EditorManager.Instance.refreshGrid({x,y});
                }}></PointView>
                <CheckboxView
                    label={LocalizationManager.Instance.getText('ProjectSetting.gridMode') || 'gridMode'}
                    tooltip={  TooltipManager.Instance.getText('ProjectSetting.gridMode') }
                    checked={fixedGridMove} onChange={function (checked){
                    dispatch(changeFixedGridMove(checked));
                    EditorManager.Instance.refreshViewport();
                }}></CheckboxView>
                {/*<SelectView label={'viewOption'} value={viewOption} onChange={function (value){*/}
                {/*    dispatch(changeEditorViewOption(value));*/}
                {/*}} tooltip={*/}
                {/*    <ViewTooltip title={<span>프로젝트 보기 옵션을 변경합니다.*/}
                {/*        <br/>default:*/}
                {/*        <br/>advance:</span>}/>*/}
                {/*}>*/}
                {/*    <Option key={'default'} value={0}>default</Option>*/}
                {/*    <Option key={'advance'} value={1}>advance</Option>*/}
                {/*</SelectView>*/}
                <SizeView
                    label={LocalizationManager.Instance.getText('ProjectSetting.gameSize') || 'gameSize'}
                    tooltip={  TooltipManager.Instance.getText('ProjectSetting.gameSize') }
                    size={{width, height}} onChange={function (w, h){
                    dispatch(changeCameraWidth(w));
                    dispatch(changeCameraHeight(h));
                    EditorManager.Instance.refreshViewport();
                }}></SizeView>
                <TextAreaView
                    label={LocalizationManager.Instance.getText('ProjectSetting.cdnList') || 'cdnList'}
                    value={cdnList}
                    onChange={function (str){
                        dispatch(changeCDNList(str));
                    }}></TextAreaView>
            </>
        </Modal>
    </>)
}
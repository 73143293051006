import * as PIXI from 'pixi.js';
import '@pixi/sound';
import SceneManager from "./sceneManager";
import Factory from "../factory";
import ComponentManager from "./componentManager";
import InstanceManager from "./instanceManager";
import AssetManager from "../asset/assetManager";
import InputManager from "./inputManager";
import Physics from "./physics";
import PostUpdate from "./postUpdate";

export interface IEventLoaderResource extends PIXI.LoaderResource {
    event : PIXI.utils.EventEmitter,
}

export default class Game {

    private _renderer : PIXI.Renderer;
    public get renderer() : PIXI.Renderer
    {
        return this._renderer;
    }

    private _ticker : PIXI.Ticker;
    public get ticker() : PIXI.Ticker
    {
        return this._ticker;
    }

    private _loader : PIXI.Loader;
    public get loader() : PIXI.Loader
    {
        return this._loader;
    }

    private _stage : PIXI.Container;
    public get stage() : PIXI.Container {
        return this._stage;
    }

    private _sceneManager : SceneManager;
    public get scenes() : SceneManager {
        return this._sceneManager;
    }

    private _factory : Factory;
    public get factory() : Factory {
        return this._factory;
    }

    private _componentManager : ComponentManager;
    public get components() : ComponentManager {
        return this._componentManager;
    }

    private _instanceManager : InstanceManager;
    public get instanceManager() : InstanceManager {
        return  this._instanceManager;
    }

    private _assetManager : AssetManager;
    public get assetManager(): AssetManager {
        return this._assetManager;
    }

    private _physics: Physics;
    public get physics(): Physics {
        return this._physics;
    }

    private _input: InputManager;
    public get input(): InputManager {
        return this._input;
    }

    private _globalVariables: any = {};
    public get globalVariables(): any {
        return this._globalVariables;
    }

    private _signal: PIXI.utils.EventEmitter = new PIXI.utils.EventEmitter();
    public get signal(): PIXI.utils.EventEmitter {
        return this._signal;
    }

    private _postUpdate: PostUpdate = new PostUpdate();
    public get postUpdate(): PostUpdate {
        return this._postUpdate;
    }

    private _pause: boolean = false;
    public get pause(): boolean {
        return this._pause;
    }
    public set pause(p: boolean) {
        this._pause = p;
    }

    constructor(options : PIXI.IApplicationOptions) {
        this._renderer = PIXI.autoDetectRenderer(options) as PIXI.Renderer;
        this._ticker = new PIXI.Ticker();
        this._stage = new PIXI.Container();
        this._loader = new PIXI.Loader();
        this._physics = new Physics(this);

        this._loader.onLoad.add((loader, resource)=> {
            const res = resource as IEventLoaderResource;
            res.event = new PIXI.utils.EventEmitter();
        })

        this._instanceManager = new InstanceManager();
        this._assetManager = new AssetManager();
        this._sceneManager = new SceneManager(this);
        this._factory = new Factory(this);
        this._componentManager = new ComponentManager();
        this._input = new InputManager(this);

        this._ticker.add( this.update, this );
        this._ticker.start();
    }

    update(dt : number) : void {
        if(!this._pause) {
            const delta = dt/60;
            this.scenes.update(delta);
        }
        this.input.update();
        this.scenes.render(this._renderer);

        this._postUpdate.update();
    }

    destroy(removeView? : boolean) {
        this._componentManager.destroy();
        this._factory.destroy();
        this._sceneManager.destroy();
        this._input.destroy();

        this._loader.destroy();
        this._stage.destroy();
        this._ticker.destroy();
        this._renderer.destroy(removeView);
        this._instanceManager.destroy();
        this._assetManager.destroy();

        this._physics.destroy();

        this._postUpdate.destroy();
        this._postUpdate = null;

        this._physics = null;
        this._renderer = null;
        this._ticker = null;
        this._stage = null;
        this._loader = null;
        this._input = null;

        this._sceneManager = null;
        this._factory = null;
        this._componentManager = null;
        this._instanceManager = null;
    }

}
import {Button, Col, Input, Row, Typography} from "antd";
import EditorManager from "../../../../../services/editorManager";
import TextureAsset from "../../../../../game/scripts/asset/textureAsset";
import {
    DeleteOutlined,
    DeleteTwoTone,
    DeleteFilled
} from '@ant-design/icons';
import {ReactElement} from "react";
import ViewTooltip from "../../viewTooltip";

const {Text} = Typography;

export default function TextureAssetView({label, disabled, texture, onClick, onRemove, tooltip}:
    {label: string, disabled?: boolean, texture?: string, onClick: Function, onRemove: Function, tooltip?:string}) {

    const spriteAsset = EditorManager.Instance.game.assetManager.getAsset(texture) as TextureAsset;

    return <Row wrap={false} className={"valueContainer"}>
        <Col className={'valueLabel'}>
            {
                tooltip? <ViewTooltip title={tooltip}>
                    <Text style={{marginRight: '5px'}}>{label}</Text>
                </ViewTooltip> :  <Text style={{marginRight: '5px'}}>{label}</Text>
            }
        </Col>
        <Col className={'valueBody'} flex="auto">
            <Input readOnly
                   status="warning"
                   className={'inputRef'}
                   disabled={disabled}
                   value={spriteAsset?.name}
                   onClick={()=>{onClick()}}
                   placeholder={'None(TextureAsset)'}
                   prefix={spriteAsset ?
                       <img width={22} height={22} src={spriteAsset.resource.url}/>:<></>}
            >
            </Input>
        </Col>
        {
            spriteAsset ? <Col>
                <Button style={{marginLeft: '5px'}} shape={'circle'} onClick={()=>{
                    onRemove();
                }}><DeleteFilled /></Button>
            </Col> : null
        }
    </Row>
}
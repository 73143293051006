import PropTypes from 'prop-types';
import {IComponentJson} from "../../../../game/scripts/core/component";
import {ISpriteJson} from "../../../../game/scripts/component/sprite";
import {Button, Input, InputNumber, Space, Switch} from "antd";
import EditorManager from "../../../../services/editorManager";
import SpriteView from "./spriteView";
import CameraView from "./cameraView";
import ScriptView from "./scriptView";
import BodyView from "./bodyView";
import {IShapeJson} from "../../../../game/scripts/component/shape";
import ShapeView from "./shapeView";
import {IScriptJson} from "../../../../game/scripts/component/script";
import ScriptAsset from "../../../../game/scripts/asset/scriptAsset";
import {IAnimatorJson} from "../../../../game/scripts/component/animator";
import AnimatorView from "./animatorView";
import {ITextJson} from "../../../../game/scripts/component/text";
import TextView from "./textView";
import ComponentBox from './componentBox';
import {ITextAnimationJson} from "../../../../game/scripts/component/textAnimation";
import TextAnimationView from "./textAnimationView";
import {IButtonJson} from "../../../../game/scripts/component/button";
import ButtonView from "./buttonView";
import {IBodyJson} from "../../../../game/scripts/component/body";
import {INineSliceJson} from "../../../../game/scripts/component/nineSlice";
import NineSliceView from "./nineSliceView";
import {
    FileImageTwoTone,
    FileImageOutlined,
    FontSizeOutlined,
    ContainerOutlined,
    VideoCameraOutlined,
    TableOutlined,
    PictureOutlined,
    ShareAltOutlined ,
    FormatPainterOutlined,
    AimOutlined,
    NodeExpandOutlined,
    BoldOutlined,
    CompressOutlined,
} from '@ant-design/icons';
import {ITilingSpriteJson} from "../../../../game/scripts/component/tilingSprite";
import TilingSpriteView from "./tilingSpriteView";

export interface IComponentViewProps {
    json: IComponentJson,
    disableComp?: boolean,
    idx?: number,
}

export default function ComponentView({json, disableComp, idx}: IComponentViewProps) {

    let detail = null;
    let element = null;
    let icon = null;

    if(json.name === 'Sprite' && json as ISpriteJson) {
        if(json.expanded) {
            detail = <SpriteView json={json}></SpriteView>
        }
        icon = <PictureOutlined style={{marginRight: '5px'}}/>
    }
    else if(json.name === 'NineSlice' && json as INineSliceJson) {
        if(json.expanded) {
            detail = <NineSliceView json={json}></NineSliceView>
        }
        icon = <TableOutlined style={{marginRight: '5px'}}/>
    }
    else if(json.name === 'TilingSprite' && json as ITilingSpriteJson) {
        if(json.expanded) {
            detail = <TilingSpriteView json={json}></TilingSpriteView>
        }
        icon = <TableOutlined style={{marginRight: '5px'}}/>
    }
    else if(json.name === 'Camera' && json as ISpriteJson) {
        if(json.expanded){
            detail = <CameraView json={json}></CameraView>
        }
        icon = <VideoCameraOutlined style={{marginRight: '5px'}}/>
    }
    else if(json.name === 'Script' && json as IScriptJson) {
        if(json.expanded){
            const scriptAsset = EditorManager.Instance.game.assetManager.getAsset((json as IScriptJson).script) as ScriptAsset;
            detail = <ScriptView scriptName={scriptAsset?.name} key={scriptAsset?.id} json={json}></ScriptView>
        }
        icon = <ContainerOutlined  style={{marginRight: '5px'}}/>
    }
    else if(json.name === 'Body' && json as IBodyJson) {
        if(json.expanded){
            detail = <BodyView json={json}></BodyView>
        }
        icon = <ShareAltOutlined style={{marginRight: '5px'}}/>
    }
    else if(json.name === 'Shape' && json as IShapeJson) {
        if(json.expanded){
            detail = <ShapeView json={json}></ShapeView>
        }
        icon = <FormatPainterOutlined  style={{marginRight: '5px'}}/>
    }
    else if(json.name === 'Animator' && json as IAnimatorJson) {
        if(json.expanded){
            detail = <AnimatorView json={json}></AnimatorView>
        }
        icon = <AimOutlined  style={{marginRight: '5px'}}/>
    }
    else if(json.name === 'Text' && json as ITextJson) {
        if(json.expanded){
            detail = <TextView json={json}></TextView>
        }
        icon = <FontSizeOutlined style={{marginRight: '5px'}}/>
    }
    else if(json.name === 'TextAnimation' && json as ITextAnimationJson) {
        if(json.expanded){
            detail = <TextAnimationView json={json}></TextAnimationView>
        }
        icon = <NodeExpandOutlined style={{marginRight: '5px'}}/>
    }
    else if(json.name === 'Button' && json as IButtonJson) {
        if(json.expanded){
            detail = <ButtonView json={json}></ButtonView>
        }
        icon = <BoldOutlined style={{marginRight: '5px'}}/>
    }
    else {
        icon = <CompressOutlined style={{marginRight: '5px'}}/>
    }

    let name = null;
    let isScript = false;
    if(json.name === 'Script') {
        const scriptJson = json as IScriptJson;
        const script = EditorManager.Instance.game.assetManager.getAsset( scriptJson.script ) as ScriptAsset;
        name = script?.name;
        isScript = true;
    }
    else {
        name = json.name;
    }

    return( <ComponentBox name={name} json={json} onChange={
        function (checked){
            json.expanded = checked;
            EditorManager.Instance.modifyComponent(json);
        }} onChangeActive={ isScript ? function (){
            json.active = !json.active;
            EditorManager.Instance.modifyComponent(json);
        } : null} onRemove={ disableComp ? null : function (){
            EditorManager.Instance.removeComponent( json );
        }} icon={icon} dropDown={true} idx={idx}>
        {detail}
    </ComponentBox>)
}
import {Typography, Row, Col, Input, Space, InputNumber} from "antd";
import {ReactElement} from "react";
import ViewTooltip from "../viewTooltip";

const {Text} = Typography;

export default function InputNumberView({ label, value, onChange, disabled, onClick, tooltip }:
    {label:string, value:number, onChange:(value:number)=>void, disabled?:boolean, onClick?:Function, tooltip?:string,}) {

    return <Row wrap={false} className={"valueContainer"}>
        <Col className={'valueLabel'}>
            {
                tooltip? <ViewTooltip title={tooltip}>
                    <Text style={{marginRight: '5px'}}>{label}</Text>
                </ViewTooltip> :  <Text style={{marginRight: '5px'}}>{label}</Text>
            }
        </Col>
        <Col className={'valueBody'} flex="auto">
            <InputNumber style={{width:'100%'}} disabled={disabled} value={Number(value?.toFixed(4))} onChange={function (num){
                onChange && onChange(num);
            }} onClick={()=>{onClick && onClick()}}></InputNumber>
        </Col>
    </Row>
}
import { Typography } from 'antd';
import {Button} from "@mui/material";
const { Title } = Typography;
const { Text, Link } = Typography;
import { RightOutlined, LeftOutlined  } from '@ant-design/icons';
import LocalizationManager from "../../../services/localizationManager";

export default function WizardPage({title, description, children, onPrev, onNext, prevText, nextText}: {
    title: string, description: string, children: any,
    onPrev?: Function, onNext?: Function, prevText?: string, nextText?: string,
}) {
    return <div className={'WizardPage'}>
            <div className={'footerButtons'}>
                <Button className={'prev'}
                        disabled={!onPrev}
                        variant="outlined"
                        size="large"
                        startIcon={<LeftOutlined />}
                        onClick={function (){
                            onPrev();
                        }}><span className={'text'}>{prevText? prevText:'이전'}</span></Button>
                <Title level={1}>{title}</Title>

                <Button className={'next'}
                        disabled={!onNext}
                        variant="outlined"
                        size="large"
                        endIcon={<RightOutlined />}
                        onClick={function (){
                            onNext();
                        }}><span className={'text'}>{nextText? nextText: '다음'}</span></Button>
            </div>
        <Text>{description}</Text>
        <div style={{marginTop: '50px', minHeight: '560px'}}>
            {children}
        </div>

    </div>
}
import Command from "./command";
import Game from "../../game/scripts/core/game";
import GameObject, {IGameObjectJson} from "../../game/scripts/gameObject/gameObject";
import EditorManager from "../editorManager";
import {store} from "../../app/store";
import {treeManager} from "../../components/panel/hierarchy/hierarchy";

export default class RemoveGameObjectCommand extends Command {
    private parentId: string
    private gameObjectId: string;
    private json: IGameObjectJson;
    private childIdx: number;

    constructor(gameObjectId: string) {
        super();
        this.gameObjectId = gameObjectId;
    }

    execute() {
        const gameObject = EditorManager.Instance.findGameObject(this.gameObjectId);
        this.json = gameObject.toJson();
        this.parentId = (gameObject.parent as GameObject).id;
        this.childIdx = gameObject.parent.children.indexOf(gameObject);

        if( EditorManager.Instance.equalHierarchyId(this.gameObjectId.toString())) {
            treeManager.tree?.selectById(undefined);
        }

        gameObject.destroy();
        EditorManager.Instance.createHierarchy();
    }

    undo() {
        const parent = EditorManager.Instance.findGameObject(this.parentId);
        const gameObject = new GameObject(EditorManager.Instance.game);
        this.gameObjectId = gameObject.id;
        parent.addChildAt( gameObject, this.childIdx );
        parent.expanded = true;
        gameObject.fromJson(this.json);

        EditorManager.Instance.createHierarchy(()=>{
            treeManager.tree?.selectById(gameObject.id);
        });
    }


}
import './propertyView.css';
import {useAppSelector} from "../../../app/hooks";
import {selectHierarchySelectIds} from "../../../store/hierarchySlice";
import EditorManager from "../../../services/editorManager";
import {Button, Checkbox, Dropdown, Input, InputNumber, Menu, Row, Space, Typography} from 'antd';
import {IComponentConstructor} from "../../../game/scripts/core/component";
import {useDispatch} from "react-redux";
import ComponentView from "./componentView/componentView";
import {EEditorViewOption, selectEditorViewOption} from "../../../store/editorSettingSlice";
import GameObject, {editable, EEditable, IGameObjectJson} from "../../../game/scripts/gameObject/gameObject";
import PointView from "./valueView/pointView";
import { DndProvider  } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {ItemType} from "antd/lib/menu/hooks/useItems";
import ScriptAsset from "../../../game/scripts/asset/scriptAsset";
import {DownOutlined, RightOutlined} from '@ant-design/icons';
import {useEffect} from "react";
import CheckboxView from "./valueView/checkboxView";
import InputView from "./valueView/inputView";
import InputNumberView from "./valueView/inputNumberView";
import GameObjectBox from "./componentView/gameObjectBox";
import {isReadOnly} from "./isView";
import AddComponentButton from "./addComponentButton";
import {selectSelectPrefabIds} from "../../../store/editorSlice";

const { Text } = Typography;





export default function PropertyView() {

    const editorViewOption = useAppSelector(selectEditorViewOption);
    const selectIds = useAppSelector(selectHierarchySelectIds);
    const selectPrefabIdx = useAppSelector(selectSelectPrefabIds);
    const target = EditorManager.Instance.getSelectPropertyTarget();
    const dispatch = useDispatch();

    let render = null;
    if(selectIds.length > 1) {

    }
    else if(target instanceof GameObject) {
        const json = target.toJson();
        const disableOptions : {[key:string]: EEditable } = {};
        for(let i = 0; i< json.disableList.length; i++) {
            const option = json.disableList[i];
            disableOptions[option.key] = option.editType;
        }

        const disableComp = !editable(target,'component');

        const components = json.components;
        const componentRender = [];
        for(let i = 0; i < components.length; i++) {
            const comp = components[i];
            componentRender.push(<ComponentView key={i.toString()} json={comp} disableComp={disableComp} idx={i}></ComponentView>)
        }

        render = <>
            <GameObjectBox disableOptions={disableOptions} json={json} editorViewOption={editorViewOption}/>
            {componentRender}
            <AddComponentButton name={target.name} disableOptions={disableOptions} editorViewOption={editorViewOption}/>
        </>
    }

    return(<div className={"PropertyViewRoot scrollType2"}>
        <DndProvider backend={HTML5Backend}>
            {render}
        </DndProvider>
    </div>)
}
import * as PIXI from 'pixi.js';

import Component, {IComponentConstructor, IComponentJson} from "../core/component";
import GameObject, {TJsonRefers} from "../gameObject/gameObject";
import {component, property} from "../util/decorator";
import {IEventLoaderResource} from "../core/game";
import TextureAsset from "../asset/textureAsset";
import EditorScene, {ECtrlMode} from "../tools/editor/editorScene";
import {SetEditorInteraction} from "../tools/editor/editorScene";

export interface INineSliceJson extends IComponentJson {
    texture: string;
    width: number;
    height: number;
    tint: number,
    alpha: number,
    border: {l:number, t:number, r: number, b: number};
}

@component('NineSlice')
export default class NineSlice extends Component {
    private _sprite : PIXI.NineSlicePlane
    public get sprite() : PIXI.NineSlicePlane {
        return this._sprite;
    }

    @property(TextureAsset)
    public texture: TextureAsset
    private __texture : TextureAsset;
    private get _texture(): TextureAsset {
        return this.__texture;
    }
    private set _texture(texture: TextureAsset) {
        this.__texture = texture;
        if(texture) {
            this._sprite.texture = texture.texture;
        }
        else {
            this._sprite.texture = PIXI.Texture.WHITE;
        }
    }

    public get tint(): number {
        return this.sprite.tint;
    }
    public set tint(color: number) {
        this._sprite.tint = color;
    }

    public get alpha(): number {
        return this.sprite.alpha;
    }
    public set alpha(a: number) {
        this.sprite.alpha = a;
    }

    public get width(): number {
        return this.sprite.width;
    }
    public get height(): number {
        return this.sprite.height;
    }

    public set width(w: number) {
        this.sprite.width = w;
    }
    public set height(h: number) {
        this.sprite.height = h;
    }

    public get scale() : PIXI.Point {
        return this.sprite.scale;
    }


    // public get flipX(): boolean {
    //     return this.sprite.scale.x < 0;
    // }
    // public set flipX(v:boolean) {
    //     if(this.sprite.scale.x < 0) {
    //         this.sprite.scale.x *= v ? 1 : -1;
    //     }
    //     else {
    //         this.sprite.scale.x *= v ? -1 : 1;
    //     }
    // }
    // public get flipY(): boolean {
    //     return this.sprite.scale.y < 0;
    // }
    // public set flipY(v:boolean) {
    //     if(this.sprite.scale.y < 0) {
    //         this.sprite.scale.y *= v ? 1 : -1;
    //     }
    //     else {
    //         this.sprite.scale.y *= v ? -1 : 1;
    //     }
    // }

    /* internal */
    create() {
        this._sprite = new PIXI.NineSlicePlane( PIXI.Texture.WHITE );
        this.sprite.visible = this.gameObject.worldActive;
        (this.gameObject as PIXI.utils.EventEmitter).on('added', this.onAdded, this );
        // (this.sprite as PIXI.NineSlicePlane).anchor.set(0.5);
        this.gameObject.addChildAt(this.sprite, 0);

        if(this.game.scenes.crtScene instanceof EditorScene) {
            SetEditorInteraction( this._sprite, this.gameObject );
        }
    }

    awake() {
    }

    private onAdded() {
        this.sprite.visible = this.gameObject.worldActive;
    }

    destroy() {
        (this.gameObject as PIXI.utils.EventEmitter).off('added', this.onAdded, this );
        this.texture = null;
        this.sprite.destroy();
        this._sprite = null;
        super.destroy();
    }

    onEnable() {
        super.onEnable();
        if(this.sprite){
            this.sprite.visible = true;
        }
    }

    onDisable() {
        super.onDisable();
        if(this.sprite){
            this.sprite.visible = false;
        }
    }

    toJson() : INineSliceJson {
        const json : INineSliceJson = super.toJson() as INineSliceJson;
        if(this.texture) {
            json.texture = this.texture.id;
        }

        // json.flipX = this.flipX;
        // json.flipY = this.flipY;

        if( this.sprite.texture?.baseTexture ) {
            json.width = this.width;
            json.height = this.height;
        }

        json.tint = this.tint;
        json.alpha = this.alpha;

        json.border = {
            l: this.sprite.leftWidth,
            t: this.sprite.topHeight,
            r: this.sprite.rightWidth,
            b: this.sprite.bottomHeight,
        }

        return json;
    }

    fromJson(json : INineSliceJson, refers: TJsonRefers) {
        super.fromJson(json, refers);

        this.sprite.leftWidth = json.border.l;
        this.sprite.topHeight = json.border.t;
        this.sprite.rightWidth = json.border.r;
        this.sprite.bottomHeight = json.border.b;

        if(json.texture) {
            const asset = this.game.assetManager.getAsset(json.texture) as TextureAsset;
            if(asset) {
                this.texture = asset;
            }
            else{
                this.texture = null;
            }

        }
        else {
            this.texture = null;
        }

        // this.flipX = json.flipX;
        // this.flipY = json.flipY;

        this.tint = json.tint || 0xffffff;
        if(json.alpha === undefined) {
            json.alpha = 1;
        }
        this.alpha = json.alpha;
        this.width = json.width;
        this.height = json.height;
    }
}
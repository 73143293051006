import ImageList from "./imageList";
import SoundList from "./soundList";
import SceneList from "./sceneList";
import ScriptEditor from "./script/scriptEditor";
import AnimationEditor from "./animationEditor";
import AnimatorList from "./animatorList";
import ProjectSetting from "./projectSetting";
import FontList from "./fontList";
import {Tabs} from "antd";
import ScriptTooltip from "./script/scriptTooltip";
import ScriptList from "./scriptList";
import AnimationPreview from "./animationPreview";
import EditorSetting from "./editorSetting";

export default function Modal() {
    return(<>
        <ImageList></ImageList>
        <SoundList></SoundList>
        <SceneList></SceneList>
        <ScriptEditor></ScriptEditor>
        <AnimatorList></AnimatorList>
        <AnimationEditor></AnimationEditor>
        <ProjectSetting></ProjectSetting>
        <EditorSetting></EditorSetting>
        <FontList></FontList>
        <ScriptList></ScriptList>
        <AnimationPreview></AnimationPreview>
    </>)
}
import { useCallback, useMemo, useState } from "react";
import TreeModel from "tree-model-improved";
import {changeHierarchyList, HierarchyData, selectHierarchyList} from "../../../store/hierarchySlice";
import {useDispatch, useSelector} from "react-redux";
import {useAppSelector} from "../../../app/hooks";
import EditorManager from "../../../services/editorManager";
import {json} from "stream/consumers";

function findById(node: any, id: string): TreeModel.Node<HierarchyData> | null {
    return node.first((n: any) => n.model.id === id);
}

function checkParent(
    src: TreeModel.Node<HierarchyData>,
    dstParent: TreeModel.Node<HierarchyData>): boolean {

    let parent = dstParent;
    let isParent = false;

    while (parent) {
        if( src === parent ) {
            isParent = true;
        }
        parent = parent.parent;
    }

    return isParent;
}

// const data : HierarchyData = {
//     id: '1',
//     key: '0',
//     isOpen: true,
//     name: 'root',
//     children: [
//         {
//             id: '2',
//             key: '0-0',
//             isOpen: true,
//             name: 'child1',
//             children: [
//                 {
//                     id: '3',
//                     key: '0-0-0',
//                     isOpen: false,
//                     name: 'child2',
//                     children: [
//
//                     ],
//                 },
//                 {
//                     id: '4',
//                     key: '0-0-1',
//                     isOpen: false,
//                     name: 'child3',
//                     children: [
//                         {
//                             id: '5',
//                             key: '0-0-1-0',
//                             isOpen: false,
//                             name: 'child5',
//                             children: [
//
//                             ],
//                         },
//                     ],
//                 },
//                 {
//                     id: '6',
//                     key: '0-0-2',
//                     isOpen: false,
//                     name: 'child4',
//                     children: [
//
//                     ],
//                 }
//             ],
//         }
//     ],
// }
//
// // const initData = data;

export default function useBackend() {

    const data = useAppSelector(selectHierarchyList) || {
        name: '',
        isOpen: false,
        isLock: false,
        id: '0',
        children:[],
        active: true,
        editName: false,
        editActive: false,
    };

    // const [data, setData] = useState<HierarchyData>(initData as HierarchyData);
    const root = useMemo(() => new TreeModel().parse(data ), [data]);
    const find = useCallback((id : string) => findById(root, id), [root]);


    return{
        data: data,
        onMove: (
            srcIds: string[],
            dstParentId: string | null,
            dstIndex: number
        ) => {
            let arr : {
                srcId: string,
                dstId: string,
                dstIdx: number
            }[] = [];

            for (const srcId of srcIds) {
                const src = find(srcId);
                const dstParent = dstParentId ? find(dstParentId) : root;
                if (!src || !dstParent) return;
                if(checkParent(src, dstParent)) return;
                arr.push({
                    srcId,
                    dstIdx: dstIndex,
                    dstId: dstParentId,
                });
                //
                // const newItem = new TreeModel().parse(src.model);
                // dstParent.addChildAtIndex(newItem, dstIndex);
                // src.drop();
            }
            // update();
            if(arr.length) {
                EditorManager.Instance.moveHierarchy( arr.reverse() );
            }
        },

        onToggle: (id: string, isOpen: boolean) => {
            const node = find(id);
            if (node) {
                const go = EditorManager.Instance.findGameObject(id);
                const json = go.toJson();
                if( json.expanded !== isOpen) {
                    json.expanded = isOpen;
                    EditorManager.Instance.modifyGameObject( json, true );
                }
            }
        },

        onEdit: (id: string, name: string) => {
            const node = find(id);
            if (node) {
                const go = EditorManager.Instance.findGameObject(id);
                const json = go.toJson();
                if( json.name !== name ) {
                    json.name = name;
                    EditorManager.Instance.modifyGameObject( json, true );
                }
                // node.model.name = name;
                // update();
            }
        },
    };
}
import * as PIXI from 'pixi.js';
import Game from "../../core/game";
import GameObject, {EEditable, IGameObjectJson} from "../../gameObject/gameObject";
import Layer from "../../gameObject/layer";
import Sprite, {ISpriteJson} from "../../component/sprite";
import Scene, {ISceneJson} from "../../gameObject/scene";
import Camera from "../../component/camera";
import Resizer from "./resizer";
import Viewport from "./viewport";
import BodyResizer from "./bodyResizer";
import Shape from "../../component/shape";
import GraphicResizer from "./graphicResizer";
import TextResizer from "./textResizer";
import Text from "../../component/text";
import Body from "../../component/body";
import NineSlice from "../../component/nineSlice";
import NineSliceResizer from "./nineSliceResizer";
import TilingSpriteResizer from "./tilingSpriteResizer";
import TilingSprite from "../../component/tilingSprite";
import ResizerManager from "./resizerManager";

export enum ECtrlMode {
    resizer,
    bodyResizer
}

export function SetEditorInteraction( target: PIXI.Sprite | PIXI.Graphics | PIXI.Text | PIXI.NineSlicePlane, gameObject: GameObject ) {
    if(!gameObject.locked) {
        //@ts-ignore
        target.interactive = true;
    }

    let down = false;
    //@ts-ignore
    target.on('pointerdown', (event)=>{
        down = true;
        (gameObject.game.scenes.crtScene as EditorScene).isDown = true;
    });
    //@ts-ignore
    target.on('pointerup', (event)=>{
        if(down) {
            down = false;
            (gameObject.game.scenes.crtScene as EditorScene).ctrlMode = ECtrlMode.resizer;
            (gameObject.game.scenes.crtScene as EditorScene).onSelect(gameObject);
        }
        down = false;
        (gameObject.game.scenes.crtScene as EditorScene).isDown = false;
    });
    //@ts-ignore
    target.on('pointerout', (event)=>{
        down = false;
        (gameObject.game.scenes.crtScene as EditorScene).isDown = false;
    });

    function locked() {
        //@ts-ignore
        target.interactive = false;
    }

    function unlocked() {
        //@ts-ignore
        target.interactive = true;
    }

    (gameObject as PIXI.utils.EventEmitter).on('locked', locked );
    (gameObject as PIXI.utils.EventEmitter).on('unlocked', unlocked );
    (gameObject as PIXI.utils.EventEmitter).on('destroy', ()=>{
        (gameObject as PIXI.utils.EventEmitter).off('locked', locked );
        (gameObject as PIXI.utils.EventEmitter).off('unlocked', unlocked );
    });
}

export default class EditorScene extends Scene {

    private _ctrlMode: ECtrlMode = ECtrlMode.resizer;
    public get ctrlMode(): ECtrlMode {
        return this._ctrlMode;
    }

    public onChangeCtrlModeCallback : (mode: ECtrlMode)=>void = null;

    public set ctrlMode(mode: ECtrlMode) {
        if(mode !== this._ctrlMode) {
            this._ctrlMode = mode;
            this.resizerManager.onChangeCtrlMove(this._ctrlMode);
            this.onChangeCtrlModeCallback && this.onChangeCtrlModeCallback(mode);
        }
    }

    private _targets : GameObject[] = [];
    public get targets(): GameObject[] {
        return this._targets;
    }

    private _isDown: boolean = false;
    public get isDown():boolean {
        return this._isDown;
    }
    public set isDown(down: boolean) {
        this._isDown = down;
    }

    private _viewPort: Viewport;
    public get viewPort(): Viewport {
        return this._viewPort;
    }

    public onSelectCallback: (id: string)=>void = null;

    private _resizerManager: ResizerManager;
    public get resizerManager(): ResizerManager {
        return this._resizerManager;
    }

    private debugGraphics : PIXI.Graphics = new PIXI.Graphics();

    constructor(game : Game, name : string = '') {
        super(game, name);

        this._resizerManager = new ResizerManager(this);
        this.addChild(this._resizerManager);

        this.addChild(this.debugGraphics);

        this._viewPort = new Viewport(this);
        this.addChild(this._viewPort);
    }

    //게임에서 밖으로 전달..
    onSelect( gameObject: GameObject ) {
        this.onSelectCallback && this.onSelectCallback(gameObject?.id);
    }

    //
    setSelect( gameObjects: GameObject[], refresh: boolean = true ) {

        if(!refresh && this.targets.length === gameObjects?.length) {
            for(let i = 0; i < gameObjects.length; i++) {
                if(this.targets[i] !== gameObjects[i]) break;
                if(i === gameObjects.length - 1) {
                    return;
                }
            }
        }

        if( gameObjects?.length ) {
            this.targets.length = 0;
            for(let i = 0; i < gameObjects.length; i++) {
                this.targets.push(gameObjects[i]);
            }
        }
        else {
            this.targets.length = 0;
        }

        this.resizerManager.setTarget(this.targets, this);
    }


    updateCamera(renderer: PIXI.Renderer) {
        if( this.destroyed ) {
            return;
        }

        this._center.x = -renderer.width/2 + this.viewPort.offsetX;      //뷰포트 크기만큼 위치 이동
        this._center.y = -renderer.height/2 + this.viewPort.offsetY;

        this._center.updateTransform();
        this._camera.updateTransform();
        // this._fixed.updateTransform();

        const tr1 = this._camera.transform.worldTransform.clone();
        const tr2 = this._center.transform.worldTransform.clone();
        tr1.append( tr2 );
        tr1.invert();

        this._world.transform.setFromMatrix( tr1 );
        this._world.updateTransform();

        this._fixed.transform.setFromMatrix( tr1 );
        this._fixed.updateTransform();
    }

    destroy() {
        super.destroy();

        this._resizerManager = null;
        this.debugGraphics = null;
        this._viewPort = null;
        this._targets.length = 0;
    }

    update(delta: number) {
        // super.update(delta);

        this._resizerManager.update(this._ctrlMode);

        this._viewPort.update();

        this.debugGraphics.clear();
        if(this._targets.length) {
            for(let i = 0; i < this.targets.length; i++) {
                this._targets[i]._debugRender(this.debugGraphics);
            }
        }

    }

    // editorUpdate(delta: number) {
    //
    // }

    fromJson(json: ISceneJson) {
        super.fromJson(json);
        this.children.sort(function (a,b){
            if( a instanceof GameObject && !(b instanceof GameObject)) {
                return -1;
            }
            if( b instanceof GameObject && !(a instanceof GameObject)) {
                return 1;
            }
            else {
                return 0;
            }
        })

    }
}
import Component, {IComponentJson} from "../core/component";
import {component, property} from "../util/decorator";
import GameObject, {TJsonRefers} from "../gameObject/gameObject";
import ScriptAsset, {IScriptAssetProp} from "../asset/scriptAsset";
import * as PIXI from 'pixi.js';
import ScriptProp from "../util/scriptProp";
import {ActionFunctions, ScriptFunctions} from "../function/scriptFunctions";
import FunctionManager from "../function/functionManager";

export interface IScriptJson extends IComponentJson {
    script: string,
    props: {
        [name: string]: any
    },
}


function onDestroy() {
    //@ts-ignore
    (this.script as PIXI.utils.EventEmitter)?.off('destroy', onDestroy, this);
    //@ts-ignore
    (this.script as PIXI.utils.EventEmitter)?.off('change', onChange, this);
    //@ts-ignore
    this.script = null;
}


function onChange() {
    //@ts-ignore
    const json = this.toJson();
    //@ts-ignore
    this.fromJson(json);
}

@component('Script')
export default class Script extends Component {

    private _script : ScriptAsset;
    public get script(): ScriptAsset {
        return this._script;
    }
    public set script(asset: ScriptAsset) {
        (this.script as PIXI.utils.EventEmitter)?.off('destroy', onDestroy, this);
        (this.script as PIXI.utils.EventEmitter)?.off('change', onChange, this);
        this._script = asset;
        (this.script as PIXI.utils.EventEmitter)?.on('destroy', onDestroy, this);
        (this.script as PIXI.utils.EventEmitter)?.on('change', onChange, this);
    }

    private props: ScriptProp;
    private functionManager : FunctionManager;

    getProp(propName: string) : any {
        const prop = this.props.getProp(propName);
        if(prop !== undefined && prop.type === 'Value') {
            return prop.value.getValue();
        }
        return undefined;
    }

    create() {
        this.props = new ScriptProp(this.game);
        this.functionManager = new FunctionManager(this);
    }

    awake() {

    }
    start() {
        const func = this.script.func;
        const funcArgs: any = this.props.funcArgs;
        func.bind(this.gameObject.functionManager)(funcArgs,
            {
                ...this.functionManager.functions,
            },
        );
    }
    update(delta: number) {
        this.functionManager.event._update(delta);
    }
    onEnable() {
        this.functionManager.event._enable();
    }
    onDisable() {
        this.functionManager.event._disable();
    }

    destroy() {
        this.functionManager.destroy();
        this.functionManager = null;

        this.props.destroy();
        this.props = null;

        this.script = null;
        super.destroy();
    }

    toJson() : IScriptJson {
        const json = super.toJson() as IScriptJson;

        if( this.script ) {
            json.script = this.script.id;
            const scriptJson = this.script.json;
            const props = scriptJson.props;
            json.props = this.props.toJson( props );
        }
        return json;
    }

    fromJson(json : IScriptJson, refers: TJsonRefers) {
        super.fromJson(json, refers);
        if(json.script) {
            const asset = this.game.assetManager.getAsset(json.script) as ScriptAsset;
            if( asset ) {
                this.script = asset;
                const props = asset.json.props;
                this.props.fromJson(props, json.props );
            }
            else {
                this.props.fromJson(undefined, json.props );
                console.log( `${json.script} 스크립트를 찾을 수 없습니다.` );
            }
        }
        else {
            this.props.fromJson(undefined, json.props );
        }
    }

    onCompleteJson(json: IScriptJson, refers : TJsonRefers) {
        if(this.script) {
            this.props.onCompleteJson( this.script.json.props, json, refers );
        }
    }
}
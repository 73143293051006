import * as PIXI from 'pixi.js';
import Game from "../core/game";
import Component, {IComponentConstructor, IComponentJson} from "../core/component";
import GameError, {GameErrorType} from "../util/GameError";
import Script, {IScriptJson} from "../component/script";
import {Tween} from "@tweenjs/tween.js";
import ActionManager from "../core/actionManager";
import {b2Vec2} from "@box2d/core";
import FunctionManager from "../function/functionManager";
import * as uuid from 'uuid';
import Scene from "./scene";
import {IPointData} from "@pixi/math";
import PrefabAsset from "../asset/prefabAsset";

export interface IGameObjectJson {
    name: string,
    id: string,
    instanceId: string,
    prefabId?: string,
    expanded: boolean,
    expanded2: boolean,
    locked: boolean
    active: boolean,
    scale: { x: number, y: number },
    position: { x: number, y: number },
    rotation: number,
    angle: number,
    children: IGameObjectJson[],
    components: IComponentJson[],
    disableList: TDisableData[],
}

export type TJsonRefers = {[id:string] : GameObject | Component | FunctionManager};
export type TDisableData = {key: string, editType: EEditable};
export enum EEditable {
    none,
    view,
    edit = 2,
    viewAndEdit = 3,
    advanceView = 4,
    viewOnly = 5,
    // editAndAdvanceView = 6,

    advanceEdit = 8,
    viewAndAdvanceEdit = 9,

    all = 15,
    // static,     //필수 구성요소, 고급 설정으로도 표시안됨
    // staticView, //필수 구성요소, 기본설정 표시안됨. 고급 설정으로 표시는 되어도 수정 불가
}
//export const EditableStatic: number = EEditable.static;

export function editable(target: GameObject, key: string) : boolean {
    return !!(target.getDisableOption('gameObject').editType & EEditable.edit
        && (target.getDisableOption(key).editType & EEditable.edit)
    );
}

export function GetGlobalPoint( gameObject: GameObject ): {x:number, y: number} {
    if( gameObject.parent ) {
        const global: {x:number, y:number} = {x:0, y:0};
        gameObject.parent.toGlobal(gameObject.position, global);
        return global;
    }
    else {
        return {x:gameObject.x, y: gameObject.y};
    }
}

export function SetGlobalPoint(gameObject: GameObject, x: number, y: number) {
    if(gameObject.parent) {
        const local: {x:number, y:number} = {x:0, y:0};
        gameObject.parent.toLocal({x,y}, undefined, local);
        gameObject.position.set( local.x, local.y );
    }
    else {
        gameObject.position.set( x, y );
    }
}

export function GetGlobalRotation(gameObject: GameObject): number {
    let rot = gameObject.rotation;
    let parent = gameObject.parent;
    while(parent) {
        rot += parent.rotation;
        parent = parent.parent;
    }
    return rot;
}

export function SetGlobalRotation(gameObject: GameObject, rotation: number) {
    let parent = gameObject.parent;
    while (parent) {
        rotation -= parent.rotation;
        parent = parent.parent;
    }
    gameObject.rotation = rotation;
}

export default class GameObject extends PIXI.Container {

    private _game : Game;
    public get game() : Game {
        return this._game;
    }

    private readonly _components : Component[] = [];
    public get components() : Component[] {
        return this._components;
    }

    //에디터용
    public expanded: boolean = true;
    public expanded2: boolean = true;
    private _locked: boolean = false;
    public set locked(isLock: boolean) {
        if(this._locked !== isLock) {
            if(this._locked === true) {
                (this as PIXI.utils.EventEmitter).emit('unlocked');
            }
            else {
                (this as PIXI.utils.EventEmitter).emit('locked');
            }
        }
        this._locked = isLock;
    };
    public get locked(): boolean {
        return this._locked;
    }


    private _active : boolean = false;
    public get active() : boolean {
        return this._active;
    }
    public set active(active: boolean) {
        if (this._active === false && active === true) {
            this._active = true;
            this.visible = true;
            if(this.worldActive) {
                this.onActive();
            }
        } else if (this._active === true && active === false) {
            if(this.worldActive)  {
                this._active = false;
                this.visible = false;
                this.onInactive();
            }
            else {
                this._active = false;
                this.visible = false;
            }
        }
    }

    public prefabId: string = '';

    private _worldLayer: GameObject = null;
    // public get worldLayer(): GameObject {
    //     return this._worldLayer;
    // }

    private static _tempPoint: PIXI.Point = new PIXI.Point();
    private static _tempPoint2: PIXI.Point = new PIXI.Point();

    public get worldPosition(): PIXI.IPointData {
        if(!this._worldLayer || !this.parent) {
            return {
                x: this.position.x, y: this.position.y
            };
        }
        GameObject._tempPoint.set(0,0);
        this.toGlobal(GameObject._tempPoint, GameObject._tempPoint2);
        this._worldLayer.toLocal(GameObject._tempPoint2, null, GameObject._tempPoint);
        return {
            x: GameObject._tempPoint.x,
            y: GameObject._tempPoint.y
        };
    }

    public set worldPosition(point: PIXI.IPointData) {
        if(!this._worldLayer || !this.parent) {
            this.position.copyFrom(point);
            return;
        }

        GameObject._tempPoint.copyFrom(point);
        this._worldLayer.toGlobal(GameObject._tempPoint, GameObject._tempPoint2);
        this.parent.toLocal( GameObject._tempPoint2, null, GameObject._tempPoint );
        this.position.copyFrom(GameObject._tempPoint);
    }

    public get worldRotation(): number {
        if(!this._worldLayer || !this.parent) {
            return this.rotation;
        }

        let rot = this.rotation;
        let parent = this.parent;
        while(parent) {
            rot += parent.rotation;
            if(parent === this._worldLayer ) {
                break;
            }
            parent = parent.parent;
        }
        return rot;
    }

    public set worldRotation(rot: number) {
        let parent = this.parent;
        while (parent) {
            rot -= parent.rotation;
            if(parent === this._worldLayer ) {
                break;
            }
            parent = parent.parent;
        }
        this.rotation = rot;
    }

    /*
     get world(): {x:number, y:number} {
        if( this.parent ) {
            const world: {x:number, y:number} = {x:0, y:0};
            this.parent.toGlobal(this.position, world);
            return world;
        }
        else {
            return {x:this.x, y: this.y};
        }
    }

    set world(worldPos: {x: number, y: number}) {
        if(this.parent) {
            const local: {x:number, y:number} = {x:0, y:0};
            this.parent.toLocal(worldPos, undefined, local);
            this.position.set( local.x, local.y );
        }
        else {
            this.position.set( worldPos.x, worldPos.y );
        }
    }

    get worldRotation(): number {
        let rot = this.rotation;
        let parent = this.parent;
        while(parent) {
            rot += parent.rotation;
            parent = parent.parent;
        }
        return rot;
    }

    set worldRotation(rot: number) {
        let parent = this.parent;
        while (parent) {
            rot -= parent.rotation;
            parent = parent.parent;
        }
        this.rotation = rot;
    }
     */

    public get worldActive() : boolean {
        if (!this._active) {
            return false;
        }
        else if(this.parent && this.parent instanceof GameObject) {
            return this.parent.worldActive;
        }
        else {
            return this._active;
        }
    }

    public get childCount(): number {
        let count = 0;
        for(let i = 0; i < this.children.length; i++) {
            if(this.children[i] instanceof GameObject) {
                count++;
            }
        }
        return count;
    }

    private _disableList: TDisableData[] = [
        {key:'gameObject', editType: EEditable.all},    //모든 하위 값 허용
        {key:'remove', editType: EEditable.all},
        {key:'active', editType: EEditable.all},
        {key:'name', editType: EEditable.all},
        {key:'position', editType: EEditable.all},
        {key:'scale', editType: EEditable.all},
        {key:'rotation', editType: EEditable.all},
        {key:'children', editType: EEditable.all},
        {key:'parent', editType: EEditable.all},
        {key:'component', editType: EEditable.all},
        {key:'clone', editType: EEditable.all},
    ];

    public get disableList(): TDisableData[] {
        return this._disableList;
    }
    public getDisableOption( key: string, isCreate: boolean = false ): TDisableData {
        let option = this._disableList.find(op=>op.key === key);
        if(!option) {
            option = {key,editType:EEditable.all};
            if(isCreate) {
                this._disableList.push(option);
            }
        }
        return option;
    }

    private _actionManager: ActionManager;
    public get actionManager(): ActionManager {
        return this._actionManager;
    }

    private _functionManager: FunctionManager;
    public get functionManager(): FunctionManager {
        return this._functionManager;
    }

    public name : string = 'gameObject';

    private readonly _id : string;
    public get id() : string {
        return this._id;
    }

    constructor(game : Game, name : string = '') {
        super();
        this._game = game;
        this.name = name;
        this._id = uuid.v4();
        this._actionManager = new ActionManager(this);
        this._functionManager = new FunctionManager(this);

        this.active = true;

        (this as PIXI.utils.EventEmitter).on('added', this.onAdded.bind(this));
        (this as PIXI.utils.EventEmitter).on('removed', this.onRemoved.bind(this));

        this.game?.instanceManager?.addInstance(this);
    }

    private onAdded(parent : PIXI.Container) {
        let _parent = parent;
        while (true) {
            if(!_parent || (_parent as Scene)?.camera !== undefined) {
                this._worldLayer = null;
                break;
            }
            else if((_parent.parent as Scene)?.camera !== undefined) {
                this._worldLayer = _parent as GameObject;
                break;
            }
            _parent = _parent.parent as GameObject;
        }

        for(let i = 0; i < this.children.length; i++) {
            (this.children[i] as PIXI.utils.EventEmitter).emit('added', this);
        }
    }

    private onRemoved(parent : PIXI.Container) {
        this._worldLayer = null;
        for(let i = 0; i < this.children.length; i++) {
            (this.children[i] as PIXI.utils.EventEmitter).emit('removed', this);
        }
    }

    private onActive() {
        this._actionManager?.onEnable();
        this._functionManager?.event._enable();

        for(let i = 0; i < this._components.length; i++)
        {
            const comp = this._components[i];
            if( comp.active ) {
                comp.onEnable();
            }
        }

        for(let i = 0; i < this.children.length; i++)
        {
            const child = this.children[i];
            if(child instanceof GameObject && child.active){
                child.onActive();
            }
        }
    }

    private onInactive() {
        this._actionManager?.onDisable();
        this._functionManager?.event._disable();

        for(let i = 0; i < this._components.length; i++)
        {
            const comp = this._components[i];
            if( comp.active ) {
                comp.onDisable();
            }
        }

        for(let i = 0; i < this.children.length; i++)
        {
            const child = this.children[i];
            if(child instanceof GameObject && child.active){
                child.onInactive();
            }
        }
    }

    /*
    update 중간에 destroy 됬을때 update에서 이미 업데이트 된 오브젝트 일수도 있고, 아닐수도 있고, 업데이트중일수도 있음. 예외처리..
     */
    public destroy() {
        this._functionManager.destroy();
        this._functionManager = null;
        this._actionManager.destroy();
        this._actionManager = null;

        (this as PIXI.utils.EventEmitter).emit('destroy');
        this.game.instanceManager.removeInstance(this);

        if(this.active) {
            this.active = false;
        }

        for(let i = 0; i < this._components.length; i++)
        {
            const comp = this._components[i];
            comp.destroy();
        }

        super.destroy({children:true});

        this._game = null;
        this._components.length = 0;
    }

    update(delta : number) {
        this._actionManager.update(delta);

        this._functionManager.event?._update(delta);
        for(let i = 0; i < this._components.length; i++)
        {
            const comp = this._components[i];
            if( comp.active ) {
                comp._update(delta);
            }
        }

        for(let i = 0; i < this.children.length; i++)
        {
            const child = this.children[i];
            if(child instanceof GameObject && child.active){
                child.update(delta);
            }
        }
    }

    _debugRender(graphic: PIXI.Graphics) {
        if(!this.active) {
            return;
        }

        if( !editable( this, 'debug' ) ) {
            return;
        }

        const c = this.toGlobal( { x: 0, y:0 } );
        const r = this.toGlobal( { x: 1, y:0 } );
        const t = this.toGlobal( { x: 0, y:-1 } );

        const rv = new b2Vec2(r.x, r.y)
        const tv = new b2Vec2(t.x, t.y);
        rv.SubtractXY( c.x, c.y );
        tv.SubtractXY( c.x, c.y );
        rv.Normalize();
        tv.Normalize();
        rv.Scale( 10 );
        tv.Scale( 10 );
        graphic.lineStyle(2, 0xff0000);
        graphic.moveTo( c.x, c.y );
        graphic.lineTo( c.x + rv.x, c.y + rv.y );

        graphic.lineStyle(2, 0x00ff00);
        graphic.moveTo( c.x, c.y );
        graphic.lineTo( c.x + tv.x, c.y + tv.y );


        for(let i = 0; i < this._components.length; i++) {
            const comp = this._components[i];
            if( comp.active ) {
                comp._debugRender(graphic);
            }
        }
    };

    addComponent<T extends Component>(  ctor : IComponentConstructor | string, json?: IComponentJson  ): T  {
        const scriptName = ctor;
        if( typeof(scriptName) === 'string' ){
            const co = this.game.components.getComponent(ctor as string);
            if(co) {
                ctor = co;
            }
            else {
                const scriptCtor = this.game.components.getComponent('Script');
                const component = new scriptCtor(this) as T;
                this.components.push(component);
                component.initProps();
                component.create();

                if( json ) {
                    component.fromJson(json);
                }
                else {
                    const json = component.toJson() as IScriptJson;
                    json.script = this.game.assetManager.getScript(scriptName as string).id;
                    json.props = {};
                    component.fromJson(json);
                    component.active = true;
                }

                return component as T;
            }
        }

        ctor = ctor as IComponentConstructor;
        if(ctor !== Script && this.components.find(comp=>comp.name===(ctor as IComponentConstructor).Name)) {
            throw new GameError(GameErrorType.ExistsComponent.type, GameErrorType.ExistsComponent.field(ctor.Name, this.name));
        }

        const component = new ctor(this) as T;
        this.components.push(component);
        component.initProps();
        component.create();
        if(json) {
            component.fromJson(json);
        }
        else {
            component.active = true;
        }

        return component;


    }

    existsComponent(ctor: IComponentConstructor): boolean {
        return !!this.components.find(comp=>comp.name===ctor.Name);
    }

    getComponent<T extends Component>(name: string): T {
        let component = this.components.find(comp=>comp.name===name) as T;
        if(!component) {
            for(let i = 0; i < this.components.length; i++) {
                const comp = this.components[i];
                if(comp.name === 'Script' && (comp as Script).script?.name === name) {
                    component = comp as T;
                }
            }
        }

        // if(!component) {
        //     throw new Error(`${name} 컴포넌트는 찾을 수 없습니다.`);
        // }

        return component;
    }

    hasComponent<T extends Component>(name: string): boolean {
        const component = this.components.find(comp=>comp.name===name) as T;
        return !!component;
    }

    removeComponent<T extends Component>( ctor : IComponentConstructor | string ) {

        const scriptName = ctor;
        if(typeof scriptName === 'string') {
            const co = this.game.components.getComponent(ctor as string);
            if(co) {
                const idx = this.components.findIndex(comp=>comp.name === co.Name);
                if(idx > -1) {
                    const comp = this.components[idx];
                    this.components.splice( idx, 1 );
                    comp.destroy();
                }
            }
            else {
                const component = this.components.find(comp=>comp.name === 'Script'
                    && (comp as Script).script?.name === scriptName ) as T;
                if(component) {
                    const idx = this.components.indexOf(component);
                    if(idx > -1) {
                        const comp = this.components[idx];
                        this.components.splice( idx, 1 );
                        comp.destroy();
                    }
                }
            }
        }
        else {
            const idx = this.components.findIndex(comp=>comp.name === (ctor as IComponentConstructor).Name);
            if(idx > -1) {
                const comp = this.components[idx];
                this.components.splice( idx, 1 );
                comp.destroy();
            }
        }
    }

    findByName(name: string): GameObject {
        if(name === this.name) {
            return this;
        }

        for(let i = 0; i < this.children.length; i++) {
            const child = this.children[i];
            if(child instanceof GameObject) {
                const go = child.findByName(name);
                if(go !== null) {
                    return go;
                }
            }
        }

        return null;
    }


    findById(id : string, searchChild: boolean = true) : GameObject | Component | FunctionManager {

        if(id === this.id) {
            return this;
        }

        if(id === this.functionManager.id) {
            return this.functionManager;
        }

        for(let i = 0; i < this.components.length; i++) {
            const comp = this.components[i];
            if(comp.id === id) {
                return comp;
            }
        }

        if(searchChild) {
            for(let i = 0; i < this.children.length; i++) {
                const child = this.children[i];
                if(child instanceof GameObject) {
                    const go = child.findById(id);
                    if(go !== null) {
                        return go;
                    }
                }
            }
        }
        return null;
    }

    getChildPrefabs() {
        const map: {[key:string]:boolean} = {};
        const prefabs : string[] = [];

        _search(this);

        function _search( go: GameObject ) {
            if(go instanceof GameObject) {
                if(go.prefabId && !map[go.prefabId]) {
                    map[go.prefabId] = true;
                    prefabs.push(go.prefabId);
                }
                for(let i = 0; i < go.children.length; i++) {
                    _search(go.children[i] as GameObject);
                }
            }
        }

        return prefabs;
    }

    getParentPrefabs() {
        const map :{[key:string]:boolean}= {};
        const prefabs : string[] = [];

        _search(this);

        function _search( go: GameObject ) {
            if(go instanceof GameObject) {
                if(go.prefabId && !map[go.prefabId]) {
                    map[go.prefabId] = true;
                    prefabs.push(go.prefabId);
                }
                _search(go.parent as GameObject);
            }
        }

        return prefabs;
    }

    toJson(propertyOnly: boolean = false) : IGameObjectJson {
        const components : IComponentJson[] = [];
        const children : IGameObjectJson[] = [];

        if( !propertyOnly ) {
            for(let i = 0; i < this.components.length; i++) {
                components.push(this.components[i].toJson());
            }

            for(let i = 0; i < this.children.length; i++) {
                const child = this.children[i];
                if(child instanceof GameObject) {
                    children.push(child.toJson());
                }
            }
        }


        return {
            name: this.name,
            id: this.id,
            instanceId: this.functionManager.id,
            prefabId: this.prefabId || undefined,
            expanded: this.expanded,
            expanded2: this.expanded2,
            locked: this.locked,
            active: this.active,
            scale: {
                x: this.scale.x,
                y: this.scale.y
            },
            position: {
                x: this.position.x,
                y: this.position.y
            },
            rotation: this.rotation,
            angle: this.angle,
            components,
            children,
            disableList: this.disableList,
        }
    }

    fromJson(json : IGameObjectJson, refers : TJsonRefers = null, propertyOnly: boolean = false, isPrefab: boolean = false) {
        this.name = json.name;
        this.expanded = json.expanded;
        this.expanded2 = json.expanded2 || false;
        this.locked = json.locked || false;
        this.scale.set(
            json.scale.x,
            json.scale.y
        );
        this.position.set(
            json.position.x,
            json.position.y
        );
        this.angle = json.angle;
        this.prefabId = json.prefabId || '';


        if(isPrefab && json.prefabId) {
            const prefabAsset = this.game.assetManager.getAsset(json.prefabId) as PrefabAsset;
            if(prefabAsset) {
                const prefabJson = prefabAsset.prefab;
                json.disableList = prefabJson.disableList;
                json.children = prefabJson.children;
                json.components = prefabJson.components;
            }
        }

        const disableList = json.disableList;
        for(let i = 0; i < disableList.length; i++) {
            const option = disableList[i];
            this.getDisableOption(option.key, true).editType = option.editType;
        }

        if(!propertyOnly) {
            const components = json.components;
            const children = json.children;

            const isRoot = refers === null;
            if( isRoot ) {
                refers = {};
            }

            refers[json.id] = this;
            if(json.instanceId) {
                refers[json.instanceId] = this.functionManager;
            }


            for(let i = 0; i < components.length; i++) {
                const compJson = components[i] as IComponentJson;
                const ctor = this.game.components.getComponent(compJson.name);
                const comp = this.addComponent(ctor);
                comp.fromJson(compJson, refers);
            }

            for(let i = 0; i < children.length; i++) {
                const childJson = children[i] as IGameObjectJson;
                const go = new GameObject(this.game);
                this.addChild(go);
                go.fromJson(childJson, refers, propertyOnly, isPrefab);
            }

            if(isRoot) {
                this.onCompleteJson(json, refers);
            }
        }

        this.active = json.active;
    }

    onCompleteJson(json : IGameObjectJson, refers : TJsonRefers ) {

        const components = json.components;
        const children = json.children;

        for (let i = 0; i < this.components.length; i++) {
            const compJson = components[i] as IComponentJson;
            const comp = this.components[i] as Component;
            comp.onCompleteJson(compJson, refers);
        }

        let idx = 0;
        for (let i = 0; i < this.children.length; i++) {
            const child = this.children[i] as GameObject;
            if (child instanceof GameObject) {
                const childJson = children[idx++] as IGameObjectJson;
                child.onCompleteJson(childJson, refers);
            }
        }
    }
}
import {IComponentViewProps} from "./componentView";
import {ISpriteJson} from "../../../../game/scripts/component/sprite";
import EditorManager from "../../../../services/editorManager";
import {store} from "../../../../app/store";
import {Button, Input, InputNumber, Space, Typography, Select} from "antd";
import PointView from "../valueView/pointView";
import {IAnimatorJson} from "../../../../game/scripts/component/animator";
import AnimatorAsset from "../../../../game/scripts/asset/animatorAsset";
import {changeOpenAnimatorList, changeSelectAnimatorIds} from "../../../../store/modalSlice";
import {useDispatch} from "react-redux";
import AnimatorAssetView from "../valueView/ref/animatorAssetView";
import {
    DeleteOutlined,
    DeleteTwoTone,
    DeleteFilled,
    SettingFilled
} from '@ant-design/icons';
import SelectView from "../valueView/selectView";
import LocalizationManager from "../../../../services/localizationManager";
import TooltipManager from "../../../../services/tooltipManager";

const { Text } = Typography;
const {Option} = Select;


export default function AnimatorView(props: IComponentViewProps) {

    const json = props.json as IAnimatorJson;
    const asset = EditorManager.Instance.game.assetManager.getAsset(json.animator) as AnimatorAsset;
    const dispatch = useDispatch();

    const options = [];
    if(asset) {
        const animations = asset.json.animations;
        for(let i = 0; i < animations.length; i++) {
            const animation = animations[i];
            options.push(<Option key={animation.name} value={animation.name}>{animation.name}</Option>)
        }
    }

    return(
        <>
            <AnimatorAssetView
                label={LocalizationManager.Instance.getText('Animator.animator') || 'animator'}
                tooltip={  TooltipManager.Instance.getText('Animator.animator') }
                assetId={asset?.id} onClick={async function (){
                const animatorId = await EditorManager.Instance.selectAnimator(asset?.id);
                if(animatorId !== null) {
                    json.animator = animatorId;
                    EditorManager.Instance.modifyComponent(json);
                }
            }} onRemove={function (){
                json.animator = null;
                EditorManager.Instance.modifyComponent(json);
            }} onCreate={function (){
                const gameObject = EditorManager.Instance.getSelectObject();
                const animatorAsset = new AnimatorAsset(EditorManager.Instance.game);
                animatorAsset.rename(EditorManager.Instance.assetManager.getVerifiedName(gameObject.name, 'Animator'));
                EditorManager.Instance.assetManager.addAsset(animatorAsset);
                store.dispatch(changeSelectAnimatorIds([animatorAsset.id]));
                json.animator = animatorAsset.id;
                EditorManager.Instance.modifyComponent(json);
            }} onModify={function (){
                dispatch(changeOpenAnimatorList(false));
                dispatch(changeSelectAnimatorIds([asset?.id]));
            }}></AnimatorAssetView>
            <SelectView
                label={LocalizationManager.Instance.getText('Animator.startAnimation') || 'startAnimation'}
                tooltip={TooltipManager.Instance.getText('Animator.startAnimation') }
                value={json.startAnimation} onChange={function (value){
                json.startAnimation = value;
                EditorManager.Instance.modifyComponent(json);
            }}>
                <Option value={''}>없음</Option>
                {options}
            </SelectView>
        </>)
}
export async function ScriptsLoader(urlList: string[]) {
    let count = 0;
    let totalCount = urlList.length;

    await new Promise<void>((resolve, reject)=>{
        function _onload() {
            if(count === totalCount) {
                resolve();
            }
        }

        for(let i = 0; i < urlList.length; i++) {
            const url = urlList[i];
            if(!url) {
                count++;
            }

            const script = document.createElement('script');
            script.onload = function() {
                count++;
                _onload();
            };
            script.src = urlList[i];
            document.head.appendChild(script);
        }

        _onload();
    });
}
import Command from "./command";
import {IComponentJson} from "../../game/scripts/core/component";
import EditorManager from "../editorManager";
import {treeManager} from "../../components/panel/hierarchy/hierarchy";
import {IScriptJson} from "mogera-game/scripts/component/script";

export default class RemoveComponentCommand extends Command {
    private gameObjectId: string;
    private json: IComponentJson;

    constructor(gameObjectId: string, json: IComponentJson) {
        super();
        this.gameObjectId = gameObjectId;
        this.json = json;
    }

    execute() {
        const gameObject = EditorManager.Instance.findGameObject( this.gameObjectId );
        // const ctor = gameObject.game.components.getComponent(this.json.name);
        if(this.json.name === 'Script') {
            const scriptAsset = EditorManager.Instance.assetManager.getAsset((this.json as IScriptJson).script);
            if(scriptAsset) {
                gameObject.removeComponent(scriptAsset.name);
            }
        }
        else {
            gameObject.removeComponent(this.json.name);
        }

        treeManager.tree?.selectById( gameObject.id );
    }

    undo() {
        const gameObject = EditorManager.Instance.findGameObject( this.gameObjectId );
        if(this.json.name === 'Script') {
            const scriptAsset = EditorManager.Instance.assetManager.getAsset((this.json as IScriptJson).script);
            if(scriptAsset) {
                const comp = gameObject.addComponent(scriptAsset.name);
                comp.fromJson( this.json );
            }
        }
        else {
            const comp = gameObject.addComponent(this.json.name);
            comp.fromJson( this.json );
        }
        // const ctor = gameObject.game.components.getComponent(this.json.name);
        // const comp = gameObject.addComponent(ctor);
        treeManager.tree?.selectById( gameObject.id );
    }
}
import {
    XY,
    b2ContactListener,
    b2DestructionListener,
    b2Fixture,
    b2Joint,
    b2World,
    b2Contact,
    b2Manifold,
    b2ContactImpulse,
    b2Body,
    b2BodyDef,
    b2Filter,
    b2_linearSlop, b2WorldManifold,
} from "@box2d/core";
import Game from "./game";
import Body, {b2Unit} from "../component/body";
import * as PIXI from 'pixi.js';
import FunctionManager from "../function/functionManager";

export class DestructionListener extends b2DestructionListener {
    public physics: Physics;

    public constructor(physics: Physics) {
        super();
        this.physics = physics;
    }

    public SayGoodbyeJoint(joint: b2Joint): void {}
    public SayGoodbyeFixture(_fixture: b2Fixture): void {}
}

export interface PhysicsSetting {
    enableWarmStarting: boolean;
    enableContinuous: boolean;
    enableSubStepping: boolean;
    enableSleep: boolean;

    velocityIterations: number;
    positionIterations: number;
}

export interface ICollisionInfo {
    other: FunctionManager,
    isSensor: boolean,
    hitPoints: XY[],
    isBottom: boolean,
    isTop: boolean,
    isLeft: boolean,
    isRight: boolean,
}

export interface IContactInfo {
    bodyA : Body,
    bodyB: Body,
    isSensor: boolean,
    hitPoints: XY[],
    normal: XY,
}

export default class Physics extends b2ContactListener {
    private _world: b2World;
    private _game: Game;
    private _destructionListener: DestructionListener;

    private _temp_b2WorldManifold : b2WorldManifold = new b2WorldManifold();

    private startContactArr: IContactInfo[] = [];
    private endContactArr: IContactInfo[] = [];

    private timeDelta: number = 0;
    private fixedTime: number = 1/60;

    public get world(): b2World {
        return this._world;
    }

    public get gravity(): XY {
        return this._world.GetGravity();
    }

    public set gravity( xy: XY ) {
        this._world.SetGravity(xy);
    }

    private _enable: boolean = false;
    public get enable(): boolean {
        return this._enable;
    }
    public set enable( v : boolean ) {
        this._enable = v;
    }

    private _event: PIXI.utils.EventEmitter = new PIXI.utils.EventEmitter();
    public addPreSolveListener(callback:(contact: b2Contact, oldManifold: b2Manifold)=>void, context?:any) {
        this._event.on('preSolve', callback, context);
    }
    public removePreSolveListener(callback:(contact: b2Contact, oldManifold: b2Manifold)=>void, context?:any) {
        this._event.off('preSolve', callback, context);
    }
    public addPostSolveListener(callback:(contact: b2Contact, oldManifold: b2Manifold)=>void, context?:any) {
        this._event.on('postSolve', callback, context);
    }
    public removePostSolveListener(callback:(contact: b2Contact, oldManifold: b2Manifold)=>void, context?:any) {
        this._event.off('postSolve', callback, context);
    }


    private _settings: PhysicsSetting = {
        enableWarmStarting: true,
        enableContinuous: true,
        enableSubStepping: false,
        enableSleep: true,

        velocityIterations: 8,
        positionIterations: 3,
    };
    public get settings(): PhysicsSetting {
        return this._settings;
    }

    constructor(game: Game) {
        super();
        this._game = game;
        this._world = b2World.Create({x: 0, y: 100});

        this._destructionListener = new DestructionListener(this);
        this._world.SetDestructionListener(this._destructionListener);
        this._world.SetContactListener(this);
    }

    destroy() {
        this._game = null;
        this._world = null;
        this._destructionListener = null;
    }

    public BeginContact(contact: b2Contact): void {
        const fixtureA = contact.GetFixtureA();
        const fixtureB = contact.GetFixtureB();
        const bodyA = fixtureA.GetUserData() as Body;
        const bodyB = fixtureB.GetUserData() as Body;

        contact.GetWorldManifold( this._temp_b2WorldManifold );
        const normal = {
            x: this._temp_b2WorldManifold.normal.x,
            y: this._temp_b2WorldManifold.normal.y
        };
        const points = [];
        for(let i = 0; i < this._temp_b2WorldManifold.points.length; i++) {
            const point = this._temp_b2WorldManifold.points[i];
            points.push({
                x: point.x / b2Unit,
                y: point.y / b2Unit,
            });
        }
        // console.log('BeginContact',contact, points);

        this.startContactArr.push({
            bodyA,
            bodyB,
            normal,
            hitPoints: points,
            isSensor : bodyA.isSensor || bodyB.isSensor,
        });


        // (bodyA.gameObject as PIXI.utils.EventEmitter).emit('collisionStart', {
        //     hitPoints: points,
        //     other: bodyB.gameObject.functionManager,
        //     isSensor: bodyB.isSensor || bodyA.isSensor,
        // });
        // (bodyB.gameObject as PIXI.utils.EventEmitter).emit('collisionStart',  {
        //     hitPoints: points,
        //     other: bodyA.gameObject.functionManager,
        //     isSensor: bodyA.isSensor || bodyB.isSensor,
        // });
    }
    public EndContact(contact: b2Contact): void {
        const fixtureA = contact.GetFixtureA();
        const fixtureB = contact.GetFixtureB();
        const bodyA = fixtureA.GetUserData() as Body;
        const bodyB = fixtureB.GetUserData() as Body;

        contact.GetWorldManifold( this._temp_b2WorldManifold );
        const normal = {
            x: this._temp_b2WorldManifold.normal.x,
            y: this._temp_b2WorldManifold.normal.y
        };
        const points = [];
        for(let i = 0; i < this._temp_b2WorldManifold.points.length; i++) {
            const point = this._temp_b2WorldManifold.points[i];
            points.push({
                x: point.x / b2Unit,
                y: point.y / b2Unit,
            });
        }

        this.endContactArr.push({
            bodyA,
            bodyB,
            normal,
            hitPoints: points,
            isSensor : bodyA.isSensor || bodyB.isSensor,
        });

        // (bodyA.gameObject as PIXI.utils.EventEmitter).emit('collisionEnd', {
        //     hitPoints: points,
        //     other: bodyB.gameObject.functionManager,
        //     isSensor: bodyB.isSensor || bodyA.isSensor,
        // });
        // (bodyB.gameObject as PIXI.utils.EventEmitter).emit('collisionEnd',  {
        //     hitPoints: points,
        //     other: bodyA.gameObject.functionManager,
        //     isSensor: bodyA.isSensor || bodyB.isSensor,
        // });

    }
    public PreSolve(contact: b2Contact, oldManifold: b2Manifold): void {
        this._event.emit('preSolve', contact, oldManifold);

        if( contact.GetFixtureA().IsSensor() || contact.GetFixtureB().IsSensor() ) return;

        contact.GetWorldManifold( this._temp_b2WorldManifold );
        const normal = {
            x: this._temp_b2WorldManifold.normal.x,
            y: this._temp_b2WorldManifold.normal.y
        };

        if( normal.x === 0 && normal.y === -1 ) {
            (contact.GetFixtureA().GetUserData() as Body).colTop = true;
            (contact.GetFixtureB().GetUserData() as Body).colBottom = true;
        }
        else if( normal.x === 0 && normal.y === 1 ) {
            (contact.GetFixtureA().GetUserData() as Body).colBottom = true;
            (contact.GetFixtureB().GetUserData() as Body).colTop = true;
        }
        else if( normal.x === -1 && normal.y === 0 ) {
            (contact.GetFixtureA().GetUserData() as Body).colLeft = true;
            (contact.GetFixtureB().GetUserData() as Body).colRight = true;
        }
        else if( normal.x === 1 && normal.y === 0 ) {
            (contact.GetFixtureA().GetUserData() as Body).colRight = true;
            (contact.GetFixtureB().GetUserData() as Body).colLeft = true;
        }

        if( (contact.GetFixtureA().GetUserData() as Body).checkFilter((contact.GetFixtureB().GetUserData() as Body)) ) {
            contact.SetEnabled(false);
        }

        // console.log(
        //     contact.GetFixtureA().GetUserData().gameObject.name,
        //     contact.GetFixtureB().GetUserData().gameObject.name,
        //     normal);


        // contact.GetWorldManifold( this._temp_b2WorldManifold );
        // const points = [];
        // for(let i = 0; i < this._temp_b2WorldManifold.points.length; i++) {
        //     const point = this._temp_b2WorldManifold.points[i];
        //     points.push({
        //         x: point.x / b2Unit,
        //         y: point.y / b2Unit,
        //     });
        // }
        // console.log('preSolve',contact, points);
    }

    public PostSolve(contact: b2Contact, impulse: b2ContactImpulse): void {
        this._event.emit('postSolve', contact, impulse);
        const fixtureA = contact.GetFixtureA();
        const fixtureB = contact.GetFixtureB();
        const bodyA = fixtureA.GetUserData() as Body;
        const bodyB = fixtureB.GetUserData() as Body;


        if( fixtureA.GetBody().GetLinearVelocity().Length() >= b2_linearSlop) {
            //console.log( this.world.GetContactList() );
        }
        if( fixtureB.GetBody().GetLinearVelocity().Length() >= b2_linearSlop ) {
            //console.log(  this.world.GetContactList() );
        }

        // contact.GetWorldManifold( this._temp_b2WorldManifold );
        // const points = [];
        // for(let i = 0; i < this._temp_b2WorldManifold.points.length; i++) {
        //     const point = this._temp_b2WorldManifold.points[i];
        //     points.push({
        //         x: point.x / b2Unit,
        //         y: point.y / b2Unit,
        //     });
        // }
        // console.log('postSolve',contact, points);


    }


    public update(delta: number) {
        this.timeDelta += delta;
        if(this.timeDelta >= this.fixedTime) {
            this.timeDelta -= this.fixedTime;
        }
        else {
            return;
        }

        const bodies = this.world.GetBodyList()
        for(let body = bodies; body !== null; body = body.GetNext() ) {
            const bodyComp = body.GetUserData();
            if(bodyComp instanceof Body) {
                bodyComp.clearColDir();
            }
        }



        this.step(this.fixedTime);
    }

    private step(timeStep: number): void {
        this.world.SetAllowSleeping(this.settings.enableSleep);
        this.world.SetWarmStarting(this.settings.enableWarmStarting);
        this.world.SetContinuousPhysics(this.settings.enableContinuous);
        this.world.SetSubStepping(this.settings.enableSubStepping);

        this.startContactArr.length = 0;
        this.endContactArr.length = 0;

        this.world.Step(timeStep, {
            velocityIterations: this.settings.velocityIterations,
            positionIterations: this.settings.positionIterations,
        });

        for(let i = 0; i < this.startContactArr.length; i++) {
            const {bodyA, bodyB, hitPoints, isSensor, normal } = this.startContactArr[i];
            (bodyA.gameObject as PIXI.utils.EventEmitter).emit('collisionStart', {
                hitPoints,
                other: bodyB.gameObject.functionManager,
                isSensor,
                isTop: normal.x === 0 && normal.y === -1,
                isBottom: normal.x === 0 && normal.y === 1,
                isLeft: normal.x === -1 && normal.y === 0,
                isRight: normal.x === 1 && normal.y === 0,
            });
            (bodyB.gameObject as PIXI.utils.EventEmitter).emit('collisionStart',  {
                hitPoints,
                other: bodyA.gameObject.functionManager,
                isSensor,
                isTop: normal.x === 0 && normal.y === 1,
                isBottom: normal.x === 0 && normal.y === -1,
                isLeft: normal.x === 1 && normal.y === 0,
                isRight: normal.x === -1 && normal.y === 0,
            });
        }
        for(let i = 0; i < this.endContactArr.length; i++) {
            const {bodyA, bodyB, hitPoints, isSensor, normal } = this.endContactArr[i];
            (bodyA.gameObject as PIXI.utils.EventEmitter).emit('collisionEnd', {
                hitPoints,
                other: bodyB.gameObject.functionManager,
                isSensor,
                isTop: normal.x === 0 && normal.y === -1,
                isBottom: normal.x === 0 && normal.y === 1,
                isLeft: normal.x === -1 && normal.y === 0,
                isRight: normal.x === 1 && normal.y === 0,
            });
            (bodyB.gameObject as PIXI.utils.EventEmitter).emit('collisionEnd',  {
                hitPoints,
                other: bodyA.gameObject.functionManager,
                isSensor,
                isTop: normal.x === 0 && normal.y === 1,
                isBottom: normal.x === 0 && normal.y === -1,
                isLeft: normal.x === 1 && normal.y === 0,
                isRight: normal.x === -1 && normal.y === 0,
            });
        }
    }

    public createBody(def: b2BodyDef): b2Body {
        return this.world.CreateBody(def);
    }

    public destroyBody(body: b2Body) {
        this.world.DestroyBody( body );
    }
}
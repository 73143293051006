import Component, {IComponentJson} from "../core/component";
import {b2Body, b2BodyType, b2CircleShape, b2EdgeShape, b2Fixture, b2PolygonShape, b2Shape, XY} from "@box2d/core";
import {TJsonRefers} from "../gameObject/gameObject";
import {component} from "../util/decorator";
import Sprite from "./sprite";
import Shape, {EShapeType} from "./shape";
import * as PIXI from 'pixi.js';
import {Util} from "../util/util";

export interface IBodyJson extends IComponentJson {
    enabled: boolean,
    bodyType: b2BodyType,
    allowSleep: boolean,
    fixedRotation: boolean,
    bullet: boolean,

    gravityScale: number,
    linearDamping: number,
    angularDamping: number,

    friction: number,               //usually in the range [0,1].
    restitution: number,            //usually in the range [0,1].
    restitutionThreshold: number,
    // density: number,
    isSensor: boolean,

    colliderType: 'circle' | 'edge' | 'box' | 'polygon',
    radius: number,
    width: number,
    height: number,

    pointCount: number;
    points: XY[],

    // filter: Partial<b2Filter>,
}
/*
shape: b2Shape;
userData?: any;


//The friction coefficient, usually in the range [0,1].
friction?: number;


//The restitution (elasticity) usually in the range [0,1].
restitution?: number;

 * Restitution velocity threshold, usually in m/s. Collisions above this
 * speed have restitution applied (will bounce).
restitutionThreshold?: number;


//The density, usually in kg/m^2.
density?: number;


isSensor?: boolean;


filter?: Partial<b2Filter>;
 */

export const b2Unit = 0.1;

@component('Body')
export default class Body extends Component {
    private _body: b2Body;
    public get body(): b2Body {
        return this._body;
    }

    private _colliderType: 'circle' | 'edge' | 'box' | 'polygon' = 'box';
    public get colliderType(): 'circle' | 'edge' | 'box' | 'polygon' {
        return this._colliderType;
    }

    public colBottom: boolean = false;
    public colTop: boolean = false;
    public colLeft: boolean = false;
    public colRight: boolean = false;

    private _radius: number = 50;
    private _width: number = 100;
    private _height: number = 100;
    public get width(): number {
        return this._width;
    }
    public set width(width: number) {
        this._width = width;
    }
    public get height(): number {
        return this._height;
    }
    public set height(height: number) {
        this._height = height;
    }
    public get radius(): number {
        return this._radius;
    }
    public set radius(radius: number) {
        this._radius = radius;
    }

    private _points: XY[] = [];
    public get points(): XY[] {
        return this._points;
    }
    private _pointCount: number = 2;
    public get pointCount(): number {
        return this._pointCount;
    }


    private _shape: b2Shape;
    public get shape(): b2Shape {
        return this._shape;
    }

    private _fixture: b2Fixture;
    public get fixture(): b2Fixture {
        return this._fixture;
    }

    private _bodyType: b2BodyType = b2BodyType.b2_dynamicBody;
    public get bodyType(): b2BodyType {
        return this._bodyType;
    }
    public set bodyType(type: b2BodyType) {
        if(this._body) {
            this._body.SetType(type)
        }
        this._bodyType = type;
    }

    private _allowSleep: boolean = false;
    public get allowSleep(): boolean {
        return this._allowSleep;
    }
    public set allowSleep(flag: boolean) {
        if(this._body) {
            this._body.SetSleepingAllowed(flag);
        }
        this._allowSleep = flag;
    }

    private _fixedRotation: boolean = true;
    public get fixedRotation(): boolean {
        return this._fixedRotation;
    }
    public set fixedRotation(flag: boolean) {
        if(this._body) {
            this._body.SetFixedRotation(flag);
        }
        this._fixedRotation = flag;
    }

    private _bullet: boolean = false;
    public get bullet(): boolean {
        return this._bullet;
    }
    public set bullet(flag: boolean) {
        if(this._body) {
            this._body.SetBullet(flag);
        }
        this._bullet = flag;
    }

    private _enabled: boolean = true;
    public get enabled(): boolean {
        return this._enabled;
    }
    public set enabled(flag: boolean) {
        if(this._body && this.active ) {
            this._body.SetEnabled(flag);
        }
        this._enabled = flag;
    }

    private _gravityScale: number = 1;
    public get gravityScale(): number {
        return this._gravityScale;
    }
    public set gravityScale(scale: number) {
        if(this._body) {
            this._body.SetGravityScale(scale);
        }
        this._gravityScale = scale;
    }

    private _linearDamping: number = 0;
    public get linearDamping(): number {
        return this._linearDamping;
    }
    public set linearDamping(damp: number) {
        if(this._body) {
            this._body.SetLinearDamping(damp);
        }
        this._linearDamping = damp;
    }

    private _angularDamping: number = 0;
    public get angularDamping(): number {
        return this._angularDamping;
    }
    public set angularDamping(damp: number) {
        if(this._body) {
            this._body.SetAngularDamping(damp);
        }
        this._angularDamping = damp;
    }

    private _isSensor: boolean = false;
    public get isSensor(): boolean {
        return this._isSensor;
    }
    public set isSensor(sensor: boolean) {
        if(this.fixture) {
            this.fixture.SetSensor(sensor);
        }
        this._isSensor = sensor;
    }

    //usually in the range [0,1].
    private _friction: number = 1;
    public get friction(): number {
        return this._friction;
    }
    public set friction(v: number) {
        if(this.fixture) {
            this.fixture.SetFriction(v);
        }
        this._friction = v;
    }

    //usually in the range [0,1].
    private _restitution: number = 0;
    public get restitution(): number {
        return this._restitution;
    }
    public set restitution(v: number) {
        if(this.fixture) {
            this.fixture.SetRestitution(v);
        }
        this._restitution = v;
    }

    private _restitutionThreshold: number = 0;
    public get restitutionThreshold(): number {
        return this._restitutionThreshold;
    }
    public set restitutionThreshold(v: number) {
        if(this.fixture) {
            this.fixture.SetRestitutionThreshold(v);
        }
        this._restitutionThreshold = v;
    }

    private filter: {mask: number, category: number} = undefined;
    public checkFilter(body: Body): boolean {
        if(!this.filter || !body.filter) return false;
        return !!(!(this.filter.mask & body.filter.category) || !(body.filter.mask & this.filter.category));
    }

    create() {

        const initSize = ()=> {
            const sprite = this.gameObject.getComponent(Sprite.Name) as Sprite;
            if(sprite) {
                this._width = sprite.width;
                this._height = sprite.height;
                return;
            }

            const shape = this.gameObject.getComponent(Shape.Name) as Shape;
            if(shape) {
                if(shape.shapeType === EShapeType.circle) {
                    this._colliderType = "circle";
                    this._radius = shape.width;
                }
                else if(shape.shapeType === EShapeType.rect) {
                    this._colliderType = "box";
                    this._width = shape.width;
                    this._height = shape.height;
                }
                return;
            }
        }

        initSize();
    }

    start() {
        const worldPos = this.gameObject.worldPosition;
        const worldRot = this.gameObject.worldRotation;

        if(this._colliderType === 'circle') {
            this._shape = new b2CircleShape(this._radius * b2Unit * this.gameObject.scale.x);
        }
        else if( this._colliderType === 'box' ) {
            this._shape = new b2PolygonShape();
            (this._shape as b2PolygonShape).SetAsBox(
                this._width * 0.5 * b2Unit * this.gameObject.scale.x,
                this._height * 0.5 * b2Unit * this.gameObject.scale.y,
            );
        }
        else if(this._colliderType === 'edge') {
            this._shape = new b2EdgeShape();
            (this._shape as b2EdgeShape).SetTwoSided( {
                x: this.points[0].x * b2Unit * this.gameObject.scale.x,
                y: this.points[0].y * b2Unit * this.gameObject.scale.y,
            }, {
                x: this.points[1].x * b2Unit * this.gameObject.scale.x,
                y: this.points[1].y * b2Unit * this.gameObject.scale.y,
            } );
        }
        else if(this._colliderType === "polygon") {
            this._shape = new b2PolygonShape();
            let points = [];
            for(let i = 0; i < this.pointCount; i++) {
                points.push(
                    {
                        x: this.points[i].x * b2Unit * this.gameObject.scale.x,
                        y: this.points[i].y * b2Unit * this.gameObject.scale.y,
                    }
                )
            }
            (this._shape as b2PolygonShape).Set(points, this.pointCount);
        }

        this._body = this.game.physics.createBody({
            type: this._bodyType,
            enabled: this._enabled,
            allowSleep: this._allowSleep,
            fixedRotation: this._fixedRotation,
            bullet: this._bullet,
            gravityScale: this._gravityScale,
            linearDamping: this._linearDamping,
            angularDamping: this._angularDamping,
            userData: this,
            position: {x:worldPos.x * b2Unit, y: worldPos.y * b2Unit},
            angle: worldRot,
        } );
        this._fixture = this._body.CreateFixture( {
            friction: this._friction,
            isSensor: this._isSensor,
            restitution: this._restitution,
            restitutionThreshold: this._restitutionThreshold,
            shape: this.shape,
            userData: this,
            density: 1,
        } );
    }

    clearColDir() {
        this.colBottom = false;
        this.colLeft = false;
        this.colTop = false;
        this.colRight = false;
    }

    destroy() {
        if(this._body) {
            this._body.DestroyFixture(this._fixture);
            this._fixture = null;
            this.game.physics.destroyBody(this._body);
            this._body = null;
        }
        super.destroy();
    }

    onEnable() {
        if(this._body) {
            this._body.SetEnabled( this.enabled );
        }
    }

    onDisable() {
        if(this._body) {
            this._body.SetEnabled(false);
        }
    }

    setVelocity(v: XY) {
        if(this._body) {
            v.x *= b2Unit;
            v.y *= b2Unit;
            this._body.SetLinearVelocity(v);
        }
    }

    setVelocityX(x: number) {
        if(this._body) {
            this._body.SetLinearVelocity({
                x:x * b2Unit,
                y: this._body.GetLinearVelocity().y
            });
        }
    }

    setVelocityY(y: number) {
        if(this._body) {
            this._body.SetLinearVelocity({
                x:this._body.GetLinearVelocity().x,
                y: y * b2Unit
            });
        }
    }

    addVelocity(v:XY) {
        if(this._body) {
            const vel = this._body.GetLinearVelocity();
            this._body.SetLinearVelocity({
                x: vel.x + v.x * b2Unit,
                y: vel.y + v.y * b2Unit
            });
        }
    }

    setAngularVelocity(v: number) {
        if(this._body) {
            this._body.SetAngularVelocity(v);
        }
    }

    getVelocity(): XY {
        if(this._body) {
            return {
                x: this.getVelocityX(),
                y: this.getVelocityY(),
            };
        }
    }

    getVelocityX(): number {
        if(this._body) {
            return this._body.GetLinearVelocity().x / b2Unit;
        }
    }

    getVelocityY(): number {
        if(this._body) {
            return this._body.GetLinearVelocity().y / b2Unit;
        }
    }

    getAngularVelocity(): number {
        if(this._body) {
            return this._body.GetAngularVelocity();
        }
    }

    setPosition(x: number, y : number) {
        if(this._body) {
            this._body.SetTransformXY(x * b2Unit, y * b2Unit, this._body.GetAngle());
        }
    }

    setAngle(a: number) {
        if(this._body) {
            this._body.SetAngle(a);
        }
    }

    setFilter(mask: number, category: number) {
        this.filter = {
            mask,
            category,
        }

        // this._createFixture(this.filter);
    }

    private _createFixture(filter: {mask: number, category: number} = undefined) {
        if(this._body && this.colliderType === "box") {
            this._body.DestroyFixture(this.fixture);
            this._shape = new b2PolygonShape();
            (this._shape as b2PolygonShape).SetAsBox(
                this._width * 0.5 * b2Unit * this.gameObject.scale.x,
                this._height * 0.5 * b2Unit * this.gameObject.scale.y,
            );

            this._fixture = this._body.CreateFixture( {
                friction: this._friction,
                isSensor: this._isSensor,
                restitution: this._restitution,
                restitutionThreshold: this._restitutionThreshold,
                shape: this.shape,
                userData: this,
                density: 1,
                filter: filter ? {
                    groupIndex: 0,
                    maskBits: filter.mask,
                    categoryBits: filter.category,
                } : undefined,
            } );
        }
        else  if(this._body && this.colliderType === "polygon") {
            this._body.DestroyFixture(this.fixture);
            this._shape = new b2PolygonShape();
            let points = [];
            for(let i = 0; i < this.pointCount; i++) {
                points.push(
                    {
                        x: this._points[i].x * b2Unit * this.gameObject.scale.x,
                        y: this._points[i].y * b2Unit * this.gameObject.scale.y,
                    }
                )
            }
            (this._shape as b2PolygonShape).Set(points, this.pointCount);
            this._fixture = this._body.CreateFixture( {
                friction: this._friction,
                isSensor: this._isSensor,
                restitution: this._restitution,
                restitutionThreshold: this._restitutionThreshold,
                shape: this.shape,
                userData: this,
                density: 1,
                filter: filter ? {
                    groupIndex: 0,
                    maskBits: filter.mask,
                    categoryBits: filter.category,
                } : undefined,
            } );
        }

    }

    setBoxSize(w: number, y: number, ) {
        if(this._body && this.colliderType === "box") {
            this.width = w;
            this.height = y;
            this._createFixture();
        }
    }

    setPolygon(pointList: XY[]) {
        if(this._body && this.colliderType === "polygon") {
            this._pointCount = pointList.length;
            this._points.length = 0;
            for(let i = 0; i < this.pointCount; i++) {
                this._points.push({
                    x: pointList[i].x,
                    y: pointList[i].y,
                });
            }
            this._createFixture();
        }
    }


    update(delta: number) {
        const pos = this._body.GetPosition();
        const x = pos.x / b2Unit;
        const y =  pos.y / b2Unit;
        this.gameObject.worldPosition = {x,y};
        this.gameObject.worldRotation = this._body.GetAngle();
    }

    _debugRender(graphic: PIXI.Graphics) {
        graphic.lineStyle( 1, 0x00ff00, 0.5 );
        if( this.colliderType === 'box' ) {
            const hw = this.width/2;
            const hh = this.height/2;

            const lt = this.gameObject.toGlobal( { x: -hw, y:-hh } );
            const lb = this.gameObject.toGlobal( { x: -hw, y:hh } );
            const rt = this.gameObject.toGlobal( { x: hw, y:-hh } );
            const rb = this.gameObject.toGlobal( { x: hw, y:hh } );
            graphic.beginFill(0x00ff00, 0.1);
            graphic.drawPolygon( [
                lt, rt, rb, lb, lt
            ] );
            graphic.endFill();
        }
        else if(this.colliderType === 'circle') {
            const pos = this.gameObject.toGlobal({x:0, y:0});
            const rPos =  this.gameObject.toGlobal( {x:this.radius, y:0} );
            const radius = Util.Math.length( pos.x, pos.y, rPos.x, rPos.y );
            graphic.beginFill(0x00ff00, 0.1);
            graphic.drawCircle(  pos.x, pos.y,  radius);
            graphic.endFill();
        }
        else if(this.colliderType === 'edge') {
            const path : PIXI.Point[] = [];
            for(let i = 0; i < 2; i++) {
                path.push( this.gameObject.toGlobal( {
                    x:this.points[i]?.x || 0,
                    y:this.points[i]?.y || 0
                } ));
            }
            graphic.beginFill(0x00ff00, 0.1);
            graphic.drawPolygon( path );
            graphic.endFill();
        }
        else if(this.colliderType === 'polygon') {
            const path : PIXI.Point[] = [];
            for(let i = 0; i < this.pointCount; i++) {
                path.push( this.gameObject.toGlobal( {
                    x:this.points[i]?.x || 0,
                    y:this.points[i]?.y || 0
                } ));
            }
            if(this.points[0]) {
                path.push( this.gameObject.toGlobal( {
                    x:this.points[0]?.x || 0,
                    y:this.points[0]?.y || 0
                } ) );
            }

            graphic.beginFill(0x00ff00, 0.1);
            graphic.drawPolygon( path );
            graphic.endFill();
        }
    }

    toJson(): IBodyJson {
        const json = super.toJson() as IBodyJson;

        json.enabled = this.enabled;
        json.bodyType = this.bodyType;
        json.allowSleep = this.allowSleep;
        json.fixedRotation = this.fixedRotation;
        json.bullet = this.bullet;
        json.gravityScale = this.gravityScale;
        json.linearDamping = this.linearDamping;
        json.angularDamping = this.angularDamping;

        json.friction = this.friction;
        json.isSensor = this.isSensor;
        json.restitution = this.restitution;
        json.restitutionThreshold = this.restitutionThreshold;

        json.colliderType = this.colliderType;
        json.radius = this.radius;
        json.width = this.width;
        json.height = this.height;
        json.points = [];
        for(let i = 0; i < this.points.length; i++) {
            json.points[i] = {
                x: this.points[i]?.x || 0,
                y: this.points[i]?.y || 0,
            }
        }
        json.pointCount = this._pointCount;

        return json;
    }

    fromJson(json: IBodyJson, refers: TJsonRefers = {}) {
        super.fromJson(json, refers);

        this.enabled = json.enabled;
        this.bodyType = json.bodyType;
        this.allowSleep = json.allowSleep;
        this.fixedRotation = json.fixedRotation;
        this.bullet = json.bullet;
        this.gravityScale = json.gravityScale;
        this.linearDamping = json.linearDamping;
        this.angularDamping = json.angularDamping;

        this.friction = json.friction;
        this.isSensor = json.isSensor;
        this.restitution = json.restitution;
        this.restitutionThreshold = json.restitutionThreshold;

        this._colliderType = json.colliderType;
        this.radius = json.radius;
        this.width = json.width;
        this.height = json.height;
        this._points = [];
        for(let i = 0; i < json.points.length; i++) {
            this._points[i] = {
                x: json.points[i].x,
                y: json.points[i].y,
            }
        }
        this._pointCount = json.pointCount;
    }
}
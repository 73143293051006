import * as PIXI from "pixi.js";
import {decl} from "postcss";
import EditorScene from "./editorScene";
import Camera from "../../component/camera";
import GameObject from "../../gameObject/gameObject";

export default class Viewport extends PIXI.Container {

    private debug : PIXI.Graphics = new PIXI.Graphics();
    private sizeText: PIXI.Text = new PIXI.Text('', {
        fontSize: 20,
        fill: 0xd93ce8,
    });
    private scene: EditorScene;

    public cameraWidth: number = 0;
    public cameraHeight: number = 0;
    public offsetX : number = 0;
    public offsetY : number = 0;

    public gridX: number = 100;
    public gridY: number = 100;
    public fixedGrid: boolean = false;

    private removeCallback : Function = null;
    public wheelCallback: (scale:number)=>void = null;

    public get centerPosition(): {x: number, y: number} {
        const width = this.scene.game.renderer.width;
        const height = this.scene.game.renderer.height;
        const pos = this.scene.world.toLocal({x:width/2, y:height/2});
        return pos;
    }

    public setCenter(pos : {x:number, y:number}) {
        const scaleX = this.scene.center.scale.x;
        const scaleY = this.scene.center.scale.y;
        const width = this.scene.game.renderer.width;
        const height = this.scene.game.renderer.height;
        // const pos = go.worldPosition;

        this.offsetX = ( pos.x + width/2 ) - (width/2) * scaleX;
        this.offsetY = ( pos.y + height/2 ) - (height/2) * scaleY;
    }

    constructor(scene: EditorScene) {
        super();
        this.addChild(this.debug);
        this.addChild(this.sizeText);

        this.sizeText.anchor.set(0, 1);

        this.scene = scene;

        let down = false;
        let prePoint = {x:0, y:0};
        //@ts-ignore
        const mouseDown = (e: PIXI.InteractionEvent)=>{
            if(!scene.isDown) {
                scene.onSelect(undefined);
                if( !scene.targets.length ) {
                    down = true;
                    prePoint.x = e.data.global.x;
                    prePoint.y = e.data.global.y;
                    document.body.style.cursor = 'grab';
                }
            }
        };
        //@ts-ignore
        const mouseMove = (e: PIXI.InteractionEvent)=>{
            if(down) {
                const offsetX = e.data.global.x;
                const offsetY = e.data.global.y;
                const moveX = offsetX - prePoint.x;
                const moveY = offsetY - prePoint.y;
                prePoint.x = offsetX;
                prePoint.y = offsetY;
                this.offsetX -= moveX * this.scene.center.scale.x;
                this.offsetY -= moveY * this.scene.center.scale.y;
                document.body.style.cursor = 'grabbing';
            }
        };
        const mouseUp = ()=>{
            if(down) {
                document.body.style.cursor = '';
            }
            down = false;
        };
        const mouseOut = ()=>{
            if(down) {
                document.body.style.cursor = '';
            }
            down = false;
        };

        const mouseWheel = (e: any)=>{
            const pos = this.scene.center.toGlobal(new PIXI.Point(
                this.offsetX,
                this.offsetY,
            ));

            const deltaY = e.deltaY / 3000;
            let scale = this.scene.center.scale.x;
            let scale2 = scale + deltaY;
            this.scene.center.scale.set(scale2, scale2);

            // const x = this.scene.game.renderer.width/2;
            // const y = this.scene.game.renderer.height/2;
            const x = e.offsetX;
            const y = e.offsetY;

            const pos2 = this.scene.center.toGlobal(new PIXI.Point(
                this.offsetX,
                this.offsetY,
            ));
            pos2.x -= x;
            pos2.y -= y;
            const moveX = pos2.x - pos.x;
            const moveY = pos2.y - pos.y;
            this.offsetX += moveX * (scale2-scale);
            this.offsetY += moveY * (scale2-scale);
            this.wheelCallback && this.wheelCallback( this.scene.center.scale.x );
        };

        const interaction = scene.game.renderer.plugins.interaction;

        interaction.on('mousedown', mouseDown);
        interaction.on('mousemove', mouseMove);
        interaction.on('mouseup', mouseUp);
        interaction.on('mouseout', mouseOut);
        scene.game.renderer.view.addEventListener('mousewheel', mouseWheel);

        this.removeCallback = ()=>{
            interaction.off('mousedown', mouseDown);
            interaction.off('mousemove', mouseMove);
            interaction.off('mouseup', mouseUp);
            interaction.off('mouseout', mouseOut);
            scene.game.renderer.view.removeEventListener('mousewheel', mouseWheel);
        }

    }

    destroy() {
        this.removeCallback && this.removeCallback();
        this.removeCallback = null;
        super.destroy();
        this.debug = null;
        this.scene = null;
    }

    update() {
        const co = '#d93ce8';
        this.debug.clear();

        const camera = this.scene.camera as Camera;
        const global = this.scene.world.toGlobal({x:camera.x, y:camera.y});
        const x = global.x - (this.cameraWidth/2) / this.scene.center.scale.x;
        const y = global.y - (this.cameraHeight/2) / this.scene.center.scale.y;


        this.debug.lineStyle( 1, 0xd93ce8, 1 );

        const width = this.cameraWidth / this.scene.center.scale.x;
        const height = this.cameraHeight / this.scene.center.scale.y;
        this.debug.drawRect(x , y, width, height);


        this.sizeText.position.set( x, y );
        this.sizeText.text = `${this.cameraWidth} x ${this.cameraHeight}`;

        if(camera.enableBounds) {
            this.debug.lineStyle( 1, 0xff0000, 0.5 );
            const global = this.scene.world.toGlobal({x:0, y:0});
            const minX = global.x + (camera.bounds.minX)/ this.scene.center.scale.x;
            const maxX = global.x + (camera.bounds.maxX)/ this.scene.center.scale.x;
            const minY = global.y + (camera.bounds.minY)/ this.scene.center.scale.y;
            const maxY = global.y + (camera.bounds.maxY)/ this.scene.center.scale.y;
            this.debug.drawRect(minX , minY, maxX - minX, maxY-minY);
        }

        this.updateGrid();

        this.updateTransform();

    }

    private updateGrid() {

        const width = this.scene.game.renderer.width;
        const height = this.scene.game.renderer.height;

        const lt = this.scene.world.toLocal({x:-this.gridX, y:-this.gridY});
        const rb = this.scene.world.toLocal( { x: width + this.gridX, y: height + this.gridY } );

        this.debug.lineStyle( 1, 0xffffff, 0.1 );


        if(this.gridX) {
            const sx = Math.floor(lt.x / this.gridX) * this.gridX;
            if((rb.x - sx)/this.gridX <= 400) {
                for(let x = sx; x < rb.x; x += this.gridX) {
                    const pos1 = this.scene.world.toGlobal({x, y: lt.y});
                    const pos2 = this.scene.world.toGlobal({x, y: rb.y});
                    this.debug.moveTo( pos1.x, pos1.y );
                    this.debug.lineTo( pos2.x, pos2.y );
                }
            }
        }


        if(this.gridY) {
            const sy = Math.floor(lt.y / this.gridY) * this.gridY;
            if((rb.y - sy)/this.gridY <= 400) {
                for(let y = sy; y < rb.y; y += this.gridY) {
                    const pos1 = this.scene.world.toGlobal({x: lt.x, y});
                    const pos2 = this.scene.world.toGlobal({x: rb.x, y});
                    this.debug.moveTo( pos1.x, pos1.y );
                    this.debug.lineTo( pos2.x, pos2.y );
                }
            }
        }





    }
}
import Component, {IComponentJson} from "../core/component";
import {component, property} from "../util/decorator";
import * as PIXI from "pixi.js";
import GameObject, {TJsonRefers} from "../gameObject/gameObject";
import EditorScene, {ECtrlMode} from "../tools/editor/editorScene";
import {SetEditorInteraction} from "../tools/editor/editorScene";
import FontAsset from "../asset/fontAsset";

export interface ITextJson extends IComponentJson {
    anchor: {x:number, y:number};
    string : string;

    fontAsset: string;
    fontSize : number;
    color: PIXI.TextStyleFill;
    alpha: number,
    fontStyle: PIXI.TextStyleFontStyle;
    fontVariant: PIXI.TextStyleFontVariant;
    fontWeight: PIXI.TextStyleFontWeight;

    wordWrap: boolean;
    breakWords: boolean;
    wordWrapWidth: number;
    align: PIXI.TextStyleAlign;
    lineHeight: number;
    leading: number;
    trim: boolean;

    dropShadow: boolean;
    dropShadowColor: string;
    dropShadowAlpha: number;
    dropShadowAngle: number;
    dropShadowBlur: number;
    dropShadowDistance: number;

    stroke: string;
    strokeThickness: number;
    lineJoin: PIXI.TextStyleLineJoin;

}

@component('Text')
export default class Text extends Component {
    private _text: PIXI.Text;
    public get text() : PIXI.Text {
        return this._text;
    }

    public get width(): number {
        return this._text.width;
    }
    public get height(): number {
        return this._text.height;
    }

    @property(FontAsset)
    public fontAsset: FontAsset;

    public fontSize: number = 24;
    public color: PIXI.TextStyleFill = '#ffffff';
    public fontStyle: PIXI.TextStyleFontStyle = 'normal';
    public fontVariant: PIXI.TextStyleFontVariant = 'normal';
    public fontWeight: PIXI.TextStyleFontWeight = 'normal';
    public align: PIXI.TextStyleAlign = 'left';

    public lineHeight: number = 0;

    //wordWrap
    public wordWrap: boolean = false;
    public breakWords: boolean = false;
    public wordWrapWidth: number = 100;
    public leading: number = 0;

    //shadow
    public dropShadow: boolean = false;
    public dropShadowColor: string = '#000000';
    public dropShadowAlpha: number = 1;
    public dropShadowAngle: number = Math.PI/6;
    public dropShadowBlur : number = 0;
    public dropShadowDistance: number = 10;

    //stroke
    public stroke: string = '#000000';
    public strokeThickness: number = 0;
    public lineJoin: PIXI.TextStyleLineJoin = 'miter';


    //texture
    public trim: boolean = false;

    public get scale() : PIXI.Point {
        return this._text.scale;
    }

    public get anchor() : PIXI.Point {
        return this._text.anchor;
    }

    public get alpha(): number {
        return this._text.alpha;
    }

    public set alpha(a: number) {
        this._text.alpha = a;
    }



    public get string(): string {
        return this._text.text;
    }
    public set string(str: string) {
        this._text.text = str;
    }

    create() {
        this._text = new PIXI.Text('text');
        this.updateStyle();
        this._text.visible = this.gameObject.worldActive;
        (this.gameObject as PIXI.utils.EventEmitter).on('added', this.onAdded, this );
        this.gameObject.addChildAt(this._text, 0);

        if(this.game.scenes.crtScene instanceof EditorScene) {
            SetEditorInteraction(this._text, this.gameObject);
        }
    }

    private onAdded() {
        this._text.visible = this.gameObject.worldActive;
    }

    destroy() {
        (this.gameObject as PIXI.utils.EventEmitter).off('added', this.onAdded, this );
        this._text.destroy();
        this._text = null;
        super.destroy();
    }

    onEnable() {
        super.onEnable();
        if(this._text){
            this._text.visible = true;
        }
    }

    onDisable() {
        super.onDisable();
        if(this._text){
            this._text.visible = false;
        }
    }

    updateStyle() {
        const fontStyle = new PIXI.TextStyle({
            fontSize: this.fontSize,
            fill: this.color,
            fontStyle: this.fontStyle,
            fontVariant: this.fontVariant,
            fontWeight: this.fontWeight,
            fontFamily: this.fontAsset?.fontFamily || '',

            wordWrap: this.wordWrap,
            breakWords: this.breakWords,
            wordWrapWidth: this.wordWrapWidth,
            align: this.align,
            lineHeight: this.lineHeight,
            leading: this.leading,

            trim: this.trim,

            dropShadow: this.dropShadow,
            dropShadowColor: this.dropShadowColor,
            dropShadowAlpha: this.dropShadowAlpha,
            dropShadowAngle: this.dropShadowAngle,
            dropShadowBlur: this.dropShadowBlur,
            dropShadowDistance: this.dropShadowDistance,
            stroke: this.stroke,
            strokeThickness: this.strokeThickness,
            lineJoin: this.lineJoin,
        })
        this.text.style = fontStyle;
    }

    toJson(): ITextJson {
        const json = super.toJson() as ITextJson;
        json.anchor = {x:this.anchor.x, y: this.anchor.y};
        json.string = this.string;
        json.color = this.color;
        json.alpha = this.alpha;
        json.fontSize = this.fontSize;
        json.fontStyle = this.fontStyle;
        json.fontVariant = this.fontVariant;
        json.fontWeight = this.fontWeight;
        json.fontAsset = this.fontAsset?.id || '';

        json.wordWrap = this.wordWrap;
        json.breakWords = this.breakWords;
        json.wordWrapWidth = this.wordWrapWidth;
        json.align = this.align;
        json.lineHeight = this.lineHeight;
        json.leading = this.leading;
        json.trim = this.trim;

        json.dropShadow = this.dropShadow;
        json.dropShadowColor = this.dropShadowColor;
        json.dropShadowAlpha = this.dropShadowAlpha;
        json.dropShadowAngle = this.dropShadowAngle;
        json.dropShadowBlur = this.dropShadowBlur;
        json.dropShadowDistance = this.dropShadowDistance;

        json.stroke = this.stroke;
        json.strokeThickness = this.strokeThickness;
        json.lineJoin = this.lineJoin;

        return json;
    }
    fromJson(json: ITextJson, refers: TJsonRefers = {}) {
        super.fromJson(json, refers);

        this.fontAsset = this.game.assetManager.getAsset( json.fontAsset ) as FontAsset;

        this.anchor.set(json.anchor.x, json.anchor.y);
        this.color = json.color;
        this.alpha = json.alpha === undefined ? 1 : json.alpha;
        this.fontSize = json.fontSize;
        this.fontStyle = json.fontStyle;
        this.fontVariant = json.fontVariant;
        this.fontWeight = json.fontWeight;

        this.wordWrap = json.wordWrap;
        this.breakWords = json.breakWords;
        this.wordWrapWidth = json.wordWrapWidth;
        this.align = json.align;
        this.lineHeight = json.lineHeight;
        this.leading = json.leading;
        this.trim = json.trim;


        this.dropShadow = json.dropShadow;
        this.dropShadowColor = json.dropShadowColor;
        this.dropShadowAlpha = json.dropShadowAlpha;
        this.dropShadowAngle = json.dropShadowAngle;
        this.dropShadowBlur = json.dropShadowBlur;
        this.dropShadowDistance = json.dropShadowDistance;

        this.stroke = json.stroke;
        this.strokeThickness = json.strokeThickness;
        this.lineJoin = json.lineJoin;

        this.updateStyle();

        this.string = json.string;
    }
}
import Component, {IComponentJson} from "../core/component";
import {component, property} from "../util/decorator";
import GameObject, {TJsonRefers} from "../gameObject/gameObject";
import TextureAsset from "../asset/textureAsset";
import * as PIXI from 'pixi.js';
import {IScriptJson} from "./script";

export interface ICameraJson extends IComponentJson {
    width: number;
    height: number;
    bounds: { minX:number, minY: number, maxX: number, maxY: number },
    followTarget: string,
    followOffset: {x: number, y: number},
    enableBounds: boolean,
}

@component('Camera', false)
export default class Camera extends Component {
    @property(Number) width : number;
    @property(Number) height : number;

    public bounds : PIXI.Bounds = new PIXI.Bounds();


    @property(GameObject)
    public followTarget: GameObject = null;

    public followOffset: PIXI.Point = new PIXI.Point();

    public enableBounds: boolean = false;

    private _shakeDis : {x: number, y: number} = {x: 0, y: 0};
    private _shakeX: number = 0;
    private _shakeY: number = 0;
    private _x : number = 0;
    private _y : number = 0;

    public set x(p:number) {
        this._x = p;
        this._updatePos( p, this._y );
    }

    public set y(p:number) {
        this._y = p;
        this._updatePos( this._x, p );
    }

    public get x(): number {
        return this._x;
    }

    public get y(): number {
        return this._y;
    }


    create() {
        this.bounds.minX = 0;
        this.bounds.minY = 0;
        this.bounds.maxX = this.width/2;
        this.bounds.maxY = this.height/2;
    }

    private _updatePos(x: number, y: number) {
        if(this.enableBounds) {
            if( this.bounds.maxX - this.bounds.minX >= this.width ) {
                const hw =  this.width/2;
                if( this.bounds.minX  > x - hw ) {
                    this.gameObject.x = this.bounds.minX + hw + this._shakeX;
                }
                else if( this.bounds.maxX < x + hw) {
                    this.gameObject.x = this.bounds.maxX - hw + this._shakeX;
                }
                else{
                    this.gameObject.x = x + this._shakeX;
                }
            }

            if(this.bounds.maxY - this.bounds.minY >= this.height) {
                const hh = this.height/2;
                if(this.bounds.minY  > y - hh) {
                    this.gameObject.y = this.bounds.minY + hh + this._shakeY;
                }
                else if(this.bounds.maxY  < y + hh) {
                    this.gameObject.y = this.bounds.maxY - hh + this._shakeY;
                }
                else {
                    this.gameObject.y = y + this._shakeY;
                }
            }
        }
        else {
            this.gameObject.x = x + this._shakeX;
            this.gameObject.y = y + this._shakeY;
        }
    }

    setShake( x: number, y: number ) {
        this._shakeDis.x = x;
        this._shakeDis.y = y;
    }


    update(delta: number) {
        if(this._shakeDis.x > 0 || this._shakeDis.y > 0) {
            this._shakeX = this.gameObject.functionManager.random.rangeInt(-this._shakeDis.x, this._shakeDis.x + 1);
            this._shakeY = this.gameObject.functionManager.random.rangeInt(-this._shakeDis.y, this._shakeDis.y + 1);
        }
        else {
            this._shakeX = 0;
            this._shakeY = 0;
        }

        if(this.followTarget) {
            this._x = this.followTarget.x + this.followOffset.x;
            this._y = this.followTarget.y + this.followOffset.y;
        }
        this._updatePos(this.x, this.y);
    }

    toJson() : ICameraJson {
        const json : ICameraJson = super.toJson() as ICameraJson;
        json.width = this.width;
        json.height = this.height;
        json.bounds = {
            minX: this.bounds.minX || 0,
            minY: this.bounds.minY || 0,
            maxX: this.bounds.maxX !== undefined ? this.bounds.maxX : this.width/2,
            maxY: this.bounds.maxY !== undefined ? this.bounds.maxY : this.height/2,
        };
        json.followTarget = this.followTarget?.id;
        json.enableBounds = this.enableBounds;
        json.followOffset = {
            x: this.followOffset.x,
            y: this.followOffset.y
        }

        return json;
    }

    fromJson(json : ICameraJson, refers: TJsonRefers) {
        super.fromJson(json, refers);
        this.width = json.width;
        this.height = json.height;
        if(json.bounds) {
            if( json.bounds.maxX === undefined ) {
                json.bounds.maxX = this.width/2;
            }
            if( json.bounds.maxY === undefined ) {
                json.bounds.maxY = this.height/2;
            }

            this.bounds.minX = json.bounds.minX || 0;
            this.bounds.maxX = json.bounds.maxX || 0;
            this.bounds.minY = json.bounds.minY || 0;
            this.bounds.maxY = json.bounds.maxY || 0;
        }
        if(json.followOffset) {
            this.followOffset.x = json.followOffset.x;
            this.followOffset.y = json.followOffset.y;
        }
        this.enableBounds = !!json.enableBounds;
        if(json.followTarget) {
            this.followTarget = this.game.scenes.crtScene.findById( json.followTarget ) as GameObject;
        }
        else {
            this.followTarget = null;
        }

        this.x = 0;
        this.y = 0;
    }

    onCompleteJson(json : ICameraJson, refers : TJsonRefers) {
        if(json.followTarget) {
            this.followTarget = refers[json.followTarget] as GameObject;
        }
        else {
            this.followTarget = null;
        }
    }
}
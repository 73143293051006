import {Typography, Row, Col, Input, Space, InputNumber, Checkbox} from "antd";
import {ReactElement} from "react";
import ViewTooltip from "../viewTooltip";

const {Text} = Typography;

export default function BoundsView({ label, bounds, onChange, disabled, onClick, tooltip }:
                                            {label:string, bounds: { minX:number, maxX:number, minY : number, maxY:number },
                                                onChange:(bounds: { minX:number, maxX:number, minY : number, maxY:number })=>void,
                                                disabled?:boolean, onClick?:Function, tooltip?:string,}) {


    return <>
        <Row wrap={false} className={"valueContainer"}>
            <Col className={'valueLabel'}>
                {
                    tooltip? <ViewTooltip title={tooltip}>
                        <Text style={{marginRight: '5px'}}>{label}</Text>
                    </ViewTooltip> :  <Text style={{marginRight: '5px'}}>{label}</Text>
                }
            </Col>
            <Col className={'valueBody'} flex={'auto'}>
                <Row wrap={false}>
                    <Col style={{flex: '0 0 20px', display: "flex", justifyContent: 'center', alignItems: 'center', marginRight: '5px'}}>
                        <Text style={{color:'#f5222d'}}>minX</Text>
                    </Col>
                    <Col flex={'auto'}>
                        <InputNumber readOnly={!onChange} style={{width:'100%'}} disabled={disabled} value={Number(bounds?.minX?.toFixed(4))}  onChange={((num)=>{
                            bounds.minX = num;
                            onChange && onChange(bounds);
                        })} onClick={()=>{onClick && onClick()}}></InputNumber>
                    </Col>
                    <Col style={{flex: '0 0 20px', display: "flex", justifyContent: 'center', alignItems: 'center', marginLeft: '5px', marginRight: '5px'}}>
                        <Text style={{color:'#a0d911'}}>maxX</Text>
                    </Col>
                    <Col flex={'auto'}>
                        <InputNumber readOnly={!onChange} style={{width:'100%'}} disabled={disabled} value={Number(bounds?.maxX?.toFixed(4))}  onChange={((num)=>{
                            bounds.maxX = num;
                            onChange && onChange(bounds);
                        })} onClick={()=>{onClick && onClick()}}></InputNumber>
                    </Col>
                </Row>
                <Row wrap={false}>
                    <Col style={{flex: '0 0 20px', display: "flex", justifyContent: 'center', alignItems: 'center', marginRight: '5px'}}>
                        <Text style={{color:'#f5222d'}}>minY</Text>
                    </Col>
                    <Col flex={'auto'}>
                        <InputNumber readOnly={!onChange} style={{width:'100%'}} disabled={disabled} value={Number(bounds?.minY?.toFixed(4))}  onChange={((num)=>{
                            bounds.minY = num;
                            onChange && onChange(bounds);
                        })} onClick={()=>{onClick && onClick()}}></InputNumber>
                    </Col>
                    <Col style={{flex: '0 0 20px', display: "flex", justifyContent: 'center', alignItems: 'center', marginLeft: '5px', marginRight: '5px'}}>
                        <Text style={{color:'#a0d911'}}>maxY</Text>
                    </Col>
                    <Col flex={'auto'}>
                        <InputNumber readOnly={!onChange} style={{width:'100%'}} disabled={disabled} value={Number(bounds?.maxY?.toFixed(4))}  onChange={((num)=>{
                            bounds.maxY = num;
                            onChange && onChange(bounds);
                        })} onClick={()=>{onClick && onClick()}}></InputNumber>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>
}
export const GameErrorType = {
    ExistsComponent: {
        type: 'ExistsComponent',
        field: function (componentName:string, gameObjectName:string){
            return {
                componentName,
                gameObjectName,
            }
        },
    },
}



export default class GameError extends Error {
    public type: string;
    public filed: any;

    constructor(errorType:string, filed: any, message?: string) {
        super(message);
        this.type = errorType;
        this.filed = filed;
    }
}
import BaseFunction from "../baseFunction";
import {scriptTableData} from "../scriptTable";
import ScriptError from "../scriptError";

export const RandomFunctionTable: scriptTableData[] = [
    {
        value: `random.rangeInt(0, 100)`,
        caption: `random.rangeInt(min: number, max: number): number`,
        score: 10,
        meta: 'function',
        name: 'random.rangeInt',
        tooltip: 'random.rangeInt(min: number, max: number): number',
        description: {
            ko: '범위(min ~ max) 안에서 랜덤한 인트형 숫자를 반환받습니다.',
            en: '',
        },
    },
    {
        value: `random.range(0, 1)`,
        caption: `random.range(min: number, max: number): number`,
        score: 10,
        meta: 'function',
        name: 'random.range',
        tooltip: 'random.range(min: number, max: number): number',
        description: {
            ko: '범위(min ~ max) 안에서 랜덤한 실수를 반환받습니다.',
            en: '',
        },
    },
    {
        value: `random.peek([])`,
        caption: `random.peek(arr: any[]) : any`,
        score: 10,
        meta: 'function',
        name: 'random.peek',
        tooltip: 'random.peek(arr: any[]) : any',
        description: {
            ko: '배열중에서 랜덤으로 하나를 선택하여 반환 받습니다.',
            en: '',
        },
    },
    {
        value: `random.peeks([], 2)`,
        caption: `random.peek(arr: any[], count: number) : any[]`,
        score: 10,
        meta: 'function',
        name: 'random.peeks',
        tooltip: 'random.peeks(arr: any[], count: number) : any[]',
        description: {
            ko: '배열에서 지정된 개수만큼 랜덤으로 중복되지 않게 요소를 뽑아서  배열로 반환 받습니다.',
            en: '',
        },
    },
    {
        value: `random.scorePeek([])`,
        caption: `random.scorePeek(arr: {score:number, item: any}[]):any`,
        score: 10,
        meta: 'function',
        name: 'random.scorePeek',
        tooltip: 'random.scorePeek(arr: {score:number, item: any}[]):any',
        description: {
            ko: '뽑힐 확률점수를 포함한 배열에서 요소를 하나 뽑아서 반환합니다.',
            en: '',
        },
    },
];

export default class RandomFunction extends BaseFunction {
    protected _name: string = 'Random';

    public get instance(): RandomFunction {
        return this;
    }

    public rangeInt(min: number, max: number) : number {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    public range(min: number, max: number) : number {
        return (Math.random() * (max - min)) + min;
    }

    public peek(arr:any[]) : any {
        const idx = this.rangeInt( 0, arr.length );
        return arr[idx];
    }

    public peeks(arr:any[], count: number) : any[] {
        if(arr.length < count) {
            throw new ScriptError('배열범위 초과',
                `뽑을 개수가 배열의 크기보다 큽니다.`,
                this.name,
                'random.peeks');
        }

        const copyArr = arr.slice();
        for(let i = 0; i < copyArr.length * 10; i++) {
            const srcIdx = this.rangeInt(0, copyArr.length);
            const dstIdx = this.rangeInt(0, copyArr.length);
            if( srcIdx === dstIdx) continue;
            const temp = copyArr[srcIdx];
            copyArr[srcIdx] = copyArr[dstIdx];
            copyArr[dstIdx] = temp;
        }
        return copyArr.slice(0, count);
    }

    public scorePeek(arr:{score: number, item: any}[]) : any {
        let sumScore = 0;
        for(let i = 0; i < arr.length; i++) {
            const a = arr[i];
            if( typeof a.score === 'number') {
                sumScore += a.score;
            }
        }
        let rnd = this.rangeInt(0, sumScore);

        for(let i = 0; i < arr.length; i++) {
            rnd -= arr[i].score;
            if(rnd <= 0) {
                return arr[i].item;
            }
        }
    }
}
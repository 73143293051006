import EditorManager from "../../../../services/editorManager";
import {Col, Input, InputNumber, Row, Space, Typography} from "antd";
import {IComponentJson} from "../../../../game/scripts/core/component";
import {IGameObjectJson} from "../../../../game/scripts/gameObject/gameObject";
import {ReactElement} from "react";
import ViewTooltip from "../viewTooltip";
const { Text } = Typography;


export interface ISizeViewProps {
    label: string;
    size: {width:number, height:number};
    disabled?: boolean;
    onChange?: (width:number, height:number)=>void;
    onClick?: Function;
    nameLabel?: ReactElement,
    tooltip?:string,
}

export default function SizeView({label, size, disabled, onChange, onClick, nameLabel, tooltip}: ISizeViewProps) {
    const localPoint = {width:size.width, height: size.height};
    return (<Row wrap={false}  className={"valueContainer"}>
        {/*<Col className={'valueLabel'}>*/}
        {/*    {nameLabel?nameLabel:<Text style={{marginRight: '5px'}}>{label}</Text>}*/}
        {/*    {tooltip}*/}
        {/*</Col>*/}
        <Col className={'valueLabel'}>
            {
                tooltip? <ViewTooltip title={tooltip}>
                    <Text style={{marginRight: '5px'}}>{label}</Text>
                </ViewTooltip> :  <Text style={{marginRight: '5px'}}>{label}</Text>
            }
        </Col>
        <Col className={'valueBody'} flex={'auto'}>
            <Row wrap={false}>
                <Col style={{flex: '0 0 20px', display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={{color:'#f5222d'}}>W</Text>
                </Col>
                <Col flex={'auto'}>
                    <InputNumber readOnly={!onChange} style={{width:'100%'}} disabled={disabled} value={Number(size?.width?.toFixed(4))}  onChange={((num)=>{
                        localPoint.width = num;
                        onChange && onChange(localPoint.width, localPoint.height);
                    })} onClick={()=>{onClick && onClick()}}></InputNumber>
                </Col>
                <Col style={{flex: '0 0 20px', display: "flex", justifyContent: 'center', alignItems: 'center', marginLeft: '5px'}}>
                    <Text style={{color:'#a0d911'}}>H</Text>
                </Col>
                <Col flex={'auto'}>
                    <InputNumber readOnly={!onChange} style={{width:'100%'}} disabled={disabled} value={Number(size?.height?.toFixed(4))}  onChange={((num)=>{
                        localPoint.height = num;
                        onChange && onChange(localPoint.width, localPoint.height);
                    })} onClick={()=>{onClick && onClick()}}></InputNumber>
                </Col>
            </Row>
        </Col>
    </Row>);
}
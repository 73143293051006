import {IComponentViewProps} from "./componentView";
import {Button, Checkbox, Input, InputNumber, Space, Typography} from "antd";
import EditorManager from "../../../../services/editorManager";
import {IScriptJson} from "../../../../game/scripts/component/script";
import ScriptAsset, {IScriptAssetProp} from "../../../../game/scripts/asset/scriptAsset";
import {DragEventHandler, ReactElement, useState} from "react";
import { useDrop } from "react-dnd";
import {treeManager} from "../../hierarchy/hierarchy";
import {DragItem} from "react-arborist/dist/types";
import {DropResult} from "react-arborist/dist/dnd/drop-hook";
import {useDispatch} from "react-redux";
import {changeSelectScriptId, selectSelectScriptId} from "../../../../store/modalSlice";
import {SettingFilled} from "@ant-design/icons";
import InputView from "../valueView/inputView";
import InputNumberView from "../valueView/inputNumberView";
import CheckboxView from "../valueView/checkboxView";
import TextureAssetView from "../valueView/ref/textureAssetView";
import AnimatorAssetView from "../valueView/ref/animatorAssetView";
import GoRefView from "../valueView/ref/goRefView";
import CompRefView from "../valueView/ref/compRefView";
import AssetView from "../valueView/ref/assetView";
import {useAppSelector} from "../../../../app/hooks";
import {IComponentJson} from "../../../../game/scripts/core/component";
import Camera from "../../../../game/scripts/component/camera";
import InstanceRefView from "../valueView/ref/instanceRefView";
import ViewTooltip from "../viewTooltip";
import PrefabAsset from "mogera-game/scripts/asset/prefabAsset";
import {store} from "../../../../app/store";
import {changeRefreshPrefabView} from "../../../../store/refeshSlice";
import TextAreaView from "../valueView/textAreaView";

const { Text } = Typography;
let dropProp: IScriptAssetProp;




export interface IScriptViewProps extends IComponentViewProps {
    scriptName: string,
}


export default function ScriptView(props: IScriptViewProps) {
    const json = props.json as IScriptJson;
    const scriptJson = json.script;
    const propsJson = json.props;
    const scriptName = props.scriptName;
    const scriptAsset = EditorManager.Instance.game.assetManager.getAsset( scriptJson ) as ScriptAsset;
    const dispatch = useDispatch();

    const specArg = function (){
        return {
            accept: "NODE",
            canDrop: (item: DragItem, monitor: any) => {
                if (dropProp?.type === 'GameObject') {
                    return true;
                }
                else if (dropProp?.type === 'Instance') {
                    return true;
                }
                else if( dropProp?.type === 'Component' ) {
                    const go = EditorManager.Instance.findGameObject(item.id);
                    const compType = dropProp.componentType;
                    const comp = go.getComponent(compType);
                    if(comp) {
                        return true;
                    }
                }
                else if( dropProp?.type === 'Asset' || dropProp?.assetType === 'Prefab' ) {
                    return true;
                }
                return false;
            },
            hover: (item: DragItem, monitor: any) => {
                // console.log('hover', item, dragProp);
            },
            drop: (item: DragItem, monitor: any): any => {
                if( dropProp?.type === 'GameObject' ){
                    const go = EditorManager.Instance.findGameObject(item.id);
                    // propsJson[dropProp.name] = go?.id;
                    // EditorManager.Instance.modifyComponent(json);
                    setNewProps(dropProp.name, go?.id);
                }
                if( dropProp?.type === 'Instance' ){
                    const go = EditorManager.Instance.findGameObject(item.id);
                    // propsJson[dropProp.name] = go?.id;
                    // EditorManager.Instance.modifyComponent(json);
                    setNewProps(dropProp.name, go?.functionManager.id);
                }
                else if( dropProp?.type === 'Component' ) {
                    const go = EditorManager.Instance.findGameObject(item.id);
                    const compType = dropProp.componentType;
                    const comp = go?.getComponent(compType);
                    // propsJson[dropProp.name] = comp?.id;
                    // EditorManager.Instance.modifyComponent(json);
                    setNewProps(dropProp.name, comp?.id);
                }
                else if( dropProp?.type === 'Asset' && dropProp?.assetType === 'Prefab' ) {
                    const asset = EditorManager.Instance.assetManager.getAsset(item.id);
                    if(asset) {
                        setNewProps(dropProp.name, asset?.id);
                    }
                    else {
                        const go = EditorManager.Instance.findGameObject(item.id);
                        if(go) {

                            const _createPrefab = ()=> {
                                const newPrefab = new PrefabAsset(go.game, go.toJson());
                                newPrefab.rename(EditorManager.Instance.assetManager.getVerifiedName(go.name, newPrefab.type));
                                newPrefab.color = `#${Math.floor(Math.random()*16777215).toString(16)}`;
                                go.prefabId = newPrefab.id;
                                go.game.assetManager.addAsset(newPrefab);
                                setNewProps(dropProp.name, newPrefab.id);
                                dispatch(changeRefreshPrefabView(Math.random()));
                                EditorManager.Instance.createHierarchy();
                            }

                            if(go.prefabId) {
                                const asset2 = EditorManager.Instance.assetManager.getAsset(go.prefabId);
                                if(asset2) {
                                    setNewProps(dropProp.name, asset2.id);
                                }
                                else {
                                    _createPrefab();
                                }
                            }
                            else {
                                _createPrefab();
                            }
                        }
                    }
                    // propsJson[dropProp.name] = asset?.id;
                    // EditorManager.Instance.modifyComponent(json);

                }
                else if( dropProp?.type === 'Asset' ) {
                    const asset = EditorManager.Instance.assetManager.getAsset(item.id);
                    // propsJson[dropProp.name] = asset?.id;
                    // EditorManager.Instance.modifyComponent(json);
                    setNewProps(dropProp.name, asset?.id);
                }

                return null;
            }
        }
    }

    function setNewProps(name: string, value: any) {
        const selectGo = EditorManager.Instance.getSelectGameObject();
        const cameraComp = selectGo.getComponent(scriptName);

        const newJson = cameraComp.toJson() as IScriptJson;
        newJson.props[name] = value;
        EditorManager.Instance.modifyComponent(newJson);
    }

    const [, drop] = useDrop<DragItem, DropResult | null, { isOver: boolean }>(specArg);

    function setProps(name: string, value: any) {
        propsJson[name] = value;
        EditorManager.Instance.modifyComponent( json );
    }

    let valueElements: ReactElement[] = [];
    if( scriptAsset ) {
        const scriptProps = scriptAsset.json.props;
        for(let i = 0; i < scriptProps.length; i++) {
            const prop = scriptProps[i];
            if(prop.type === "Value") {
                if(prop.valueType === 'String') {
                    valueElements.push(<TextAreaView tooltip={prop.tooltip} key={prop.name} label={prop.name} value={propsJson[prop.name]} onChange={function (value){
                        setProps(prop.name, value);
                    }}></TextAreaView>);
                }
                else if(prop.valueType === 'Number') {
                    valueElements.push(<InputNumberView tooltip={prop.tooltip} key={prop.name} label={prop.name} value={propsJson[prop.name]} onChange={function (value){
                        setProps(prop.name, value);
                    }}></InputNumberView>)
                }
                else if(prop.valueType === 'Boolean') {
                    valueElements.push(<CheckboxView tooltip={prop.tooltip} key={prop.name} label={prop.name} checked={propsJson[prop.name]} onChange={function (value){
                        setProps(prop.name, value);
                    }}></CheckboxView>)
                }
            }
            else if(prop.type === 'Asset') {
                if( prop.assetType === 'Texture' ) {
                    valueElements.push(<TextureAssetView tooltip={prop.tooltip} key={prop.name} label={prop.name} texture={propsJson[prop.name]} onClick={async function () {
                        const id = await EditorManager.Instance.selectImage(propsJson[prop.name]);
                        if(id !== null) {
                            setProps(prop.name, id);
                        }
                    }} onRemove={function (){
                        setProps(prop.name, '');
                    }}/>)
                }
                else if(prop.assetType === 'Sound') {
                    valueElements.push(<AssetView tooltip={prop.tooltip} key={prop.name} assetType={prop.assetType} label={prop.name} assetId={propsJson[prop.name]} onClick={async function () {
                        const id = await EditorManager.Instance.selectSound(propsJson[prop.name]);
                        if(id !== null) {
                            setProps(prop.name, id);
                        }
                    }} onRemove={function (){
                        setProps(prop.name, '');
                    }}/>)
                }
                else if(prop.assetType === 'Scene') {
                    valueElements.push(<AssetView tooltip={prop.tooltip} key={prop.name} assetType={prop.assetType} label={prop.name} assetId={propsJson[prop.name]} onClick={async function () {
                        const id = await EditorManager.Instance.selectScene(propsJson[prop.name]);
                        if(id !== null) {
                            setProps(prop.name, id);
                        }
                    }} onRemove={function (){
                        setProps(prop.name, '');
                    }}/>)
                }
                else if(prop.assetType === 'Prefab') {
                    valueElements.push(<AssetView tooltip={prop.tooltip} key={prop.name} assetType={prop.assetType} label={prop.name} assetId={propsJson[prop.name]} onClick={async function () {

                    }} onRemove={function (){
                        setProps(prop.name, '');
                    }} onDragLeave={function (){
                        dropProp = null;
                    }} onDragEnter={function (){
                        dropProp = prop;
                    }} onDrop={function (event) {
                        const assetId = event.dataTransfer.getData('assetId');
                        const asset = EditorManager.Instance.assetManager.getAsset(assetId);
                        if(asset?.type === 'Prefab') {
                            setProps(prop.name, asset.id);
                        }
                    }}
                    />)
                }
                else if(prop.assetType === 'Animator') {
                    valueElements.push(<AnimatorAssetView tooltip={prop.tooltip} key={prop.name} assetId={propsJson[prop.name]} label={prop.name} onClick={async function (){
                        const assetId = await EditorManager.Instance.selectAnimator(propsJson[prop.name]);
                        if(assetId !== null) {
                            setProps(prop.name, assetId);
                        }
                    }} onRemove={function (){
                        setProps(prop.name, '');
                    }}/>)
                }
                else if(prop.assetType === 'Font') {
                    valueElements.push(<AssetView tooltip={prop.tooltip} key={prop.name} assetType={prop.assetType} label={prop.name} assetId={propsJson[prop.name]} onClick={async function () {
                        const id = await EditorManager.Instance.selectFont(propsJson[prop.name]);
                        if(id !== null) {
                            setProps(prop.name, id);
                        }
                    }} onRemove={function (){
                        setProps(prop.name, '');
                    }}/>)
                }
                //스크립트 에셋은 참조 안함
                // else if(prop.assetType === 'Script') {
                //
                // }
            }
            else if(prop.type === 'GameObject') {
                const go = EditorManager.Instance.findGameObject( propsJson[prop.name] );
                valueElements.push(<GoRefView tooltip={prop.tooltip} key={prop.name} id={go?.id} label={prop.name} onClick={function (){

                }} onRemove={function (){
                    setProps(prop.name, '');
                }}  onDragLeave={function (){
                    dropProp = null;
                }} onDragEnter={function (){
                    dropProp = prop;
                }}/>);
            }
            else if(prop.type === 'Instance') {
                const instance = EditorManager.Instance.findInstance( propsJson[prop.name] );
                valueElements.push(<InstanceRefView tooltip={prop.tooltip} key={prop.name} id={instance?.id} label={prop.name} onClick={function (){

                }} onRemove={function (){
                    setProps(prop.name, '');
                }}  onDragLeave={function (){
                    dropProp = null;
                }} onDragEnter={function (){
                    dropProp = prop;
                }}/>);
            }
            else if(prop.type === 'Component') {
                const comp = EditorManager.Instance.findComponent( propsJson[prop.name] );
                valueElements.push(<CompRefView tooltip={prop.tooltip} key={prop.name} id={comp?.id} compType={prop.componentType} label={prop.name} onClick={function (){

                }} onRemove={function (){
                    setProps(prop.name, '');
                }}  onDragLeave={function (){
                    dropProp = null;
                }} onDragEnter={function (){
                    dropProp = prop;
                }}/>);
            }
        }
    }

    return ( <div ref={drop} >
        <Button style={{marginLeft: '5px'}} shape={'circle'} onClick={()=>{
            dispatch(changeSelectScriptId(scriptAsset?.id));
        }}>
            <SettingFilled/>
        </Button>
        {valueElements}
    </div>);
}
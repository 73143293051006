import {DndProvider, useDrop} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import EditorManager from "../../../services/editorManager";
import {Button, Card, Image, Space, Typography} from "antd";
import PrefabAsset from "../../../game/scripts/asset/prefabAsset";
import {DragItem} from "react-arborist/dist/types";
import {DropResult} from "react-arborist/dist/dnd/drop-hook";
import {useDispatch} from "react-redux";
import Previewer from "../../../game/scripts/tools/editor/previewer";
import {useAppSelector} from "../../../app/hooks";
import {changeRefreshPrefabView, selectRefreshPrefabView} from "../../../store/refeshSlice";
import {changePrefabTagList, changeSelectPrefabIds, selectPrefabTagList} from "../../../store/editorSlice";
import TagListView from "./tagListView";
import './prefabView.css';
import {useEffect, useMemo} from "react";
import {treeManager} from "../hierarchy/hierarchy";
import LocalizationManager from "../../../services/localizationManager";

const {Text} = Typography;

export const prefabSelector : {
    select?: (id: string)=>void
    selectId : string,
}
    = {
    select : undefined,
    selectId: undefined,
}


export default function PrefabView() {
    const assetList = EditorManager.Instance.assetManager.getPrefabAssetList();
    const refresh = useAppSelector(selectRefreshPrefabView);

    const prefabTagList = [...useAppSelector(selectPrefabTagList)];
    const tags : {tag: {tag:string, color:string,hide: boolean }, list:PrefabAsset[]}[] = useMemo(()=>{
        const arr: {tag: {tag:string, color:string, hide: boolean }, list:PrefabAsset[]}[] = [];
        for(let i = 0; i < prefabTagList.length; i++) {
            arr.push({
                tag: prefabTagList[i],
                list: []
            });
        }

        for(let i = 0; i < assetList.length; i++) {
            const prefab = assetList[i];
            const tag = prefab.tag;
            let tagItem = arr.find( (item)=>item.tag.tag === tag );
            if(!tagItem) {
                tagItem = { tag:{tag:tag, color: '#000000', hide: false}, list:[] };
                arr.push(tagItem);
            }
            tagItem.list.push(prefab);
        }

        return arr;
    }, [prefabTagList]);

    const dispatch = useDispatch();

    useEffect(function (){
        prefabSelector.select = function (id: string) {
            prefabSelector.selectId = id;
            if(id) {
                dispatch(changeSelectPrefabIds([id]));
                treeManager.tree?.selectById(undefined);
            }
            else {
                dispatch(changeSelectPrefabIds([]));
            }
        }

        return function (){
            prefabSelector.select = null;
        }
    }, []);

    let render = [];
    for(let i = 0; i < tags.length; i++) {
        const tagData = tags[i];
        render.push( <TagListView
            key={tagData.tag.tag}
            tagData={tagData}
            idx={i}
            onColorChange={function (color){
                prefabTagList[i] = {
                    tag: tagData.tag.tag,
                    color,
                    hide: tagData.tag.hide,
                }
                dispatch(changePrefabTagList( [ ...prefabTagList ] ));
            }}
            onHideChange={function (){
                prefabTagList[i] = {
                    tag: tagData.tag.tag,
                    color : tagData.tag.color,
                    hide: !tagData.tag.hide,
                }
                dispatch(changePrefabTagList( [ ...prefabTagList ] ));
            }}
            onRemove={function (){
                for(let j = 0; j < assetList.length; j++) {
                    const prefab = assetList[j];
                    if(prefab.tag === tagData.tag.tag) {
                        prefab.tag = '';
                    }
                }
                prefabTagList.splice(i, 1);
                dispatch(changePrefabTagList( [ ...prefabTagList ] ));
            }}
            onMove={function(idx: number, dir: number){
                if((dir === 1 && idx < tags.length  - 1)
                    || (dir === -1 && idx > 1)) {
                    const temp = prefabTagList[idx];
                    prefabTagList[idx] = prefabTagList[idx + dir];
                    prefabTagList[idx + dir] = temp;
                    dispatch(changePrefabTagList( [ ...prefabTagList ] ));
                }
            }}
            onRename={function (){
                const newTag = prompt('변경할 태그이름 입력: ');
                if(newTag) {
                    if( !prefabTagList.find( tagItem => tagItem.tag === newTag.trim() )){

                        for(let j = 0; j < assetList.length; j++) {
                            const prefab = assetList[j];
                            if(prefab.tag === tagData.tag.tag) {
                                prefab.tag = newTag;
                            }
                        }

                        prefabTagList[i] = {
                            tag: newTag,
                            color: prefabTagList[i].color,
                            hide: prefabTagList[i].hide,
                        };
                        dispatch(changePrefabTagList( [ ...prefabTagList ] ))
                    }
                }
            }}
        ></TagListView> );
    }

    return (
        <div className={'prefabViewRoot scrollType2'}>
            <DndProvider backend={HTML5Backend}>
                <div style={{
                    marginTop: '10px',
                }}>
                    {render}
                    <Button onClick={function (){
                        const newTag = prompt(LocalizationManager.Instance.getText('Popup.addTag'));
                        if(newTag) {
                            if( !prefabTagList.find( tagItem => tagItem.tag === newTag.trim() )){
                                prefabTagList.push( {
                                    tag: newTag.trim(),
                                    color: '#000000',
                                    hide: false,
                                } );
                                dispatch(changePrefabTagList( [ ...prefabTagList ] ))
                            }
                        }

                    }}>태그 추가</Button>
                </div>
            </DndProvider>
        </div>)

}
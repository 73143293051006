import BaseFunction from "../baseFunction";
import {scriptTableData} from "../scriptTable";

export const SystemFunctionTable: scriptTableData[] = [
    {
        value: `system.pause()`,
        caption: `system.pause()`,
        score: 10,
        meta: 'function',
        name: 'system.pause',
        description: {
            ko: '게임을 일시정지 합니다.',
            en: '',
        },
    },
    {
        value: `system.resume()`,
        caption: `system.resume()`,
        score: 10,
        meta: 'function',
        name: 'system.resume',
        description: {
            ko: '일시정지된 게임을 다시 재개합니다.',
            en: '',
        },
    },
    {
        value: `system.isPause`,
        caption: `system.isPause`,
        score: 10,
        meta: 'getter',
        name: 'system.isPause',
        description: {
            ko: '게임 일시 정지 여부를 확인합니다.',
            en: '',
        },
    },
    {
        value: `system.width`,
        caption: `system.width`,
        score: 10,
        meta: 'getter',
        name: 'system.width',
        description: {
            ko: '게임의 가로 해상도 크기를 가져옵니다.',
            en: '',
        },
    },
    {
        value: `system.height`,
        caption: `system.height`,
        score: 10,
        meta: 'getter',
        name: 'system.height',
        description: {
            ko: '게임의 세로 해상도 크기를 가져옵니다.',
            en: '',
        },
    },
];

export default class SystemFunction extends BaseFunction {
    protected _name: string = 'System';
    public get instance(): SystemFunction {
        return this;
    }

    public pause() {
        this.gameObject.game.pause = true;
    }
    public resume() {
        this.gameObject.game.pause = false;
    }
    public get isPause(): boolean {
        return this.gameObject.game.pause;
    }
    public get width(): number {
        return this.gameObject.game.renderer.width;
    }
    public get height(): number {
        return this.gameObject.game.renderer.height;
    }
}
import {Button, Modal, Image, Space, Row, Col, Menu, Popconfirm} from 'antd';
import {useEffect, useState} from "react";
import {useAppSelector} from "../../app/hooks";
import {
    changeOnClickAsset, changeOpenSceneList,
    selectOnClickAsset, selectOpenFontList,
    selectOpenSceneList, changeOpenFontList, selectSelectAssetId, changeSelectAssetId
} from "../../store/modalSlice";
import {useDispatch} from "react-redux";
import EditorManager from "../../services/editorManager";
import {
    DeleteFilled,
    DeleteOutlined,
    FileOutlined
} from '@ant-design/icons';
import AssetItem from "./assetItem";
import * as React from "react";
import {
    BYTE_TO_MEGABYTE,
    UPLOAD_FONT_MAX_SIZE,
    UPLOAD_IMAGE_MAX_SIZE,
    UPLOAD_SOUND_MAX_SIZE
} from "../../services/constSetting";
import LocalizationManager from "../../services/localizationManager";

export default function FontList() {

    const isOpen = useAppSelector(selectOpenFontList);
    const selectId = useAppSelector(selectSelectAssetId);
    const onClickFunc = selectOnClickAsset();

    const dispatch = useDispatch();

    const handleOk = () => {
        dispatch(changeOpenFontList(false));
    };

    const handleCancel = () => {
        dispatch(changeOpenFontList(false));
    };

    const afterClose = () => {
        const onClickFunc = selectOnClickAsset()
        onClickFunc && onClickFunc(null);
        changeOnClickAsset(null);
        dispatch(changeSelectAssetId(''));
    }

    const addFont = ()=>{
        const input = document.createElement('input') as HTMLInputElement;
        input.type = 'file';
        input.accept = '.ttf';
        input.multiple = true;
        input.click();
        input.onchange = async ()=> {
            const file = input.files[0];
            if(file.size > UPLOAD_FONT_MAX_SIZE) {
                confirm( LocalizationManager.Instance.getText('Popup.failUploadFont', Math.round(UPLOAD_FONT_MAX_SIZE/BYTE_TO_MEGABYTE).toString()))
                return;
            }
            if(file) {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file);
                await new Promise<void>((resolve)=>{
                    fileReader.addEventListener('load', async ()=>{
                        resolve();
                    });
                });

                const fileName = file.name.split('.')[0];
                const newAsset = await EditorManager.Instance.addFont( fileName, fileReader.result as ArrayBuffer );
                assetList.push(newAsset);
                setAssetList([...assetList]);
            }
        }
    }

    const assetList = EditorManager.Instance.assetManager.getFontAssetList();
    const [initList, setAssetList] = useState(assetList);

    let listRender = [];
    for(let i = 0; i < assetList.length; i++) {
        const asset = assetList[i];
        listRender.push(<AssetItem
            key={asset.id}
            asset={asset}
            span={6}
            menu={<Menu items={[
                {
                    key: '1', label: <Popconfirm
                        title={LocalizationManager.Instance.getText('Popup.confirmRemove', asset.name)}
                        onConfirm={function (event){
                            event.preventDefault();
                            event.stopPropagation();
                            EditorManager.Instance.assetManager.removeAsset( asset );
                            const removeIdx = assetList.indexOf(asset);
                            assetList.splice( removeIdx, 1 );
                            setAssetList([...assetList]);
                        }}
                        onCancel={function (event){
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                        okText="제거"
                        cancelText="취소"
                    >
                        <div><DeleteFilled  style={{marginRight:'10px'}}/>
                            {LocalizationManager.Instance.getText('ContextMenu.remove') || 'Remove'}
                        </div>
                    </Popconfirm>
                }
            ]}></Menu>}
            select={selectId === asset.id}
            onClick={function (){
                if(onClickFunc){
                    onClickFunc(asset.id);
                }
            }} selectable={false}>
        </AssetItem>);
    }

    const blankLength = listRender.length % 4;
    if(blankLength) {
        for(let i = 0; i < 4- blankLength; i++) {
            listRender.push(<Col
                key={(listRender.length + i).toString()}
                className="gutter-row" span={6}/>)
        }
    }


    return( <Modal title={LocalizationManager.Instance.getText('AssetList.Font.title') || 'Font List'}
                   visible={isOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   afterClose={afterClose}
                   footer={[
                       <Button key="back" onClick={addFont}>
                           {LocalizationManager.Instance.getText('AssetList.Font.add') || 'Add Font'}
                       </Button>,
                   ]}
    >
        <Row gutter={[12,12]} justify={'center'}>
            {assetList.length ? listRender : <div>{LocalizationManager.Instance.getText('AssetList.Font.empty') || 'empty'}</div>}
        </Row>
    </Modal>)

}
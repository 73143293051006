import BaseFunction from "../baseFunction";
import Body from "../../component/body";
import Component from "../../core/component";

export default class ComponentFunction extends BaseFunction {
    protected _name: string = '';
    protected _temp: Component = null;
    public get temp(): Component {
        return this._temp;
    }
}
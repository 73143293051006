import {Spin} from "antd";
import React, {useEffect} from "react";
import {useAppSelector} from "../../app/hooks";
import {selectSpinText} from "../../store/editorSlice";

export default function EditorSpin() {
    const spinning = useAppSelector(selectSpinText);

    return <div className={'editorSpinRoot'} style={{display: !!spinning ? '' : 'none'}}>
        <Spin spinning={!!spinning} style={{maxHeight:'100%'}} wrapperClassName={'editorSpin'} tip={spinning} size={"large"}>
            { !!spinning? <div className={'spinBg'}></div> : null }
        </Spin>
    </div>
}
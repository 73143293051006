import BaseFunction, {ComponentFunctionError} from "../baseFunction";
import Sprite from "../../component/sprite";
import Animator from "../../component/animator";
import {scriptTableData} from "../scriptTable";
import ComponentFunction from "./componentFunction";
import ScriptError, {CheckComp, CheckValueType} from "../scriptError";


export const AnimatorFunctionTable: scriptTableData[] = [
    {
        value: `animator.play('animationName')`,
        caption: `animator.play(name: string, restart: boolean = false, callback: Function = null)`,
        score: 10,
        meta: 'function',
        name: 'animator.play',
        description: {
            ko: '애니메이션을 재생합니다.',
            en: '',
        },
    },
]

export default class AnimatorFunction extends ComponentFunction {
    protected _name: string = 'Animator';
    protected _temp: Animator = null;
    public get instance(): AnimatorFunction {
        this._temp = this.gameObject.getComponent(this._name);
        return this;
    }

    destroy() {
        super.destroy();
        this._temp = null;
    }

    play(name: string, restart: boolean= false, onComplete: Function = null) {
        if(!CheckComp(this,'play')) return;
        if(!this._temp.hasSprite()) {
            throw new ScriptError('컴포넌트 없음',
                'Animator 컴포넌트를 사용하기 위해서는 Sprite 컴포넌트가 필요합니다.',
                this.log.script?.script?.name, 'animator.play');
        }
        if(!CheckValueType(this, name, 'string', 'play')) return;
        if(!CheckValueType(this, restart, 'boolean', 'play')) return;
        if(!this._temp.getAnimation(name)) {
            throw new ScriptError('애니메이션 없음',
                `'${name}' 애니메이션을 찾을수 없습니다.`, this.log.script?.script?.name, 'animator.play');
        }

        const manager = this.manager;
        const func = function (){
            try {
                onComplete && onComplete();
            }
            catch (e) {
                manager.console._catch(e);
            }
        }

        this._temp.play(name, restart, func);
    }

}
import {IComponentConstructor} from "./component";

//@ts-ignore
const compFiles = require.context('./../component/', true, /\.ts$/);
const comps = compFiles.keys().reduce((comps: any[], compPath: string) => {
    const moduleName = compPath.replace(/^\.\/(.*)\.\w+$/, '$1');
    const value = compFiles(compPath);
    comps.push(value.default);
    return comps;
}, []);

export default class ComponentManager {

    private componentConstructs : { [name : string] : IComponentConstructor } = {};
    public get components() : { [name : string] : IComponentConstructor } {
        return this.componentConstructs;
    }

    constructor() {
        for(let i = 0; i < comps.length; i++) {
            const comp = comps[i];
            this.addComponent(comp);
        }
        // this.addComponent(Component);
        // this.addComponent(Sprite);
    }

    public addComponent(ctor : IComponentConstructor, name : string = undefined) {

        if(!ctor || !ctor.Name) {
            throw new Error(`올바르지 않은 컴포넌트 입니다.`);
        }

        if(!name) {
            name = ctor.Name;
        }

        if( this.componentConstructs[name] ) {
            throw new Error(`${name} 컴포넌트는 이미 추가되어 있습니다.`);
        }

        this.componentConstructs[name] = ctor;
    }


    public getComponent(name : string) : IComponentConstructor {
        // if(!this.componentConstructs[name]) {
        //     throw new Error(`${name} 컴포넌트를 찾을 수 없습니다.`);
        // }

        return this.componentConstructs[name];
    }

    public destroy() {
        this.componentConstructs = {};
    }
}
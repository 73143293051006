import EditorManager from "../../../../services/editorManager";
import {Col, Input, InputNumber, Row, Space, Typography} from "antd";
import {IComponentJson} from "../../../../game/scripts/core/component";
import {IGameObjectJson} from "../../../../game/scripts/gameObject/gameObject";
import {ReactElement} from "react";
import ViewTooltip from "../viewTooltip";
const { Text } = Typography;


export interface IPointViewProps {
    label: string,
    point: {x:number, y:number},
    disabled?: boolean,
    onChange: (x:number, y:number)=>void;
    tooltip?:string,
    onClick?: ()=>void,
    step?: number,
}

export default function PointView({label, point, step, disabled, onChange, tooltip, onClick}: IPointViewProps) {
    const localPoint = {x:point.x, y: point.y};
    return (<Row wrap={false}  className={"valueContainer"}>
        <Col className={'valueLabel'}>
            {
                tooltip? <ViewTooltip title={tooltip}>
                    <Text style={{marginRight: '5px'}}>{label}</Text>
                </ViewTooltip> :  <Text style={{marginRight: '5px'}}>{label}</Text>
            }
        </Col>
        <Col className={'valueBody'} flex={'auto'}>
            <Row wrap={false}>
                <Col style={{flex: '0 0 20px', display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={{color:'#f5222d'}}>X</Text>
                </Col>
                <Col flex={'auto'}>
                    <InputNumber step={step} style={{width:'100%'}} disabled={disabled} value={Number(point?.x?.toFixed(4))}  onChange={((num)=>{
                        localPoint.x = num;
                        onChange && onChange(localPoint.x, localPoint.y);
                    })} onClick={onClick}></InputNumber>
                </Col>
                <Col style={{flex: '0 0 20px', display: "flex", justifyContent: 'center', alignItems: 'center', marginLeft: '5px'}}>
                    <Text style={{color:'#a0d911'}}>Y</Text>
                </Col>
                <Col flex={'auto'}>
                    <InputNumber step={step} style={{width:'100%'}} disabled={disabled} value={Number(point?.y?.toFixed(4))}  onChange={((num)=>{
                        localPoint.y = num;
                        onChange && onChange(localPoint.x, localPoint.y);
                    })} onClick={onClick}></InputNumber>
                </Col>
            </Row>
        </Col>
    </Row>);
}
import {useEffect} from "react";

export default function Chatbot() {

    useEffect(function (){
        (function(d, s, id){
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s) as HTMLScriptElement;
            js.id = id;
            js.src = "https://frogue.danbee.ai/js/embed/v20220906/frogue-embed.min.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'frogue-embed'));
    }, []);

    return <div id="frogue-container" className="position-right-bottom"
                data-chatbot="18aef24f-9984-469a-9415-4d09c92a4b07"
                data-service-type=""
                data-user="사용자ID"
                data-init-key="value"
                data-close-use="Y" data-close-bgcolor="#ffffff" data-close-linecolor="#000000"
                data-move-use="Y" data-move-bgcolor="#ffffff" data-move-linecolor="#000000"
    ></div>
}
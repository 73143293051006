import {component, property} from "../util/decorator";
import Component, {IComponentJson} from "../core/component";
import * as PIXI from "pixi.js";
import AnimatorAsset, {IAnimationJson} from "../asset/animatorAsset";
import {TJsonRefers} from "../gameObject/gameObject";
import TextureAsset from "../asset/textureAsset";
import Sprite from "./sprite";

export interface IAnimatorJson extends IComponentJson {
    animator: string,
    startAnimation: string,
}

@component('Animator')
export default class Animator extends Component {

    @property(AnimatorAsset)
    private animator: AnimatorAsset;

    private startAnimation: string = '';

    private sprite: Sprite;
    private currentAnimation : IAnimationJson;
    private delay : number = 0;
    private frameIdx : number = 0;
    private finish: boolean = false;

    private completeCallback : Function = null;

    start() {
        this.sprite = this.gameObject.getComponent(Sprite.Name);

        if(this.startAnimation) {
            this.play( this.startAnimation );
        }
    }

    hasSprite(): boolean {
        if(this.sprite) return true;
        this.sprite = this.gameObject.getComponent(Sprite.Name);
        return !!this.sprite;
    }

    getAnimator(): AnimatorAsset {
        return this.animator;
    }

    getAnimation(name: string): IAnimationJson{
        const animations = this.animator?.json.animations;
        if( animations?.length ) {
            for(let i = 0; i < this.animator.json.animations.length; i++) {
                const ani = this.animator.json.animations[i];
                if(ani.name === name) {
                    return ani;
                }
            }
        }
    }

    play(name: string, restart: boolean = false, onComplete: Function = null) {

        if(!name || !this.sprite) {
            return;
        }

        const ani = this.getAnimation(name);
        if(ani) {
            this.playAnimation( ani, restart, onComplete );
        }

        // const animations = this.animator?.json.animations;
        // if( animations?.length && this.sprite ) {
        //     for(let i = 0; i < this.animator.json.animations.length; i++) {
        //         const ani = this.animator.json.animations[i];
        //         if(ani.name === name) {
        //
        //         }
        //     }
        // }
    }

    private playAnimation(ani: IAnimationJson, restart: boolean = false, onComplete: Function = null) {
        if(!restart && !this.finish && this.currentAnimation?.name === ani?.name) {
            return;
        }
        this.currentAnimation = ani;
        this.delay = 0;
        this.frameIdx = 0;
        this.finish = false;
        this.completeCallback = onComplete;
        this.updateFrame();
    }

    private updateFrame() {

        const uid = this.currentAnimation.frames[this.frameIdx];
        const tex = this.game.assetManager.getAsset(uid) as TextureAsset || null;
        this.sprite.texture = tex;
    }

    update(delta: number) {
        if(this.finish) {
            return;
        }

        this.delay += delta;
        if( this.currentAnimation?.interval <= this.delay ) {
            this.delay -= this.currentAnimation.interval;
            this.frameIdx++;
            if(this.frameIdx >= this.currentAnimation.frames.length) {
                if( this.currentAnimation.loop ) {
                    this.frameIdx = this.frameIdx % this.currentAnimation.frames.length;
                    this.updateFrame();
                }
                else {
                    this.finish = true;
                    const temp = this.completeCallback;
                    this.completeCallback = null;
                    temp && temp();
                }
            }
            else {
                this.updateFrame();
            }

        }
    }

    toJson(): IAnimatorJson {
        const json = super.toJson() as IAnimatorJson;
        if(this.animator) {
            json.animator = this.animator.id;
            json.startAnimation = this.startAnimation;
        }
        else {
            json.animator = null;
            json.startAnimation = '';
        }

        return json;
    }

    fromJson(json: IAnimatorJson, refers: TJsonRefers = {}) {
        super.fromJson(json, refers);
        if(json.animator) {
            const asset = this.game.assetManager.getAsset(json.animator) as AnimatorAsset;
            if(asset) {
                this.animator = asset;
                this.startAnimation = json.startAnimation || '';
                if(json.startAnimation && this.gameObject.getComponent(Sprite.Name)) {
                    const startAni = this.getAnimation(this.startAnimation);
                    if(startAni) {
                        const sprite = this.gameObject.getComponent(Sprite.Name) as Sprite;
                        const uid = startAni.frames[0];
                        const tex = this.game.assetManager.getAsset(uid) as TextureAsset || null;
                        sprite.texture = tex;
                    }
                }
            }
            else{
                this.animator = null;
                this.startAnimation = '';
            }
        }
        else {
            this.animator = null;
            this.startAnimation = '';
        }
    }

}